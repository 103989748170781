import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LayoutModal from "../../../../components/layouts/layout_modal";
import { useState } from "react";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import CustomSpinner from "../../../../components/customs/spinner";
import { postGalery } from "../../../../services/content/galery_service";

const GaleryCreate = ({
    accessToken,
    open,
    setOpen,
    setRefresh
}) => {

    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    const handleImageChange = (e) => {
        const files = Array.from(e.target.files || []);
        const newImages = files.map(file => ({
            file,
            url: URL.createObjectURL(file)
        }));
        setImages(prevImages => [...prevImages, ...newImages]);
    };

    const handleRemoveImage = (index) => {
        setImages(prevImages => prevImages.filter((_, i) => i !== index));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const formData = new FormData()
            if (images.length > 0) {
                images.forEach((image, index) => {
                    formData.append(`images[${index}]`, image.file)
                })
            }
            
            const response = await postGalery(accessToken, formData)

            if (response.status === 201) {
                setOpen(false)
                setRefresh(true)
            } else {
                alert("Gagal menambahkan galery..!")
            }
        } catch (error) {
            alert("Gagal menambahkan galery..!")
        }

        setLoading(false)

    }

    return (
        <LayoutModal open={open} setOpen={setOpen} name="Tambah Galery">
            <form onSubmit={handleSubmit}>
                <div className="p-3">
                    <div className="flex flex-col items-center justify-center mb-5">
                        <label htmlFor="file-upload" className="cursor-pointer px-3 py-2 flex flex-col items-center space-x-2 border-2  border-dashed rounded-xl w-full hover:border-custom-navy bg-gray-50">
                            <div className="flex items-center gap-3 font-semibold">
                                <FontAwesomeIcon icon={faUpload} />
                                <p>Upload Gambar</p>
                            </div>
                            <p className="text-sm text-gray-700">{images.length} gambar dipilih</p>
                        </label>
                        <input
                            id="file-upload"
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={handleImageChange}
                            className="mr-3 hidden"
                        />
                    </div>

                    <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-3 ">
                        {images.map((image, index) => (
                            <div key={index} className="relative border shadow-md rounded-lg overflow-hidden">
                                <img
                                    src={image.url}
                                    alt={`Preview ${index}`}
                                    className="w-full aspect-square object-cover"
                                />
                                <button
                                    type="button"
                                    onClick={() => handleRemoveImage(index)}
                                    className="absolute top-0 right-0 bg-red-500 hover:bg-red-600 text-white rounded-full aspect-square w-6"
                                >
                                    &times;
                                </button>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="flex items-center space-x-3 pt-5">
                    <button
                        type="button"
                        onClick={() => setOpen(false)}
                        className="bg-red-500 hover:bg-red-600 text-white w-full py-2 font-semibold rounded-full"
                    >
                        Kembali
                    </button>
                    <button
                        type="submit"
                        className="bg-custom-navy hover:bg-blue-950 text-white w-full py-2 font-semibold rounded-full flex items-center justify-center"
                    >
                        {loading === true ? (
                            <CustomSpinner addClass="bg-white" />
                        ) : (
                            " Simpan"
                        )}
                    </button>
                </div>
            </form>
        </LayoutModal>
    )
}

export default GaleryCreate;