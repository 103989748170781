import React, { useEffect, useState } from 'react';
import { getAccountBallances } from '../../../../../services/finance/account_service';
import { postProcurementPayment } from '../../../../../services/logistic/procurment_service';
import LayoutModal from '../../../../../components/layouts/layout_modal';
import SelectFilter from '../../../../../components/form/select_filter';
import InputDefault from '../../../../../components/form/input_default';
import CustomCurrency from '../../../../../components/customs/currency';
import BtnDefault from '../../../../../components/buttons/btn_default';
import CustomSpinner from '../../../../../components/customs/spinner';
import TextAreaDefault from '../../../../../components/form/text_area_default';


const ProcurementPayment = ({ 
    isOpen, setIsOpen, setRefresh, accessToken, remainingPayment, procurementId,
    setAlert, setTypeAlert, setMessageAlert
}) => {
    const [loading, setLoading] = useState(false)

    const [accountId, setAccountId] = useState('');
    const [nominal, setNominal] = useState('0');
    const [description, setDescription] = useState('')

    const [errorAccountId, setErrorAccountId] = useState(null)
    const [errorNominal, setErrorNominal] = useState(null)
    const [errorDescription, setErrorDescription] = useState(null)

    const [dataAccount, setDataAccount] = useState([])
    const fetchData = async () => {
        try {
            const response = await getAccountBallances({ accessToken })
            if (response.status === 200) {
                setDataAccount(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    };

    useEffect(() => {
        if (accessToken) {
            fetchData()
        }
    }, [accessToken])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {

            setErrorAccountId(null)
            setErrorNominal(null)
            setErrorDescription(null)

            const nominalValue = parseInt(nominal.replace(/[^0-9]/g, ""), 10);
            if (!accountId) {
                setErrorAccountId("Silahkan pilih kas / bank..!")
            } else if (!nominalValue || nominalValue === 0) {
                setErrorNominal("Silahkan masukkan nominal..!")
            } else if (nominalValue > remainingPayment) {
                setErrorNominal("Nominal tidak boleh lebih besar dari total pembayaran..!")
            } else if (!description) {
                setErrorDescription("Silahkan masukkan keterangan..!")
            } else {
                const formData = new FormData()
                formData.append('procurement_id', procurementId)
                formData.append('account_id', accountId)
                formData.append('nominal', nominalValue)
                formData.append('description', description)
                const response = await postProcurementPayment(accessToken, formData, procurementId)
                if (response.status === 201) {
                    setIsOpen && setIsOpen(false)
                    setRefresh && setRefresh(true)
                    setAlert && setAlert(true)
                    setTypeAlert && setTypeAlert('success')
                    setMessageAlert && setMessageAlert('Pembayaran berhasil disimpan..!')
                } else {
                    setAlert && setAlert(true)
                    setTypeAlert && setTypeAlert('error')
                    if ([400, 422, 403].includes(response.status)) {
                        setMessageAlert && setMessageAlert(response.data.message ?? 'gagal menyimpan data..!')
                    } else {
                        setMessageAlert && setMessageAlert('gagal menyimpan data..!')
                    }
                }
            }

        } catch (error) {
            setAlert && setAlert(true)
            setTypeAlert && setTypeAlert('error')
            setMessageAlert && setMessageAlert('Silahkan periksa koneksi internet Anda..!')
            console.log(error)
        }
        setLoading(false)
    }

    const formatRupiah = (number) => {
        return new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            maximumFractionDigits: 0,
        }).format(number);
    };

    const handleChange = (event) => {
        const value = event.target.value;
        const rawValue = parseInt(value.replace(/[^0-9]/g, ""), 10);
        const nominalValue = Math.max(rawValue || 0, 0); // Nominal tidak boleh kurang dari 0
        setNominal(formatRupiah(nominalValue));
    };

    const calculateChange = () => {
        const nominalValue = parseInt(nominal.replace(/[^0-9]/g, ""), 10) || 0;
        const remaining = remainingPayment ?? 0;
        const change = nominalValue - remaining;
        return Math.max(change, 0); // Kembalian tidak boleh kurang dari 0
    };


    return (
        <LayoutModal open={isOpen} setOpen={setIsOpen} name={'Pembayaran Pembelian'}>
            <form onSubmit={handleSubmit} className='flex flex-col gap-3'>
                <SelectFilter
                    label='Pilih akun kas / bank pembayaran'
                    placeholder='Pilih akun kas / bank'
                    options={dataAccount.map(account => ({ value: account.id, label: `(${account?.id}) ${account?.name}` }))}
                    value={accountId}
                    onSelect={(e) => setAccountId(e)}
                    errorMessage={errorAccountId}
                />

                <div className='flex items-center justify-between w-full text-xs md:text-sm'>
                    <div className='font-semibold w-[100px]'>Saldo Akun :</div>
                    <div className='px-3 py-2 border-2 rounded-full w-full text-right'>
                        <CustomCurrency value={dataAccount.find(account => account.id === accountId)?.ballance ?? 0} />
                    </div>
                </div>

                <div className='flex flex-col text-xs md:text-sm'>
                    <label htmlFor="" className='font-semibold'>Total yang harus dibayar</label>
                    <div className='px-3 py-2 border-2 rounded-full w-full text-right'>
                        <CustomCurrency value={remainingPayment ?? 0} />
                    </div>
                </div>

                <InputDefault
                    id="nominal"
                    type='text'
                    label="Nominal Pembayaran"
                    placeholder='Masukkan nominal pembayaran'
                    inputMode='numeric'
                    addClass="text-right"
                    value={nominal}
                    handleChange={(e) => handleChange(e)}
                    errorMessage={errorNominal}
                />

                <TextAreaDefault
                    id="description"
                    label="Keterangan"
                    value={description}
                    setValue={setDescription}
                    errorMessage={errorDescription}
                />

                <div className="w-full flex items-center gap-3 justify-evenly text-xs md:text-sm">
                    <BtnDefault type="button" handleClick={() => setIsOpen && setIsOpen(false)} addClass='bg-red-500 w-full text-white'>
                        Kembali
                    </BtnDefault>
                    <BtnDefault type="submit" addClass='w-full text-white'>
                        {loading === true ? <CustomSpinner addClass='' /> : "Bayar"}
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default ProcurementPayment