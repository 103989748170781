import ApiService from "../base/api_service"


export const getTestimonies = async ({ page }) => {
    try {
        const response = await ApiService.get(`/content/testimonies?page=${page}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}

export const showTestimony = async ({id}) => {
    try {
        const response = await ApiService.get(`/content/testimony/show/${id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}

export const postTestimony = async (accessToken, formData) => {
    try {
        const response = await ApiService.post(`/content/testimony/create`, formData, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}

export const updateTestimony = async (accessToken, formData, id) => {
    try {
        const response = await ApiService.post(`/content/testimony/update/${id}`, formData, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}

export const deleteTestimony = async ({accessToken, id}) => {
    try {
        const response = await ApiService.post(`/content/testimony/delete/${id}`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}