import ApiService from "../base/api_service"


export const getMaterials = async (accessToken, params) => {
    try {
        const response = await ApiService.get(`/materials`, {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}

export const showMaterial = async (accessToken, id) => {
    try {
        const response = await ApiService.get(`/material/show/${id}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}

export const updateMaterial = async (accessToken, formData, id) => {
    try {
        const response = await ApiService.post(`/material/update/${id}`, formData, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}