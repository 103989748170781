import { Link, useHref } from "react-router-dom";

const NavLink = ({name, link}) => {
    const currentPath = useHref()
    return (
        <Link
            to={link}
            className={`font-semibold border-b-2 hover:border-custom-dark-yellow transition-all duration-300 ${link === currentPath
                    ? "border-custom-dark-yellow"
                    : "border-custom-navy"
                }`}
        >
            {name}
        </Link>
    );
};

export default NavLink;
