const CustomSpinner = ({ addClass }) => {
    return (
        <div className="spinner-wave">
            {Array.from({ length: 4 }).map(() => (
                <div className={`spinner-wave-dot  ${addClass ?? "bg-custom-dark-yellow"}`} />
            ))}
        </div>
    )
}

export default CustomSpinner