import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../../components/layouts/layout_dashboard"
import BtnDefault from "../../../../components/buttons/btn_default"
import CustomHeader from "../../../../components/customs/header"
import BtnBack from "../../../../components/buttons/btn_back"
import { useNavigate, useParams } from "react-router-dom"
import { useState } from "react"
import { showMaterial } from "../../../../services/logistic/material_service"
import { useEffect } from "react"
import CustomCurrency from "../../../../components/customs/currency"
import CustomSpinner from "../../../../components/customs/spinner"
import FormatDateV2 from "../../../../components/format/date_v2"

const MaterialDetail = () => {
    const [accessToken, setAccessToken] = useState(null)
    const [userRole, setUserRole] = useState(null)
    const navigate = useNavigate()

    const { id } = useParams()
    const [loading, setLoading] = useState(false)

    const [data, setData] = useState(null)
    const [dataUsed, setDataUsed] = useState([])

    const fetchData = async (accessToken, id) => {
        setLoading(true)
        try {
            const response = await showMaterial(accessToken, id)

            if (response.status === 401) {
                navigate('/login')
            } else if (response.status === 403) {
                navigate('/dashboard')
            } else if (response.status === 200) {
                setData(response.data.data)
                setDataUsed(response.data.data_used)
            }
        } catch (error) {
            console.log("__")
        }

        setLoading(false)
    }

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token-terra_app')
        const userRole = localStorage.getItem('user_role-terra_app')

        if (!accessToken || !userRole) {
            navigate('/login')
        } else {
            setAccessToken(accessToken)
            setUserRole(userRole)
            fetchData(accessToken, id)
        }
    }, [])

    return (
        <>
            <Helmet>Detail Barang</Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack />
                    <p className="text-center">Detail Barang</p>
                </CustomHeader>


                <div className="flex flex-col gap-3 p-3">
                    <table>
                        <tr>
                            <td className="w-[150px] capitalize">Nama Supplier</td>
                            <td>:</td>
                            <td>{data?.suplier?.name}</td>
                        </tr>
                        <tr>
                            <td className="w-[150px] capitalize">Nama Barang</td>
                            <td>:</td>
                            <td>{data?.name}</td>
                        </tr>

                        <tr>
                            <td className="capitalize">Satuan</td>
                            <td>:</td>
                            <td>{data?.unit}</td>
                        </tr>
                        <tr>
                            <td>Harga</td>
                            <td>:</td>
                            <td><CustomCurrency value={data?.price} /></td>
                        </tr>

                    </table>
                </div>

            <div className="w-full pb-3 overflow-auto scrollbar-thin scrollbar-track-custom-dark-yellow scrollbar-thumb-custom-navy p-3">
                <table className={`max-w-screen-2xl text-xs md:text-sm ${loading || dataUsed?.length === 0 ? 'min-w-max md:min-w-full' : 'min-w-max md:min-w-full'}`}>
                    <thead className="bg-custom-dark-yellow">
                        <tr className="border-b-2 border-custom-dark-yellow uppercase text-sm text-custom-navy">
                            <th className="px-3 py-2 text-center w-fit rounded-tl-xl">No</th>
                            <th className="px-3 py-2 text-center w-fit">Tanggal</th>
                            <th className="px-3 py-2 text-center w-fit">Kode Pengadaan</th>
                            <th className="px-3 py-2 text-center w-fit">Tujuan Alokasi</th>
                            <th className="px-3 py-2 text-center w-fit">Harga</th>
                            <th className="px-3 py-2 text-center w-fit">Jumlah Alokasi</th>
                            <th className="px-3 py-2 text-end min-w-fit rounded-tr-xl">
                                Total Harga
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading
                                ? <tr>
                                    <td colSpan={10} className="py-2 flex items-center justify-center w-full">
                                        Loading...!
                                    </td>
                                </tr>
                                :

                                dataUsed.length === 0 ?
                                    "Belum ada data..!" :
                                    dataUsed?.map((item, index) => (
                                        <tr
                                            className={`${index % 2 === 1 ? "bg-gray-200" : ""
                                                }  hover:bg-gray-300 transition-colors`}
                                        >
                                            <td className="px-3 py-2 text-center">{index + 1}</td>
                                            <td className="px-3 py-2 text-center">
                                                <FormatDateV2 date={item?.date} />
                                            </td>
                                            <td className="px-3 py-2 capitalize">{item?.code}</td>
                                            <td className="px-3 py-2 capitalize">{item?.reference?.name}</td>
                                            <td className="px-3 py-2 text-end">
                                                <CustomCurrency value={item.price} />
                                            </td>
                                            <td className="px-3 py-2 text-center">
                                                {item.quantity} {item?.unit}
                                            </td>
                                            <td className="px-3 py-2 text-end">
                                                <CustomCurrency value={item.total_price} />
                                            </td>
                                        </tr>
                                    ))
                        }
                        <tr>
                            <td colSpan={10} className="py-4 bg-custom-dark-yellow" />
                        </tr>
                    </tbody>
                </table>
            </div>
        </LayoutDashboard >
        </>
    )
}

export default MaterialDetail