import { Helmet } from "react-helmet";
import LayoutAuth from "../../components/layouts/layout_auth";
import { Link, useNavigate } from "react-router-dom";
import LogoNavy from "../../assets/icons/only_logo_navy.png";
import TextLogoNavy from "../../assets/icons/only_text_logo_navy.png";
import { useState } from "react";
import { RegisterService } from "../../services/authenticate/auth_service";
import InputDefault from "../../components/form/input_default";
import InputPassword from "../../components/form/input_password";
import BtnDefault from "../../components/buttons/btn_default";
import CustomSpinner from "../../components/customs/spinner";
import CustomAlert from "../../components/customs/alert";

const Register = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const [loading, setLoading] = useState(false);

    const [errorName, setErrorName] = useState(null);
    const [errorPhoneNumber, setErrorPhoneNumber] = useState(null);
    const [errorEmail, setErrorEmail] = useState(null);
    const [errorPassword, setErrorPassword] = useState(null);
    const [errorPasswordConfirm, setErrorPasswordConfirm] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            setErrorName(null);
            setErrorPhoneNumber(null);
            setErrorEmail(null);
            setErrorPassword(null);
            setErrorPasswordConfirm(null);

            const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

            if (name.length < 3 || name.length === 0) {
                if (name.length === 0) {
                    setErrorName("Nama tidak boleh kosong..!");
                } else if (name.length < 3) {
                    setErrorName("Nama minimal terdiri dari 3 karakter..!");
                }
            } else if (phoneNumber.length > 0 && phoneNumber.length < 12) {
                setErrorPhoneNumber("No telepon harus terdiri dari 12-13 angka");
            } else if (!isValidEmail) {
                setErrorEmail("format email yang anda berikan salah..!");
                if (email.length === 0) {
                    setErrorEmail("email tidak boleh kosong..!");
                }
            } else if (password.length === 0) {
                setErrorPassword("password tidak boleh kosong..!");
            } else if (password.length < 8) {
                setErrorPassword("password minimal terdiri dari 8 karacter..!");
            } else if (!/[A-Z]/.test(password) || !/[a-z]/.test(password) || !/\d/.test(password) || !/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
                setErrorPassword("password harus mengandung huruf besar, huruf kecil, angka, dan simbol..!");
            } else if (passwordConfirm !== password) {
                setErrorPasswordConfirm("Password berbeda..!");
            } else {
                const formData = new FormData();
                formData.append("name", name);
                formData.append("phone_number", phoneNumber);
                formData.append("email", email);
                formData.append("password", password);
                formData.append("password_confirmation", passwordConfirm);

                const response = await RegisterService({formData});
                if (response.status === 200) {
                    navigate(`/verify-email?type=register&email=${email}`);
                } else {
                    let message;
                    if (response.data?.phone_number) {
                        message = "Nomor telepon sudah di gunakan..!";
                    } else if (response.data?.email) {
                        message = "Email telah digunaan..!";
                    } else {
                        message = "Pendaptaran gagal..!";
                    }
                    setMessageAlert(message)
                    setTypeAlert("error")
                    setOpenAlert(true)

                }
            }
        } catch (error) {
            alert("Silahkan cek koneksi internet Anda..!");
        }

        setLoading(false);
    };

    const [openAlert, setOpenAlert] = useState(false)
    const [messageAlert, setMessageAlert] = useState("")
    const [typeAlert, setTypeAlert] = useState("")
    return (
        <>
            <Helmet>
                <title>Daftar</title>
            </Helmet>
            <LayoutAuth name={`Daftar`}>
                <form onSubmit={handleSubmit} className="w-full h-fit max-h-full space-y-3 overflow-auto px-5 text-sm md:text-base scrollbar-thin scrollbar-thumb-custom-dark-yellow scrollbar-track-custom-navy">
                    <div className="hidden lg:flex items-center justify-center py-3 space-x-3">

                        <p className="font-bold text-3xl text-custom-navy uppercase">Daftar</p>
                        <div className="flex flex-col items-center justify-center">
                            <img src={LogoNavy} alt="Logo TERRA APP" className="w-[80px]" />
                            <img src={TextLogoNavy} alt="Logo TEXT TERRA APP" className="h-[30px] w-fit" />
                        </div>
                    </div>

                    <div className="space-y-3">
                        <InputDefault
                            label="Nama"
                            value={name}
                            handleChange={(e) => setName(e.target.value)}
                            inputMode="text"
                            placeholder="Masukkan nama lengkap"
                            errorMessage={errorName}
                        />

                        <InputDefault
                            type="number"
                            label="No Telepon"
                            value={phoneNumber}
                            handleChange={(e) => {
                                const value = e.target.value;
                                if (value.length < 13) {
                                    setPhoneNumber(value)
                                }
                            }}
                            inputMode="tel"
                            placeholder="Masukkan no telepon"
                            errorMessage={errorPhoneNumber}
                        />

                        <InputDefault
                            type="email"
                            label="Email"
                            value={email}
                            handleChange={(e) => setEmail(e.target.value)}
                            inputMode="email"
                            placeholder="Masukkan email"
                            errorMessage    ={errorEmail}
                        />
                        <InputPassword
                            label="Password"
                            value={password}
                            handleChange={(e) => setPassword(e.target.value)}
                            inputMode="text"
                            placeholder="Masukkan password"
                            errorMessage={errorPassword}
                        />
                        <InputPassword
                            label="Konfirmasi Password"
                            value={passwordConfirm}
                            handleChange={(e) => setPasswordConfirm(e.target.value)}
                            inputMode="text"
                            placeholder="Masukkan konfirmasi password"
                            errorMessage={errorPasswordConfirm}
                        />
                    </div>

                    <div className="flex items-center justify-center w-full pt-3">
                        <BtnDefault type="submit" width="w-full">
                            {loading ?
                                <CustomSpinner />
                                : "Daftar"}
                        </BtnDefault>
                    </div>

                    <div className="flex items-center justify-center space-x-3 pt-3 text-custom-navy">
                        <p>Sudah memiliki akun..?</p>
                        <Link to="/login" className="text-custom-navy font-semibold">
                            MASUK
                        </Link>
                    </div>

                    <div className="flex items-center justify-center space-x-3">
                        <Link to="/" className="text-custom-navy font-semibold">
                            Kembali ke halaman utama
                        </Link>
                    </div>
                </form>

                <CustomAlert 
                    open={openAlert} 
                    setOpen={setOpenAlert} 
                    message={messageAlert} 
                    type={typeAlert} 
                />
            </LayoutAuth>
        </>
    );
};

export default Register;
