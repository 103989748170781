import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { exportBigbook, getBigbook } from "../../../../services/finance/bigbook_service"
import LayoutDashboard from "../../../../components/layouts/layout_dashboard"
import { Helmet } from "react-helmet"
import BtnBack from "../../../../components/buttons/btn_back"
import SelectFilter from "../../../../components/form/select_filter"
import CustomHeader from "../../../../components/customs/header"
import FormatDate from "../../../../components/format/date"
import CustomCurrency from "../../../../components/customs/currency"
import { getAccount } from "../../../../services/finance/account_service"
import { months } from "../../../../components/utils/month_data"
import InputSearch from './../../../../components/form/input_search';
import CustomPaginate from "../../../../components/customs/paginate"
import BtnDefault from "../../../../components/buttons/btn_default"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileExcel, faSearch } from "@fortawesome/free-solid-svg-icons"
import SelectDefault from './../../../../components/form/select_default';
import InputDefault from './../../../../components/form/input_default';
import FormatDateV2 from './../../../../components/format/date_v2';
import CustomSpinner from "../../../../components/customs/spinner"

const BigBook = () => {
    const [accessToken, setAccessToken] = useState("")
    const [userRole, setUserRole] = useState("")
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [openPiutangCreate, setOpenCreate] = useState(false)

    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");

    const [data, setData] = useState([])
    const [account, setAccount] = useState(null)
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    let [from, setFrom] = useState(1)
    const [keyword, setKeyword] = useState("")
    const [debit, setDebit] = useState(0)
    const [credit, setCredit] = useState(0)
    const [balance, setBalance] = useState(0)
    const fetchData = async (accessToken, accountId, keyword, page) => {
        setLoading(true)
        try {
            const params = {
                account_id: accountId,
                year: year,
                month: month,
                day: day,
                keyword: keyword,
                page: page
            }
            const response = await getBigbook(accessToken, params)
            if (response.status === 200) {
                setData(response.data.data);
                setAccount(response.data.account)
                setTotalData(response.data.meta.total);
                setCurrentPage(response.data.meta.current_page);
                setLastPage(response.data.meta.last_page);
                setFrom(response.data.meta.from)
                setDebit(response.data.debit)
                setCredit(response.data.credit)
                setBalance(response.data.balance)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    const [accountId, setAccountId] = useState(null)

    const [dataAccount, setDataAccount] = useState([])
    const fetchDataAccount = async (accessToken, keyword, page) => {
        setLoading(true)
        try {
            const response = await getAccount(accessToken, keyword, page)
            if (response.status === 200) {
                setDataAccount(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        if (refresh) {
            fetchData(accessToken, accountId, keyword, currentPage)
            setRefresh(false)
        }
    }, [refresh])

    useEffect(() => {
        const getAccessToken = localStorage.getItem('access_token-terra_app')
        const getUserRole = localStorage.getItem('user_role-terra_app')
        setAccessToken(getAccessToken)
        fetchData(getAccessToken, accountId, keyword, currentPage)
        fetchDataAccount(getAccessToken, keyword, currentPage)
    }, [])

    let currentYear = new Date().getFullYear()
    const years = Array.from({ length: 20 }).map((_, index) => ({
        value: (currentYear - 10 + index).toString(),
        label: (currentYear - 10 + index).toString()
    }));

    const optionsExportCategory = [
        { "value": "tahunan", "label": "Tahunan" },
        { "value": "triwulan", "label": "Triwulan" },
        { "value": "bulanan", "label": "Bulanan" }
    ]

    const [exportCategory, setExportCategory] = useState("tahunan")
    const [triwulan, setTriwulan] = useState(1)


    const [loadingExport, setLoadingExport] = useState(false)
    const handleExport = async () => {
        setLoadingExport(true)
        try {
            const params = {
                account_id: accountId,
                year: year,
                month: month,
                day: day,
            }
            const response = await exportBigbook(accessToken, params)
            if (response.status === 200) {
                const generateRandomString = (length) => {
                    return Math.random().toString(36).substring(2, 2 + length);
                };
                const uniqid = Date.now() + '-' + generateRandomString(5);
                const fileName = `transaksi_${uniqid}.xlsx`;

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();

                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingExport(false)
    }

    return (
        <>
            <Helmet>
                <title>Buku Besar</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack />
                    <p className='text-center'>Buku Besar</p>
                    <div className="flex items-center justify-end gap-3">
                        <BtnDefault
                            type="submit"
                            handleClick={handleExport}
                        >
                            {loadingExport ?
                                <CustomSpinner /> :
                                <>
                                    <FontAwesomeIcon icon={faFileExcel} />
                                    <span>Export</span>
                                </>}
                        </BtnDefault>
                    </div>
                </CustomHeader>

                <div className="sticky left-0 z-10">
                    <div className="px-3 py-3 flex flex-col md:flex-row items-start justify-between gap-3">
                        <div className="w-full">
                            <table className={`font-semibold`}>
                                {loading ?
                                    Array.from({ length: 2 }).map(() => (
                                        <tr>
                                            {Array.from({ length: 2 }).map(() => (
                                                <td className="px-3 py-2 w-32">
                                                    <div className="w-full h-5 bg-gray-300 rounded-full" />
                                                </td>
                                            ))}
                                        </tr>
                                    )) :
                                    <>
                                        <tr>
                                            <td className="w-[80px] md:w-auto">ID</td>
                                            <td className="px-2">:</td>
                                            <td>{account?.id}</td>
                                        </tr>
                                        <tr>
                                            <td className="w-[80px] md:w-auto">Nama</td>
                                            <td className="px-2">:</td>
                                            <td className="capitalize">{account?.name}</td>
                                        </tr>
                                        <tr>
                                            <td className="w-[80px] md:w-auto">Kategori</td>
                                            <td className="px-2">:</td>
                                            <td className="capitalize">{account?.category?.name}</td>
                                        </tr>
                                    </>
                                }
                            </table>
                        </div>

                        <div>
                            <table className="font-semibold">
                                {loading ?
                                    Array.from({ length: 3 }).map(() => (
                                        <tr>
                                            {Array.from({ length: 2 }).map(() => (
                                                <td className="px-3 py-2 w-32">
                                                    <div className="w-full h-5 bg-gray-300 rounded-full" />
                                                </td>
                                            ))}
                                        </tr>
                                    )) :
                                    <>
                                        <tr>
                                            <td className="w-[80px] md:w-auto">Debit</td>
                                            <td className="px-2">:</td>
                                            <td><CustomCurrency value={debit} /></td>
                                        </tr>
                                        <tr>
                                            <td className="w-[80px] md:w-auto">Kredit</td>
                                            <td className="px-2">:</td>
                                            <td><CustomCurrency value={credit} /></td>
                                        </tr>
                                        <tr>
                                            <td className="w-[80px] md:w-auto">Balance</td>
                                            <td className="px-2">:</td>
                                            <td><CustomCurrency value={balance} /></td>
                                        </tr>
                                    </>
                                }
                            </table>
                        </div>

                    </div>

                    <div className="md:hidden w-full px-3 pb-1">
                        <form className="w-full relative flex items-center " onSubmit={(e) => {
                            e.preventDefault()
                            setCurrentPage(1)
                            fetchData(accessToken, accountId, keyword, 1)
                        }}>
                            <InputSearch
                                keyword={keyword}
                                handleSearch={(e) => {
                                    setKeyword(e.target.value)
                                    setCurrentPage(1)
                                    fetchData(accessToken, accountId, e.target.value, 1)
                                }}
                                placeholder="Cari..."
                                addClass="w-full py-2"
                            />

                            <button type='submit' className=' absolute right-[2px] bg-custom-dark-yellow text-custom-navy px-3 py-2 md:py-[2px] rounded-r-full'>
                                <FontAwesomeIcon icon={faSearch} />
                            </button>
                        </form>
                    </div>

                    <div className="w-full grid grid-cols-2 md:grid-cols-5 gap-3 px-3">
                        <form className='hidden md:flex items-center relative gap-2 w-full' onSubmit={(e) => {
                            e.preventDefault()
                            setCurrentPage(1)
                            fetchData(accessToken, accountId, keyword, 1)
                        }}>
                            <InputSearch
                                placeholder='Cari data transaksi'
                                value={keyword}
                                handleSearch={(e) => {
                                    setKeyword(e.target.value)
                                }}
                                addClass="w-full"
                            />
                            <button type='submit' className=' absolute right-[2px] bg-custom-dark-yellow text-custom-navy px-3 py-2 md:py-[2px] rounded-r-full'>
                                <FontAwesomeIcon icon={faSearch} />
                            </button>
                        </form>

                        <SelectFilter
                            name=''
                            placeholder='Pilih Akun'
                            addClass="w-44"
                            options={dataAccount.map(account => ({ value: account.id, label: `(${account?.id}) ${account?.name}` }))}
                            value={accountId}
                            onSelect={(e) => {
                                setAccountId(e)
                                setRefresh(true)
                            }}
                            widthContent="w-[300px] md:w-[350px]"
                            position=""
                        />

                        <SelectFilter
                            placeholder='Pilih Tahun'
                            options={years}
                            onSelect={(e) => {
                                setYear(e);
                                setMonth("")
                                setDay("")
                                setCurrentPage(1)
                                setRefresh(true)
                            }}
                            value={year}
                            plusOption={
                                <button
                                    onClick={() => {
                                        setYear("")
                                        setMonth("")
                                        setDay("")
                                        setCurrentPage(1)
                                        setRefresh(true)
                                    }}
                                    className="w-full py-2 hover:bg-gray-200 transition-colors border-b rounded-full">
                                    Pilih Hari
                                </button>
                            }
                        />

                        <SelectFilter
                            placeholder='Pilih bulan'
                            options={months.map(item => ({ value: item.id, label: item.name }))}
                            value={month}
                            plusOption={
                                <button
                                    onClick={() => {
                                        setMonth("")
                                        setDay("")
                                        setCurrentPage(1)
                                        setRefresh(true)
                                    }}
                                    className="w-full py-2 hover:bg-gray-200 transition-colors border-b rounded-full">
                                    Pilih Hari
                                </button>
                            }
                            onSelect={(e) => {
                                setMonth(e)
                                setDay("")
                                setCurrentPage(1)
                                setRefresh(true)
                            }}
                        // widthName="w-[200px]"
                        />

                        <SelectFilter
                            placeholder='Pilih Hari'
                            options={Array.from({ length: 31 }, (_, i) => ({
                                value: String(i + 1).padStart(2, '0'),
                                label: String(i + 1)
                            }))}
                            plusOption={
                                <button
                                    onClick={() => {
                                        setDay("")
                                        setCurrentPage(1)
                                        setRefresh(true)
                                    }}
                                    className="w-full py-2 hover:bg-gray-200 transition-colors border-b rounded-full">
                                    Pilih Hari
                                </button>
                            }
                            value={day}
                            onSelect={(e) => {
                                setDay(e);
                                setCurrentPage(1)
                                setRefresh(true)
                            }}
                        // widthName="w-[200px]"
                        />
                    </div>
                </div>

                <div className="p-3 text-xs md:text-sm">
                    <table className={`max-w-screen-2xl ${data?.length === 0 || loading === true ? 'min-w-max md:min-w-full' : 'min-w-max'}`}>
                        <thead className="bg-custom-dark-yellow text-custom-navy sticky top-[15px] ">
                            <tr className="">
                                <th className="px-3 py-2 rounded-tl-xl" >No</th>
                                <th className="px-3 py-2" >Tanggal</th>
                                <th className="px-3 py-2" >Kode Transaksi</th>
                                <th className="px-3 py-2" >Kategori Transaksi</th>
                                <th className="px-3 py-2" >Jenis Peruntukan</th>
                                <th className="px-3 py-2" >Nominal</th>
                                <th className="px-3 py-2 rounded-tr-xl">Keterangan</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? "Loading..!" :
                                    data?.length === 0 ?
                                        <tr className="">
                                            <td className="px-3 py-2" colSpan={20}>
                                                Tidak ada transaksi..!
                                            </td>
                                        </tr> :

                                        data.map((item, index) => (
                                            <tr className={`${index % 2 === 1 ? 'bg-gray-200' : ''} hover:bg-gray-400 transition-colors`}>
                                                <td className="px-3 py-2  text-center" >{from++}</td>
                                                <td className="px-3 py-2  " >
                                                    <FormatDateV2 date={item?.date} />
                                                </td>
                                                <td className="px-3 py-2  ">
                                                    {item?.code}
                                                </td>
                                                <td className="px-3 py-2  ">
                                                    {item?.category?.name}
                                                </td>
                                                <td className="px-3 py-2  ">
                                                    {item?.account_from?.id}  -
                                                    {item?.account_from?.name}
                                                </td>
                                                <td className="px-3 py-2  text-end">
                                                    <CustomCurrency value={item?.nominal} />
                                                </td>
                                                <td className="px-3 py-2  max-w-[500px]">
                                                    {item?.description}
                                                </td>
                                            </tr>
                                        ))}

                            {
                                lastPage < 2 && (
                                    <tr>
                                        <td className="px-3 py-4 bg-custom-dark-yellow" colSpan={20} />
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>

                {lastPage > 1 &&
                    <div className="sticky bottom-0 left-0 flex items-center justify-end w-full bg-custom-dark-yellow px-3 py-2">
                        <CustomPaginate
                            currentPage={currentPage} lastPage={lastPage}
                            handlePaginate={(e) => {
                                setCurrentPage(e)
                                fetchData(accessToken, accountId, year, month, day, keyword, e)
                            }} />
                    </div>
                }

                {/* <div className="fixed w-full h-screen bg-black/50 top-0 left-0 z-50 flex items-center justify-center">
                    <div className="w-full max-w-[500px] bg-white p-3 rounded-xl">
                        <SelectDefault
                            options={optionsExportCategory}
                            value={exportCategory}
                            onSelect={(e) => {
                                setExportCategory(e)
                            }}
                        />

                        <SelectDefault
                            options={optionsExportCategory}
                            value={exportCategory}
                            onSelect={(e) => {
                                setExportCategory(e)
                            }}
                        />

                        <InputDefault
                            placeholder="Tahun"
                            value={year}
                            onChange={(e) => {
                                setYear(e.target.value)
                            }}
                        />

                        <SelectDefault
                            options={months.map(item => ({ value: item.id, label: item.name }))}
                            value={month}
                            onSelect={(e) => {
                                setMonth(e)
                            }}
                        />
                    </div>
                </div> */}
            </LayoutDashboard>
        </>
    )
}

export default BigBook