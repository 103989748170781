import { useEffect, useState } from "react";
import SectionTitle from "./section_title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faStar,
  faStarHalf,
  faStarHalfStroke,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarReguler } from "@fortawesome/free-regular-svg-icons";
import { motion } from "framer-motion";
import CustomSpinner from "../../../components/customs/spinner";
import { BaseUrl } from "../../../services/base/base_url";

import CustomRating from "../../../components/customs/rating"  
import { getTestimonies } from "../../../services/content/testimony_service";

const TestimonySection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonies.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonies.length) % testimonies.length
    );
  };

  const [loading, setLoading] = useState(false);

  const [testimonies, setTestimonies] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getTestimonies({ page: 1 });
      if (response && response.status === 200) {
        setTestimonies(response.data.data);
      } else {
        setTestimonies([]);
      }
    } catch (error) {
      // alert("Silahkan periksa koneksi internet Anda..!");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="w-full  text-black bg-white px-3 md:px-5 lg:px-10">
      <SectionTitle
        title="Testimony"
        color={"text-black"}
        subTitle={
          <>
            <p>
              Apa Kata Mereka Tentang Kami..? <br></br>
              Dengar pengalaman langsung dari para penghuni yang telah merasakan
              kenyamanan tinggal di RENJANA PASOPATI
            </p>
          </>
        }
      />

      <div className="flex items-center justify-center py-5 md:py-10">
        {loading ? (
          <CustomSpinner addClass="" />
        ) : (
          testimonies.length > 0 && (
            <motion.div
              initial={{ scale: 0 }}
              whileInView={{ scale: 1 }}
              transition={{
                duration: 0.5,
                ease: "easeInOut",
              }}
              className="w-[90%] md:w-[80%] bg-custom-navy flex flex-col lg:flex-row items-center justify-center rounded-2xl gap-5 relative  p-10"
            >
              <button
                onClick={handlePrev}
                className="absolute -left-5 top-1/2 transform -translate-y-1/2 bg-custom-dark-yellow text-custom-navy p-2 h-10 aspect-square rounded-full"
              >
                <FontAwesomeIcon icon={faChevronLeft} className="text-2xl" />
              </button>

              <div className="h-[150px] lg:h-[300px] aspect-square rounded-full bg-blue-800 flex items-center justify-center overflow-hidden">
                {testimonies[currentIndex].image == null ? (
                  <FontAwesomeIcon
                    icon={faUserTie}
                    className="h-[50%] aspect-square text-white"
                  />
                ) : (
                  <img
                    src={
                      BaseUrl + "/storage/" + testimonies[currentIndex].image
                    }
                    alt="testimony"
                    className="h-full aspect-square object-cover"
                  />
                )}
              </div>

              <div className="w-full text-center lg:text-justify text-white">
                <p className=" text-sm md:text-base">
                  &quot;{testimonies[currentIndex].testimony}&quot;
                </p>
                <p className="pt-10 text-xl md:text-2xl font-bold text-white">
                  {testimonies[currentIndex].name}
                </p>
                <p className="font-bold text-sm md:text-base ">
                  {testimonies[currentIndex].city}
                </p>
                <div className="flex items-center flex-col md:flex-row justify-center md:justify-start md:gap-3">
                  <CustomRating rating={testimonies[currentIndex].rating ?? 5} />
                  <p className="font-bold text-custom-dark-yellow order-1 md:order-2">
                    ( {testimonies[currentIndex].rating ?? 0} )
                  </p>
                </div>
              </div>
              <button
                onClick={handleNext}
                className="absolute -right-5 top-1/2 transform -translate-y-1/2 bg-custom-dark-yellow text-custom-navy p-2 h-10 aspect-square rounded-full"
              >
                <FontAwesomeIcon icon={faChevronRight} className="text-2xl" />
              </button>
            </motion.div>
          )
        )}
      </div>
    </div>
  );
};

export default TestimonySection;
