import ApiService from "../base/api_service"


export const getProcurementPayments = async (accessToken, procurementId) => {
    try {
        const response = await ApiService.get(`/procurement/payments`, {
            params: {
                procurement_id: procurementId
            },
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}
