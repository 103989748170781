import { Helmet } from "react-helmet"
import LayoutAuth from "../../components/layouts/layout_auth"
import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { MailResendService, MailSendService, MailVerifyService } from "../../services/authenticate/mail_verification_service"
import CustomSpinner from "../../components/customs/spinner"
import CustomAlert from "../../components/customs/alert"
import InputDefault from "../../components/form/input_default"
import BtnDefault from "../../components/buttons/btn_default"
import LogoNavy from "../../assets/icons/only_logo_navy.png"
import TextLogoNavy from "../../assets/icons/only_text_logo_navy.png";
import { Link } from "react-router-dom"

const VerifyEmail = () => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const type = searchParams.get("type")
    const email = searchParams.get("email")

    const [loading, setLoading] = useState(false)

    const [code, setCode] = useState("")
    const [errorEmail, setErrorEmail] = useState(null)
    const [errorCode, setErrorCode] = useState(null)

    const [openAlert, setOpenAlert] = useState(false)
    const [messageAlert, setMessageAlert] = useState("")
    const [typeAlert, setTypeAlert] = useState("")
    const [redirect, setRedirect] = useState("")

    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const formData = new FormData()
            formData.append("category", type)
            formData.append("email", email)
            formData.append("code", code)

            const response = await MailVerifyService(formData)
            if (response.status === 200) {
                if (type === "forgot_password") {
                    navigate(`/create-new-password?email=${email}&code=${code}`)
                } else {
                    navigate("/login")
                }
            } else if (response.status === 422) {
                setMessageAlert(response.data.message ?? "Kode verifikasi salah")
                setTypeAlert("error")
                setOpenAlert(true)
            } else {
                setMessageAlert("Kode verifikasi salah")
                setTypeAlert("error")
                setOpenAlert(true)
            }
        } catch (error) {
            setMessageAlert("Kode verifikasi salah")
            setTypeAlert("error")
            setOpenAlert(true)
        } finally {
            setLoading(false)
        }
    }

    const [loadingResend, setLoadingResend] = useState(false)
    const handleResendCode = async () => {
        setLoadingResend(true)
        try {
            const formData = new FormData()
            formData.append("email", email)
            formData.append("category", type)

            const response = await MailResendService(formData)
            if (response.status === 200) {
                setMessageAlert("Kode verifikasi berhasil dikirim")
                setTypeAlert("success")
                setOpenAlert(true)
            }
        } catch (error) {

        } finally {
            setLoadingResend(false)
        }
    }
    return (
        <>
            <Helmet>
                <title>Verifikasi Email</title>
            </Helmet>
            <LayoutAuth>
                <form
                    onSubmit={handleSubmit}
                    className="w-full h-fit max-h-full space-y-3 overflow-auto px-5 text-sm md:text-base"
                >
                    <div className="hidden lg:flex items-center justify-center py-3 space-x-3">
                        <p className="font-bold text-3xl text-custom-navy uppercase">
                            Masuk
                        </p>
                        <div className="flex flex-col items-center justify-center">
                            <img src={LogoNavy} alt="Logo TERRA APP" className="w-[80px]" />
                            <img
                                src={TextLogoNavy}
                                alt="Logo TEXT TERRA APP"
                                className="h-[30px] w-fit"
                            />
                        </div>
                    </div>

                    <p className="text-center text-custom-navy font-semibold">
                        Silahkan masukkan email anda, dan kami akan mengirimkan kode verifikasi ke email Anda..!
                    </p>

                    <InputDefault
                        type="text"
                        // label="Kode Verifikasi"
                        placeholder="Masukkan kode verifikasi"
                        value={code}
                        handleChange={(e) => setCode(e.target.value)}
                        errorMessage={errorCode}
                        addClass="text-center"
                    />


                    <div className="pt-3">
                        <BtnDefault type="submit" addClass="w-full">
                            {loading === true ? (
                                <CustomSpinner />
                            ) : "Kirim kode verifikasi"}
                        </BtnDefault>
                    </div>

                    <div className="flex items-center justify-center space-x-3 text-custom-navy">
                        <p >
                            <span>Tidak menerima kode verifikasi..?</span>
                            <span className="px-3">
                                <button
                                    type="button"
                                    onClick={handleResendCode}
                                    className="text-custom-navy font-semibold"
                                >
                                    {loadingResend === true ? (
                                        <CustomSpinner addClass="bg-custom-navy"/>
                                    ) : "Kirim ulang kode verifikasi"}
                                </button>
                            </span>
                        </p>

                    </div>

                    <div className="flex items-center justify-center space-x-3 ">
                        <Link to="/" className="text-custom-navy font-semibold">
                            Kembali ke halaman utama
                        </Link>
                    </div>
                </form>

                <CustomAlert open={openAlert} setOpen={setOpenAlert} message={messageAlert} type={typeAlert} />
            </LayoutAuth>
        </>
    )
}

export default VerifyEmail