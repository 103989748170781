import { useEffect, useState } from "react";

const CheckInternetConnection = ({ isOnline, setIsOnline }) => {

    useEffect(() => {
        window.addEventListener("online", () => setIsOnline(true));
        window.addEventListener("offline", () => setIsOnline(false));
    }, []);

    return isOnline;
}

export default CheckInternetConnection;

