
import ApiService from '../base/api_service';


export const getUnits = async (accessToken, params) => {
    try {
        const response = await ApiService.get(`/units`, {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const showUnit = async (accessToken, id) => {
    try {
        const response = await ApiService.get(`/unit/show/${id}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const postUnit = async (accessToken, data) => {
    try {
        const response = await ApiService.post(`/unit/create`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const deleteUnit = async (accessToken, id) => {
    try {
        const response = await ApiService.post(`/unit/delete/${id}`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}   

export const updateUnit = async (accessToken, data, id) => {
    try {
        const response = await ApiService.post(`/unit/update/${id}`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const postUnitImage = async (accessToken, data) => {
    try {
        const response = await ApiService.post(`/unit/add-image`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const deleteUnitImage = async (accessToken, data) => {
    try {
        const response = await ApiService.post(`/unit/delete-image/`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}
