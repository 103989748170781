import CustomAccording from "../../../customs/according"
import LinkSidebar from "../../../links/link_sidebar"

const SidebarConsument = () => {
    return (
        <>
            <LinkSidebar link="/dashboard" name="Dashboard" />
        </>
    )
}

export default SidebarConsument

