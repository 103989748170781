import { useEffect, useState } from "react"
import LayoutDashboard from "../../../../components/layouts/layout_dashboard"
import PiutangCreate from "./create"
import { getDebiturCreditur } from "../../../../services/user_management/debitur_creditur_service"
import { useNavigate } from "react-router-dom"
import CustomCurrency from "../../../../components/customs/currency"
import BtnDefault from "../../../../components/buttons/btn_default"
import BtnBack from "../../../../components/buttons/btn_back"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import CustomHeader from "../../../../components/customs/header"
import CustomAlert from "../../../../components/customs/alert"
import { Helmet } from "react-helmet"

const Piutang = () => {
    const [accessToken, setAccessToken] = useState("")
    const [userRole, setUserRole] = useState("")
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [openPiutangCreate, setOpenCreate] = useState(false)

    const [data, setData] = useState([])
    const [debit, setDebit] = useState(0)
    const [credit, setCredit] = useState(0)
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [keyword, setKeyword] = useState("")
    const fetchData = async (accessToken, keyword, page) => {
        setLoading(true)
        try {
            const response = await getDebiturCreditur(accessToken, "piutang", keyword, page)
            if (response.status === 200) {
                setDebit(response.data.debit)
                setCredit(response.data.credit)
                setData(response.data.data);
                setTotalData(response.data.meta.total);
                setCurrentPage(response.data.meta.current_page);
                setLastPage(response.data.meta.last_page);
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        if (refresh) {
            fetchData(accessToken, keyword, currentPage)
        }
    }, [refresh])

    useEffect(() => {
        const getAccessToken = localStorage.getItem('access_token-terra_app')
        const getUserRole = localStorage.getItem('user_role-terra_app')

        setAccessToken(getAccessToken)
        fetchData(getAccessToken, keyword, currentPage)
    }, [])

    const MyTr = ({ name, value }) => {
        return (
            <tr>
                <td className="font-semibold capitalize">{name}</td>
                <td className="font-semibold px-2">:</td>
                <td>{value}</td>
            </tr>
        )
    }

    const [openAlert, setOpenAlert] = useState(false)
    const [messageAlert, setMessageAlert] = useState("")
    const [typeAlert, setTypeAlert] = useState("")

    return (
        <>
            <Helmet>
                <title>Data Piutang</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack />
                    <p className="text-center">Data Piutang</p>
                    <div className="flex items-center justify-end">
                        <BtnDefault handleClick={() => setOpenCreate(true)}>
                            <FontAwesomeIcon icon={faPlus} />
                            <p>Transaksi baru</p>
                        </BtnDefault>
                    </div>
                </CustomHeader>

                <div className="w-full flex items-center justify-between px-3 py-2">
                    <div>
                        <MyTr name="piutang"
                            value={
                                <CustomCurrency value={debit} />
                            }
                        />
                        <MyTr name="piutang dibayar"
                            value={
                                <CustomCurrency value={credit} />
                            }
                        />
                        <MyTr name="Total Tagihan"
                            value={
                                <p className="font-semibold"><CustomCurrency value={debit - credit} /></p>
                            }
                        />
                    </div>
                </div>

                <div className="w-ful px-3 py-2">
                    <table className={`max-w-screen-2xl ${loading === true || data.length === 0 ? "min-w-full" : "min-w-max md:min-w-full"}`}>
                        <thead className="bg-custom-dark-yellow text-custom-navy sticky top-0 ">
                            <tr className="">
                                <th className="px-3 py-2 rounded-tl-xl" >No</th>
                                <th className="px-3 py-2" >Id</th>
                                <th className="px-3 py-2   " >Nama</th>
                                <th className="px-3 py-2  " >Kategori</th>
                                <th className="px-3 py-2 rounded-tr-xl">Total Tagihan</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ?
                                Array.from({ length: 10 }).map(() => (
                                    <tr>
                                        {Array.from({ length: 5 }).map(() => (
                                            <td className="px-3 py-2">
                                                <div className="w-full h-5 bg-gray-300 animate-pulse rounded-full" />
                                            </td>
                                        ))}
                                    </tr>
                                ))
                                :
                                data?.length === 0 ? <p className="px-3">Belum ada transaksi..!</p> :
                                    data.map((item, index) => (
                                        <>
                                            <tr onClick={() => navigate(`detail/${item?.id}`)} className={`${index % 2 === 1 ? 'bg-gray-200' : ''} hover:bg-gray-400 transition-colors cursor-pointer`}>
                                                <td className="px-3 py-2  text-center" >{index + 1}</td>
                                                <td className="px-3 py-2  text-center">
                                                    {item?.id}
                                                </td>
                                                <td className="px-3 py-2  capitalize">
                                                    {item?.name}
                                                </td>
                                                <td className="px-3 py-2  text-center">
                                                    {item?.category?.name}
                                                </td>
                                                <td className="px-3 py-2  text-center">
                                                    <CustomCurrency value={item?.total_bill || 0} />
                                                </td>
                                            </tr>
                                        </>
                                    ))}
                                    <tr>
                                        <td colSpan={20} className="text-center px-3 py-5 bg-custom-dark-yellow"/>
                                    </tr>
                        </tbody>
                    </table>
                </div>
                <CustomAlert open={openAlert} setOpen={setOpenAlert} message={messageAlert} type={typeAlert} />
                <PiutangCreate
                    accessToken={accessToken}
                    open={openPiutangCreate} setOpen={setOpenCreate}
                    setRefresh={setRefresh}
                    setOpenAlert={setOpenAlert} setMessageAlert={setMessageAlert} setTypeAlert={setTypeAlert}
                />
            </LayoutDashboard>
        </>
    )
}

export default Piutang