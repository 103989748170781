

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PiutangPdf from "./pdf";
import { Helmet } from "react-helmet";
import LayoutDashboard from "../../../../components/layouts/layout_dashboard";
import CustomHeader from "../../../../components/customs/header";
import BtnBack from "../../../../components/buttons/btn_back";
import { showDebiturCreditur } from "../../../../services/user_management/debitur_creditur_service";
import { getUtang } from "../../../../services/finance/utang_service";
import UtangPdf from "./pdf";

const UtangPrint = () => {
    const [accessToken, setAccessToken] = useState("")
    const [userRole, setUserRole] = useState("")
    const navigate = useNavigate()
    const { id } = useParams()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [openUtangCreate, setOpenUtangCreate] = useState(false)

    const [debit, setDebit] = useState(0)
    const [credit, setCredit] = useState(0)
    const [data, setData] = useState([])
    const fetchData = async (accessToken) => {
        setLoading(true)
        try {
            const response = await getUtang(accessToken, id)
            if (response.status === 200) {
                setDebit(response.data.debit)
                setCredit(response.data.credit)
                setData(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    const [dataCreditur, setDataCreditur] = useState(null)
    const fetchDataCreditur = async (accessToken) => {
        setLoading(true)
        try {
            const response = await showDebiturCreditur(accessToken, id)
            if (response.status === 200) {
                setDataCreditur(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        const getAccessToken = localStorage.getItem('access_token-terra_app')
        const getUserRole = localStorage.getItem('user_role-terra_app')

        setAccessToken(getAccessToken)
        fetchData(getAccessToken)
        fetchDataCreditur(getAccessToken)
    }, [])

    const totalDebit = data.reduce((total, item) => {
        return item?.category?.name === 'utang dibayar' ? total + item.nominal : total; // Hitung total debit
    }, 0);

    const totalKredit = data.reduce((total, item) => {
        return item?.category?.name === 'utang' ? total + item.nominal : total; // Hitung total debit
    }, 0);
    
    return (
        <>
            <Helmet>
                <title>Utang Pdf</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack />
                    <p className="text-center uppercase">Utang Print Pdf</p>
                </CustomHeader>
                <div className="w-full h-full -mt-16 pt-16">
                    <UtangPdf
                        dataCreditur={dataCreditur} dataTransaction={data}
                        totalUtang={totalKredit} totalUtangDibayar={totalDebit}
                        sisaUtang={totalKredit - totalDebit}
                    />
                </div>
            </LayoutDashboard>
        </>
    )
}

export default UtangPrint