
import ApiService from '../base/api_service';

export const getUserRole = async (accessToken) => {
    try {
        const response = await ApiService.get(`/user-roles`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}
