import { Helmet } from "react-helmet"
import LayoutLP from "../../../../components/layouts/layout_lp"
import { useNavigate, useParams } from "react-router-dom"
import { useState } from "react"
import CardUnitDetail from "../../../../components/card/unit_detail"
import BtnBack from "../../../../components/buttons/btn_back"

const UnitDetailLp = () => {
    const [accessToken, setAccessToken] = useState('')
    const [userRole, setUserRole] = useState('')
    const navigate = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(false)

    return (
        <>
            <Helmet>
                <title>Detail UNIT</title>
            </Helmet>
            <LayoutLP>
                <div className="w-full h-16 flex items-center justify-between bg-custom-dark-yellow px-3 md:px-5">
                    <BtnBack link="/unit" />
                    <p className="text-center uppercase font-semibold">Detail Unit / Kavling</p>
                </div>
                <CardUnitDetail id={id} />
            </LayoutLP>
        </>
    )
}

export default UnitDetailLp