import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../components/layouts/layout_dashboard"
import CustomHeader from "../../../components/customs/header"
import BtnBack from "../../../components/buttons/btn_back"


const CreditureDetail = () => {
    return (
        <>
            <Helmet>Detail Kreditur / Debitur</Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack />
                    <p className="text-xl text-center font-semibold">Detail Kreditur / Debitur</p>
                    
                </CustomHeader>
            </LayoutDashboard>
        </>
    )
}

export default CreditureDetail