
import ApiService from '../base/api_service';
export const getUnitSelling = async ({accessToken, keyword, page, status, paymentStatus}) => {
    try {
        const response = await ApiService.get(`/unit-sellings`, {
            params: {
                page: page,
                keyword: keyword,
                status: status,
                payment_status: paymentStatus
            },
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const showUnitSelling = async (accessToken, id) => {
    try {
        const response = await ApiService.get(`/unit-selling/show/${id}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const postUnitSelling = async (accessToken, data) => {
    try {
        const response = await ApiService.post(`/unit-selling/create`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const updateUnitSelling = async (accessToken, data) => {
    try {
        const response = await ApiService.post(`/unit-selling/update`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const getUnitSellingChart = async (accessToken, year) => {
    try {
        const response = await ApiService.get(`/unit-selling/chart`, {
            params: {
                year: year
            },
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}
