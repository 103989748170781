import ApiService from "../base/api_service"

export const getProcurements = async (accessToken, params) => {
    try {
        const response = await ApiService.get('/procurements', {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}

export const showProcurement = async (accessToken, id) => {
    try {
        const response = await ApiService.get(`/procurement/show/${id}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}

export const postProcurement = async (accessToken, formData) => {
    try {
        const response = await ApiService.post('/procurement/create', formData, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}

export const postProcurementPayment = async (accessToken, formData, id) => {
    try {
        const response = await ApiService.post(`/procurement/payment/${id}`, formData, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}