import { Helmet } from 'react-helmet';
import LayoutDashboard from '../../../../components/layouts/layout_dashboard';
import CustomHeader from '../../../../components/customs/header';
import BtnBack from '../../../../components/buttons/btn_back';
import BtnDefault from '../../../../components/buttons/btn_default';
import { exportJurnal, getTransaction } from '../../../../services/finance/transaction_service';
import CustomCurrency from '../../../../components/customs/currency';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import SelectFilter from '../../../../components/form/select_filter';
import { months } from '../../../../components/utils/month_data';
import CustomPaginate from '../../../../components/customs/paginate';
import FormatDate from '../../../../components/format/date';
import InputSearch from '../../../../components/form/input_search';
import CustomSpinner from '../../../../components/customs/spinner';
import Procurement from './../../logistic/procurement/index';
const JurnalUmum = () => {
    const [accessToken, setAccessToken] = useState("")
    const [userRole, setUserRole] = useState("")
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [openPiutangCreate, setOpenCreate] = useState(false)

    const [year, setYear] = useState(null);
    const [month, setMonth] = useState(null);
    const [day, setDay] = useState(null);

    const [data, setData] = useState([])
    const [debit, setDebit] = useState(0)
    const [credit, setCredit] = useState(0)
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    let [from, setFrom] = useState(1)
    const [keyword, setKeyword] = useState("")
    const fetchData = async (accessToken, keyword, currentPage) => {
        setLoading(true)
        try {
            const params = {
                year: year,
                month: month,
                day: day,
                keyword: keyword,
                page: currentPage
            }

            const response = await getTransaction(accessToken, params)
            if (response.status === 200) {
                setDebit(response.data.debit)
                setCredit(response.data.credit)
                setData(response.data.data);
                setTotalData(response.data.meta.total);
                setCurrentPage(response.data.meta.current_page);
                setLastPage(response.data.meta.last_page);
                setFrom(response.data.meta.from);
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        const getAccessToken = localStorage.getItem('access_token-terra_app')
        const getUserRole = localStorage.getItem('user_role-terra_app')
        if (getAccessToken && getUserRole) {
            if (['super admin', 'admin', 'keuangan'].includes(getUserRole)) {
                setAccessToken(getAccessToken)
                fetchData(getAccessToken, keyword, currentPage)
            } else {
                navigate("/403")
            }
        } else {
            navigate("/login")
        }
    }, [])

    useEffect(() => {
        fetchData(accessToken, keyword, currentPage)
    }, [year, month, day,])

    const MyTr = ({ name, value }) => {
        return (
            <tr>
                <td className="font-semibold capitalize">{name}</td>
                <td className="font-semibold px-2">:</td>
                <td>{value}</td>
            </tr>
        )
    }

    let currentYear = new Date().getFullYear()
    const years = Array.from({ length: 20 }).map((_, index) => ({
        value: (currentYear - 10 + index).toString(),
        label: (currentYear - 10 + index).toString()
    }));

    const [loadingExport, setLoadingExport] = useState(false)
    const handleExport = async () => {
        setLoadingExport(true)
        try {
            const params = {
                year: year,
                month: month,
                day: day,
            }
            const response = await exportJurnal(accessToken, params)
            if (response.status === 200) {
                const generateRandomString = (length) => {
                    return Math.random().toString(36).substring(2, 2 + length);
                };
                const uniqid = Date.now() + '-' + generateRandomString(5);
                const fileName = `jurnal_umum_${uniqid}.xlsx`;

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();

                // Membersihkan elemen setelah selesai
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingExport(false)
    }

    return (
        <>
            <Helmet>
                <title>Jurnal Umum</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack />
                    <p className="text-center uppercase">Jurnal Umum</p>

                    <div className="flex items-center justify-end gap-2">
                        <BtnDefault handleClick={handleExport}>
                            {loadingExport ? <CustomSpinner /> :
                                <>
                                    <FontAwesomeIcon icon={faFileExcel} />
                                    <p className='hidden md:flex'>Export Data</p>
                                </>
                            }
                        </BtnDefault>
                    </div>
                </CustomHeader>

                <div className="w-full flex flex-col md:flex-row items-start md:items-center md:justify-between px-3 py-2 bg-white z-10 sticky top-16 left-0 gap-3">
                    <div className="">
                        <MyTr name="Total Pemasukan"
                            value={
                                <CustomCurrency value={debit} />
                            }
                        />
                        <MyTr name="Total Pengeuaran"
                            value={
                                <CustomCurrency value={credit} />
                            }
                        />
                        <MyTr name="Sisa Saldo"
                            value={
                                <p className="font-semibold"><CustomCurrency value={debit - credit} /></p>
                            }
                        />
                    </div>


                    <div className="flex flex-col items-center gap-2">
                        <div className='grid grid-cols-2 gap-2'>
                            <form className='flex items-center relative gap-2 w-full' onSubmit={(e) => {
                                e.preventDefault()
                                setCurrentPage(1)
                                fetchData(accessToken, keyword, 1)
                            }}>
                                <InputSearch
                                    placeholder='Cari data transaksi'
                                    value={keyword}
                                    handleSearch={(e) => {
                                        setKeyword(e.target.value)
                                    }}
                                    addClass="w-full"
                                />
                                <button type='submit' className=' absolute right-[2px] bg-custom-dark-yellow text-custom-navy px-3 py-2 md:py-[2px] rounded-r-full'>
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                            </form>

                            <SelectFilter
                                placeholder='Pilih Tahun'
                                options={years}
                                onSelect={(e) => {
                                    setYear(e);
                                    setMonth(null)
                                    setDay(null)
                                    setCurrentPage(1)
                                    fetchData(accessToken, keyword, 1);
                                }}
                                value={year}
                                plusOption={
                                    <button
                                        onClick={() => {
                                            setYear(null)
                                            setMonth(null)
                                            setDay(null)
                                            setCurrentPage(1)
                                            fetchData(accessToken, keyword, 1);
                                        }}
                                        className="w-full py-2 hover:bg-gray-200 transition-colors border-b rounded-full">
                                        Pilih Tahun
                                    </button>
                                }
                                widthName="w-full md:w-[200px]"
                            />
                        </div>

                        <div className='grid grid-cols-2 gap-3 w-full'>
                            <SelectFilter
                                placeholder='Pilih bulan'
                                options={months.map(item => ({ value: item.id, label: item.name }))}
                                value={month}
                                plusOption={
                                    <button
                                        onClick={() => {
                                            setMonth(null)
                                            setDay(null)
                                            setCurrentPage(1)
                                            fetchData(accessToken, keyword, 1);
                                        }}
                                        className="w-full py-2 hover:bg-gray-200 transition-colors border-b rounded-full">
                                        Pilih Bulan
                                    </button>
                                }
                                onSelect={(e) => {
                                    setMonth(e)
                                    setDay(null)
                                    setCurrentPage(1)
                                    fetchData(accessToken, keyword, 1);
                                }}
                                widthName="w-full md:w-[200px]"
                            />

                            <SelectFilter
                                placeholder='Pilih Hari'
                                options={Array.from({ length: 31 }, (_, i) => ({
                                    value: String(i + 1).padStart(2, '0'),
                                    label: String(i + 1)
                                }))}
                                plusOption={
                                    <button
                                        onClick={() => {
                                            setDay(null)
                                            setCurrentPage(1)
                                            fetchData(accessToken, keyword, 1);
                                        }}
                                        className="w-full py-2 hover:bg-gray-200 transition-colors border-b rounded-full">
                                        Pilih Hari
                                    </button>
                                }
                                value={day}
                                onSelect={(e) => {
                                    setDay(e);
                                    setCurrentPage(1)
                                    fetchData(accessToken, keyword, 1);
                                }}
                                widthName="w-full md:w-[200px]"
                            />
                        </div>
                    </div>
                </div>

                <div className='w-full text-xs md:text-sm px-3 z-0'>
                    <table className={`max-w-screen-2xl ${data?.length === 0 || loading === true ? 'min-w-max md:min-w-full' : 'min-w-max'}`}>
                        <thead className="bg-custom-dark-yellow text-custom-navy sticky top-[168px] z-0">
                            <tr className="">
                                <th className="px-3 py-2 rounded-tl-xl" >No</th>
                                <th className="px-3 py-2" >Tanggal</th>
                                <th className="px-3 py-2" >Kode Transaksi</th>
                                <th className="px-3 py-2" >Kategori Transaksi</th>
                                <th className="px-3 py-2" >Keterangan Transaksi</th>
                                <th className="px-3 py-2" >Nilai Transaksi</th>
                                <th className="px-3 py-2" >Debitur / Kreditur</th>
                                <th className="px-3 py-2" >Konsumen</th>
                                <th className="px-3 py-2" >Pengadaan</th>
                                <th className="px-3 py-2 rounded-tr-xl">Kavling</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? "Loading..!" :
                                    data?.length === 0 ?
                                        <tr className="">
                                            <td className="px-3 py-2" colSpan={20}>
                                                Tidak ada transaksi..!
                                            </td>
                                        </tr> :
                                        data.map((item, index) => (
                                            <>
                                                <tr className={`${index % 2 === 1 ? 'bg-gray-200' : ''} hover:bg-gray-400 transition-colors`}>
                                                    <td className="px-3 py-2  text-center" >{from++}</td>
                                                    <td className="px-3 py-2  " >
                                                        <FormatDate value={item?.created_at} />
                                                    </td>
                                                    <td className="px-3 py-2  ">
                                                        {item?.code}
                                                    </td>
                                                    <td className="px-3 py-2  ">
                                                        {item?.category?.name}
                                                    </td>
                                                    <td className="px-3 py-2  ">
                                                        <div>
                                                            <div className='border-b-2 border-gray-400'>
                                                                {item?.description}
                                                            </div>
                                                            <div className='flex gap-2 border-b-2 border-gray-400'>
                                                                {item?.account_from?.id}  -
                                                                {item?.account_from?.name}
                                                            </div>
                                                            <div className='flex gap-2'>
                                                                {item?.account_to?.id}  -
                                                                {item?.account_to?.name}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-3 py-2  text-end">
                                                        <CustomCurrency value={item?.nominal} />
                                                    </td>
                                                    <td className="px-3 py-2  max-w-[500px]">
                                                        {item?.debitur_creditur?.id}-
                                                        {item?.debitur_creditur?.name}
                                                    </td>
                                                    <td className="px-3 py-2  max-w-[500px]">
                                                        {item?.consument?.name ?? "-"}
                                                    </td>
                                                    <td className="px-3 py-2  max-w-[500px]">
                                                        {item?.procurement?.code ?? "-"}
                                                    </td>
                                                    <td className="px-3 py-2  max-w-[500px]">
                                                        {item?.unit?.name ?? "-"}
                                                    </td>
                                                </tr>
                                            </>
                                        ))
                            }
                            {
                                lastPage < 2 && (
                                    <tr>
                                        <td colSpan={20} className='py-4 bg-custom-dark-yellow' />
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>


                {
                    lastPage > 1 && (
                        <div className="flex items-center justify-center md:justify-end w-full bg-custom-dark-yellow px-3 py-1 sticky bottom-0 left-0">
                            <CustomPaginate
                                currentPage={currentPage} lastPage={lastPage}
                                handlePaginate={(e) => {
                                    setCurrentPage(e)
                                    fetchData(accessToken, keyword, e)
                                }} />
                        </div>
                    )
                }

            </LayoutDashboard >
        </>
    )
}

export default JurnalUmum