import ApiService from "../base/api_service"

export const RegisterService = async ({formData}) => {
    try {
        const response = await ApiService.post('/register', formData, {
            headers: {
                // Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}

export const LoginService = async ({formData}) => {
    try {
        const response = await ApiService.post(`/login`, formData, {
            headers: {
                // Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const LogoutService = async (accessToken) => {
    try {
        const response = await ApiService.post(`/logout`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const CreateNewPasswordService = async (formData) => {
    try {
        const response = await ApiService.post(`/create-new-password`, formData, {
            headers: {
                // Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}