import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../../components/layouts/layout_dashboard"
import CustomHeader from "../../../../components/customs/header"
import BtnBack from "../../../../components/buttons/btn_back"
import { showUsedMaterial } from "../../../../services/logistic/used_material_service"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import CustomCurrency from "../../../../components/customs/currency"
import FormatDate from "../../../../components/format/date"

const UsedMaterialDetail = () => {
    const [accessToken, setAccessToken] = useState('')
    const [userRole, setUserRole] = useState('')
    const navigate = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [data, setData] = useState(null)
    const fetchData = async (accessToken) => {
        try {
            const params = {
                accessToken: accessToken,
            }
            const response = await showUsedMaterial(accessToken, id, params)
            if (response.status === 200) {
                setData(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token-terra_app')
        const userRole = localStorage.getItem('role-terra_app')
        if (!accessToken) {
            navigate('/login')
        } else {
            setAccessToken(accessToken)
            setUserRole(userRole)
            fetchData(accessToken)
        }
    }, [])

    const CustomRow = ({ name, value }) => {
        return (
            <tr>
                <td className="py-1 capitalize">{name}</td>
                <td className="px-3 py-1">:</td>
                <td className="py-1">{value}</td>
            </tr>
        )
    }

    return (
        <>
            <Helmet>
                <title>Detail Penggunaan Barang</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack />
                    <p className="text-center">Detail Alokasi Terpakai</p>
                </CustomHeader>

                <div className="w-full px-3 pt-3">
                    <CustomRow name="Tanggal" value={<FormatDate value={data?.created_at} />} />
                    <CustomRow name="Kode Penggunaan" value={data?.code} />
                    <CustomRow name="Kategori" value={data?.category ?? '-'} />
                    <CustomRow name="Alokasi Penggunaan" value={data?.reference?.name ?? '-'} />
                    <CustomRow name="Dibuat Oleh" value={data?.created_by?.name ?? '-'} />
                    <CustomRow name="Jumlah Item" value={data?.items?.length} />
                </div>

                <div className="w-full pb-3 overflow-auto scrollbar-thin scrollbar-track-custom-dark-yellow scrollbar-thumb-custom-navy p-3">
                    <table className={`max-w-screen-2xl text-xs md:text-sm ${loading || data?.items?.length === 0 ? 'min-w-full' : 'min-w-max md:min-w-full'}`}>
                        <thead className="bg-custom-dark-yellow">
                            <tr className="border-b-2 border-custom-dark-yellow uppercase text-sm text-custom-navy">
                                <th className="px-3 py-2 text-center w-fit rounded-tl-xl">No</th>
                                <th className="px-3 py-2 text-center w-fit">Nama Suplayer</th>
                                <th className="px-3 py-2 text-center">Nama Barang</th>
                                <th className="px-3 py-2 text-center w-fit">Harga</th>
                                <th className="px-3 py-2 text-center w-fit">Jumlah Alokasi</th>
                                <th className="px-3 py-2 text-end min-w-fit rounded-tr-xl">
                                    Total Harga
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading
                                    ? Array.from({ length: 5 }).map(() => (
                                        <tr className="animate-pulse">
                                            {Array.from({ length: 7 }).map(() => (
                                                <td className="px-3 py-2">
                                                    <div className="w-full h-5 rounded-full bg-gray-300"></div>
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                    : data && data?.items?.map((item, index) => (
                                        <tr
                                            className={`${index % 2 === 1 ? "bg-gray-200" : ""
                                                }  hover:bg-gray-300 transition-colors`}
                                        >
                                            <td className="px-3 py-2 text-center">{index + 1}</td>
                                            <td className="px-3 py-2 capitalize">{item?.material?.suplier?.name}</td>
                                            <td className="px-3 py-2 capitalize">{item?.material?.name}</td>
                                            <td className="px-3 py-2 text-end">
                                                <CustomCurrency value={item.price} />
                                            </td>
                                            <td className="px-3 py-2 text-center">
                                                {item.quantity} {item?.material?.unit}
                                            </td>
                                            <td className="px-3 py-2 text-end">
                                                <CustomCurrency value={item.total_price} />
                                            </td>
                                        </tr>
                                    ))
                            }

                            <tr className="bg-custom-dark-yellow">
                                <td className="px-3 py-5" colSpan={11}></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </LayoutDashboard>
        </>
    )
}

export default UsedMaterialDetail
