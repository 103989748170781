import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import BtnDefault from '../buttons/btn_default';
import CustomSpinner from './spinner';

const CustomConfirm = ({
    open, setOpen,
    loading,
    handleConfirm,
    message,
    type
}) => {
    return (
        <div className={`w-full h-screen absolute top-0 left-0 z-[10000]  bg-transparent flex items-center justify-center transition-all duration-500 ${open ? ' opacity-100 scale-100 rounded-none ' : ' opacity-0 scale-0 rounded-full'}`}>
            <div className={` bg-white backdrop-blur-sm rounded-2xl flex flex-col items-center justify-center  max-w-[90%] max-h-[90%]  ${open ? 'scale-100 ' : 'scale-0 delay-100'} overflow-hidden transition-all duration-500 shadow-2xl shadow-black/50 border px-3 py-5`}>
                <FontAwesomeIcon icon={faExclamationTriangle} className='w-16 h-16 text-red-500' />
                <p className='text-sm md:text-base font-semibold text-center pt-3'>
                    {message}
                </p>
                <div className='flex justify-center space-x-3 mt-4 w-full border-t-2 pt-3'>
                    <BtnDefault handleClick={() => setOpen(false)} width="w-[150px]" addClass="bg-red-500 hover:bg-red-600 text-white">
                        Tidak
                    </BtnDefault>
                    <BtnDefault handleClick={handleConfirm} width="w-[150px]" addClass="text-white">
                        {loading ? <CustomSpinner /> : 'Ya'}
                    </BtnDefault>
                </div>
            </div>
        </div>
    )
}

export default CustomConfirm