import CustomCurrency from "../../../../components/customs/currency";

const TableRowCashflow = ({name, loading, data, navigate, currentYear }) => {
    return (
        <>
            <tr className="bg-yellow-300 sticky top-[100px] md:top-[113px] z-10">
                <td></td>
                <td colSpan={30} className="px-3 py-2 font-semibold sticky left-0 ">{name}</td>
            </tr>

            {loading ?
                Array.from({ length: 10 }).map(() => (
                    <tr>
                        {Array.from({ length: 27 }).map(() => (
                            <td className="px-3 py-2">
                                <div className="w-full h-5 bg-gray-300 animate-pulse rounded-full" />
                            </td>
                        ))}
                    </tr>
                ))
                :
                data?.length === 0 ? <p className="px-3">Belum ada transaksi..!</p> :
                    data.map((item, index) => (
                        <>
                            <tr onClick={() => navigate(`detail/${item?.id}?year=${currentYear}`)} className={`${index % 2 === 1 ? 'bg-gray-200' : ''} hover:bg-gray-300 transition-colors cursor-pointer group`}>
                                <td className="px-3 py-2 sticky z-0 text-center" >{index + 1}</td>
                                <td
                                    className={`px-3 py-2 md:sticky left-0  capitalize  ${index % 2 === 1 ? 'bg-gray-200' : 'bg-white'} group-hover:bg-gray-300 transition-colors`}>
                                    {item?.id}-{item?.name}
                                </td>
                                {Array.from({ length: 12 }, (_, i) => {
                                    const monthKey = new Date(0, i).toLocaleString('default', { month: 'long' }).toLowerCase(); // Mendapatkan nama bulan dalam format lowercase
                                    return (

                                        <td key={`${item.id}-actual-${monthKey}`} className={`px-3 py-2 text-center font-semibold  ${index % 2 === 1 ? '' : ''} group-hover:bg-gray-300 transition-colors`}>
                                            <CustomCurrency value={item?.[monthKey]?.actual} />
                                        </td>
                                    );
                                })}
                            </tr>
                        </>
                    ))}

                    <tr className="bg-green-300">
                        <td></td>
                        <td className="px-3 py-2 sticky left-0 bg-green-300 font-semibold">Sub Total</td>
                        {data.reduce((acc, item) => {
                            // Menghitung subtotal untuk setiap bulan
                            for (let i = 0; i < 12; i++) {
                                const monthKey = new Date(0, i).toLocaleString('default', { month: 'long' }).toLowerCase();
                                acc[i] = (acc[i] || 0) + (item?.[monthKey]?.actual || 0);
                            }
                            return acc;
                        }, []).map((subtotal, i) => (
                            <td key={`subtotal-${i}`} className={`px-3 py-2 text-center font-semibold group-hover:bg-gray-300 transition-colors`}>
                                <CustomCurrency value={subtotal} />
                            </td>
                        ))}
                    </tr>
        </>
    )
}

export default TableRowCashflow