import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlus,
  faTrash,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteTestimony, getTestimonies } from '../../../../services/content/testimony_service';
import { Helmet } from "react-helmet";
import LayoutDashboard from "../../../../components/layouts/layout_dashboard";
import CustomHeader from "../../../../components/customs/header";
import BtnDefault from "../../../../components/buttons/btn_default";
import { BaseUrl } from "../../../../services/base/base_url";
import CustomRating from "../../../../components/customs/rating";
import TestimonyCreate from "./create";
import CustomConfirm from "../../../../components/customs/confirm";
import CustomAlert from "../../../../components/customs/alert";
import TestimonyUpdate from "./update";

const Testimony = () => {
  const [accessToken, setAccessToken] = useState('')
  const [userRole, setUserRole] = useState('')
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);


  const [id, setId] = useState(null);
  const [image, setImage] = useState(null);
  const [name, setName] = useState(null);
  const [city, setCity] = useState(null);
  const [rating, setRating] = useState(null);
  const [testimony, setTestimony] = useState(null);



  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const [data, setData] = useState([]);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getTestimonies({ page: 1 });
      if (response && response.status === 200) {
        setData(response.data.data);
      }
    } catch (error) {
      alert("Silahkan periksa koneksi internet Anda..!");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (refresh) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    try {
      const newAccessToken = localStorage.getItem('access_token-terra_app') ?? ''
      const newUserRole = localStorage.getItem('user_role-terra_app') ?? ''
      if (!newAccessToken) {
        navigate('/login')
      } else if (!['super admin', 'admin', 'lapangan', 'keuangan'].includes(newUserRole)) {
        navigate('/403')
      } else {
        setAccessToken(newAccessToken)
        setUserRole(newUserRole)
        fetchData()
      }
    } catch (error) {
      alert('Silahkan periksa koneksi internet Anda')
    }
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault()
    setLoadingDelete(true);
    try {
      const response = await deleteTestimony({accessToken:accessToken, id: deleteId ?? ''});
      if (response.status === 200) {
        setRefresh(true);
        setShowConfirm(false);
      } else {
        setShowConfirm(false);
        alert("Data gagal di hapus..!");
      }
    } catch (error) {
      alert("Silahkan periksa koneksi internet Anda..!");
    }
    setLoadingDelete(false);
  };

  const [showAlert, setShowAlert] = useState(false)
  const [typeAlert, setTypeAlert] = useState('')
  const [messageAlert, setMessageAlert] = useState('')

  const [dataUpdate, setDataUpdate] = useState(null)
  const [showUpdate, setShowUpdate] = useState(false)

  return (
    <>
      <Helmet>Testimony - TERRA APP</Helmet>
      <LayoutDashboard  >
        <CustomHeader>
          <div className="hidden md:flex"></div>
          <p className="md:text-center">Testiony</p>
          <div className="flex items-center justify-end">
            <BtnDefault handleClick={() => { setOpenModalAdd(true) }}>
              <FontAwesomeIcon icon={faPlus} />
              <p className="hidden md:flex">Testimony Baru</p>
            </BtnDefault>
          </div>
        </CustomHeader>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 text-white p-3">
          {loading
            ? Array.from({ length: 20 }).map(() => "Loading")
            : data.map((item, index) => (
              <div
                key={index}
                className="bg-custom-navy p-3 rounded-md relative group"
              >
                <div className="flex items-center gap-3 absolute top-2 right-2 transition-all duration-300 scale-0 group-hover:scale-100">
                  <button
                    onClick={() => {
                      setDataUpdate(item)
                      setShowUpdate(true)
                    }}
                    className=" bg-yellow-500 text-white rounded p-1 w-[40px] hover:scale-105 transition-all duration-300"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button
                    onClick={() => {
                      setShowConfirm(true);
                      setDeleteId(item.id ?? '');
                    }}
                    className=" bg-red-500 text-white rounded p-1 w-[40px] hover:scale-105 transition-all duration-300"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>

                <div className="flex items-center gap-3 pb-3">
                  <div className="w-[120px] aspect-square object-cover rounded-full overflow-hidden bg-blue-900 flex items-center justify-center">
                    {!item.image ? (
                      <FontAwesomeIcon
                        icon={faUserTie}
                        className="h-[50%] aspect-square text-white"
                      />
                    ) : (
                      <img
                        src={BaseUrl + "/storage/" + item.image}
                        alt="Testimony"
                        loading="lazy"
                        className="w-full aspect-square object-cover rounded-full"
                      />
                    )}
                  </div>
                  <div>
                    <p className="font-semibold md:text-xl">{item.name}</p>
                    <p className="font-semibold text-sm md:text-base">
                      {item.city}
                    </p>
                    <div className="flex items-center pt-3">
                      <CustomRating rating={item.rating ?? 0} />
                      <span className="ml-2">( {item?.rating} )</span>
                    </div>
                  </div>
                </div>
                <p className="text-center">"{item.testimony}"</p>
              </div>
            ))}
        </div>

        {/* Modal */}
        <TestimonyCreate 
          open={openModalAdd} 
          setOpen={setOpenModalAdd} 
          setRefresh={setRefresh} 
          accessToken={accessToken}
          setAlert={setShowAlert} 
          typeAlert={setTypeAlert} 
          messageAlert={setMessageAlert}
        />

        <TestimonyUpdate
          setRefresh={setRefresh}
          open={showUpdate}
          setOpen={setShowUpdate}
          getData={dataUpdate}
          accessToken={accessToken}
          setAlert={setShowAlert}
          setTypeAlert={setTypeAlert}
          setMessageAlert={setMessageAlert}
        />

        <CustomConfirm
          open={showConfirm}
          setOpen={setShowConfirm}
          handleConfirm={handleDelete}
          loading={loadingDelete}
          message={"Apakah anda yakin ingin menghapus data ini?"}
        />

        <CustomAlert
          open={showAlert}
          setOpen={setShowAlert}
          type={typeAlert}
          message={messageAlert}
        />

      </LayoutDashboard>
    </>
  );
};

export default Testimony;
