
import { Link, useHref} from "react-router-dom";

const LinkSidebar = ({link, name}) => {
    const router = useHref()

    return (
        <Link to={link ?? '/404'} className={`w-full text-center text-sm border-2 border-custom-dark-yellow px-3 py-1 font-semibold hover:bg-custom-dark-yellow hover:text-black rounded-full transition-colors ${link === '/dashboard' && router.pathname === '/dashboard' || link !== '/dashboard' && router.startsWith(link || "/404") ? 'bg-custom-dark-yellow text-black' : 'bg-custom-navy text-custom-dark-yellow'}`}>
            {name} 
        </Link>
    )
}

export default LinkSidebar