import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteAdvantage, getAdvantages } from "../../../../services/content/advantage_service";
import { Helmet } from "react-helmet";
import LayoutDashboard from "../../../../components/layouts/layout_dashboard";
import CustomHeader from "../../../../components/customs/header";
import BtnDefault from "../../../../components/buttons/btn_default";
import AdvantageCreate from "./create";
import CustomAlert from "../../../../components/customs/alert";
import AdvantageUpdate from "./update";
import CustomConfirm from "../../../../components/customs/confirm";

const Advantage = () => {
  const [accessToken, setAccessToken] = useState('')
  const [userRole, setUserRole] = useState('')
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [data, setData] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getAdvantages();
      if (response && response.status === 200) {
        setData(response.data.data);
      }
    } catch (error) {
      alert("Silahkan periksa koneksi internet Anda..!");
    }
    setLoading(false);
  };

  useEffect(() => {

    if (refresh === true) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    try {
      const newAccessToken = localStorage.getItem('access_token-terra_app') ?? ''
      const newUserRole = localStorage.getItem('user_role-terra_app') ?? ''
      if (!newAccessToken) {
        navigate('/login')
      } else if (!['super admin', 'admin', 'lapangan', 'keuangan'].includes(newUserRole)) {
        navigate('/403')
      } else {
        setAccessToken(newAccessToken)
        setUserRole(newUserRole)
        fetchData()
      }
    } catch (error) {
      alert('Silahkan periksa koneksi internet Anda')
    }
  }, [refresh]);


  const [id, setId] = useState(null);
  const [level, setLevel] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);

  const [showAlert, setShowAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState('');
  const [messageAlert, setMessageAlert] = useState('');

  const [openUpdate, setOpenUpdate] = useState(false);
  const [dataUpdate, setDataUpdate] = useState(null);

  const [openConfirm, setOpenConfirm] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleConfirmDelete = async (e) => {
    e.preventDefault();
    setLoadingConfirm(true);
    try {
      const response = await deleteAdvantage(accessToken, deleteId);
      if (response.status === 200) {
        setDeleteId(null);
        setOpenConfirm(false);
        setRefresh(true);
        setShowAlert(true);
        setTypeAlert('success');
        setMessageAlert('Berhasil menghapus data..!');
      } else {
        setShowAlert(true);
        setTypeAlert('error');
        setMessageAlert('Gagal menghapus data..!');   
      }
    } catch (error) {
      setShowAlert(true);
      setTypeAlert('error');
      setMessageAlert('Silahkan periksa koneksi internet Anda..!');

    }
    setLoadingConfirm(false);
  }
  return (
    <>
      <Helmet>
        <title>Keunggulan Perum RENJANA PASOPATI</title>
      </Helmet>
      <LayoutDashboard >
        <CustomHeader>
          <div className="hidden md:flex"></div>
          <p className="text-center">Keunggulan Perum RENJANA PASOPATI</p>
          <div className="flex items-center justify-end">
            <BtnDefault handleClick={() => setOpenModalAdd(true)}>
              <FontAwesomeIcon icon={faPlus} />
              <p className="hidden md:flex">Tambah Data</p>
            </BtnDefault>
          </div>
        </CustomHeader>

        <div className="w-full flex items-center justify-center pt-5 lg:pt-10">
          <div className="w-full px-3 md:w-[70%] lg:w-1/2">
            <div className="accordion-group [50%]">
              {data.map((item, index) => (
                <div className="accordion ">
                  <input
                    type="checkbox"
                    id={`accor-${index}`}
                    className="accordion-toggle"
                  />
                  <label
                    htmlFor={`accor-${index}`}
                    className="accordion-title bg-transparent border-custom-dark-yellow py-2 text-center text-base md:text-xl "
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-3 capitalize text-xs md:text-sm lg:text-base">
                        <p className="flex items-center gap-3 line-clamp-1">
                          <span>[ {item.level} ]</span>
                          <span>{item.title}</span>
                        </p>
                      </div>
                      {["super admin", "admin"].includes(userRole) && (
                        <div className="flex items-center gap-3 text-sm text-white">
                          <button
                            type="button"
                            onClick={() => {
                              setOpenUpdate(true);
                              setDataUpdate(item);
                            }}
                            className="bg-yellow-500 hover:bg-yellow-600 transition-colors px-3 py-1 rounded-md"
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              setDeleteId(item?.id ?? null)
                              setOpenConfirm(true)
                            }}
                            className="bg-red-500 hover:bg-red-600 transition-colors px-3 py-1 rounded-md"
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </div>
                      )}
                    </div>
                  </label>
                  <div className="accordion-content border-2 rounded-b-xl border-custom-dark-yellow">
                    <div className="min-h-0 px-5 ">
                      <div
                        className="rounded-xl p-3 text-black"
                        dangerouslySetInnerHTML={{ __html: item?.description ?? "" }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>


        {/* Modal */}
        <AdvantageCreate
          accessToken={accessToken}
          open={openModalAdd}
          setOpen={setOpenModalAdd}
          setRefresh={setRefresh}
        />

      <CustomConfirm
          open={openConfirm}
          setOpen={setOpenConfirm}
          handleConfirm={handleConfirmDelete}
          loading={loadingConfirm}
          message={"Apakah anda yakin ingin menghapus data ini?"}
        />


        <CustomAlert
          open={showAlert}
          setOpen={setShowAlert}
          type={typeAlert}
          message={messageAlert}
        />

        <AdvantageUpdate
          accessToken={accessToken}
          open={openUpdate}
          setOpen={setOpenUpdate}
          setRefresh={setRefresh}
          getData={dataUpdate}
          setAlert={setShowAlert}
          setTypeAlert={setTypeAlert}
          setMessageAlert={setMessageAlert}
        />
      </LayoutDashboard>
    </>
  );
};

export default Advantage;
