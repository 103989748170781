import { useEffect, useState } from "react";
import SectionTitle from "./section_title";
import { getAdvantages } from "../../../services/content/advantage_service";

const OurAdvantagesSection = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await getAdvantages()
            if (response.status === 200) {
                setData(response.data.data)
            }
        } catch (error) {
            alert('Silahkan periksa koneksi internet Anda..!')
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className="w-full text-black px-3 md:px-5 lg:px-10 bg-white">
            <SectionTitle
                title="Keunggulan Kami"
                subTitle="kenapa harus memilih kami..?"
                color="text-black"
            />
            <div className="w-full flex items-center justify-center py-5 lg:py-10">
                <div className="w-full md:w-[70%] lg:w-[70%]">
                    <div className="accordion-group [50%]">
                        {data.map((item, index) => (
                            <div className="accordion ">
                                <input type="checkbox" id={`accor-${index}`} className="accordion-toggle" />
                                <label htmlFor={`accor-${index}`} className="accordion-title bg-transparent border-custom-navy py-2 text-center text-base md:text-xl capitalize">{item.title}</label>
                                <div className="accordion-content border-2 rounded-b-xl border-custom-navy" >
                                    <div className="min-h-0 px-5 ">
                                        <div className="rounded-xl text-black" dangerouslySetInnerHTML={{ __html: item?.description ?? "" }} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurAdvantagesSection