import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../components/layouts/layout_dashboard"
import BtnBack from "../../../components/buttons/btn_back"
import CustomHeader from "../../../components/customs/header"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import BtnDefault from "../../../components/buttons/btn_default"
import FormatDate from "../../../components/format/date"
import CustomCurrency from "../../../components/customs/currency"
import { showUnitSelling } from "../../../services/unit/unit_selling_service"
import { BaseUrl } from "../../../services/base/base_url"
import { getUnitSellingPayments } from "../../../services/unit/unit_selling_payment_service"
import CustomAlert from "../../../components/customs/alert"
import NewSellingPayment from "./new_payment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import FormatDateV2 from "../../../components/format/date_v2"

const SellingDetail = () => {
    const [accessToken, setAccessToken] = useState('');
    const [userRole, setUserRole] = useState('');
    const navigate = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const [loadingPayment, setLoadingPayment] = useState(false);

    const [openModalAdd, setOpenModalAdd] = useState(false)

    const [sellingId, setSellingId] = useState(null)

    const [data, setData] = useState(null);
    const fetchData = async (accessToken) => {
        setLoading(true);

        const response = await showUnitSelling(accessToken, id);
        if (response.status === 200) {
            setData(response.data.data);
        }

        setLoading(false);
    };

    const [dataPayment, setDataPayment] = useState([])
    const fetchDataPayment = async (accessToken) => {
        setLoadingPayment(true);
        try {
            const response = await getUnitSellingPayments(accessToken, id);
            if (response.status === 200) {
                setDataPayment(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }

        setLoadingPayment(false);
    };

    useEffect(() => {
        try {
            if (refresh === true) {
                fetchData(accessToken)
                fetchDataPayment(accessToken)
                setRefresh(false)
            }
        } catch (error) {
            alert("Silahkan periksa koneksi internet Anda..!");
        }

    }, [refresh])

    useEffect(() => {
        try {
            const newAccessToken = localStorage.getItem('access_token-terra_app') ?? ''
            const newUserRole = localStorage.getItem('user_role-terra_app') ?? ''
            if (!newAccessToken) {
                navigate('/login')
            } else if (!['super admin', 'admin', 'lapangan', 'keuangan'].includes(newUserRole)) {
                navigate('/403')
            } else {
                setAccessToken(newAccessToken)
                setUserRole(newUserRole)
                fetchData(newAccessToken)
                fetchDataPayment(newAccessToken)
            }
        } catch (error) {
            // alert("Silahkan periksa koneksi internet Anda..!");
        }
    }, [id]);

    const MyRow = ({ name, value, addClass }) => {
        return (
            <tr className={addClass}>
                <td className="w-[120px] md:w-auto">{name}</td>
                <td className="px-2">:</td>
                <td>{value}</td>
            </tr>
        )
    }

    const [openAlert, setOpenAlert] = useState(false)
    const [messageAlert, setMessageAlert] = useState("")
    const [typeAlert, setTypeAlert] = useState("")

    return (
        <>
            <Helmet>Detail Penjualan</Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack />
                    <p className="text-center uppercase">Detail Penjualan</p>
                    <div className="md:hidden" />
                </CustomHeader>

                <div className="px-3 pt-2 pb-3 flex flex-col gap-3">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3 pt-3 items-start">
                        <table className={loading === true ? 'w-full' : 'w-fit'}>
                            {
                                loading === true ?
                                    Array.from({ length: 3 }).map(() => (
                                        <tr className="animate-pulse">
                                            <td className="px-3 py-2">
                                                <div className="w-full h-4 rounded-full bg-gray-300" />
                                            </td>
                                            <td className="px-3 py-2 w-16">
                                                <div className="w-full h-4 rounded-full bg-gray-300" />
                                            </td>
                                            <td className="px-3 py-2">
                                                <div className="w-full h-4 rounded-full bg-gray-300" />
                                            </td>
                                        </tr>
                                    )) :
                                    <>
                                        <MyRow name="Nama Lengkap" value={data?.consument.name ?? 'Tidak Tersedia'} />
                                        <MyRow name="Nomor Telepon" value={data?.consument.phone_number.replace(/(\d{4})(?=\d)/g, "$1 ") ?? 'Tidak Tersedia'} />
                                        <MyRow name="Email" value={data?.consument.email ?? 'Tidak Tersedia'} />
                                    </>
                            }
                        </table>
                        <table className={loading === true ? 'w-full' : 'w-full'}>
                            {
                                loading === true ?
                                    Array.from({ length: 2 }).map(() => (
                                        <tr className="animate-pulse">
                                            <td className="px-3 py-2">
                                                <div className="w-full h-4 rounded-full bg-gray-300" />
                                            </td>
                                            <td className="px-3 py-2 w-16">
                                                <div className="w-full h-4 rounded-full bg-gray-300" />
                                            </td>
                                            <td className="px-3 py-2">
                                                <div className="w-full h-4 rounded-full bg-gray-300" />
                                            </td>
                                        </tr>
                                    )) :
                                    <>
                                        <MyRow name="Waktu Dibuat" value={<>
                                            <FormatDateV2 date={data?.date ?? ''} />
                                        </>} />
                                        <MyRow name="Status" value={<>
                                            <div
                                                className={`${data?.status?.name === "gagal"
                                                    ? "bg-red-500"
                                                    : data?.status?.name === "dalam proses"
                                                        ? "bg-yellow-500" : "bg-green-500"
                                                    } rounded-full text-center font-semibold text-white md:py-1 px-3 text-xs md:text-sm`}
                                            >
                                                {data?.status?.name}
                                            </div>
                                        </>} />
                                        <MyRow name="Status Pembayaran" value={<>
                                            <div
                                                className={`${data?.payment_status?.name === "belum dibayar"
                                                    ? "bg-red-500"
                                                    : data?.payment_status?.name === "dibayar sebagian"
                                                        ? "bg-yellow-500" : "bg-green-500"
                                                    } rounded-full text-center font-semibold text-white md:py-1 px-3 text-xs md:text-sm`}
                                            >
                                                {data?.payment_status?.name}
                                            </div>
                                        </>} />
                                    </>
                            }
                        </table>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-3 pt-3">
                        <div>
                            <p className="font-semibold border-b-2 border-black mb-2">Alamat</p>
                            <p className="text-justify">{data?.consument?.address}</p>
                        </div>

                        <div>
                            <p className="font-semibold border-b-2 border-black mb-2">Foto KTP</p>
                            <div className={`w-full aspect-video rounded-xl overflow-hidden relative ${loading ? 'bg-gray-300 animate-pulse' : ''}`}>
                                {!loading &&
                                    <img
                                        src={`${BaseUrl}/storage/${data?.consument?.ktp}`}
                                        alt="image terra app"
                                        layout="fill"
                                        loading="lazy"
                                        className="w-full aspect-video object-cover"
                                    />
                                }
                            </div>
                        </div>

                        <div>
                            <p className="font-semibold border-b-2 border-black mb-2">Foto Kartu Keluarga</p>
                            <div className={`w-full aspect-video rounded-xl overflow-hidden relative ${loading ? 'bg-gray-300 animate-pulse' : ''}`}>
                                {!loading &&
                                    <img
                                        src={BaseUrl + "/storage/" + data?.consument?.kk}
                                        alt="image terra app"
                                        layout="fill"
                                        loading="lazy"
                                        className="w-full aspect-video object-cover"
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 px-3 gap-3">
                    <div>
                        <p className="px-3 py-2 bg-custom-dark-yellow text-black font-semibold rounded-xl">Data Unit</p>
                        <table className={`max-w-screen-2xl ${loadingPayment === true || dataPayment.length === 0 ? 'minw-max md:min-w-full' : 'min-w-max md:min-w-full'}`}>
                            {
                                loading === true ?
                                    Array.from({ length: 4 }).map(() => (
                                        <tr className="animate-pulse">
                                            <td className="px-3 py-2">
                                                <div className="w-full h-4 rounded-full bg-gray-300" />
                                            </td>
                                            <td className="px-3 py-2 w-16">
                                                <div className="w-full h-4 rounded-full bg-gray-300" />
                                            </td>
                                            <td className="px-3 py-2">
                                                <div className="w-full h-4 rounded-full bg-gray-300" />
                                            </td>
                                        </tr>
                                    )) :
                                    <>
                                        <MyRow name="Nama Unit" value={data?.unit?.name ?? 'Tidak Tersedia'} />
                                        <MyRow name="Biaya Proses" value={<CustomCurrency value={data?.unit?.cost_of_booking ?? 0} />} />
                                        <MyRow name="DP" value={<CustomCurrency value={data?.unit?.dp ?? 0} />} />
                                        <MyRow name="Biaya Hook" value={<CustomCurrency value={data?.unit?.cost_of_hook ?? 0} />} />
                                        <MyRow name="Biaya Akad" value={<CustomCurrency value={data?.unit?.cost_of_akad ?? 0} />} />
                                        <MyRow name="Biaya Notaris" value={<CustomCurrency value={data?.unit?.cost_of_notaris ?? 0} />} />
                                    </>
                            }
                        </table>
                    </div>

                    <div>
                        <p className="px-3 py-2 bg-custom-dark-yellow text-black font-semibold rounded-xl">Data Tagihan</p>
                        <table className={`max-w-screen-2xl ${loading === true || dataPayment.length === 0 ? 'min-w-max md:min-w-full' : 'min-w-max md:min-w-full'}`}>
                            {
                                loading === true ?
                                    Array.from({ length: 4 }).map(() => (
                                        <tr className="animate-pulse">
                                            <td className="px-3 py-2">
                                                <div className="w-full h-4 rounded-full bg-gray-300" />
                                            </td>
                                            <td className="px-3 py-2 w-16">
                                                <div className="w-full h-4 rounded-full bg-gray-300" />
                                            </td>
                                            <td className="px-3 py-2">
                                                <div className="w-full h-4 rounded-full bg-gray-300" />
                                            </td>
                                        </tr>
                                    )) :
                                    <>
                                        <MyRow name="Tagihan Biaya Proses" value={<CustomCurrency value={data?.remaining_booking ?? 0} />} />
                                        <MyRow name="Tagihan DP" value={<CustomCurrency value={data?.remaining_dp ?? 0} />} />
                                        <MyRow name="Tagihan Biaya Hook" value={<CustomCurrency value={data?.remaining_hook ?? 0} />} />
                                        <MyRow name="Tagihan Biaya Akad" value={<CustomCurrency value={data?.remaining_akad ?? 0} />} />
                                        <MyRow name="Tagihan Biaya Notaris" value={<CustomCurrency value={data?.remaining_notaris ?? 0} />} />
                                        <MyRow addClass="font-semibold" name="Total Tagihan" value={<CustomCurrency value={(data?.remaining_booking ?? 0) + (data?.remaining_dp ?? 0) + (data?.remaining_hook ?? 0) + (data?.remaining_akad ?? 0) + (data?.remaining_notaris ?? 0)} />} />
                                    </>
                            }
                        </table>
                    </div>
                </div>

                <div className="w-full flex flex-col gap-3 p-3">
                    <div className="px-3 py-2 bg-custom-dark-yellow text-black font-semibold rounded-xl flex items-center justify-between">
                        <p className="">Riwayat Transaksi</p>
                        {data?.remaining_booking || data?.remaining_hook !== 0 || data?.remaining_dp !== 0 || data?.remaining_akad || data?.remaining_notaris ?
                            <BtnDefault handleClick={() => setOpenModalAdd(true)}>
                                <FontAwesomeIcon icon={faPlus} />
                                <p className="hidden md:flex">Pembayaran Baru</p>
                            </BtnDefault> : ""
                        }
                    </div>

                    <div className="w-full overflow-auto scrollbar-thin scrollbar-track-custom-dark-yellow scrollbar-thumb-custom-navy scrollbar-track-rounded-full scrollbar-thumb-rounded-full pb-3">
                        <table className={`${loading === true ? 'w-full' : 'min-w-max md:min-w-full'} max-w-screen-2xl`}>
                            <thead>
                                <tr className="bg-custom-dark-yellow">
                                    <th className="px-3 py-2 rounded-tl-xl " >No</th>
                                    <th className="px-3 py-2 " >Tanggal</th>
                                    <th className="px-3 py-2 " >No Ref</th>
                                    <th className="px-3 py-2 " >Nominal</th>
                                    <th className="px-3 py-2 " >Msuk Ke</th>
                                    <th className="px-3 py-2 rounded-tr-xl text-center">Keterangan</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!loading ?
                                    dataPayment.length === 0 ? "Tidak ada data" :
                                        dataPayment?.map((item, index) => (
                                            <tr className={`${index % 2 === 1 ? 'bg-gray-200' : ''} hover:bg-gray-300 transition-all duration-300`}>
                                                <td className="px-3 py-2 text-center">{index + 1}</td>
                                                <td className="px-3 py-2 text-center">
                                                    <FormatDate value={item?.created_at ?? ''} />
                                                </td>
                                                <td className="px-3 py-2 text-center">{item?.code}</td>
                                                <td className="px-3 py-2 text-end"> <CustomCurrency value={item?.nominal} /></td>
                                                <td className="px-3 py-2 text-start">{item?.account_to?.id} - {item?.account_to?.name}</td>
                                                <td className="px-3 py-2 text-start">{item?.description}</td>
                                            </tr>
                                        )) :
                                    Array.from({ length: 5 }).map(() => (
                                        <tr className="animate-pulse">
                                            {Array.from({ length: 6 }).map(() => (
                                                <td className="px-3 py-2">
                                                    <div className="w-full h-4 rounded-full bg-gray-300" />
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                }
                                <tr className="bg-custom-dark-yellow">
                                    <td className="py-4" colSpan={10} />
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <CustomAlert
                    open={openAlert}
                    setOpen={setOpenAlert}
                    message={messageAlert}
                    type={typeAlert}
                />

                <NewSellingPayment
                    accessToken={accessToken}
                    open={openModalAdd}
                    setOpen={setOpenModalAdd}
                    setRefresh={setRefresh}
                    getSellingId={id}
                    getTotalPayment={data?.remaining_booking + data?.remaining_hook + data?.remaining_dp + data?.remaining_akad + data?.remaining_notaris}
                    setOpenAlert={setOpenAlert}
                    setMessageAlert={setMessageAlert}
                    setTypeAlert={setTypeAlert}
                />
            </LayoutDashboard>
        </>
    )
}

export default SellingDetail