import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import InputDefault from "../../../../components/form/input_default";
import SelectDefault from "../../../../components/form/select_default";
import CustomRating from './../../../../components/customs/rating';
import CustomSpinner from "../../../../components/customs/spinner";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { postTestimony } from "../../../../services/content/testimony_service";
import LayoutModal from './../../../../components/layouts/layout_modal';
import { faFileImage } from "@fortawesome/free-solid-svg-icons";
import TextAreaDefault from "../../../../components/form/text_area_default";

const TestimonyCreate = ({
    open, setOpen, accessToken, setRefresh
}) => {

    const [loading, setLoading] = useState(false);

    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [name, setName] = useState("");
    const [city, setCity] = useState("");
    const [rating, setRating] = useState(3.5);
    const [testimony, setTestimony] = useState("");

    const [errorName, setErrorName] = useState(null);
    const [errorCity, setErrorCity] = useState(null);
    const [errorRating, setErrorRating] = useState(null);
    const [errorTestimony, setErrorTestimony] = useState(null);

    const handleImageChange = (e) => {
        if (e.target.files) {
            const file = e.target.files[0];
            setImage(() => file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    const handleRatingChange = (e) => {
        const value = parseFloat(e.target.value);
        setRating(value);
        setErrorRating(null);
    };

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            setErrorName(null);
            setErrorCity(null);
            setErrorRating(null);
            setErrorTestimony(null);

            if (name.length === 0) {
                setErrorName("Nama tidak boleh kosong..!");
            } else if (name.length < 3) {
                setErrorName("Nama harus terdiri dari 3 karakter atau lebih..!");
            } else if (city.length === 0) {
                setErrorCity("Kota tidak boleh kosong..!");
            } else if (testimony.length === 0) {
                setErrorTestimony("Testimony tidak boleh kosong..!");
            } else {
                const formData = new FormData();
                if (image !== null) {
                    formData.append("image", image);
                }
                formData.append("name", name);
                formData.append("city", city);
                formData.append("rating", rating.toString());
                formData.append("testimony", testimony);

                const response = await postTestimony(accessToken, formData);
                if (response.status === 201) {
                    setOpen(false);
                    setRefresh(true);
                } else {
                    alert("Gagal menyimpan data, Silahkan coba beberapa saat lagi..!");
                }
            }
        } catch (error) {
            alert("Silahkan cek koneksi internet Anda..!");
        }

        setLoading(false);
    };

    const option = [
        {"value": 0.0},
        {"value": 0.5},
        {"value": 1.0},
        {"value": 1.5},
        {"value": 2.0},
        {
            "value": 2.5
        },
        {
            "value": 3.0
        },
        {
            "value": 3.5
        },
        {
            "value": 4.0
        },
        {
            "value": 4.5
        },
        {
            "value": 5.0
        }
    ]

    return (
        <LayoutModal open={open} setOpen={setOpen} name="Tambah Testimoni">
            <form
                onSubmit={handleSubmit}
                className="flex flex-col gap-3"
            >
                <div className="flex flex-col items-center justify-center mb-5 gap-3">
                    {imagePreview && (
                        <img
                            src={imagePreview}
                            alt="Preview"
                            className="w-32 aspect-square object-cover rounded-full mr-2"
                        />
                    )}

                    <label
                        htmlFor="file-upload"
                        className="cursor-pointer bg-custom-navy text-custom-dark-yellow px-3 py-2 rounded-md flex items-center space-x-2"
                    >
                        <FontAwesomeIcon icon={faFileImage} className="text-2xl mr-2" />
                        <p className="font-semibold">Upload Gambar</p>
                    </label>

                    <input
                        id="file-upload"
                        type="file"
                        accept="image/*"
                        className="mr-3 hidden"
                        onChange={handleImageChange}
                    />
                </div>

                <InputDefault
                    label="Nama"
                    placeholder="Masukkan nama"
                    value={name}
                    setValue={setName}
                    errorMessage={errorName || ""}
                />

                <InputDefault
                    label="Kota Asal"
                    placeholder="Masukkan nama kota"
                    value={city}
                    setValue={setCity}
                    errorMessage={errorCity || ""}
                />

                <div className="flex flex-col">
                    <SelectDefault
                        label="Rating"
                        options={option.map((item) => ({ value: item.value.toString(), label: item.value.toString() }))}
                        getSelected={rating.toString()}
                        onSelect={(e) => setRating(parseFloat(e))}
                    />

                    <div><CustomRating rating={rating} /></div>

                    {errorRating && (
                        <p className="text-red-500 text-sm mt-1">{errorRating}</p>
                    )}
                </div>

                <TextAreaDefault
                    label="Testimony"
                    placeholder="Masukkan testimony"
                    value={testimony}
                    setValue={setTestimony}
                    errorMessage={errorTestimony || ""}
                />

                <div className="flex items-center space-x-3 pt-5">
                    <button
                        type="button"
                        onClick={() => {
                            setImage(null);
                            setImagePreview(null);
                            setName("");
                            setCity("");
                            setRating(0);
                            setTestimony("");
                            setOpen(false);
                        }}
                        className="bg-red-500 hover:bg-red-600 text-white w-full py-2 font-semibold rounded-full"
                    >
                        Kembali
                    </button>
                    <button
                        type="submit"
                        className="bg-custom-navy hover:bg-blue-950 text-white w-full py-2 font-semibold rounded-full flex items-center justify-center"
                    >
                        {loading === true ? (
                            <CustomSpinner addClass="bg-white" />
                        ) : (
                            " Simpan"
                        )}
                    </button>
                </div>
            </form>
        </LayoutModal>
    )
}

export default TestimonyCreate