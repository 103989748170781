import React, { useEffect, useState } from 'react';
import { getDebiturCrediturCategory } from '../../../services/user_management/debitur_creditur_category_service';
import SelectFilter from '../../../components/form/select_filter';
import BtnDefault from '../../../components/buttons/btn_default';
import InputDefault from '../../../components/form/input_default';
import { postDebiturCreditur, updateDebiturCreditur } from '../../../services/user_management/debitur_creditur_service';
import CustomSpinner from '../../../components/customs/spinner';
import CreditureCategoryAdd from './category';
import LayoutModal from '../../../components/layouts/layout_modal';



const CreditureAdd = ({
    accessToken,
    isOpen, setIsOpen,
    setRefresh,
    setAlert,
    setTypeAlert,
    setMessageAlert
}) => {

    const [crediturId, setCrediturId] = useState("")
    const [name, setName] = useState('');
    const [categoryId, setCategoryId] = useState("");

    const [errorName, setErrorName] = useState(null);
    const [errorCategory, setErrorCategory] = useState(null);

    const [loading, setLoading] = useState(false)
    const [loadingCategory, setLoadingCategory] = useState(false)
    const [refreshCategory, setRefreshCategory] = useState(false)

    const [dataCategory, setDataCategory] = useState([])
    const fetchDataCategory = async (accessToken) => {
        setLoadingCategory(true)
        const response = await getDebiturCrediturCategory(accessToken)
        if (response.status === 200) {
            setDataCategory(response.data.data)
        }

        setLoadingCategory(false)
    }
    useEffect(() => {
        if (refreshCategory) {
            fetchDataCategory(accessToken)
            setRefreshCategory(false)
        }

    }, [refreshCategory])

    useEffect(() => {
        if (accessToken) {
            fetchDataCategory(accessToken)
        }
    }, [accessToken])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            setErrorName(null);
            setErrorCategory(null);

            if (name.trim() === '') {
                setErrorName('Nama lengkap wajib diisi..!');
            } else if (name.length < 3) {
                setErrorName('Nama lengkap minimal memiliki 3 huruf..!');
            } else if (categoryId.length === 0) {
                setErrorName('Kategori wajib diisi..!');
            } else {
                const formData = new FormData()
                formData.append('name', name)
                formData.append('category_id', categoryId)

                const response = await postDebiturCreditur(accessToken, formData)
                if (response.status === 201) {
                    setIsOpen && setIsOpen(false)
                    setRefresh && setRefresh(true)
                    setAlert && setAlert(true)
                    setTypeAlert && setTypeAlert('success')
                    setMessageAlert && setMessageAlert('Berhasil menyimpan data..!')
                } else if (response.status === 404) {
                    setAlert && setAlert(true)
                    setTypeAlert && setTypeAlert('error')
                    setMessageAlert && setMessageAlert(response.data.message ?? 'gagal menyimpan data..!')
                } else {
                    setAlert && setAlert(true)
                    setTypeAlert && setTypeAlert('error')
                    setMessageAlert && setMessageAlert('gagal menyimpan data..!')
                }

            }
        } catch (error) {
            setAlert && setAlert(true)
            setTypeAlert && setTypeAlert('error')
            setMessageAlert && setMessageAlert('Silahkan periksa koneksi internet Anda..!')
        }
        setLoading(false)
    }

    const [openCreateCategory, setOpenCreateCategory] = useState(false)
    const [getId, setGetId] = useState("")
    const [getName, setGetName] = useState("")

    return (
        <>
            <LayoutModal name={`${crediturId ? "Ubah" : "Tambah"} Data Debitur / Kreditur`} open={isOpen} setOpen={setIsOpen}>
                <form onSubmit={handleSubmit} className='w-full h-full flex py-3 flex-col gap-3 overflow-auto scrollbar-thin scrollbar-track-custom-navy scrollbar-thumb-custom-dark-yellow'>
                    <InputDefault
                        id="name"
                        label="Nama Lengkap"
                        placeholder='Masukkan Nama Lengkap'
                        value={name}
                        setValue={setName}
                        errorMessage={errorName ?? undefined}
                    />

                    <div className="flex flex-col gap-2">
                        <SelectFilter
                            label='Kategori'
                            placeholder='Pilih Kategori'
                            options={dataCategory.map(item => ({ value: item.id, label: item.name }))}
                            onSelect={(e) => setCategoryId(e)}
                            loading={loadingCategory}
                            plusOption={
                                <BtnDefault handleClick={() => {
                                    setGetId("")
                                    setGetName("")
                                    setOpenCreateCategory(true)
                                }} addClass='w-full'>
                                    Tambah Kategori
                                </BtnDefault>
                            }
                            value={categoryId}
                            handleUpdate={(e) => {
                                setGetId(e.value)
                                setGetName(e.label)
                                setOpenCreateCategory(true)
                            }}
                        />
                        {errorCategory && <p className="text-red-500 text-sm">{errorCategory}</p>}
                    </div>

                    <div className="w-full flex items-center gap-3 justify-evenly">
                        <BtnDefault type="button" handleClick={() => setIsOpen && setIsOpen(false)} addClass='bg-red-500 w-full '>
                            Kembali
                        </BtnDefault>
                        <BtnDefault type="submit" addClass='w-full'>
                            {loading ?
                                <CustomSpinner /> :
                                "Simpan"
                            }
                        </BtnDefault>
                    </div>
                </form>
            </LayoutModal>

            <CreditureCategoryAdd
                accessToken={accessToken}
                isOpen={openCreateCategory}
                setIsOpen={setOpenCreateCategory}
                setRefresh={setRefreshCategory}
                getId={getId}
                getName={getName}
            />
        </>
    )
}

export default CreditureAdd