import React from "react";

const BtnDefault = ({
    type, addClass, handleClick, children, inversColor, width
}) => {

    return (
        <button
            type={type ?? 'button'}
            onClick={handleClick ?? undefined}
            className={`${inversColor ? 'bg-custom-dark-yellow text-black hover:bg-yellow-200' : 'bg-custom-navy hover:bg-blue-900 text-custom-dark-yellow'} transition-colors rounded-full px-3 py-2 font-semibold flex items-center justify-center gap-3 capitalize ${addClass} ${width ?? ''}`}>
            {children}
        </button>
    )
}

export default BtnDefault