
import { useNavigate } from "react-router-dom";
import Footer from "../../pages/landing_page/sections/footer"
import Navbar from "../navigations/navbar"
import { useEffect, useState } from "react";
import CheckInternetConnection from "../utils/check_internet_connection";

const LayoutLP = ({children}) => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const navigate = useNavigate();

    CheckInternetConnection({ isOnline, setIsOnline });

    useEffect(() => {
        if (!isOnline) {
            navigate('/no-internet-connection'); 
        }
    }, [isOnline, navigate]);
    return (
        <div className="w-full h-screen bg-white text-black overflow-auto scrollbar-thin scrollbar-track-custom-navy scrollbar-thumb-custom-dark-yellow">
            <Navbar />
            {children}
            <Footer />
        </div>
    )
}

export default LayoutLP