import InputDefault from "../../../../components/form/input_default";
import LayoutModal from "../../../../components/layouts/layout_modal";
import { postAdvantage } from './../../../../services/content/advantage_service';
import { useState } from "react";
import CustomSpinner from "../../../../components/customs/spinner";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const AdvantageCreate = ({
    accessToken,
    open,
    setOpen,
    setRefresh
}) => {

    const [loading, setLoading] = useState(false);
    const [errorLevel, setErrorLevel] = useState(null);
    const [errorTitle, setErrorTitle] = useState(null);
    const [errorDescription, setErrorDescription] = useState(null);

    const [level, setLevel] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        setErrorLevel(null);
        setErrorTitle(null);
        setErrorDescription(null);

        const description = draftToHtml(convertToRaw(editorState.getCurrentContent()));

        if (title.length === 0) {
            setErrorTitle("Judul tidak boleh kosong..!");
        } else if (title.length < 3) {
            setErrorTitle("Judul harus terdiri dari 3 karakter atau lebih..!");
        } else {
            const formData = new FormData();
            formData.append('level', level.toString());
            formData.append('title', title);
            formData.append('description', description);
            const response = await postAdvantage(accessToken, formData);
            if (response.status === 201) {
                setOpen(false);
                setRefresh(true);
            } else if (response.status === 400) {
                if (response?.data?.level) {
                    alert('Level sudah digunakan..!')
                } else if (response?.data?.title) {
                    alert('Judul sudah digunakan..!')
                }
            } else {
                alert('Gagal menyimpan data, Silahkan coba beberapa saat lagi..!')
            }
        }

        setLoading(false);
    };


    return (
        <LayoutModal open={open} setOpen={setOpen} name="Tambah Keunggulan" >
            <form onSubmit={handleSubmit} className="flex flex-col gap-3">

                <InputDefault
                    label="Level"
                    type={'number'}
                    placeholder="level keunggulan"
                    value={level}
                    setValue={setLevel}
                    errorMessage={errorLevel}
                />

                <InputDefault
                    label="Judul Keunggulan"
                    placeholder="Masukkan Judul Keunggulan"
                    value={title}
                    setValue={setTitle}
                    errorMessage={errorTitle}
                />

                <div className="px-3 w-full overflow-auto">
                    {errorDescription && <p className="text-xs font-semibold text-red-500">{errorDescription}</p>}
                    <div className="border-2 rounded-xl p-3">
                        <label htmlFor="description" className="font-semibold text-xs md:text-sm">Deskripsi</label>
                        <Editor
                            editorState={editorState}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={onEditorStateChange}
                        />
                    </div>
                </div>

                <div className="flex items-center space-x-3 pt-5">
                    <button
                        type="button"
                        onClick={() => setOpen(false)}
                        className="bg-red-500 hover:bg-red-600 text-white w-full py-2 font-semibold rounded-full"
                    >
                        Kembali
                    </button>
                    <button
                        type="submit"
                        className="bg-custom-navy hover:bg-blue-950 text-white w-full py-2 font-semibold rounded-full flex items-center justify-center"
                    >
                        {loading === true ? (
                            <CustomSpinner addClass="bg-white" />
                        ) : (
                            " Simpan"
                        )}
                    </button>
                </div>
            </form>
        </LayoutModal>
    )
}

export default AdvantageCreate;