
import ApiService from '../base/api_service';

export const getBigbook = async (accessToken, params) => {
    try {
        const response = await ApiService.get('/bigbook', {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const exportBigbook = async (accessToken, params) => {
    try {
        const response = await ApiService.get('/bigbook/export', {
            params: params,
            responseType: 'blob',
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response;
    }
}
