import { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts";
import { getUnitSellingChart } from "../../../services/unit/unit_selling_service";
import { useNavigate } from "react-router-dom";

const generateRandomData = (length, min, max) => {
    return Array.from({ length }, () => Math.floor(Math.random() * (max - min + 1)) + min);
};

const SellingChart = () => {

    const [accessToken, setAccessToken] = useState(null)
    const [userRole, setUserRole] = useState(null)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [year, setYear] = useState(new Date().getFullYear())

    const [data, setData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const fetchData = async (accessToken, year) => {
        setLoading(true)
        try {
            const response = await getUnitSellingChart(accessToken, year)
            if (response.status === 200) {
                setData(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    const [dataChart, setDataChart] = useState({

        series: [{
            name: "Penjualan Unit Rumah / Ruko",
            data: data
        }],

        options: {
            chart: {
                type: 'area',
                height: 350,
            },
            dataLabels: {
                enabled: true
            },
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            title: {
                text: 'Penjualan Unit Rumah / Ruko',
                align: 'left'
            },
            subtitle: {
                text: 'Tahun 2024',
                align: 'left'
            },
            stroke: {
                curve: 'smooth'
            },
            yaxis: {
                opposite: true
            },
            legend: {
                horizontalAlign: 'left'
            }
        }
    })

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token-terra_app')
        const userRole = localStorage.getItem('user_role-terra_app')
        if (!accessToken || !userRole) {
            navigate('/login')
        } else {
            setAccessToken(accessToken)
            setUserRole(userRole)
            fetchData(accessToken, year)
        }
    }, [])

    useEffect(() => {
        setDataChart((prev) => ({
            ...prev,
            series: [{
                name: "Penjualan Unit Rumah / Ruko",
                data: data
            }]
        }));
    }, [data]);

    return (
        <div className="px-5">
            <div id="chart">
                <ReactApexChart
                    options={dataChart.options}
                    series={dataChart.series}
                    type="area"
                    height={350}
                />
            </div>
            <div id="html-dist"></div>
        </div>
    )
}

export default SellingChart