import { Helmet } from "react-helmet";
import LayoutDashboard from './../../../components/layouts/layout_dashboard';
import CustomHeader from './../../../components/customs/header';
import { useNavigate } from "react-router-dom";
import FormatDate from "../../../components/format/date";
import CustomCurrency from "../../../components/customs/currency";
import { useState } from "react";
import { useEffect } from "react";
import CustomAlert from "../../../components/customs/alert";
import SellingCreate from "./create";
import BtnDefault from "../../../components/buttons/btn_default";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUnitSelling } from "../../../services/unit/unit_selling_service";
import SellingChart from "./chart";
import CustomPaginate from "../../../components/customs/paginate";
import InputSearch from "../../../components/form/input_search";
import CustomDropdown from "../../../components/customs/dropdown";
import FormatDateV2 from "../../../components/format/date_v2";

const Selling = () => {
    const [accessToken, setAccessToken] = useState(null)
    const [userRole, setUserRole] = useState(null)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [number, setNumber] = useState(1)

    const [openModalAdd, setOpenModalAdd] = useState(false)
    const [openModalDetail, setOpenModalDetail] = useState(false)
    const [openModalEdit, setOpenModalEdit] = useState(false)
    const [openModalDelete, setOpenModalDelete] = useState(false)

    const [paymentStatus, setPaymentStatus] = useState("semua")
    const [status, setStatus] = useState("semua")
    const [data, setData] = useState(null)
    const [dataTotal, setDataTotal] = useState(0)
    const [keyword, setKeyword] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    let [from, setFrom] = useState(1)

    const fetchData = async (accessToken, keyword, currentPage) => {
        setLoading(true)
        try {
            const response = await getUnitSelling({
                accessToken: accessToken,
                keyword: keyword,
                status: status,
                payment_status: paymentStatus,
                page: currentPage
            })
            if (response.status === 200) {
                setData(response.data.data)
                setDataTotal(response.data.total)
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
                setFrom(response.data.meta.from)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        if (refresh) {
            fetchData(accessToken)
            setRefresh(false)
        }
    }, [refresh])

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token-terra_app')
        const userRole = localStorage.getItem('user_role-terra_app')
        if (accessToken) {
            if (['super admin', 'admin', 'keuangan'].includes(userRole)) {
                setAccessToken(accessToken)
                setUserRole(userRole)
                fetchData(accessToken)
            } else {
                navigate('/403')
            }
        } else {
            navigate('/login')
        }
    }, [])

    const [openAlert, setOpenAlert] = useState(false)
    const [messageAlert, setMessageAlert] = useState("")
    const [typeAlert, setTypeAlert] = useState("")

    const optionPaymentStatus = [
        { 'value': 'semua' },
        { 'value': 'belum dibayar' },
        { 'value': 'dibayar sebagian' },
        { 'value': 'lunas' },
    ]
    const optionStatus = [
        { 'value': 'semua' },
        { 'value': 'gagal' },
        { 'value': 'dalam proses' },
        { 'value': 'selesai' },
    ]
    return (
        <>
            <Helmet>
                <title>Data Penjualan</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <div></div>
                    <p className="text-center uppercase">Data Penjualan</p>
                    {['super admin', 'keuangan'].includes(userRole) && (
                        <div className="flex items-center justify-end">
                            <BtnDefault handleClick={() => setOpenModalAdd(true)}>
                                <FontAwesomeIcon icon={faPlus} />
                                <p className="hidden md:flex">Penjualan Baru</p>
                            </BtnDefault>
                        </div>
                    )}
                </CustomHeader>

                <SellingChart />

                <div className="w-full flex flex-col md:flex-row items-center justify-between p-3 gap-3 text-xs md:text-sm">
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        setCurrentPage(1)
                        fetchData(accessToken, keyword, 1)
                    }} className="w-full md:w-[250px] flex items-center gap-3 relative">
                        <InputSearch
                            value={keyword}
                            handleSearch={(e) => {
                                setKeyword(e.target.value)
                            }}
                            addClass={"w-full md:w-[250px]"}
                        />
                        <button type="submit" className="absolute right-[2px] flex bg-custom-dark-yellow text-custom-navy px-3 py-2 md:py-[6px] rounded-r-full  border-custom-navy text-base hover:bg-custom-navy hover:text-custom-dark-yellow transition-all duration-300">
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </form>

                    <div className="w-full md:w-fit grid grid-cols-2 md:flex flex-col md:flex-row items-center gap-3  ">
                        <CustomDropdown
                            name="Status"
                            addClass="w-full " >
                            {optionStatus.map((item, index) => (
                                <BtnDefault handleClick={() => {
                                    setStatus(item.value)
                                    setRefresh(true)
                                }} key={index}>
                                    {item.value}
                                </BtnDefault>
                            ))}
                        </CustomDropdown>

                        <CustomDropdown
                            name="Status Pembayaran"
                            addClass="w-full " >
                            {optionPaymentStatus.map((item, index) => (
                                <BtnDefault handleClick={() => {
                                    setPaymentStatus(item.value)
                                    setRefresh(true)
                                }} key={index}>
                                    {item.value}
                                </BtnDefault>
                            ))}
                        </CustomDropdown>
                    </div>
                </div>

                <div className="w-full p-3">
                    <div className="w-full text-xs md:text-sm overflow-x-auto">
                        <table className={`max-w-screen-xl ${loading || dataTotal === 0 ? 'min-w-full' : 'min-w-max lg:min-w-full'} `}>
                            <thead>
                                <tr className="bg-custom-dark-yellow">
                                    <th className="px-3 py-2 rounded-tl-xl" >No</th>
                                    <th className="px-3 py-2">Tanggal</th>
                                    <th className="px-3 py-2">Nama konsumen</th>
                                    <th className="px-3 py-2">Nama Unit</th>
                                    <th className="px-3 py-2">Status</th>
                                    <th className="px-3 py-2">Status Pembayaran</th>
                                    <th className="px-3 py-2 rounded-tr-xl ">Total Tagihan</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!loading ?
                                    !data ? "Tidak ada data..!" : data.map((item, index) => (
                                        <tr
                                            onClick={() => navigate(`/dashboard/selling/detail/${item.id}`)}
                                            className={`${index % 2 === 1 ? "bg-gray-200" : ""} hover:bg-gray-300 cursor-pointer`}
                                        >
                                            <td className="px-3 py-2 text-center">{from++}</td>
                                            <td className="px-3 py-2">
                                                {item?.date && <FormatDateV2 date={item.date} />}
                                            </td>
                                            <td className="px-3 py-2 capitalize">{item?.consument?.name}</td>
                                            <td className="px-3 py-2 capitalize">{item?.unit?.name}</td>
                                            <td className="px-3 py-2">
                                                <div
                                                    className={`${item?.status?.name === "gagal"
                                                        ? "bg-red-500"
                                                        : item?.status?.name === "dalam proses"
                                                            ? "bg-yellow-500" : "bg-green-500"
                                                        } rounded-full text-center font-semibold text-white py-1 px-3 text-sm`}
                                                >
                                                    {item?.status?.name}
                                                </div>
                                            </td>
                                            <td className="px-3 py-2">
                                                <div
                                                    className={`${item?.status_payment?.name === "belum dibayar"
                                                        ? "bg-red-500"
                                                        : item?.payment_status?.name === "dibayar sebagian"
                                                            ? "bg-yellow-500" : "bg-green-500"
                                                        } rounded-full text-center font-semibold text-white py-1 px-3 text-sm`}
                                                >
                                                    {item?.payment_status?.name}
                                                </div>
                                            </td>
                                            <td className="px-3 py-2 text-end font-bold">
                                                <CustomCurrency value={
                                                        (item?.remaining_booking ?? 0) + (item?.remaining_dp ?? 0) + (item?.remaining_hook ?? 0) + (item?.remaining_akad ?? 0) + (item?.remaining_notaris ?? 0)
                                                } />
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    Array.from({ length: 10 }).map(() => (
                                        <tr className="animate-pulse">
                                            {Array.from({ length: 7 }).map(() => (
                                                <td className="px-3 py-2">
                                                    <div className="w-full h-5 rounded-full bg-gray-300" />
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="flex justify-center px-3">
                    <div className="w-full flex items-center justify-center md:justify-end px-3 py-1 bg-custom-dark-yellow">
                        <CustomPaginate
                            currentPage={currentPage}
                            lastPage={lastPage}
                            handlePageChange={(page) => {
                                setCurrentPage(page)
                                fetchData(accessToken, keyword, page)
                            }}
                        />
                    </div>
                </div>

                <CustomAlert open={openAlert} setOpen={setOpenAlert} message={messageAlert} type={typeAlert} />
                <SellingCreate
                    accessToken={accessToken}
                    open={openModalAdd}
                    setOpen={setOpenModalAdd}
                    setRefresh={setRefresh}
                    setOpenAlert={setOpenAlert}
                    setMessageAlert={setMessageAlert}
                    setTypeAlert={setTypeAlert}
                />
            </LayoutDashboard>
        </>
    )
};

export default Selling;
