import MyImage from "../../../assets/images/image 1.jpg"
import image8 from "../../../assets/images/image 8.jpg"    
import SectionTitle from "./section_title"

const AboutSection = () => {
    return (
        <div className="w-full bg-custom-navy px-3 md:px-5 lg:px-10">
            <SectionTitle
                title="Tentang Kami"
                subTitle="Mengenal Lebih Dekat dengan Perumahan RENJANA PASOPATI "
            />
            <div className="flex flex-col lg:flex-row items-center justify-center gap-3 md:gap-5 py-5 lg:py-10">
                <div className="w-full lg:w-[50%] grid grid-cols-1 md:grid-cols-2 md:gap-5 md:px-16 lg:px-0">
                    <img src={MyImage} alt="Perumahan RENJANA PASOPATI " className="w-[70%] md:w-full mx-auto rounded-lg shadow-lg aspect-square object-cover" />
                    <img src={image8} alt="Perumahan RENJANA PASOPATI " className="hidden md:flex rounded-lg shadow-lg aspect-square object-cover" />
                </div>
                <div className="w-full lg:w-[50%] text-center lg:text-justify space-y-3 text-white">
                    <p className="text-xs md:text-sm lg:indent-10">
                        Perumahan Renjana Pasopati bertempat di pinggir jalan raya pasopati, Leles – Garut. Perumahan tipe 34/60 dengan konsep tropical minimalis ini di design langsung oleh arsitek sehingga tampilan rumahnya menarik tapi tetap fungsional.
                    </p>
                    <img src={image8} alt="Perumahan RENJANA PASOPATI " className="w-[70%] mx-auto md:hidden rounded-lg shadow-lg aspect-square object-cover" />
                    <p className="text-xs lg:text-sm lg:indent-10">
                        Area gerbang perumahan yang lebar menjadi nilai tambah karena terdapat mesjid dan ruko dengan lahan parkir yang cukup luas membuat perumahan ini terlihat modern dan mewah menjadikan Renjana Pasopati sebagai satu satunya perumahan subsidi berkonsep estetik di pinggir jalan!
                    </p>
                    <p className="text-xs lg:text-sm lg:indent-10">
                        Perumahan ini dikembangkan oleh <strong>PT Terra Renjana Adyatama </strong> yang merupakan developer resmi yang sudah berpengalaman di bidang properti dan memiliki tagline “building trust and quality” yang mengutamakan kepuasan konsumen perumahan kami.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AboutSection