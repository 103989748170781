import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../../components/layouts/layout_dashboard"
import CustomHeader from "../../../../components/customs/header"
import BtnBack from "../../../../components/buttons/btn_back"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import BtnDefault from "../../../../components/buttons/btn_default"
import { faPlus, faSave, faTrash } from "@fortawesome/free-solid-svg-icons"
import CustomSpinner from "../../../../components/customs/spinner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import InputDefault from "../../../../components/form/input_default"
import SelectDefault from "../../../../components/form/select_default"
import SelectFilter from "../../../../components/form/select_filter"
import { getUnits } from "../../../../services/unit/unit_service"
import { getMaterials } from "../../../../services/logistic/material_service"
import SelectFilterWithPaginate from "../../../../components/form/select_filter_paginate"
import { getOtherUsedMaterials } from "../../../../services/logistic/other_used_material_service"
import CreateOtherUsedMaterial from "./create_other_used_material"
import { postUsedMaterial } from "../../../../services/logistic/used_material_service"

const UsedMaterialCreate = () => {
    const [accessToken, setAccessToken] = useState('')
    const [userRole, setUserRole] = useState('')
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [loadingUnit, setLoadingUnit] = useState(false)
    const [loadingMaterial, setLoadingMaterial] = useState(false)

    const [category, setCategory] = useState('')
    const [unitId, setUnitId] = useState('')
    const [date, setDate] = useState('')

    const [errorCategory, setErrorCategory] = useState(null)
    const [errorUnitId, setErrorUnitId] = useState(null)
    const [errorDate, setErrorDate] = useState(null)

    const [dataUnit, setDataUnit] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [keywordUnit, setKeywordUnit] = useState('')
    const fetchDataUnit = async (accessToken, keyword, page) => {
        setLoadingUnit(true);
        try {
            const params = {
                page: page,
                keyword: keyword
            }
            const response = await getUnits(accessToken, params);
            if (response.status === 200) {
                setDataUnit(response.data.data);
                setTotalData(response.data.meta.total);
                setCurrentPage(response.data.meta.current_page);
                setLastPage(response.data.meta.last_page);
            }
        } catch (error) {
            console.log("__")
        }

        setLoadingUnit(false);
    };

    const [dataMaterial, setDataMaterial] = useState([])
    const fetchDataMaterial = async (accessToken) => {
        setLoadingMaterial(true);

        const response = await getMaterials(accessToken, "");
        if (response.status === 200) {
            setDataMaterial(response.data.data);
        }

        setLoadingMaterial(false);
    };

    const [usedMaterialId, setUsedMaterialId] = useState('')
    const [usedMaterialName, setUsedMaterialName] = useState('')
    const [openCreateUsedMaterial, setOpenCreateUsedMaterial] = useState(false)
    const [errorUsedMaterial, setErrorUsedMaterial] = useState(null)
    const [loadingUsedMaterial, setLoadingUsedMaterial] = useState(false)
    const [dataUsedMaterial, setDataUsedMaterial] = useState([])

    const [refreshUsedMaterial, setRefreshUsedMaterial] = useState(false)
    const fetchDataUsedMaterial = async (accessToken) => {
        setLoadingMaterial(true);
        const response = await getOtherUsedMaterials(accessToken);
        if (response.status === 200) {
            setDataUsedMaterial(response.data.data);
        }

        setLoadingMaterial(false);
    };

    useEffect(() => {
        if (refreshUsedMaterial) {
            fetchDataUsedMaterial(accessToken)
            setRefreshUsedMaterial(false)
        }
    }, [refreshUsedMaterial])

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token-terra_app')
        const userRole = localStorage.getItem('user_role-terra_app')
        if (!accessToken || !userRole) {
            navigate('/login')
        } else {
            setAccessToken(accessToken)
            setUserRole(userRole)
            fetchDataUnit(accessToken, '', 1)
            fetchDataMaterial(accessToken)
            fetchDataUsedMaterial(accessToken)
        }
    }, [])

    let number = 1;

    const [items, setItems] = useState([
        {

            materialId: "",
            unit: "",
            stock: 0,
            quantity: 1,
        },
    ]);

    const handleAddItem = () => {
        setItems([
            ...items,
            {
                materialId: "",
                unit: "",
                stock: 0,
                quantity: 0.0
            },
        ]);
    };

    const handleRemoveItem = (index) => {
        if (items.length > 1) {
            const values = [...items];
            values.splice(index, 1);
            setItems(values);
        }
    };

    const handleChange = (index, event) => {
        const { name, value } = event.target;
        const values = [...items];
        (values[index])[name] = value;

        // Tambahkan logika untuk mengubah stock berdasarkan material yang dipilih
        if (name === "materialId") {
            const selectedMaterial = dataMaterial.find(item => item.id === value);
            if (selectedMaterial) {
                values[index].stock = selectedMaterial.remaining_stock ?? 0; // Ganti dengan field yang sesuai
                values[index].unit = selectedMaterial.unit ?? '';
            }
        }

        setItems(values);
    };

    const validateItems = () => {
        for (const item of items) {
            if (!item.materialId || item.quantity < 0.1 || item.quantity > item.stock) {
                return false; // Pastikan semua item terisi, kuantitas minimal 0.5, dan tidak melebihi stok
            }
        }
        return true;
    };

    const [errorMessage, setErrorMessage] = useState(null)
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {

            setErrorCategory(null);
            setErrorUnitId(null);
            setErrorDate(null);
            setErrorMessage(null);

            if (!category) {
                setErrorCategory("Kategori peruntukan harus dipilih..!")
            } else if (!unitId) {
                setErrorUnitId("Tujuan alokasi harus dipilih..!")
            } else if (!date) {
                setErrorDate("Tanggal penggunaan harus dipilih..!")
            } else if (!validateItems()) {
                setErrorMessage("Semua item harus diisi dengan benar, kuantitas tidak boleh kurang dari 0.5 atau melebihi stok!");
            } else {
                const formData = new FormData()
                formData.append('category', category)
                formData.append('date', date)
                if (category === 'other') {
                    formData.append('reference_id', usedMaterialId)
                } else {
                    formData.append('reference_id', unitId)
                }
                items.forEach((item, index) => {
                    formData.append(`material[${index}][material_id]`, item.materialId)
                    formData.append(`material[${index}][quantity]`, item.quantity);
                })

                const response = await postUsedMaterial(accessToken, formData)
                if (response.status === 201) {
                    navigate('/dashboard/logistic/used-material')
                } else {
                    alert("Gagal menambah data..!")
                }
            }
        } catch (error) {
            alert("Gagal menambah data..!")
        }
        setLoading(false)
    }

    const optionCategory = [
        { value: 'unit', label: 'unit / ruko' },
        { value: 'other', label: 'Lainnya' },
    ]

    return (
        <>
            <Helmet>Penggunaan Baru</Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack />
                    <p className="text-center">Penggunaan Baru</p>
                    <div className="flex items-center justify-end">
                        <BtnDefault type="button" handleClick={handleSubmit}>
                            {loading ? <CustomSpinner /> : <>
                                <FontAwesomeIcon icon={faSave} />
                                <p className="hidden md:flex">Simpan</p>
                            </>}
                        </BtnDefault>
                    </div>
                </CustomHeader>

                <div className="p-3 sticky top-16 left-0 bg-white z-20">
                    <div className="grid grid-cols-1 md:grid-cols-2 items-end gap-3">
                        <div className="flex flex-col gap-3 ">
                            <SelectDefault
                                label="Kategori Peruntukan"
                                value={category} setValue={setCategory}
                                options={optionCategory.map((item) => ({ value: item.value, label: item.label }))}
                                onSelect={(e) => setCategory(e)}
                                errorMessage={errorCategory}
                            />

                            {category === 'other' ?
                                <SelectFilter
                                    label="Tujuan Alokasi"
                                    placeholder='Pilih Tujuan Alokasi'
                                    options={dataUsedMaterial.map(item => ({ value: item.id, label: item.name }))}
                                    onSelect={(e) => setUsedMaterialId(e)}
                                    loading={loadingUsedMaterial}
                                    plusOption={
                                        <BtnDefault handleClick={() => {
                                            setOpenCreateUsedMaterial(true)
                                        }} addClass='w-full'>
                                            Tambah Barang
                                        </BtnDefault>
                                    }
                                    value={usedMaterialId}
                                    handleUpdate={(e) => {
                                        setUsedMaterialId(e.value)
                                        setUsedMaterialName(e.label)
                                        setOpenCreateUsedMaterial(true)
                                    }}
                                    errorMessage={errorUsedMaterial}
                                />
                                :
                                <SelectFilterWithPaginate
                                    label="Tujuan Alokasi"
                                    value={unitId} setValue={setUnitId}
                                    options={dataUnit.map((item) => ({ value: item.id || '', label: item.name || '' }))}
                                    onSelect={(e) => setUnitId(e)}
                                    placeholder="Pilih Tujuan Alokasi"
                                    error={errorUnitId}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    lastPage={lastPage}
                                    keyword={keywordUnit}
                                    handleSearch={(e) => {
                                        setKeywordUnit(e.target.value)
                                        fetchDataUnit(accessToken, e.target.value, 1)
                                    }}
                                    handlePaginate={(e) => {
                                        fetchDataUnit(accessToken, keywordUnit, e)
                                    }}
                                    errorMessage={errorUnitId}
                                />
                            }
                        </div>

                        <div className="h-full grid grid-cols-1 gap-3">
                            <InputDefault
                                label="Tanggal Penggunaan"
                                type="date"
                                value={date} setValue={setDate}
                                errorMessage={errorDate}
                                addClass="w-full"
                            />

                            <BtnDefault handleClick={handleAddItem}>
                                <FontAwesomeIcon icon={faPlus} />
                                <p className="">Tambah Barang</p>
                            </BtnDefault>
                        </div>
                    </div>
                </div>

                <div className="p-3 w-full ">
                    {errorMessage && <p className="text-red-500 text-center">{errorMessage}</p>}
                    <table className="min-w-max md:min-w-full max-w-screen-2xl">
                        <thead className="sticky top-[228px] bg-white z-10">
                            <tr className="bg-custom-dark-yellow">
                                <th className="px-3 py-2 rounded-tl-xl">No</th>
                                <th className="px-3 py-2">Aksi</th>
                                <th className="px-3 py-2">Nama Barang</th>
                                <th className="px-3 py-2">Stok Barang</th>
                                <th className="px-3 py-2 rounded-tr-xl">Kuantitas</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item, index) => (
                                <tr className="hover:bg-gray-200 transition-colors" key={index}>

                                    <td className="px-1 py-2 text-center">{number++}</td>
                                    <td className="px-1 py-1 text-center">
                                        <button
                                            type="button"
                                            onClick={() => handleRemoveItem(index)}
                                            className="bg-red-500 hover:bg-red-600 transition-colors px-3 py-2 text-white rounded-md"
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </td>
                                    <td className="px-1 py-1">
                                        <SelectFilter
                                            placeholder="Pilih Barang"
                                            value={item.materialId}
                                            onSelect={(e) => handleChange(index, { target: { name: "materialId", value: e } })}
                                            addClass="w-56 rounded-md"
                                            options={dataMaterial.map(item => ({ value: item.id || '', label: item.name || '' }))}
                                        />
                                    </td>
                                    <td className="px-1 py-1">
                                        <input
                                            type="text"
                                            name="stock"
                                            value={`${item.stock} ${item.unit}`}
                                            placeholder="Stok Barang"
                                            onChange={(event) => handleChange(index, event)}
                                            disabled
                                            className="bg-white text-black w-full outline-none uppercase border-2 focus:border-custom_green rounded-md px-3 py-2 text-center"
                                        />
                                    </td>
                                    <td className="px-1 py-1">
                                        <div className="flex items-center justify-center relative">
                                            <input
                                                type="number"
                                                name="quantity"
                                                inputMode="decimal"
                                                step="0.01"
                                                min="0.1"
                                                value={item.quantity || 0}
                                                onChange={(event) => {
                                                    let value = event.target.value;

                                                    if (value.startsWith('0') && value.length > 1 && value[1] !== '.') {
                                                        value = value.substring(1);
                                                    }

                                                    const numericValue = parseFloat(value);

                                                    if (!isNaN(numericValue) && numericValue >= 0.1) {
                                                        handleChange(index, {
                                                            target: { name: "quantity", value: numericValue },
                                                        });
                                                    } else {
                                                        handleChange(index, {
                                                            target: { name: "quantity", value: 0 },
                                                        });
                                                    }
                                                }}
                                                className="bg-white text-black w-full outline-none border-2 focus:border-custom_green rounded-md px-3 py-2 text-center"
                                            />
                                            <p className="px-3 py-3 text-black absolute right-2 border-l-2">{item.unit}</p>
                                        </div>
                                    </td>
                                </tr>
                            ))}

                            <tr className="bg-custom-dark-yellow">
                                <td
                                    className="text-end font-bold text-custom-navy px-3 py-4 uppercase "
                                    colSpan={5}
                                />
                            </tr>
                        </tbody>
                    </table>
                </div>

                <CreateOtherUsedMaterial
                    accessToken={accessToken}
                    loading={loadingUsedMaterial}
                    isOpen={openCreateUsedMaterial} setIsOpen={setOpenCreateUsedMaterial}
                    setRefresh={setRefreshUsedMaterial}
                    getId={usedMaterialId} getName={usedMaterialName}
                />
            </LayoutDashboard>
        </>
    )
}

export default UsedMaterialCreate