import WelcomeSection from './sections/welcome_section';
import LayoutLP from '../../components/layouts/layout_lp';
import Splash from './splash';
import { useEffect, useState } from 'react';
import AboutSection from './sections/about_section';
import OurAdvantagesSection from './sections/our_advantages_section';
import GalerySection from './sections/galery_section';
import TestimonySection from './sections/testimony_section';
const LandingPage = () => {
    const [showSplash, setShowSplash] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setShowSplash(false);
        }, 10000);
    }, []);
    return (
        <>
            {showSplash === true ? (
                <Splash />
            ) : (
                <LayoutLP>
                    <WelcomeSection />
                    <AboutSection />
                    <OurAdvantagesSection />
                    <GalerySection />
                    <TestimonySection />
                </LayoutLP>
            )}
        </>
    );
}

export default LandingPage