
import ApiService from '../base/api_service';

export const getNeraca = async ({accessToken, params}) => {
    try {
        const response = await ApiService.get(`/neraca`, {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const getNeracaAktiva = async ({accessToken, params}) => {
    try {
        const response = await ApiService.get(`/neraca/aktiva`, {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const getNeracaPasiva = async ({accessToken, params}) => {
    try {
        const response = await ApiService.get(`/neraca/pasiva`, {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const getNeracaPendapatan = async ({accessToken, params}) => {
    try {
        const response = await ApiService.get(`/neraca/pendapatan`, {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const getNeracaBeban = async ({accessToken, params}) => {
    try {
        const response = await ApiService.get(`/neraca/beban`, {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const exportNeraca = async (accessToken, params) => {
    try {
        const response = await ApiService.get(`/neraca/export`, {
            params: params,
            responseType: 'blob',
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response;
    }
} 