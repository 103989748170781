
import ApiService from '../base/api_service';
export const getUnitSellingPayments = async (accessToken, sellingID) => {
    try {
        const response = await ApiService.get(`/unit-selling-payments`, {
            params: {
                selling_id: sellingID
            },
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const postUnitSellingPayment = async (accessToken, data) => {
    try {
        const response = await ApiService.post(`/unit-selling-payment/create`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const updateUnitSellingPayment = async (accessToken, data) => {
    try {
        const response = await ApiService.post(`/unit-selling-payments/update`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}
