
import CustomCurrency from "../../../../components/customs/currency";
import CustomSpinner from "../../../../components/customs/spinner";
import { PDFViewer, Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer';






const CustomDay = ({ dateString }) => {
    const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];

    const date = new Date(dateString);

    const dayName = days[date.getDay()];

    return `${dayName}`;
};


const CustomDate = ({ dateString }) => {
    const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];

    const date = new Date(dateString);

    const dayName = days[date.getDay()];
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
};


const UtangPdf = ({ dataCreditur, dataTransaction, totalUtang, totalUtangDibayar, sisaUtang }) => {

    const styles = StyleSheet.create({
        page: {
            padding: 30,
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 20,
        },
        headerText: {
            fontSize: 12,
        },
        table: {
            display: 'flex',
            width: 'auto',
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,
        },
        tableRow: {
            margin: 'auto',
            flexDirection: 'row',
        },
        tableColHeader: {
            borderStyle: 'solid',
            borderBottomWidth: 1,
            borderRightWidth: 1,
            padding: 5,
            backgroundColor: '#f0e68c',
        },
        tableCol: {
            borderStyle: 'solid',
            borderBottomWidth: 1,
            borderRightWidth: 1,
            padding: 5,
        },
        tableCellHeader: {
            fontSize: 10,
            fontWeight: 'bold',
        },
        tableCell: {
            fontSize: 10,
        },
        summary: {
            fontSize: 12,
            marginTop: 10,
            justifyContent: "flex-end",
            alignItems: "flex-end"
        },
    });

    return (
        <>
            {!dataCreditur ?
                <div className="w-full h-full flex items-center justify-center">
                    <CustomSpinner />
                </div> :
                <PDFViewer height="100%" width="100%" >
                    <Document >
                        <Page size="A4" orientation="landscape" style={{ padding: "20px" }}>
                            {/* Header */}
                            <View style={{ padding: 10, marginBottom: 10, borderBottomWidth: 3, borderStyle: 'solid', display: "flex", gap: 10 }}>
                                <Text style={{ fontSize: "16pt", textAlign: "center", fontWeight: "bold", textTransform: 'uppercase' }}>Data Tagihan Utang</Text>
                                <Text style={{ fontSize: "12pt", textAlign: "center", fontWeight: "bold", textTransform: 'uppercase' }}>
                                    PERUM TERRA RENJANA PASOPATI
                                </Text>
                            </View>
                            <View style={styles.header}>
                                <View>
                                    <View style={{ display: "flex", flexDirection: "row", gap: 3 }}>
                                        <Text style={{ width: "50px", fontSize: "14pt" }}>Id</Text>
                                        <Text style={{ fontSize: "14pt" }}>:</Text>
                                        <Text style={{ fontSize: "14pt" }}>{dataCreditur?.id}</Text>
                                    </View>
                                    <View style={{ display: "flex", flexDirection: "row", gap: 3 }}>
                                        <Text style={{ width: "50px", fontSize: "14pt" }}>Nama</Text>
                                        <Text style={{ fontSize: "14pt" }}>:</Text>
                                        <Text style={{ fontSize: "14pt" }}>{dataCreditur?.name}</Text>
                                    </View>
                                </View>
                                {/* Summary */}
                                <View style={styles.summary}>
                                    <View style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end", gap: 3 }}>
                                        <Text>Total Utang : </Text>
                                        <Text style={{ width: "120px", textAlign: "right" }}>
                                            <CustomCurrency value={totalUtang} />
                                        </Text>
                                    </View>
                                    <View style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end", gap: 3 }}>
                                        <Text>Total Utang Dibayar : </Text>
                                        <Text style={{ width: "120px", textAlign: "right" }}>
                                            <CustomCurrency value={totalUtangDibayar} />
                                        </Text>
                                    </View>
                                    <View style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end", gap: 3 }}>
                                        <Text>Sisa Utang : </Text>
                                        <Text style={{ width: "120px", textAlign: "right" }}>
                                            <CustomCurrency value={sisaUtang} />
                                        </Text>
                                    </View>
                                </View>
                            </View>



                            {/* Tabel */}
                            <View style={styles.table}>
                                {/* Header Tabel */}
                                <View style={styles.tableRow}>
                                    <View style={styles.tableColHeader}>
                                        <Text style={{ width: "50px", textAlign: "center", fontSize: "12pt" }}>No</Text>
                                    </View>
                                    <View style={styles.tableColHeader}>
                                        <Text style={{ width: "110px", textAlign: "center", fontSize: "12pt" }}>Tanggal</Text>
                                    </View>
                                    <View style={styles.tableColHeader}>
                                        <Text style={{ width: "120px", textAlign: "center", fontSize: "12pt" }}>Kode Transaksi</Text>
                                    </View>
                                    <View style={styles.tableColHeader}>
                                        <Text style={{ width: "100px", textAlign: "center", fontSize: "12pt" }}>Kategori Transaksi</Text>
                                    </View>
                                    <View style={styles.tableColHeader}>
                                        <Text style={{ width: "120px", textAlign: "center", fontSize: "12pt" }}>Nominal</Text>
                                    </View>
                                    <View style={{ width: "250px", textAlign: "center", fontSize: "12pt", backgroundColor: '#f0e68c', borderStyle: 'solid', borderBottomWidth: 1, borderRightWidth: 1, padding: 5, }}>
                                        <Text style={styles.tableCellHeader}>Deskripsi</Text>
                                    </View>
                                </View>

                                {/* Baris data */}
                                {
                                    dataTransaction &&
                                    dataTransaction.map((item, index) => (
                                        <View style={styles.tableRow} key={index}>
                                            <View style={styles.tableCol}>
                                                <Text style={{ width: "50px", textAlign: "center", fontSize: "10pt" }}>{index + 1}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={{ width: "110px", textAlign: "left", fontSize: "10pt" }}>
                                                    <CustomDay dateString={item.created_at} />,
                                                    <CustomDate dateString={item.created_at} />
                                                </Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={{ width: "120px", textAlign: "left", fontSize: "10pt" }}>{item?.code}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={{ width: "100px", textAlign: "center", fontSize: "10pt" }}>{item?.category?.name}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={{ width: "120px", textAlign: "right", fontSize: "10pt" }}>
                                                    <CustomCurrency value={item?.nominal} />
                                                </Text>
                                            </View>
                                            <View style={{ width: "250px", textAlign: "justify", fontSize: "10pt", borderStyle: 'solid', borderBottomWidth: 1, borderRightWidth: 1, padding: 5, }}>
                                                <Text style={styles.tableCell}>{item?.description} </Text>
                                            </View>
                                        </View>
                                    ))}
                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
            }
        </>
    )
}


export default UtangPdf