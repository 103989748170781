import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import LayoutLP from "../../../../components/layouts/layout_lp";
import InputSearch from "../../../../components/form/input_search";
import LoadingUnit from "../../../../components/loading/unit";
import CardUnit from "../../../../components/card/unit";
import { getUnits } from "../../../../services/unit/unit_service";
import { useNavigate } from "react-router-dom";
import CustomPaginate from "../../../../components/customs/paginate";
import BtnDefault from "../../../../components/buttons/btn_default";
import CustomDropdown from "../../../../components/customs/dropdown";


const UnitPage = () => {
    const [accessToken, setAccessToken] = useState('')
    const [userRole, setUserRole] = useState('')
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)
    const [fromNumber, setFromNumber] = useState(1)

    const [keyword, setKeyword] = useState('')
    const [category, setCategory] = useState("")
    const [status, setStatus] = useState("semua")

    const fetchData = async (keyword, currentPage) => {
        setLoading(true)
        try {
            const params = {
                page: currentPage,
                keyword: keyword,
                status: status
            }
            const response = await getUnits("", params);
            if (response.status == 200) {
                setData(response.data.data)
                setTotalData(response.data.meta.total)
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
                setFromNumber(response.data.meta.from)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        try {
            if (refresh) {
                fetchData(keyword, currentPage)
                setRefresh(false)
            }
        } catch (error) {
            alert('Silahkan periksa koneksi internet Anda..!')
        }
    }, [refresh])


    useEffect(() => {
        fetchData(keyword, currentPage)
    }, [status])

    useEffect(() => {
        try {
            fetchData(keyword, currentPage)
        } catch (error) {
            alert('Silahkan periksa koneksi internet Anda..!')
        }
    }, [])

    const optionStatus = [
        { 'value': "semua" },
        { 'value': "sudah terjual" },
        { 'value': "sudah dipesan" },
        { 'value': "belum terjual" }
    ]
    return (
        <>
            <Helmet>
                <title>DATA UNIT</title>
            </Helmet>
            <LayoutLP>
                <div className="w-full flex flex-col md:flex-row items-center justify-between px-5 py-3 border-b-2 border-custom-dark-yellow sticky top-16 left-0 z-10 bg-white gap-3">
                    <div className="flex items-center gap-3 w-full">
                        <InputSearch
                            handleSearch={(e) => {
                                setKeyword(e.target.value)
                                setCurrentPage(1)
                                fetchData(e.target.value, 1)
                            }}
                            keyword={keyword}
                            addClass="w-full lg:max-w-[300px]"
                        />
                        <p className="items-end gap-1 md:gap-3 font-semibold text-sm md:text-base hidden md:flex">
                            <span className="text-base md:text-xl">{totalData}</span>
                            <span>Unit</span>
                        </p>
                    </div>
                    <div className="flex items-center justify-between lg:justify-end gap-3 w-full">
                        <p className="flex md:hidden items-end gap-1 md:gap-3 font-semibold text-sm md:text-base">
                            <span className="text-base md:text-xl">{totalData}</span>
                            <span>Unit</span>
                        </p>
                        <CustomDropdown
                            name={<>
                                <p className="flex items-center gap-1 text-xs md:text-md">
                                    <FontAwesomeIcon icon={faFilter} />
                                    <span>{status}</span>
                                </p>
                            </>}
                        >
                            {optionStatus.map((item, index) => (
                                <BtnDefault
                                    handleClick={() => setStatus(item.value)}
                                    addClass="w-full text-xs md:text-base" inversColor={item.value === status ? false : true}>
                                    <p className="">{item.value}</p>
                                </BtnDefault>
                            ))}
                        </CustomDropdown>
                    </div>
                </div>

                {loading === true ?
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 md:gap-3 lg:gap-4 p-3 md:p-5">
                        {Array.from({ length: 20 }).map((_, index) => (
                            <LoadingUnit key={index} />
                        ))}
                    </div> :
                    totalData === 0 ? 'Tidak ada data' :
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 md:gap-3 lg:gap-4 p-3 md:p-5">
                            {data.map((item) => (
                                <CardUnit
                                    id={item?.id}
                                    image={item?.images[0]?.image}
                                    name={item?.name}
                                    category={item?.category}
                                    type={item?.type}
                                    price={item?.price}
                                    dp={item?.dp}
                                    costOfBooking={item?.cost_of_booking}
                                    status={item?.selling_status}
                                />
                            ))}
                        </div>
                }

                <div className="p-3 ">
                    <div className="px-3 bg-custom-dark-yellow flex items-center justify-center md:justify-end py-1">
                        <CustomPaginate
                            currentPage={currentPage}
                            lastPage={lastPage}
                            handlePaginate={(page) => {
                                setCurrentPage(page)
                                fetchData(keyword, page)
                            }}
                        />
                    </div>
                </div>

            </LayoutLP>
        </>
    )
}

export default UnitPage