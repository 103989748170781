import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import LandingPage from './pages/landing_page';
import Dashboard from './pages/dashboard';
import Piutang from './pages/dashboard/financial/piutang';
import Neraca from './pages/dashboard/financial/neraca';
import Finance from './pages/dashboard/financial';
import PiutangDetail from './pages/dashboard/financial/piutang/detail';
import Cashflow from './pages/dashboard/financial/cashflow';
import Transaction from './pages/dashboard/financial/transaction';
import JurnalUmum from './pages/dashboard/financial/jurnal-umum';
import Utang from './pages/dashboard/financial/utang';
import PiutangPrint from './pages/dashboard/financial/piutang/print';
import Account from './pages/dashboard/financial/account';
import FinancialReport from './pages/dashboard/financial/report';
import BigBook from './pages/dashboard/financial/big-book';
import Login from './pages/authenticate/login';
import Testimony from './pages/dashboard/content/testimony';
import Galery from './pages/dashboard/content/galery';
import Advantage from './pages/dashboard/content/advantage';
import Crediture from './pages/dashboard/crediture';
import CreditureDetail from './pages/dashboard/crediture/detail';
import Consument from './pages/dashboard/consument';
import Suplier from './pages/dashboard/logistic/suplier';
import Procurement from './pages/dashboard/logistic/procurement';
import ProcurementCreate from './pages/dashboard/logistic/procurement/create';
import ProcurementDetail from './pages/dashboard/logistic/procurement/detail';
import UsedMaterial from './pages/dashboard/logistic/used-material';
import UsedMaterialCreate from './pages/dashboard/logistic/used-material/create';
import Unit from './pages/dashboard/unit';
import UnitCreate from './pages/dashboard/unit/create';
import UnitDetail from './pages/dashboard/unit/detail';
import User from './pages/dashboard/user';
import Material from './pages/dashboard/logistic/material';
import Selling from './pages/dashboard/selling';
import UtangDetail from './pages/dashboard/financial/utang/detail';
import UtangPrint from './pages/dashboard/financial/utang/print';
import SellingDetail from './pages/dashboard/selling/detail';
import GaleryLp from './pages/landing_page/page/galery';
import ContactPage from './pages/landing_page/page/contact';
import UnitPage from './pages/landing_page/page/unit';
import TransactionCreate from './pages/dashboard/financial/transaction/create';
import UsedMaterialDetail from './pages/dashboard/logistic/used-material/detail';
import UnitDetailLp from './pages/landing_page/page/unit/detail';
import LabaRugi from './pages/dashboard/financial/laba_rugi';
import Register from './pages/authenticate/register';
import NoConnection from './pages/errors/no_connection';
import ConsumentDetail from './pages/dashboard/consument/detail';
import Setting from './pages/dashboard/setting';
import CashflowDetail from './pages/dashboard/financial/cashflow/detail';
import CashflowUpdate from './pages/dashboard/financial/cashflow/update';
import ForgotPassword from './pages/authenticate/forgot_password';
import VerifyEmail from './pages/authenticate/verify_email';
import CreateNewPassword from './pages/authenticate/create_new_password';
import UnitUpdate from './pages/dashboard/unit/update';
import MaterialDetail from './pages/dashboard/logistic/material/detail';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route path='/no-internet-connection' Component={NoConnection} />

      <Route path='/login' Component={Login} />
      <Route path='/register' Component={Register} />
      <Route path='/verify-email' Component={VerifyEmail} />
      <Route path='/forgot-password' Component={ForgotPassword} />
      <Route path='/create-new-password' Component={CreateNewPassword} />

      <Route path='/' Component={LandingPage} />
      <Route path='/galery' Component={GaleryLp} />
      <Route path='/contact' Component={ContactPage} />
      <Route path='/unit' Component={UnitPage} />
      <Route path='/unit/detail/:id' Component={UnitDetailLp} />
      <Route path='/dashboard' Component={Dashboard} />

      <Route path='/dashboard/user' Component={User} />

      <Route path='/dashboard/consument' Component={Consument} />
      <Route path='/dashboard/consument/detail/:id' Component={ConsumentDetail} />

      <Route path='/dashboard/selling' Component={Selling} />
      <Route path='/dashboard/selling/detail/:id' Component={SellingDetail} />
      
      <Route path='/dashboard/unit' Component={Unit} />
      <Route path='/dashboard/unit/detail/:id' Component={UnitDetail} />
      <Route path='/dashboard/unit/create' Component={UnitCreate} />
      <Route path='/dashboard/unit/update/:id' Component={UnitUpdate} />
      
      <Route path='/dashboard/crediture' Component={Crediture} />
      <Route path='/dashboard/crediture/detail/:id' Component={CreditureDetail} />

      <Route path='/dashboard/logistic/suplier' Component={Suplier} />

      <Route path='/dashboard/logistic/material' Component={Material} />
      <Route path='/dashboard/logistic/material/detail/:id' Component={MaterialDetail} />

      <Route path='/dashboard/logistic/procurement' Component={Procurement} />
      <Route path='/dashboard/logistic/procurement/create' Component={ProcurementCreate} />
      <Route path='/dashboard/logistic/procurement/detail/:id' Component={ProcurementDetail} />

      <Route path='/dashboard/logistic/used-material' Component={UsedMaterial} />
      <Route path='/dashboard/logistic/used-material/create' Component={UsedMaterialCreate} />
      <Route path='/dashboard/logistic/used-material/detail/:id' Component={UsedMaterialDetail} />

      <Route path='/dashboard/financial' Component={Finance} />
      <Route path='/dashboard/financial/account' Component={Account} />
      <Route path='/dashboard/financial/transaction' Component={Transaction} />
      <Route path='/dashboard/financial/transaction/create' Component={TransactionCreate} />
      <Route path='/dashboard/financial/jurnal-umum' Component={JurnalUmum} />

      <Route path='/dashboard/financial/utang' Component={Utang} />
      <Route path='/dashboard/financial/utang/detail/:id' Component={UtangDetail} />
      <Route path='/dashboard/financial/utang/detail/:id/print' Component={UtangPrint} />

      <Route path='/dashboard/financial/piutang' Component={Piutang} />
      <Route path='/dashboard/financial/piutang/detail/:id' Component={PiutangDetail} />
      <Route path='/dashboard/financial/piutang/detail/:id/print' Component={PiutangPrint} />

      <Route path='/dashboard/financial/neraca' Component={Neraca} />
      <Route path='/dashboard/financial/big-book' Component={BigBook} />

      <Route path='/dashboard/financial/arus-kas' Component={Cashflow} />
      <Route path='/dashboard/financial/arus-kas/detail/:id' Component={CashflowDetail} />
      <Route path='/dashboard/financial/arus-kas/update/:id' Component={CashflowUpdate} />

      <Route path='/dashboard/financial/report' Component={FinancialReport} />
      <Route path='/dashboard/financial/laba-rugi' Component={LabaRugi} />
      
      <Route path='/dashboard/content/testimony' Component={Testimony} />
      <Route path='/dashboard/content/galery' Component={Galery} />
      <Route path='/dashboard/content/advantage' Component={Advantage} />

      <Route path='/dashboard/setting' Component={Setting} />
    </Routes>
  </Router>
);

reportWebVitals();
