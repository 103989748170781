const LayoutModal = ({ name, children, open, width }) => {
    return (
        <div
            className={`fixed z-[10000] top-0 left-0 flex items-center justify-center bg-black/50 transition-all duration-700  
                ${open === true
                    ? "w-full h-screen px-5"
                    : "h-screen w-0 rounded-3xl delay-500"
                }`}
        >
            <div
                className={` bg-white rounded-2xl backdrop-blur-sm overflow-hidden flex flex-col justify-between transition-all duration-700 ${open === true
                    ? `${width ?? 'w-full lg:max-w-[700px]'} max-h-[95%] delay-500`
                    : `max-h-0 ${width ?? 'w-full lg:max-w-[700px]'} delay-0`
                    }`}
            >
                <div className="w-full absolute z-10 top-0 bg-custom-navy flex items-center justify-center">
                    <p className="text-center text-custom-dark-yellow text-base md:text-xl lg:text-2xl font-semibold px-3 py-3 uppercase">
                        {name}
                    </p>
                </div>

                <div className="w-full h-full px-5 space-y-3 py-[70px] overflow-auto scrollbar-thin scrollbar-track-custom-navy scrollbar-thumb-custom-dark-yellow">
                    {children}
                </div>
                
                <div className="w-full absolute bottom-0 bg-custom-navy flex items-center justify-center">
                    <p className="text-center text-custom-dark-yellow text-base md:text-xl lg:text-2xl font-semibold uppercase px-3 py-3">
                        TERRA APP
                    </p>
                </div>
            </div>
        </div >
    )
}

export default LayoutModal