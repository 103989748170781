import { faFacebook, faFacebookF, faInstagram, faTiktok, faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import Logo from "../../../assets/icons/logo_yellow_version.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import { Link } from "react-router-dom"

const Footer = () => {
    return (
        <div className="w-full bg-custom-navy">
            <div className="w-full text-custom-dark-yellow grid grid-cols-1 lg:grid-cols-5 gap-3 py-3 px-3 md:px-5 rounded-t-2xl">
                <div className="flex flex-col justify-center items-center">
                    <div className="w-48 aspect-square relative">
                    <img src={Logo} alt="Logo" layout="fill"  className="" />
                    </div>
                </div>
                <div className="hidden lg:block">
                    <p className="text-white font-bold mb-3 border-b-2">Sosial Media</p>
                    <div className="space-y-2">
                        <Link to={"/s"} className="flex items-center hover:scale-105 transition-all">
                            <FontAwesomeIcon icon={faInstagram} className="w-8 aspect-square" />
                            <p>Instagram</p>
                        </Link>
                        <Link to={"/s"} className="flex items-center hover:scale-105 transition-all">
                            <FontAwesomeIcon icon={faFacebookF} className="w-8 aspect-square" />
                            <p>Facbook</p>
                        </Link>
                        <Link to={"/s"} className="flex items-center hover:scale-105 transition-all">
                            <FontAwesomeIcon icon={faTiktok} className="w-8 aspect-square" />
                            <p>Tiktok</p>
                        </Link>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-3 w-full">
                    <div className="lg:hidden">
                        <p className="text-white font-bold mb-3 border-b-2">Sosial Media</p>
                        <div className="space-y-2">
                            <Link to={"/s"} className="flex items-center hover:scale-105 transition-all">
                                <FontAwesomeIcon icon={faInstagram} className="w-8 aspect-square" />
                                <p>Instagram</p>
                            </Link>
                            <Link to={"/s"} className="flex items-center hover:scale-105 transition-all">
                                <FontAwesomeIcon icon={faFacebookF} className="w-8 aspect-square" />
                                <p>Facbook</p>
                            </Link>
                            <Link to={"/s"} className="flex items-center hover:scale-105 transition-all">
                                <FontAwesomeIcon icon={faTiktok} className="w-8 aspect-square" />
                                <p>Tiktok</p>
                            </Link>
                        </div>
                    </div>
                    <div className="w-full">
                        <p className="text-white font-bold mb-3 border-b-2 w-full">Kontak</p>
                        <div className="space-y-2">
                            <Link to={"/s"} className="flex items-center hover:scale-105 transition-all">
                                <FontAwesomeIcon icon={faWhatsapp} className="w-8 aspect-square" />
                                <p>0853-2187-9991</p>
                            </Link>
                            <Link to={"/s"} className="flex items-center hover:scale-105 transition-all">
                                <FontAwesomeIcon icon={faEnvelope} className="w-8 aspect-square" />
                                <p>renjanapasopati@gmail.com</p>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className=" w-full h-full">
                    <p className="text-white font-bold mb-3 border-b-2">Alamat</p>
                    <p className="text-white text-xs md:text-sm lg:text-base">
                        Jl. Pasopati Blok Dangdeur, Ds. Cangkuang, Kec. Leles, Kab. Garut
                    </p>
                </div>
                <div className="w-full h-full md:mt-3 lg:mt-0">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15836.482562444678!2d107.88862995541992!3d-7.112015499999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68b7001e52a2cf%3A0x9e425e40dbe6a526!2sPERUMAHAN%20RENJANA%20PASOPATI!5e0!3m2!1sid!2sid!4v1721438380014!5m2!1sid!2sid"
                        className="w-full h-[200px] md:h-[300px] lg:h-full  rounded-xl"
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade" />
                </div>
            </div>
            <div className="border-t-2 border-custom-dark-yellow">
                <p className="text-center py-2 text-white font-semibold"> &copy; RESERVE BY TERRA</p>
            </div>
        </div>
    )
}

export default Footer