import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../components/layouts/layout_dashboard"
import CustomHeader from "../../../components/customs/header"
import BtnBack from "../../../components/buttons/btn_back"
import { useNavigate, useParams } from "react-router-dom"
import CardUnitDetail from "../../../components/card/unit_detail"
import BtnDefault from "../../../components/buttons/btn_default"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons"
import CustomAlert from "../../../components/customs/alert"
import CustomConfirm from "../../../components/customs/confirm"
import { useEffect, useState } from "react"
import { deleteUnit } from "../../../services/unit/unit_service"

const UnitDetail = () => {
    const [accessToken, setAccessToken] = useState('')
    const [userRole, setUserRole] = useState('')
    const [loading, setLoading] = useState(false)

    const { id } = useParams();
    const navigate = useNavigate()

    const [openAlert, setOpenAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [alertType, setAlertType] = useState('success')

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token-terra_app')
        const userRole = localStorage.getItem('user_role-terra_app')
        if (accessToken && userRole) {
            if (['admin', 'super admin', 'logistik', 'lapangan'].includes(userRole)) {
                setAccessToken(accessToken)
                setUserRole(userRole)
            } else {
                navigate('/login')
            }
        } else {
            navigate('/login')
        }
    }, [])

    const [openConfirm, setOpenConfirm] = useState(false)
    const handleDelete = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const response = await deleteUnit(accessToken, id)
            if (response.status === 200) {
                navigate("/dashboard/unit")
            } else {
                setOpenAlert(true)
                setAlertMessage(response.data.message ?? 'Gagal menghapus unit..!')
                setAlertType('error')
            }
        } catch (error) {
            setOpenAlert(true)
            setAlertMessage(error.response.data.message ?? 'Gagal menghapus unit..!')
            setAlertType('error')
        }
        setLoading(false)

    }
    return (
        <>
            <Helmet>Detail Unit</Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack link={`/dashboard/unit`} />
                    <p className="text-center text-sm md:text-base lg:text-lg font-semibold uppercase">Detail Unit</p>
                    <div className="flex items-center justify-end space-x-3">
                        <BtnDefault
                            addClass="w-fit"
                            handleClick={() => navigate(`/dashboard/unit/update/${id}`)}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                            <span className="hidden md:flex">Edit</span>
                        </BtnDefault>
                        <BtnDefault
                            addClass="w-fit bg-red-500 hover:bg-red-600 text-white"
                            handleClick={() => setOpenConfirm(true)}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                            <span className="hidden md:flex">Hapus</span>
                        </BtnDefault>
                    </div>
                </CustomHeader>

                <CardUnitDetail id={id} />

                <CustomAlert
                    open={openAlert}
                    setOpen={setOpenAlert}
                    message={alertMessage}
                    type={alertType}
                />

                <CustomConfirm
                    open={openConfirm}
                    setOpen={setOpenConfirm}
                    handleConfirm={handleDelete}
                    loading={loading}
                    message="Apakah anda yakin ingin menghapus unit ini?"
                />
            </LayoutDashboard>
        </>
    )
}

export default UnitDetail