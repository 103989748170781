import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import BtnBack from "../../../../components/buttons/btn_back"
import BtnDefault from "../../../../components/buttons/btn_default"
import CustomHeader from "../../../../components/customs/header"
import LayoutDashboard from "../../../../components/layouts/layout_dashboard"
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { getUsedMaterials } from "../../../../services/logistic/used_material_service"
import FormatDate from './../../../../components/format/date';
import CustomPaginate from "../../../../components/customs/paginate"
import InputSearch from "../../../../components/form/input_search"

const UsedMaterial = () => {
    const [accessToken, setAccessToken] = useState('')
    const [userRole, setUserRole] = useState('')
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [keyword, setKeyword] = useState('')

    const fetchData = async (accessToken, keyword, currentPage) => {
        try {
            const params = {
                accessToken: accessToken,
                page: currentPage,
                keyword: keyword
            }
            const response = await getUsedMaterials(accessToken, params)
            if (response.status === 200) {
                setData(response.data.data)
                setTotalData(response.data.meta.total)
                setLastPage(response.data.meta.last_page)
                setCurrentPage(response.data.meta.current_page)
            }
        } catch (error) {
            console.log("__")
        }
    }

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token-terra_app')
        const userRole = localStorage.getItem('user_role-terra_app')

        if (!accessToken || !userRole) {
            navigate('/login')
        } else {
            setAccessToken(accessToken)
            setUserRole(userRole)
            fetchData(accessToken, keyword, currentPage)
        }
    }, [])

    return (
        <>
            <Helmet>Data Penggunaan Barang</Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <div/>
                    <p className="text-center uppercase">Data Penggunaan Barang</p>
                    <div className="flex items-center justify-end">
                        <BtnDefault handleClick={() => navigate('/dashboard/logistic/used-material/create')}>
                            <FontAwesomeIcon icon={faPlus} />
                            <p className="hidden md:flex">Penggunaan Baru</p>
                        </BtnDefault>
                    </div>
                </CustomHeader>

                <div className="w-full flex flex-col md:flex-row items-center justify-between p-3 gap-3 text-xs md:text-sm sticky top-16 left-0">
                    <form
                        className="flex items-center gap-3 relative w-full md:w-[250px]"
                        onSubmit={(e) => {
                            e.preventDefault()
                            setCurrentPage(1)
                            fetchData(accessToken, keyword, 1)
                        }}
                    >
                        <InputSearch
                            value={keyword}
                            handleSearch={(e) => {
                                setKeyword(e.target.value)
                            }}
                            addClass={"w-full"}
                        />
                        <button type="submit" className="absolute right-[2px] px-3 py-2 md:py-[4px] bg-custom-dark-yellow rounded-r-full">
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </form>
                </div>
                
                <div className="px-3">
                    <table className="min-w-max md:min-w-full max-w-2xl">
                        <thead>
                            <tr className="bg-custom-dark-yellow">
                                <th className="px-2 py-3 rounded-tl-xl">No</th>
                                <th className="px-2 py-3">Tanggal</th>
                                <th className="px-2 py-3">Kode Penggunaan</th>
                                <th className="px-2 py-3">Alokasi Penggunaan</th>
                                <th className="px-2 py-3">Dibuat Oleh</th>
                                <th className="px-2 py-3 rounded-tr-xl">Jumlah Item</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.length === 0 ? "Tidak ada data..!" :
                                    data.map((item, index) => (
                                        <tr
                                            onClick={() => navigate(`/dashboard/logistic/used-material/detail/${item?.id}`)} key={index}
                                            className={`${index % 2 === 1 ? 'bg-gray-100' : ''} hover:bg-gray-200 cursor-pointer`}
                                        >
                                            <td className="px-2 py-3 text-center">{index + 1}</td>
                                            <td className="px-2 py-3">
                                                <FormatDate value={item?.created_at} />
                                            </td>
                                            <td className="px-2 py-3">{item.code}</td>
                                            <td className="px-2 py-3 capitalize">{item?.reference?.name ?? '-'}</td>
                                            <td className="px-2 py-3">{item?.created_by?.name ?? '-'}</td>
                                            <td className="px-2 py-3 text-center">{item?.items?.length ?? 0} item</td>
                                        </tr>
                                    ))}

                            {lastPage < 2 && (
                                <tr>
                                    <td colSpan={6} className="py-4 bg-custom-dark-yellow" />
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {lastPage > 1 && (
                    <div className="flex items-center justify-center px-3">
                        <div className="w-full bg-custom-dark-yellow flex items-center justify-center md:justify-end py-1 px-3">
                            <CustomPaginate
                                handlePageChange={(page) => {
                                    setCurrentPage(page)
                                    fetchData(accessToken)
                                }}
                                currentPage={currentPage}
                                lastPage={lastPage}
                            />
                        </div>
                    </div>
                )}
            </LayoutDashboard>
        </>
    )
}

export default UsedMaterial