import { useState } from "react";
import BtnDefault from "../../../../components/buttons/btn_default";
import CustomSpinner from "../../../../components/customs/spinner";
import InputDefault from "../../../../components/form/input_default";
import LayoutModal from './../../../../components/layouts/layout_modal';
import { useEffect } from "react";
import { postOtherUsedMaterial, updateOtherUsedMaterial } from "../../../../services/logistic/other_used_material_service";

const CreateOtherUsedMaterial = ({ accessToken, isOpen, setIsOpen, setRefresh, getId, getName }) => {
    const [id, setId] = useState('id')
    const [name, setName] = useState('');
    const [errorName, setErrorName] = useState(null);

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (getId) {
            setId(getId)
        }

        if (getName) {
            setName(getName)
        }

    }, [getId, getName])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            setErrorName(null);

            if (name.trim() === '') {
                setErrorName('Nama harus diisi..!');
            } else if (name.length < 3) {
                setErrorName('Nama minimal memiliki 3 huruf..!');
            } else {
                const formData = new FormData()
                formData.append('name', name)

                if (id) {
                    formData.append('id', id)
                    const response = await updateOtherUsedMaterial( accessToken, formData)
                    if (response.status === 200) {
                        setId("")
                        setName("")

                        setIsOpen && setIsOpen(false)
                        setRefresh && setRefresh(true)
                    } else if (response.status === 422) {
                        alert(`${response.data.message}`)
                    } else {
                        alert('gagal menyimpan data..!')
                    }
                } else {
                    const response = await postOtherUsedMaterial( accessToken, formData)
                    if (response.status === 201) {
                        setId("")
                        setName("")
                        
                        setIsOpen && setIsOpen(false)
                        setRefresh && setRefresh(true)
                    } else {
                        alert('gagal menyimpan data..!')
                    }
                }

            }
        } catch (error) {
            alert('Silahkan periksa koneksi internet Anda..!')
        }
        setLoading(false)
    }
    return (
        <LayoutModal  open={isOpen} setIsOpen={setIsOpen} name="Tambah Penggunaan Barang Lain">
              <form onSubmit={handleSubmit} className='w-full h-full px-3 py-20 flex flex-col gap-3 overflow-auto scrollbar-thin scrollbar-track-custom-navy scrollbar-thumb-custom-dark-yellow'>
                    <InputDefault
                        id="name"
                        name="Nama Lengkap"
                        value={name}
                        setValue={setName}
                        errorMessage={errorName ?? undefined}
                    />

                    <div className="w-full flex items-center gap-3 justify-evenly">
                        <BtnDefault type="button" handleClick={() => {
                            setId("")
                            setName("")

                            setIsOpen && setIsOpen(false)
                        }} addClass='bg-red-500 w-full '>
                            Kembali
                        </BtnDefault>
                        <BtnDefault type="submit" addClass='w-full'>
                            {loading ?
                                <CustomSpinner /> :
                                "Simpan"
                            }
                        </BtnDefault>
                    </div>
                </form>
        </LayoutModal>
    )
}

export default CreateOtherUsedMaterial