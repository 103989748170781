import { faAngleLeft} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"

const BtnBack = ({addClass, link}) => {
    const navigate = useNavigate()
    return (
        <button type="button" onClick={() => navigate(link ?? -1)} className={`flex items-center gap-3 font-semibold bg-red-500 md:bg-transparent text-white md:text-black rounded-full px-3 py-2 ${addClass} `}>
            <FontAwesomeIcon icon={faAngleLeft} />
            <p className="hidden md:flex">Kembali</p>
        </button>
    )
}

export default BtnBack