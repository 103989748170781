
import ApiService from '../base/api_service';

export const getUtang    = async (accessToken, crediturId, page, keyword) => {
    try {
        const response = await ApiService.get(`/utang`, {
            params: {
                creditur_id: crediturId,
                page: page,
                keyword: keyword
            },
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const postUtang = async (accessToken, data) => {
    try {
        const response = await ApiService.post('/utang/create', data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}