
import ApiService from '../base/api_service';

export const getMandors = async (accessToken, keyword, page ) => {
    try {
        const response = await ApiService.get(`/mandors`, {
            params: {
                page: page,
                keyword: keyword
            },
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const showMandor = async (accessToken, id) => {
    try {
        const response = await ApiService.get(`/mandor/show/${id}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const postMandor = async (accessToken, data) => {
    try {
        const response = await ApiService.post(`/mandor/create`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const updateMandor = async (accessToken, data) => {
    try {
        const response = await ApiService.post(`/mandor/update`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}
