import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const InputPassword = ({
    id, type, placeholder, label,
    value, handleChange, setValue, errorMessage, addClass, disabled,
}) => {
    const [showPassword, setShowPassword] = useState(false)
    return (
        <div className="flex flex-col text-xs md:text-sm ">
            <label htmlFor={id} className="font-semibold capitalize">{label}</label>
            <div className="relative w-full flex items-center">
                <input
                    id={id} type={showPassword ? "text" : "password"}
                    inputMode="text"
                    value={value ?? ''}
                    onChange={(e) => {
                        if (handleChange) {
                            handleChange(e);
                        } else if (setValue) {
                            setValue(e.target.value);
                        }
                    }}

                    disabled={disabled ?? false}

                    placeholder={placeholder}
                    className={`w-full outline-none border-2 hover:border-custom-navy focus:border-custom-navy transition-colors rounded-full px-3 py-2 bg-white text-black  ${addClass}`}
                />
                <button type="button" onClick={() => setShowPassword(!showPassword)} className="absolute right-3">
                    {!showPassword ?
                        <FontAwesomeIcon icon={faEyeSlash} /> :
                        <FontAwesomeIcon icon={faEye} />
                    }
                </button>
            </div>
            {errorMessage && <p className="text-[10px] md:text-xs font-semibold text-red-500">{errorMessage}</p>}
        </div>
    );
};

export default InputPassword;