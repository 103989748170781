
import ApiService from '../base/api_service';

export const getReportFinance = async (accessToken, params) => {
    try {
        const response = await ApiService.get('/report-finance', {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const getReportFinanceChart = async (accessToken, params) => {
    try {
        const response = await ApiService.get('/report-finance/chart', {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}
