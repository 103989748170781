
const FormatDate = ({ value }) => {
    const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];

    const date = new Date(value);

    const dayName = days[date.getDay()];
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const year = date.getFullYear();

    const formattedDate = `${dayName}, ${day}-${month}-${year}`;

    return formattedDate
};

export default FormatDate;

