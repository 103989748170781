import { useEffect, useState } from "react"
import BtnDefault from "../../../../components/buttons/btn_default"
import LayoutModal from "../../../../components/layouts/layout_modal"
import CustomSpinner from "../../../../components/customs/spinner"
import InputDefault from "../../../../components/form/input_default"
import SelectDefault from "../../../../components/form/select_default"
import { getAccountCategory, postAccount, updateAccount } from "../../../../services/finance/account_service"
import SelectFilter from "../../../../components/form/select_filter"

const AccountUpdate = ({
    accessToken, userRole,
    open, setOpen,
    setRefresh,
    dataUpdate,
    setAlert,
    setAlertType,
    setAlertMessage,
}) => {
    const [loading, setLoading] = useState(false)
    const [loadingCategory, setLoadingCategory] = useState(false)

    const [id, setId] = useState("")
    const [name, setName] = useState("")
    const [category, setCategory] = useState("aktiva")
    const [postType, setPostType] = useState("debit")

    const [errorName, setErrorName] = useState(null)
    const [errorId, setErrorId] = useState(null)
    const [errorCategoryId, setErrorCategoryId] = useState(null)
    const [errorPostType, setErrorPostType] = useState(null)

    const [dataCategory, setDataCategory] = useState([])
    const fetchDataCategory = async (accessToken) => {
        setLoadingCategory(true)
        try {
            const response = await getAccountCategory(accessToken)
            if (response.status === 200) {
                setDataCategory(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingCategory(false)
    }

    useEffect(() => {
        if (dataUpdate) {
            setId(dataUpdate.id)
            setName(dataUpdate.name)
            setCategory(dataUpdate.category)
            setPostType(dataUpdate.post_type)
        }
        fetchDataCategory(accessToken)
    }, [dataUpdate])

    const dataOptionCategory = [
        { 'name': 'aktiva' },
        { 'name': 'pasiva' },
        { 'name': 'ekuitas' },
        { 'name': 'pendapatan' },
        { 'name': 'beban' },
    ]

    const dataOption = [
        { 'name': 'debit' },
        { 'name': 'kredit' },
    ]

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            setErrorId(null)
            setErrorName(null)
            setErrorCategoryId(null)
            setErrorPostType(null)

            const formData = new FormData()
            formData.append('name', name)
            formData.append('post_type', postType)

            const response = await updateAccount(accessToken, formData, dataUpdate.id)
            if (response.status === 200) {
                setOpen(false)
                setRefresh(true)
                setAlert(true)
                setAlertType("success")
                setAlertMessage("Akun berhasil diubah..!")
            } else if (response.status === 403) {
                setAlert(true)
                setAlertType("error")
                setAlertMessage(response.data.message)
            }else{
                setAlert(true)
                setAlertType("error")
                setAlertMessage("Akun gagal diubah..!")
            }
        } catch (error) {
            setAlert(true)
            setAlertType("error")
            setAlertMessage("Silahkan periksa koneksi internet Anda..!")
        }
        setLoading(false)
    }
    return (
        <LayoutModal name="Ubah Akun" open={open}>
            <form onSubmit={handleSubmit} className="flex flex-col gap-3">


                <InputDefault
                    id="name"
                    type='text'
                    name="ID Akun"
                    placeholder={"000"}
                    value={id}
                    setValue={setId}
                    errorMessage={errorId}
                    addClass=""
                    disabled={true}
                />

                <InputDefault
                    id="name"
                    type='text'
                    name="Nama Akun"
                    value={name}
                    setValue={setName}
                    errorMessage={errorName}
                    addClass="uppercase"
                />

                <SelectDefault
                    name='Tipe Post'
                    options={dataOption.map((item) => ({ value: item?.name, label: item?.name }))}
                    value={postType}
                    onSelect={(e) => setPostType(e)}
                />

                <div className="w-full flex items-center gap-3 justify-evenly">
                    <BtnDefault type="button" handleClick={() => {
                        setOpen(false)
                    }} addClass='bg-red-500 w-full '>
                        Kembali
                    </BtnDefault>
                    <BtnDefault type="submit" addClass='w-full'>
                        {loading === true ? <CustomSpinner addClass='' /> : "Simpan"}
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default AccountUpdate
