const TextAreaDefault = ({
    id, label, value, handleChange, setValue, errorMessage, addClass, rows
}) => {

    return (
        <div className="flex flex-col text-xs md:text-sm lg:text-base">
            <label htmlFor={id} className="font-semibold">{label}</label>
            <textarea
                id={id}
                value={value ?? ''}
                onChange={(e) => {
                    if (handleChange) {
                        handleChange();
                    } else if (setValue) {
                        setValue(e.target.value);
                    }
                }}
                rows={rows ?? 5}
                className={`outline-none border-2 focus:border-custom-navy rounded-xl px-3 py-2 bg-white ${addClass}`}
            />
            {errorMessage && <p className="text-[10px] md:text-sm font-semibold text-red-500">{errorMessage}</p>}
        </div>
    );
};

export default TextAreaDefault;
