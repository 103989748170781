import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useNavigate } from "react-router-dom"
import LayoutDashboard from "../../../../components/layouts/layout_dashboard"
import CustomHeader from "../../../../components/customs/header"
import BtnDefault from './../../../../components/buttons/btn_default';
import SuplierCreate from "./create"
import { getSupliers } from "../../../../services/user_management/suplier_service"
import InputSearch from "../../../../components/form/input_search"

const Suplier = () => {
    const [accessToken, setAccessToken] = useState('')
    const [userRole, setUserRole] = useState('')
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)


    const [openModalAdd, setOpenModalAdd] = useState(false)

    const [data, setData] = useState([])
    const [keyword, setKeyword] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)

    const fetchData = async (accessToken, keyword, page) => {
        try {
            setLoading(true)
            const response = await getSupliers(accessToken, keyword, page)
            if (response.status === 200) {
                setData(response.data.data)
            }
        } catch (error) {
            console.log('--')
        }
        setRefresh(false)
        setLoading(false)
    }

    useEffect(() => {
        const newAccessToken = localStorage.getItem('access_token-terra_app') ?? ''
        const newUserRole = localStorage.getItem('user_role-terra_app') ?? ''
        if (!newAccessToken) {
            navigate('/login')
        } else if (!['super admin', 'admin', 'lapangan', 'keuangan', 'logistik'].includes(newUserRole)) {
            navigate('/403')
        } else {
            setAccessToken(newAccessToken)
            setUserRole(newUserRole)
            fetchData(newAccessToken)
        }
    }, [accessToken, userRole])

    useEffect(() => {
        if (refresh === true) {
            fetchData(accessToken)
        }
    }, [refresh])
    return (
        <>
            <Helmet>
                <title>Data Suplier</title>
            </Helmet>

            <LayoutDashboard>
                <CustomHeader>
                    <div></div>
                    <p className="text-center">Data Suplier</p>
                    <div className="flex items-center justify-end">
                        <BtnDefault handleClick={() => setOpenModalAdd(true)}>
                            <FontAwesomeIcon icon={faPlus} />
                            <p className="hidden md:flex">Suplier Baru</p>
                        </BtnDefault>
                    </div>
                </CustomHeader>

                
                <div className="w-full flex p-3">
                    <InputSearch
                        value={keyword}
                        handleSearch={(e) => {
                            setKeyword(e.target.value)
                            setCurrentPage(1)
                            fetchData(accessToken, e.target.value, 1)
                        }}
                        addClass={"w-full md:w-[250px]"}
                    />
                </div>

                <div className="px-3 pb-5">
                    <table className="min-w-full max-w-screen-2xl text-xs md:text-sm">
                        <thead>
                            <tr className="bg-custom-dark-yellow">
                                <th className="px-3 py-2 rounded-tl-xl">No</th>
                                <th className="px-3 py-2">ID Suplier</th>
                                <th className="px-3 py-2 rounded-tr-xl">Nama Suplier</th>
                            </tr>
                        </thead>
                        {loading ?
                            Array.from({ length: 20 }).map(() => (
                                <tr className="animate-pulse">
                                    {Array.from({ length: 3 }).map(() => (
                                        <td className="px-3 py-2">
                                            <div className="w-full h-5 rounded-full bg-gray-300" />
                                        </td>
                                    ))}
                                </tr>
                            )) :
                            data.map((item, index) => (
                                <tr className={`hover:bg-gray-300 transition-colors cursor-pointer ${index % 2 === 1 ? 'bg-gray-200' : ''}`} key={item.id}>
                                    <td className="px-3 py-2 text-center">{index + 1}</td>
                                    <td className="px-3 py-2 capitalize">{item?.id}</td>
                                    <td className="px-3 py-2 capitalize">{item?.name}</td>
                                </tr>
                            ))
                        }
                        <tr className="bg-custom-dark-yellow">
                            <td className="py-4" colSpan={10}></td>
                        </tr>
                    </table>
                </div>

                {/* Modal */}
                <SuplierCreate  isOpen={openModalAdd} setIsOpen={setOpenModalAdd} setRefresh={setRefresh} accessToken={accessToken} userRole={userRole} />
            </LayoutDashboard>
        </>
    )
}

export default Suplier