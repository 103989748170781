
import ApiService from '../base/api_service';

export const getOtherUsedMaterials = async (accessToken) => {
    try {
        const response = await ApiService.get(`/other-used-materials`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const postOtherUsedMaterial = async (accessToken, data) => {
    try {
        const response = await ApiService.post(`/other-used-material/create`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const updateOtherUsedMaterial = async (accessToken, data) => {
    try {
        const response = await ApiService.post(`/other-used-material/update`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}