import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useNavigate } from "react-router-dom"
import LayoutDashboard from "../../../../components/layouts/layout_dashboard"
import CustomHeader from "../../../../components/customs/header"
import BtnDefault from "../../../../components/buttons/btn_default"
import FormatDate from "../../../../components/format/date"
import CustomCurrency from "../../../../components/customs/currency"
import { getProcurements } from "../../../../services/logistic/procurment_service"
import CustomPaginate from "../../../../components/customs/paginate"
import InputSearch from "../../../../components/form/input_search"
import CustomDropdown from "../../../../components/customs/dropdown"
import Suplier from './../suplier/index';

const Procurement = () => {
    const [accessToken, setAccessToken] = useState('')
    const [userRole, setUserRole] = useState('')
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)


    const [openModalAdd, setOpenModalAdd] = useState(false)

    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    let [from, setFrom] = useState(1)
    const [keyword, setKeyword] = useState('')

    const [paymentStatus, setPaymentStatus] = useState('semua')
    const optionPaymentStatus = [
        { value: 'semua', label: 'Semua' },
        { value: 'dibayar sebagian', label: 'Dibayar Sebagian' },
        { value: 'belum dibayar', label: 'Belum Dibayar' },
        { value: 'sudah dibayar', label: 'Sudah Dibayar' },
    ]

    const [procurementStatus, setProcurementStatus] = useState('semua')
    const optionProcurementStatus = [
        { value: 'semua', label: 'Semua' },
        { value: 'pre order', label: 'Pre Order' },
        { value: 'dalam proses', label: 'Dalam Proses' },
        { value: 'selesai', label: 'Selesai' }
    ]

    const fetchData = async (accessToken, keyword, currentPage) => {
        try {
            setLoading(true)
            const params = {
                page: currentPage,
                keyword: keyword,
                payment_status: paymentStatus,
                status: procurementStatus
            }
            const response = await getProcurements(accessToken, params)
            if (response.status === 200) {
                setData(response.data.data)
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
                setFrom(response.data.meta.from)
            }
        } catch (error) {
            console.log('--')
        }
        setLoading(false)
    }

    useEffect(() => {
        const newAccessToken = localStorage.getItem('access_token-terra_app') ?? ''
        const newUserRole = localStorage.getItem('user_role-terra_app') ?? ''
        if (!newAccessToken) {
            navigate('/login')
        } else if (!['super admin', 'admin', 'lapangan', 'keuangan', 'logistik'].includes(newUserRole)) {
            navigate('/403')
        } else {
            setAccessToken(newAccessToken)
            setUserRole(newUserRole)
            fetchData(newAccessToken, keyword, currentPage)
        }
    }, [accessToken, userRole])

    useEffect(() => {
        if (refresh) {
            fetchData(accessToken, keyword, currentPage)
            setRefresh(false)
        }

        

    }, [refresh])


    useEffect(() => {
        if (procurementStatus || paymentStatus) {
            fetchData(accessToken, keyword, currentPage)
        }
    }, [procurementStatus, paymentStatus])


    return (
        <>
            <Helmet>Data Pengadaan Barang</Helmet>

            <LayoutDashboard>
                <CustomHeader>
                    <div />
                    <p className="text-center">Data Pengadaan Barang</p>
                    <div className="flex items-center justify-end">
                        <BtnDefault handleClick={() => navigate('/dashboard/logistic/procurement/create')}>
                            <FontAwesomeIcon icon={faPlus} />
                            <p className="hidden md:flex">Pengadaan Baru</p>
                        </BtnDefault>
                    </div>
                </CustomHeader>

                <div className="px-3 py-3 flex flex-col md:flex-row items-center justify-between sticky top-16 bg-white left-0 z-50 gap-3">
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        setCurrentPage(1)
                        fetchData(accessToken, keyword, 1)
                    }}
                        className="w-full md:w-[250px] relative flex items-center"
                    >
                        <InputSearch
                            value={keyword}
                            handleSearch={(e) => setKeyword(e.target.value)}
                            addClass="w-full "
                        />
                        <button type="submit" className="absolute right-[2px] bg-custom-dark-yellow px-3 py-2 md:py-1 rounded-r-full">
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </form>

                    <div className=" grid grid-cols-2 md:flex items-center gap-3 w-full md:w-fit ">
                        <CustomDropdown
                            name="Status Pengadaan"
                            addClass="w-full text-xs md:text-sm" >
                            {optionProcurementStatus.map((item, index) => (
                                <button
                                    type="button"
                                    onClick={() => {
                                        setProcurementStatus(item.value)
                                        setRefresh(true)
                                    }}
                                    key={index}
                                    className={`${procurementStatus === item.value ? 'bg-custom-dark-yellow' : 'bg-custom-navy text-white'} rounded-full px-3 py-1 font-semibold hover:bg-custom-dark-yellow hover:text-black transition-colors text-xs md:text-sm`}
                                >
                                    {item.value}
                                </button>
                            ))}
                        </CustomDropdown>

                        <CustomDropdown
                            name="Status Pembayaran"
                            addClass="w-full text-xs md:text-sm" >
                            {optionPaymentStatus.map((item, index) => (
                                <button
                                    type="button"
                                    key={index}
                                    onClick={() => {
                                        setPaymentStatus(item.value)
                                        setRefresh(true)
                                    }}
                                    className={`${paymentStatus === item.value ? 'bg-custom-dark-yellow' : 'bg-custom-navy text-white'} rounded-full px-3 py-1 font-semibold hover:bg-custom-dark-yellow hover:text-black transition-colors text-xs md:text-sm`}
                                >
                                    {item.value}
                                </button>
                            ))}
                        </CustomDropdown>
                    </div>
                </div>

                <div className="px-3 ">
                    <table className="min-w-max max-w-screen-2xl text-xs md:text-sm">
                        <thead className="sticky top-[168px] md:top-[124px] z-10">
                            <tr className="bg-custom-dark-yellow">
                                <th className="px-3 py-2 rounded-tl-xl">No</th>
                                <th className="px-3 py-2">Tanggal</th>
                                <th className="px-3 py-2">Kode Pengadaan</th>
                                <th className="px-3 py-2">Status Pembayaran</th>
                                <th className="px-3 py-2">Status Pengadaan</th>
                                <th className="px-3 py-2">Nama Suplier</th>
                                <th className="px-3 py-2">Dibuat Oleh</th>
                                <th className="px-3 py-2">Jumlah Barang</th>
                                <th className="px-3 py-2">Total Pembayaran</th>
                                <th className="px-3 py-2 rounded-tr-xl">Sisa Pembayaran</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ?
                                Array.from({ length: 20 }).map(() => (
                                    <tr className="animate-pulse">
                                        {Array.from({ length: 10 }).map(() => (
                                            <td className="px-3 py-2">
                                                <div className="w-full h-5 rounded-full bg-gray-300" />
                                            </td>
                                        ))}
                                    </tr>
                                )) :
                                data.length < 1 ? "Tidak ada data..!" :
                                data.map((item, index) => (
                                    <tr onClick={() => navigate(`detail/${item?.id}`)} className={`hover:bg-gray-300 transition-colors cursor-pointer ${index % 2 === 1 ? 'bg-gray-200' : ''}`} key={item.id}>
                                        <td className="px-3 py-2 text-center">{index + 1}</td>
                                        <td className="px-3 py-2">
                                            <FormatDate value={item?.created_at} />
                                        </td>
                                        <td className="px-3 py-2">{item?.code}</td>
                                        <td className="px-3 py-2">
                                            <div
                                                className={`px-3 rounded-full py-1 text-center font-semibold text-white ${item?.payment_status === "dibayar sebagian"
                                                    ? "bg-yellow-600"
                                                    : item?.payment_status === "sudah dibayar"
                                                        ? "bg-green-500"
                                                        : "bg-red-500"
                                                    }`}
                                            >
                                                {item?.payment_status}
                                            </div>
                                        </td>
                                        <td className="px-3 py-2">
                                            <div
                                                className={`px-3 rounded-full py-1 text-center font-semibold text-white ${item?.status === "dalam proses"
                                                    ? "bg-yellow-600"
                                                    : item?.status === "selesai"
                                                        ? "bg-green-500"
                                                        : "bg-red-500"
                                                    }`}
                                            >
                                                {item?.status}
                                            </div>
                                        </td>
                                        <td className="px-3 py-2 capitalize">{item?.suplier ?? '-'}</td>
                                        <td className="px-3 py-2 capitalize">{item?.created_by}</td>
                                        <td className="px-3 py-2 text-center">{item?.items.length} Item</td>
                                        <td className="px-3 py-2 text-end">
                                            <CustomCurrency value={item?.total_price} />
                                        </td>
                                        <td className="px-3 py-2 text-end font-semibold">
                                            <CustomCurrency value={item?.remaining_payment} />
                                        </td>

                                    </tr>
                                ))
                            }
                            {lastPage === 1 && (
                                <tr>
                                    <td colSpan={9} className="text-center py-4 bg-custom-dark-yellow"/>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {lastPage > 1 && (
                    <div className="sticky bottom-0 left-0 w-full">
                    <div className="w-full items-center flex justify-center md:justify-end px-3 bg-custom-dark-yellow py-1">
                        <CustomPaginate
                            currentPage={currentPage}
                            lastPage={lastPage}
                            setCurrentPage={setCurrentPage}
                            setRefresh={setRefresh}
                            addClass="text-xs md:text-sm"
                            handlePaginate={(e) => {
                                setCurrentPage(e)
                                setRefresh(true)
                            }}
                        />
                        </div>
                    </div>
                )}
            </LayoutDashboard>
        </>
    )
}

export default Procurement