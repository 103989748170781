import { useState } from "react"
import BtnDefault from "../../../components/buttons/btn_default"
import CustomSpinner from "../../../components/customs/spinner"
import LayoutModal from "../../../components/layouts/layout_modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUpload } from "@fortawesome/free-solid-svg-icons"
import { postUnitImage } from "../../../services/unit/unit_service"

const UnitAddImage = ({ open, setOpen, setRefresh, accessToken, unitId, setOpenAlert, setAlertMessage, setAlertType }) => {
    const [loading, setLoading] = useState(false)

    const [images, setImages] = useState([])
    const [error, setError] = useState(null)
    const handleImageChange = (e) => {
        const files = Array.from(e.target.files || []);
        const newImages = files.map(file => ({
            file,
            url: URL.createObjectURL(file)
        }));
        setImages(prevImages => [...prevImages, ...newImages]);
    };

    const handleRemoveImage = (index) => {
        setImages(prevImages => prevImages.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {

            if (images.length === 0) {
                setError('Gambar tidak boleh kosong..!')
            } else {
                const data = new FormData()
                data.append('unit_id', unitId)
                images.forEach((image, index) => {
                    data.append(`images[${index}]`, image.file)
                })
                const response = await postUnitImage(accessToken, data)
                if (response.status === 201) {
                    setImages([])
                    setOpen(false)
                    setRefresh(true)
                    setOpenAlert(true)
                    setAlertMessage("Gambar berhasil ditambahkan..!")
                    setAlertType('success')
                } else {
                    setOpenAlert(true)
                    setAlertMessage("Gagal menambahkan gambar..!")
                    setAlertType('error')
                }
            }
        } catch (error) {
            setOpenAlert(true)
            setAlertMessage("Gagal menambahkan gambar..!")
            setAlertType('error')
        }

        setLoading(false)

    }
    return (
        <LayoutModal name={"Tambah Gambar"} open={open} setOpen={setOpen}>
            <form onSubmit={handleSubmit}>
                <div className="p-3">
                    <div className="flex flex-col items-center justify-center mb-5">
                        <label htmlFor="file-upload" className="cursor-pointer px-3 py-2 flex flex-col items-center space-x-2 border-2  border-dashed rounded-xl w-full hover:border-custom-navy bg-gray-50">
                            <div className="flex items-center gap-3 font-semibold">
                                <FontAwesomeIcon icon={faUpload} />
                                <p>Upload Gambar</p>
                            </div>
                            <p className="text-sm text-gray-700">{images.length} gambar dipilih</p>
                        </label>
                        <input
                            id="file-upload"
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={handleImageChange}
                            className="mr-3 hidden"
                        />
                    </div>

                    <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 ">
                        {images.map((image, index) => (
                            <div key={index} className="relative border shadow-md rounded-lg overflow-hidden">
                                <img
                                    src={image.url}
                                    alt={`Preview ${index}`}
                                    className="w-full aspect-square object-cover"
                                />
                                <button
                                    type="button"
                                    onClick={() => handleRemoveImage(index)}
                                    className="absolute top-0 right-0 bg-red-500 hover:bg-red-600 text-white rounded-full aspect-square w-6"
                                >
                                    &times;
                                </button>
                            </div>
                        ))}
                    </div>
                    {error && <p className="text-red-500 text-sm text-center">{error}</p>}
                </div>
                <div className="w-full flex items-center gap-3 justify-evenly">
                    <BtnDefault type="button" handleClick={() => {
                        setImages([])
                        setOpen(false)
                    }} addClass='bg-red-500 w-full '>
                        Kembali
                    </BtnDefault>
                    <BtnDefault type="submit" addClass='w-full'>
                        {loading === true ? <CustomSpinner addClass='' /> : "Simpan"}
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default UnitAddImage