
import ApiService from '../base/api_service';

export const getConsument = async (accessToken, keyword, page) => {
    try {
        const response = await ApiService.get('/consuments', {
            params: {
                keyword: keyword,
                page: page
            },
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const showConsument = async (accessToken, id) => {
    try {
        const response = await ApiService.get(`/consument/show/${id}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const postConsument = async (accessToken, data) => {
    try {
        const response = await ApiService.post('/consument/create', data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const updateConsument = async (accessToken, data, id) => {
    try {
        const response = await ApiService.post(`/consument/update/${id}`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const deleteConsument = async (accessToken, id) => {
    try {
        const response = await ApiService.post(`/consument/delete/${id}`, {}, {
            headers: {
                Authorization: accessToken,
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}
