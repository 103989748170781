
import ApiService from '../base/api_service';

export const getProfile = async ({ accessToken }) => {
    try {
        const response = await ApiService.get(`/profile`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const updateProfile = async ({ accessToken, data }) => {
    try {
        const response = await ApiService.post(`/profile/update`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        })

        return response
    } catch (error) {
        return error.response;
    }
}

export const updatePassword = async ({ accessToken, data }) => {
    try {
        const response = await ApiService.post(`/profile/update-password`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response;
    }
}