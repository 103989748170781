

const FormatDateV2 = ({ date }) => {
    const hari = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
    const [tahun, bulan, tanggal] = date.split('-');
    const dateObj = new Date(`${tahun}-${bulan}-${tanggal}`);
    const namaHari = hari[dateObj.getDay()];
    return `${namaHari}, ${tanggal}-${bulan}-${tahun}`;
}

export default FormatDateV2;