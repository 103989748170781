import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarReguler } from '@fortawesome/free-regular-svg-icons';
import { faStar, faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";

const CustomRating = ({ rating }) => {
    return (
        <div className="flex items-center gap-1">
            {Array.from({ length: 5 }).map((_, index) => {
                if (rating === 0) {
                    return <FontAwesomeIcon key={index} icon={faStarReguler} className="text-yellow-500" />;
                } else if (rating >= index + 1) {
                    return <FontAwesomeIcon key={index} icon={faStar} className="text-yellow-500" />;
                } else if (rating > index && rating < index + 1) {
                    return <FontAwesomeIcon key={index} icon={faStarHalfStroke} className="text-yellow-500" />;
                } else {
                    return <FontAwesomeIcon key={index} icon={faStarReguler} className="text-yellow-500" />;
                }
            })}
        </div>
    )
}

export default CustomRating