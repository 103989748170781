
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useHref, useNavigate } from "react-router-dom";

const CustomAccording = ({ name, height, children, link }) => {
    const [open, setOpen] = useState(false)
    const router = useHref()
    return (
        <div className="w-full relative text-xs md:text-sm lg:text-base">
            <button type="button" onClick={() => setOpen(!open)} className={`w-full rounded-full px-3 py-1 border-2 hover:bg-custom-dark-yellow hover:text-custom-navy text-sm transition-colors border-custom-dark-yellow font-semibold relative flex items-center justify-center ${router.startsWith(link || '/404') ? 'bg-custom-dark-yellow text-custom-navy' : 'bg-custom-navy text-custom-dark-yellow'}`}>
                {name}
                <FontAwesomeIcon icon={faCaretDown} className={`absolute right-3 transition-all duration-500 ${open === true ? '-rotate-180' : 'rotate-0'}`} />
            </button>
            <div className={`w-full rounded-xl bg-black/30 overflow-hidden flex flex-col space-y-2 transition-all pl-3 lg:pl-5 pr-3 duration-500 ${open === true ? `max-h-[500px] py-3` : 'max-h-0 py-0'}`}>
                {children}
            </div>
        </div>
    )
}

export default CustomAccording