import { useEffect } from "react";
import LayoutModal from "../../../../components/layouts/layout_modal";
import { getUnits } from "../../../../services/unit/unit_service";
import { getConsument } from "../../../../services/user_management/consument_service";
import { useState } from "react";
import InputDefault from "../../../../components/form/input_default";
import BtnDefault from './../../../../components/buttons/btn_default';
import CustomCurrency from './../../../../components/customs/currency';
import CustomSpinner from "../../../../components/customs/spinner";
import { postUnitSelling } from "../../../../services/unit/unit_selling_service";

const Booking = ({
    accessToken, open, setOpen, setRefresh,
    setOpenAlert, setMessageAlert, setTypeAlert,
    getData
}) => {

    const [loading, setLoading] = useState(false)
    const [nominal, setNominal] = useState("0")
    const [errorNominal, setErrorNominal] = useState("")

    const [consumentId, setConsumentId] = useState("")
    const [errorConsument, setErrorConsument] = useState("")

    const [dataConsument, setDataConsument] = useState([])
    const [currentPageConsument, setCurrentPageConsument] = useState(1)
    const [lastPageConsument, setLastPageConsument] = useState(1)
    const [keywordConsument, setKeywordConsument] = useState("")
    const [loadingConsument, setLoadingConsument] = useState(false)

    const [unitId, setUnitId] = useState("")
    const [dp, setDp] = useState(0)
    const [bookingFee, setBookingFee] = useState(0)
    const [hookFee, setHookFee] = useState(0)
    const [akadFee, setAkadFee] = useState(0)
    const [notarisFee, setNotarisFee] = useState(0)
    const [total, setTotal] = useState(0)

    const handleChange = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/[^0-9]/g, '');
        setNominal(numericValue === '' ? '0' : numericValue);
    };

    const formatCurrency = (value) => {
        const numberValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
        return isNaN(numberValue) || numberValue < 1 ? '0' : numberValue.toLocaleString('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {

            
            setErrorNominal(null)


                const formData = new FormData()
                formData.append('unit_id', unitId)

                const response = await postUnitSelling(accessToken, formData)

                if (response.status === 201) {
                    setOpenAlert(true)
                    setMessageAlert("Berhasil menambahkan data penjualan..!")
                    setTypeAlert("success")
                    setOpen(false)
                    setRefresh(true)
                } else {
                    setOpenAlert(true)
                    setMessageAlert(response.data.message ?? "Gagal menambahkan data penjualan..!")
                    setTypeAlert("error")
            }
        } catch (error) {
            setOpenAlert(true)
            setMessageAlert("Gagal menambahkan data penjualan")
            setTypeAlert("error")
        }
        setLoading(false)
    }

    return (
        <LayoutModal open={open} setOpen={setOpen} name={"Pesan Unit"}>
            <form className="flex flex-col gap-3" onSubmit={handleSubmit}>

                <table className='w-full'>
                    <tr>
                        <td className="px-3">
                            Biaya Proses
                        </td>
                        <td className="text-right px-3">
                            <CustomCurrency value={bookingFee} />
                        </td>
                    </tr>
                    <tr>
                        <td className="px-3">
                            DP
                        </td>
                        <td className="text-right px-3">
                            <CustomCurrency value={dp} />
                        </td>
                    </tr>
                    <tr>
                        <td className="px-3">
                            Biaya Hook
                        </td>
                        <td className="text-right px-3">
                            <CustomCurrency value={hookFee} />
                        </td>
                    </tr>
                    <tr>
                        <td className="px-3">
                            Biaya Akad
                        </td>
                        <td className="text-right px-3">
                            <CustomCurrency value={akadFee} />
                        </td>
                    </tr>
                    <tr>
                        <td className="px-3">
                            Biaya Notaris
                        </td>
                        <td className="text-right px-3">
                            <CustomCurrency value={notarisFee} />
                        </td>
                    </tr>
                    <tr className='font-bold border-t-2 border-custom-navy'>
                        <td className="px-3">
                            Total Pembayaran
                        </td>
                        <td className="text-right px-3">
                            <CustomCurrency value={total} />
                        </td>
                    </tr>
                </table>

                <InputDefault
                    id="nominal"
                    type='text'
                    label="Nominal"
                    value={formatCurrency(nominal)}
                    handleChange={handleChange}
                    errorMessage={errorNominal}
                    addClass={"text-right"}
                />


                <div className="w-full flex items-center gap-3 justify-evenly">
                    <BtnDefault type="button" handleClick={() => {
                        setOpen(false)
                    }} addClass='bg-red-500 w-full '>
                        Kembali
                    </BtnDefault>
                    <BtnDefault type="submit" addClass='w-full'>
                        {loading === true ? <CustomSpinner addClass='' /> : "Simpan"}
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default Booking;