import Logo from '../../../assets/icons/logo_yellow_version.png'
import { useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"
import SidebarSuperAdmin from './menu/super_admin'
import HandleClickOutside from '../../utils/handle_click_outside'
import { useEffect } from 'react'
import SidebarAdmin from './menu/admin'
import SidebarLogistic from './menu/logistic'
import SidebarKeuangan from './menu/keuangan'
import SidebarConsument from './menu/consument'
import CheckInternetConnection from '../../utils/check_internet_connection'

const Sidebar = () => {
    const [userRole, setUserRole] = useState(null);
    const [openMenu, setOpenMenu] = useState(false)
    const ref = useRef(null)

    let [menu, setMenu] = useState(null)

    useEffect(() => {
        const userRole = localStorage.getItem("user_role-terra_app");
        setUserRole(userRole)
        switch (userRole) {
            case 'super admin':
                setMenu(<SidebarSuperAdmin />)
                break;

            case 'admin':
                setMenu(<SidebarAdmin />)
                break;

            case 'keuangan':
                setMenu(<SidebarKeuangan />)
                break;

            case 'lapangan':
                setMenu(<SidebarLogistic />)
                break;

            case 'logistik':
                setMenu(<SidebarLogistic />)
                break;

            case 'konsumen':
                setMenu(<SidebarConsument />)
                break;

            default:
                break;
        }

    }, [])
    return (
        <>
            {/* Desktop Menu */}
            <div className="w-72 h-screen bg-custom-navy overflow-auto scrollbar-none scrollbar-thumb-custom-navy scrollbar-track-custom-dark-yellow hidden lg:flex flex-col">
                <div className="w-full aspect-square border-b-2 sticky top-0 bg-custom-navy flex items-center justify-center">
                    <img src={Logo} alt="logo" layout="fill" className="w-[70%] aspect-square object-contain" />
                </div>
                <div className="w-full flex flex-col gap-2 px-3 text-sm pt-3 pb-5 ">
                    {menu}
                </div>
            </div>

            {/* Mobile Menu */}
            <div className="fixed bottom-10 right-8 z-50 lg:hidden" ref={ref}>
                <button type="button" onClick={() => setOpenMenu(!openMenu)} className="bg-custom-dark-yellow w-14 aspect-square rounded-full absolute bottom-0 right-0 z-10 shadow-md ">
                    <FontAwesomeIcon icon={faHome} />
                </button>
                <div className={` bg-black/30 rounded-xl absolute bottom-5 right-5 p-3 backdrop-blur-sm`}>
                    <div className={`flex flex-col gap-3 overflow-auto scrollbar-none ${openMenu ? 'w-[250px] max-h-[400px]' : 'w-0 max-h-0'} transition-all duration-500`}>
                        {menu}
                    </div>
                </div>
            </div>

            <HandleClickOutside setOpen={setOpenMenu} filterRef={ref} />
        </>
    )
}

export default Sidebar