import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../../components/layouts/layout_dashboard"
import CustomHeader from "../../../../components/customs/header"
import BtnBack from "../../../../components/buttons/btn_back"
import { getAccountBeban, getAccountPendapatan } from "../../../../services/finance/account_service"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import CustomCurrency from "../../../../components/customs/currency"
import BtnDefault from "../../../../components/buttons/btn_default"
import CustomSpinner from "../../../../components/customs/spinner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileExcel } from "@fortawesome/free-solid-svg-icons"
import { exportLabaRugi } from "../../../../services/finance/laba_rugi"

const LabaRugi = () => {
    const [accessToken, setAccessToken] = useState("")
    const [userRole, setUserRole] = useState("")
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [loadingAccountPendapatan, setLoadingAccountPendapatan] = useState(false)
    const [loadingAccountBeban, setLoadingAccountBeban] = useState(false)

    const [dataAccountPendapatan, setDataAccountPendapatan] = useState([])
    const fetchDataAccountPendapatan = async (accessToken) => {
        setLoadingAccountPendapatan(true)
        try {
            const response = await getAccountPendapatan({ accessToken });
            if (response.status === 200) {
                setDataAccountPendapatan(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingAccountPendapatan(false)
    };

    const [dataAccountBeban, setDataAccountBeban] = useState([])
    const fetchDataAccountBeban = async (accessToken) => {
        setLoadingAccountBeban(true)
        try {
            const response = await getAccountBeban({ accessToken });
            if (response.status === 200) {
                setDataAccountBeban(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingAccountBeban(false)
    };

    useEffect(() => {
        const accessToken = localStorage.getItem("access_token-terra_app")
        const userRole = localStorage.getItem("user_role-terra_app")
        if (accessToken && userRole) {
            setAccessToken(accessToken)
            setUserRole(userRole)

            fetchDataAccountPendapatan(accessToken)
            fetchDataAccountBeban(accessToken)
        }
    }, [])


    const totalBalancePendapatan = dataAccountPendapatan.reduce((data, item) => data + (item.ballance || 0), 0);
    const totalBalanceBeban = dataAccountBeban.reduce((data, item) => data + (item.ballance || 0), 0);

    const MyTr = ({ name, value }) => {
        return (
            <tr>
                <td className="font-semibold capitalize">{name}</td>
                <td className="font-semibold px-2">:</td>
                <td>{value}</td>
            </tr>
        )
    }

    const [loadingExport, setLoadingExport] = useState(false)
    const handleExport = async () => {
        setLoadingExport(true)
        try {
            const params = {
                year:  new Date().getFullYear(),
            }
            const response = await exportLabaRugi(accessToken, params)
            if (response.status === 200) {
                const generateRandomString = (length) => {
                    return Math.random().toString(36).substring(2, 2 + length);
                };
                const uniqid = Date.now() + '-' + generateRandomString(5);
                const fileName = `laba_rugi_${uniqid}.xlsx`;

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();

                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingExport(false)
    }

    return (
        <>
            <Helmet>
                <title>Laba Rugi</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack />
                    <p className="font-semibold text-center">Laba Rugi</p>
                    <div className="flex items-center justify-end gap-2">
                        <BtnDefault handleClick={handleExport}>
                            {loadingExport ? <CustomSpinner /> :
                                <>
                                    <FontAwesomeIcon icon={faFileExcel} />
                                    <p className='hidden md:flex'>Export Data</p>
                                </>
                            }
                        </BtnDefault>
                    </div>
                </CustomHeader>

                <div className="px-3 pt-3 sticky left-0">
                    <MyTr name="Total Pendapatan"
                        value={
                            <CustomCurrency value={totalBalancePendapatan} />
                        }
                    />

                    <MyTr name="Total Beban / Biaya"
                        value={
                            <CustomCurrency value={totalBalanceBeban} />
                        }
                    />

                    <MyTr name="Laba Rugi"
                        value={
                            <p className="font-semibold">
                                <CustomCurrency value={totalBalancePendapatan - totalBalanceBeban} />
                            </p>
                        }
                    />
                </div>

                <div className="p-3 flex flex-col gap-3">
                    <div>
                        <p className="font-semibold uppercase">Pendapatan</p>
                        <table className="max-w-screen-2xl min-w-max md:min-w-full">
                            <thead>
                                <tr className="bg-custom-dark-yellow">
                                    <th className="px-3 py-2 rounded-tl-xl">Id</th>
                                    <th className="px-3 py-2">Nama</th>
                                    <th className="px-3 py-2">Debet</th>
                                    <th className="px-3 py-2">Kredit</th>
                                    <th className="px-3 py-2 rounded-tr-xl">Total Saldo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loadingAccountPendapatan ? "Loading..." :
                                    dataAccountPendapatan.map((item, index) => (
                                        <tr className={`hover:bg-gray-300 transition-colors ${index % 2 === 1 ? "bg-gray-200" : ""}`}>
                                            <td className="px-3 py-2 w-20">{item.id}</td>
                                            <td className="px-3 py-2 text-start">{item.name}</td>
                                            <td className="px-3 py-2 text-end font-semibold">
                                                <CustomCurrency value={item?.debit} />
                                            </td>
                                            <td className="px-3 py-2 text-end font-semibold">
                                                <CustomCurrency value={item?.credit} />
                                            </td>
                                            <td className="px-3 py-2 text-end font-semibold">
                                                <CustomCurrency value={item?.ballance} />
                                            </td>
                                        </tr>
                                    ))
                                }
                                <tr className="bg-custom-dark-yellow font-semibold">
                                    <td colSpan={2} className="px-3 py-5 text-start">Sub Total</td>
                                    <td className="px-3 py-5 text-end font-semibold">
                                        <CustomCurrency value={dataAccountPendapatan.reduce((acc, item) => acc + (item.debit || 0), 0)} />
                                    </td>
                                    <td className="px-3 py-5 text-end font-semibold">
                                        <CustomCurrency value={dataAccountPendapatan.reduce((acc, item) => acc + (item.credit || 0), 0)} />
                                    </td>
                                    <td className="px-3 py-5 text-end font-semibold">
                                        <CustomCurrency value={totalBalancePendapatan} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <p className=" font-semibold uppercase">Beban / Biaya</p>
                        <table className="max-w-screen-2xl min-w-max md:min-w-full">
                            <thead>
                                <tr className="bg-custom-dark-yellow">
                                    <th className="px-3 py-2 rounded-tl-xl">Id</th>
                                    <th className="px-3 py-2">Nama</th>
                                    <th className="px-3 py-2">Debet</th>
                                    <th className="px-3 py-2">Kredit</th>
                                    <th className="px-3 py-2 rounded-tr-xl">Total Saldo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loadingAccountBeban ? "Loading..." :
                                    dataAccountBeban.map((item, index) => (
                                        <tr className={`hover:bg-gray-300 transition-colors ${index % 2 === 1 ? "bg-gray-200" : ""}`}>
                                            <td className="px-3 py-2 w-20">{item.id}</td>
                                            <td className="px-3 py-2 text-start">{item.name}</td>
                                            <td className="px-3 py-2 text-end font-semibold">
                                                <CustomCurrency value={item?.debit} />
                                            </td>
                                            <td className="px-3 py-2 text-end font-semibold">
                                                <CustomCurrency value={item?.credit} />
                                            </td>
                                            <td className="px-3 py-2 text-end font-semibold">
                                                <CustomCurrency value={item?.ballance} />
                                            </td>
                                        </tr>
                                    ))
                                }
                                <tr className="bg-custom-dark-yellow font-semibold">
                                    <td colSpan={2} className="px-3 py-5 text-start">Sub Total</td>
                                    <td className="px-3 py-5 text-end font-semibold">
                                        <CustomCurrency value={dataAccountBeban.reduce((acc, item) => acc + (item.debit || 0), 0)} />
                                    </td>
                                    <td className="px-3 py-5 text-end font-semibold">
                                        <CustomCurrency value={dataAccountBeban.reduce((acc, item) => acc + (item.credit || 0), 0)} />
                                    </td>
                                    <td className="px-3 py-5 text-end font-semibold">
                                        <CustomCurrency value={totalBalanceBeban} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </LayoutDashboard>
        </>
    )
}

export default LabaRugi