import { Helmet } from "react-helmet";
import LayoutErrorPage from "../../components/layouts/layout_error_page";
import MyImage from "../../assets/images/no_internet_access.jpg";
import BtnDefault from "../../components/buttons/btn_default";
import CheckInternetConnection from "../../components/utils/check_internet_connection";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomSpinner from "../../components/customs/spinner";

const NoConnection = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    CheckInternetConnection({ isOnline, setIsOnline });

    const handleRefresh = () => {
        setLoading(true);
        if (navigator.onLine) {
            navigate(-1);
        } else {
            alert("Masih tidak ada koneksi internet. Silakan coba lagi.");
        }
        setLoading(false);
    };

    return (
        <>
            <Helmet>
                <title>Tidak Ada Koneksi Internet</title>
            </Helmet>
            <LayoutErrorPage>
                <div className="w-full h-full flex flex-col items-center justify-center gap-5">
                    <img
                        src={MyImage}
                        alt="no internet connection"
                        className="h-[50%] aspect-square object-contain"
                    />
                    <h1 className="text-2xl font-bold">Tidak Ada Koneksi Internet</h1>
                    <p className="text-sm">Mohon periksa koneksi internet Anda dan coba lagi.</p>
                    <BtnDefault addClass="w-[300px]" handleClick={handleRefresh}>
                        {loading ? <CustomSpinner /> : "Refresh"}
                    </BtnDefault>
                </div>
            </LayoutErrorPage>
        </>
    );
}

export default NoConnection;
