
import ApiService from '../base/api_service';

export const getTransaction = async (accessToken, params) => {
    try {

        const response = await ApiService.get(`/transaction`, {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const postTransaction = async (accessToken, data) => {
    try {
        const response = await ApiService.post("/transaction/create", data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response;
    }
}

export const exportTransaction = async (accessToken, params) => {
    try {
        const response = await ApiService.get(`/transaction/export`, {
            params: params,
            responseType: 'blob',
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response;
    }
} 

export const exportJurnal = async (accessToken, params) => {
    try {
        const response = await ApiService.get(`/transaction/export-jurnal`, {
            params: params,
            responseType: 'blob',
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response;
    }
}   