import { useEffect, useState } from "react";

import Logo from '../../assets/icons/only_logo.png'
import TextLogo from '../../assets/icons/only_text_logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import NavLink from './../links/link_navbar';
import { Link, useHref } from "react-router-dom";

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [accessToken, setAccessToken] = useState('')
    const currentPath = useHref()

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const newAccessToken = localStorage.getItem('access_token-terra_app');
        setAccessToken(newAccessToken || '');
    }, [])

    return (
        <>
            <div className="w-full sticky top-0 z-50 bg-custom-navy flex flex-col">
                <div className="w-full h-16 z-20 border-b-2 border-custom-dark-yellow text-custom-dark-yellow grid grid-cols-3 px-3 xs:px-5 md:px-10 relative">
                    <div className="flex items-center">
                        <img src={Logo} alt="Logo Terra" className="h-10 aspect-square object-contain" />
                        <img src={TextLogo} alt="Text Logo Terra" className="h-8 object-contain" />
                    </div>
                    <div className="md:hidden"></div>
                    <div className="hidden md:flex items-center justify-center space-x-3">
                        <NavLink name="Home" link="/" />
                        <NavLink name="Unit" link="/unit" />
                        <NavLink name="Galeri" link="/galery" />
                        <NavLink name="Kontak" link="/contact" />
                    </div>
                    <div className="hidden md:flex items-center justify-end space-x-3">
                        {!accessToken ? (
                            <Link to="/login" className="px-3 py-1 rounded-full bg-white border border-custom-dark-yellow w-32 text-custom-navy font-semibold hover:bg-custom-dark-yellow transition-all text-center">
                                Login
                            </Link>
                        ) : (
                            <Link to="/dashboard" className="px-3 py-1 rounded-full bg-white border border-custom-dark-yellow w-32 text-custom-navy font-semibold hover:bg-custom-dark-yellow transition-all text-center">
                                Dashboard
                            </Link>
                        )}
                    </div>
                    <div className="md:hidden flex items-center justify-end relative">
                        <button onClick={toggleMenu} className="justify-self-end text-2xl">
                            <FontAwesomeIcon icon={faBars} className={`text-custom-dark-yellow transition-transform duration-300 ${isMenuOpen ? 'scale-0' : 'scale-100'}`} />
                        </button>
                        <button onClick={toggleMenu} className="justify-self-end text-2xl absolute right-0">
                            <FontAwesomeIcon icon={faClose} className={`text-red-500 transition-transform duration-300 ${isMenuOpen ? 'scale-100' : 'scale-0'}`} />
                        </button>
                    </div>
                </div>

                {/* mobile menu */}
                <div className={`md:hidden static z-0 flex flex-col justify-center items-center gap-2 text-xs md:text-sm lg:text-base transition-all duration-700 overflow-hidden
                ${isMenuOpen ? 'h-[250px] md:h-[300px] py-3 px-5' : 'h-0 space-y-0 py-0 px-0 '}`}>
                    <Link to="/" className={`w-full z-0 text-center font-bold  hover:text-custom-navy hover:bg-custom-dark-yellow px-3 py-1 transition-colors rounded-full ${currentPath === "/" ? "bg-custom-dark-yellow text-custom-navy" : "bg-transparent text-custom-dark-yellow "}`}>
                        Home
                    </Link>
                    <Link to="/unit" className={`w-full z-0 text-center font-bold  hover:text-custom-navy hover:bg-custom-dark-yellow px-3 py-1 transition-colors rounded-full ${currentPath === "/unit" ? "bg-custom-dark-yellow text-custom-navy" : "bg-transparent text-custom-dark-yellow "}`}>
                        Unit
                    </Link>
                    <Link to="/galery" className={`w-full z-0 text-center font-bold  hover:text-custom-navy hover:bg-custom-dark-yellow px-3 py-1 transition-colors rounded-full ${currentPath === "/about" ? "bg-custom-dark-yellow text-custom-navy" : "bg-transparent text-custom-dark-yellow "}`}>
                        Galeri
                    </Link>
                    <Link to="/contact" className={`w-full z-0 text-center font-bold  hover:text-custom-navy hover:bg-custom-dark-yellow px-3 py-1 transition-colors rounded-full ${currentPath === "/kontak" ? "bg-custom-dark-yellow text-custom-navy" : "bg-transparent text-custom-dark-yellow "}`}>
                        Kontak
                    </Link>
                    {!accessToken ? (
                        <Link to="/login" className="px-3 py-1 rounded-full bg-white border border-custom-dark-yellow w-32 text-custom-navy font-semibold hover:bg-custom-dark-yellow transition-all text-center">
                            Login
                        </Link>
                    ) : (
                        <Link to="/dashboard" className="px-3 py-1 rounded-full bg-white border border-custom-dark-yellow w-32 text-custom-navy font-semibold hover:bg-custom-dark-yellow transition-all text-center">
                            Dashboard
                        </Link>
                    )}
                </div>
            </div>
        </>
    )
}

export default Navbar