import { faCaretDown, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useRef } from 'react';
import HandleClickOutside from '../utils/handle_click_outside';

const SelectFilter = ({
    options, label,
    value, onSelect,
    placeholder = "Select an option...",
    addClass, plusOption, handleUpdate, loading,
    position,
    errorMessage,
    widthName,
    widthContent
}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [selectedOption, setSelectedOption] = useState(null);

    const filterRef = useRef(null);

    useEffect(() => {
        // Set selected option based on value prop
        const selected = options.find(option => option.value === value);
        setSelectedOption(selected || null);
    }, [value, options]);

    useEffect(() => {
        if (searchTerm === '') {
            setFilteredOptions(options);
        } else {
            setFilteredOptions(
                options.filter(option =>
                    option.label.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        }
    }, [searchTerm, options]);

    const handleSelect = (option) => {
        setSelectedOption(option);
        onSelect(option.value);
        setIsOpen(false);
    };

    return (
        <>
            <div className="custom-select-container relative text-xs md:text-sm" ref={filterRef}>
                <label htmlFor="" className='font-semibold'>{label}</label>
                <div
                    className={`selected-option bg-white rounded-full px-3 py-2 border-2 hover:border-custom-navy focus:border-custom-navy transition-colors outline-none cursor-pointer flex items-center justify-between gap-3 capitalize  ${isOpen ? "border-custom-navy" : ""} ${addClass} ${widthName ? widthName : "w-full"}`}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <p className='line-clamp-1'>
                        {selectedOption ? selectedOption.label : placeholder}
                    </p>
                    <FontAwesomeIcon icon={faCaretDown} className={`transition-all ${isOpen ? '-rotate-90' : 'rotate-0'}`} />
                </div>
                {errorMessage && <p className='text-red-500 text-[10px] md:text-sm font-semibold'>{errorMessage}</p>}
                {isOpen && (
                    <div
                        className={`options-dropdown absolute top-[100%] border-2 border-custom-navy rounded-xl max-h-[300px] min-w-full mt-2 bg-white z-[1000] px-3 pb-3 overflow-auto scrollbar-thin scrollbar-thumb-custom-navy scrollbar-track-custom-dark-yellow scrollbar-track-rounded-full scrollbar-thumb-rounded-full gap-3 flex flex-col ${widthContent ? widthContent : "w-full"} ${position ? position : "left-0"}`}
                    >
                        <div className="bg-white pt-3 sticky top-0">
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                placeholder="Search..."
                                className='w-full rounded-xl px-3 py-2 border-2 focus:border-custom-navy outline-none '
                            />
                        </div>
                        <div>
                            {plusOption}
                        </div>
                        {
                            loading ? "Loading...!" :
                                filteredOptions.length > 0 ? (
                                    filteredOptions.map((option) => (
                                        <div
                                            key={option.value}
                                            onClick={() => handleSelect(option)}
                                            className="option-item flex items-center justify-between hover:bg-gray-200 rounded-full px-3 capitalize"
                                            style={{ padding: '8px', cursor: 'pointer', borderBottom: '1px solid #eee' }}
                                        >
                                            <p>{option.label}</p>
                                            {handleUpdate &&
                                                <button type='button' onClick={() => handleUpdate(option)} className="text-yellow-500 hover:text-yellow-600 transition-colors ">
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </button>
                                            }
                                        </div>
                                    ))
                                ) : (
                                    <div className="no-options" style={{ padding: '8px' }}>
                                        No options found
                                    </div>
                                )
                        }
                    </div>
                )}
            </div>
            <HandleClickOutside setOpen={setIsOpen} filterRef={filterRef} />
        </>
    );
};

export default SelectFilter;
