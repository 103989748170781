
import ApiService from '../base/api_service';

export const getDebiturCreditur = async (accessToken, category, keyword, page) => {
    try {
        const response = await ApiService.get(`/debitur-creditur?category=${category}&page=${page}&keyword=${keyword}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const showDebiturCreditur = async (accessToken, id) => {
    try {
        const response = await ApiService.get(`/debitur-creditur/show/${id}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const postDebiturCreditur = async (accessToken, data) => {
    try {
        const response = await ApiService.post(`/debitur-creditur/create`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const updateDebiturCreditur = async (accessToken, data, id) => {
    try {
        const response = await ApiService.post(`/debitur-creditur/update/${id}`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const deleteDebiturCreditur = async (accessToken, id) => {
    try {
        const response = await ApiService.post(`/debitur-creditur/delete/${id}`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response;
    }
}