
import ApiService from '../base/api_service';

export const getDebiturCrediturCategory = async (accessToken ) => {
    try {
        const response = await ApiService.get(`/debitur-creditur/category`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const postDebiturCrediturCategory = async (accessToken, data) => {
    try {
        const response = await ApiService.post(`/debitur-creditur/category/create`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const updateDebiturCrediturCategory = async (accessToken, data) => {
    try {
        const response = await ApiService.post(`/debitur-creditur/category/update`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}