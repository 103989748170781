import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LayoutDashboard from '../../../components/layouts/layout_dashboard';
import CustomHeader from './../../../components/customs/header';
import BtnBack from './../../../components/buttons/btn_back';
import BtnDefault from '../../../components/buttons/btn_default';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faExclamationTriangle, faEye, faPlus, faSearch, faTrash, faUser } from '@fortawesome/free-solid-svg-icons';
import UserCreate from './create';
import CustomAlert from './../../../components/customs/alert';
import { deleteUser, getUsers } from '../../../services/user_management/user_service';
import CustomConfirm from '../../../components/customs/confirm';
import UserUpdate from './update';
import { getUserRole } from '../../../services/user_management/user_role_service';
import InputSearch from '../../../components/form/input_search';
import { BaseUrl } from '../../../services/base/base_url';
const User = () => {
    const [accessToken, setAccessToken] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const [open, setOpen] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const confirmRef = useRef(null);

    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    let [from, setFrom] = useState(1);
    const [keyword, setKeyword] = useState("");

    const fetchData = async (accessToken, keyword, page) => {
        setLoading(true);
        try {
            const params = {
                keyword: keyword,
                page: page
            }
            const response = await getUsers(accessToken, params);
            if (response && response.status === 200) {
                setData(response.data.data);
                setTotalData(response.data.meta.total);
                setCurrentPage(response.data.meta.current_page);
                setLastPage(response.data.meta.last_page);
                setFrom(response.data.meta.from);
            }
        } catch (error) {
            console.log("__");
        }
        setLoading(false);
    }

    const [dataUserRole, setDataUserRole] = useState([]);
    const fetchUserRole = async () => {
        setLoading(true);
        try {
            const response = await getUserRole(accessToken);
            if (response.status === 200) {
                setDataUserRole(response.data.data);
            }
        } catch (error) {
            console.log("__");
        }
        setLoading(false);
    }

    useEffect(() => {
        if (refresh) {
            setRefresh(false);
            fetchData(accessToken, keyword, currentPage);
        }
    }, [refresh])

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token-terra_app');
        const userRole = localStorage.getItem('user_role-terra_app');
        if (!accessToken) {
            navigate('/login');
        } else {
            setAccessToken(accessToken);
            setUserRole(userRole);
            fetchData(accessToken, keyword, currentPage);
            fetchUserRole();
        }
    }, [navigate])

    const [userId, setUserId] = useState(null);

    const [openAlert, setOpenAlert] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("");

    const [deleteId, setDeleteId] = useState(null);

    const handleConfirmDelete = async () => {
        if (deleteId) {
            setLoading(true);
            try {
                const response = await deleteUser(accessToken, deleteId);
                if (response.status === 200) {
                    setDeleteId(null);
                    setOpenAlert(true);
                    setTypeAlert('success');
                    setMessageAlert('Berhasil menghapus data..!');
                    setOpenConfirm(false);
                    setRefresh(true);
                } else {
                    setOpenAlert(true);
                    setTypeAlert('error');
                    setMessageAlert('Hapus data gagal..!');
                }
            } catch (error) {
                setOpenAlert(true);
                setTypeAlert('error');
                setMessageAlert('Silahkan periksa koneksi internet Anda..!');
            }
        }
        setLoading(false);
    };

    const [dataUpdate, setDataUpdate] = useState(null);
    return (
        <>
            <Helmet>Pengguna</Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <div className='hidden md:flex'/>
                    <p className='text-center uppercase'>Data Pengguna</p>
                    <div className='flex items-center justify-end'>
                        <BtnDefault handleClick={() => setOpen(true)}>
                            <FontAwesomeIcon icon={faPlus} />
                            <p className='hidden md:flex'>Pengguna Baru</p>
                        </BtnDefault>
                    </div>
                </CustomHeader>

                <div className="p-3 sticky top-16 left-0 bg-white z-10">
                    <form className="flex items-center gap-3 relative w-full md:w-[300px]"
                        onSubmit={(e) => {
                            e.preventDefault()
                            setCurrentPage(1)
                            fetchData(accessToken, keyword, 1)
                        }}
                    >
                        <InputSearch
                            value={keyword}
                            handleSearch={(e) => {
                                setKeyword(e.target.value)
                            }}
                            addClass={"w-full"}
                        />
                        <button type="submit" className="absolute right-[2px] px-3 py-2 md:py-[2px] bg-custom-dark-yellow rounded-r-full">
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </form>
                </div>

                <div className="w-full px-5 py-3">
                    <table className={`max-w-2xl min-w-max md:min-w-full`}>
                        <thead>
                            <tr className="bg-custom-dark-yellow">
                                <th className="px-3 py-2 w-fit rounded-tl-xl">No</th>
                                <th className="px-3 py-2">Foto Profil</th>
                                <th className="px-3 py-2 text-start">Nama Staf</th>
                                <th className="px-3 py-2 text-start">email</th>
                                <th className="px-3 py-2 border-b-2">User Role</th>
                                <th className="px-3 py-2 w-fit rounded-tr-xl" colSpan={3}>
                                    Aksi
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading === true ? (
                                "Loading..."
                            ) : (
                                data.length === 0 ? "Data tidak ditemukan" :
                                    data && data.map((item, index) => (
                                        <tr className={`${index % 2 === 1 && 'bg-gray-200'} hover:bg-gray-300 transition-colors`}>
                                            <td className="px-3 py-2 text-center w-fit">
                                                {from++}
                                            </td>
                                            <td className="px-3 py-2 ">
                                                <div className="w-12 aspect-square rounded-full bg-custom-dark-yellow mx-auto flex items-center justify-center relative overflow-hidden ">
                                                    {item?.image ?
                                                        <img
                                                            src={BaseUrl + "/storage/" + item?.image}
                                                            alt="profile"
                                                            className="w-full h-full object-cover"
                                                        /> : <FontAwesomeIcon icon={faUser} className="text-2xl" />}
                                                </div>
                                            </td>
                                            <td className="px-3 py-2 capitalize">{item.name}</td>
                                            <td className="px-3 py-2">{item.email}</td>
                                            <td className="px-3 py-2 text-center">{item?.user_role?.name}</td>
                                            <td className="px-3 py-2 w-10">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setDataUpdate(item);
                                                        setOpenUpdate(true);
                                                    }}
                                                    className="px-2 py-1 bg-yellow-500 hover:bg-yellow-600 rounded-md text-white transition-colors"
                                                >
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </button>
                                            </td>

                                            <td className="px-3 py-2 w-10 relative">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setDeleteId(item.id);
                                                        setOpenConfirm(true);
                                                    }}
                                                    className="px-2 py-1 bg-red-500 hover:bg-red-600 rounded-md text-white transition-colors"
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                            )}

                            <tr className="bg-custom-dark-yellow">
                                <td className="py-4" colSpan={8} />
                            </tr>
                        </tbody>
                    </table>
                </div>

                <UserCreate
                    accessToken={accessToken} open={open} setOpen={setOpen}
                    setUserId={setUserId} setOpenAlert={setOpenAlert}
                    setMessageAlert={setMessageAlert} setTypeAlert={setTypeAlert}
                    setRefresh={setRefresh}
                />

                <CustomConfirm
                    open={openConfirm} setOpen={setOpenConfirm}
                    setRefresh={setRefresh}
                    handleConfirm={() => handleConfirmDelete()}
                    loading={loading}
                    message="Apakah Anda yakin ingin menghapus data ini..?"
                />

                <UserUpdate
                    open={openUpdate} setOpen={setOpenUpdate}
                    accessToken={accessToken} setRefresh={setRefresh}
                    getData={dataUpdate}
                    setOpenAlert={setOpenAlert}
                    setTypeAlert={setTypeAlert}
                    setMessageAlert={setMessageAlert}
                    dataUserRole={dataUserRole}
                />

                <CustomAlert open={openAlert} setOpen={setOpenAlert} message={messageAlert} type={typeAlert} />
                
            </LayoutDashboard>
        </>
    )
}

export default User