import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/icons/only_logo.png'
import TextLogo from '../../assets/icons/only_text_logo.png'
import useIntersectionObserver from '../utils/intersection_observer';
import CheckInternetConnection from '../utils/check_internet_connection';


const LayoutAuth = ({ name, children }) => {
    const content = useIntersectionObserver({ targetElementId: 'content' });
    const textLogin = useIntersectionObserver({ targetElementId: 'textLogin' });
    const logoTerra = useIntersectionObserver({ targetElementId: 'logoTerra' });
    const textLogo = useIntersectionObserver({ targetElementId: 'textLogo' });

    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const navigate = useNavigate();

    CheckInternetConnection({ isOnline, setIsOnline });

    useEffect(() => {
        if (!isOnline) {
            navigate('/no-internet-connection'); 
        }
    }, [isOnline, navigate]);

    return (
        <>
            <div className="w-full h-screen grid grid-cols-1 lg:grid-cols-2 bg-custom-navy text-xs md:text-base">
                <div className="fixed md:static top-0 bg-custom-navy z-50 w-full h-fit md:h-full flex flex-col items-center lg:justify-center pt-12 sm:pt-16 ">
                    <div className="flex items-center text-custom-dark-yellow space-x-3">
                        <p id="textLogin" className={`lg:hidden text-base xs:text-2xl sm:text-3xl uppercase font-bold transition-all duration-1000 ${textLogin ? 'scale-100' : 'scale-0'}`}>
                            {name}
                        </p>
                        <div className="flex flex-col items-center">
                            <img id="logoTerra" src={Logo} alt="Logo TERRA APP" className={`w-[70px] xs:w-[80px] sm:w-[100px] lg:w-[200px] transition-all duration-1000 delay-1000 ${logoTerra ? 'rotate-0 translate-x-0 scale-100' : 'rotate-180 translate-x-28 xs:translate-x-32 sm:translate-x-40 scale-0'}`} />

                            <img id="textLogo" src={TextLogo} alt="Logo TEXT TERRA APP" className={`h-[30px] sm:h-[40px] w-fit lg:h-[150px] transition-all duration-1000 delay-500 ${textLogo ? 'translate-x-0 scale-100' : 'translate-x-24 xs:translate-x-32 sm:translate-x-40 scale-0'}`} />
                        </div>
                    </div>
                </div>
                <div className="fixed z-50 bottom-0 lg:static w-full h-screen flex items-end justify-end md:items-center md:justify-center pt-[200px] xs:pt-[250px] lg:pt-0 ">
                    <div id="content" className={`w-full max-w-[500px] mx-auto ${content ? 'h-full md:h-[80%] py-5' : 'h-0 py-0'} lg:h-[80%] bg-custom-dark-yellow rounded-t-2xl md:rounded-2xl flex items-center justify-center transition-all duration-1000 overflow-hidden`}>
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default LayoutAuth
