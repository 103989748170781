import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../components/layouts/layout_dashboard"
import CustomHeader from "../../../components/customs/header"
import BtnBack from "../../../components/buttons/btn_back"
import { useNavigate, useParams } from "react-router-dom"
import { useState } from "react"
import { useEffect } from "react"
import { deleteConsument, showConsument } from "../../../services/user_management/consument_service"
import { BaseUrl } from "../../../services/base/base_url"
import BtnDefault from "../../../components/buttons/btn_default"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons"
import CustomConfirm from "../../../components/customs/confirm"
import CustomAlert from "../../../components/customs/alert"
import ConsumentUpdate from "./update"

const ConsumentDetail = () => {
    const [accessToken, setAccessToken] = useState(null)
    const [userRole, setUserRole] = useState(null)
    const navigate = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [refresh, setRefresh] = useState(false)

    const [data, setData] = useState(null)
    const fetchData = async (accessToken) => {
        setLoading(true)
        try {
            const response = await showConsument(accessToken, id)
            if (response.status === 200) {
                setData(response.data.data)
            } else if (response.status === 404) {
                alert("Data tidak ditemukan")
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        const accessToken = localStorage.getItem("access_token-terra_app")
        const userRole = localStorage.getItem("user_role-terra_app")
        if (accessToken && userRole) {
            setAccessToken(accessToken)
            if (['super admin', 'admin', 'keuangan'].includes(userRole)) {
                setUserRole(userRole)
                fetchData(accessToken)
            } else {
                navigate('/403')
            }
        }
    }, [])

    useEffect(() => {
        if (refresh) {
            fetchData(accessToken)
            setRefresh(false)
        }
    }, [refresh])

    const MyRow = ({ name, value, addClass }) => {
        return (
            <tr className={addClass}>
                <td>{name}</td>
                <td className="px-2">:</td>
                <td>{value}</td>
            </tr>
        )
    }

    const [openAlert, setOpenAlert] = useState(false)
    const [typeAlert, setTypeAlert] = useState(null)
    const [messageAlert, setMessageAlert] = useState(null)

    const [openConfirm, setOpenConfirm] = useState(false)
    const [messageConfirm, setMessageConfirm] = useState(null)
    const [loadingConfirm, setLoadingConfirm] = useState(false)
    const handleConfirm = async () => {
        setLoadingConfirm(true)
        try {
            const response = await deleteConsument(accessToken, id)
            if (response.status === 200) {
                setTypeAlert("success")
                setMessageAlert("Data berhasil dihapus..!")
                setOpenAlert(true)
                navigate('/dashboard/consument')
            } else {
                setTypeAlert("error")
                setMessageAlert("Data gagal dihapus..!")
                setOpenAlert(true)
            }
        } catch (error) {
            setTypeAlert("error")
            setMessageAlert("Data gagal dihapus..!")
            setOpenAlert(true)
        }
        setLoadingConfirm(false)
    }

    const [openUpdate, setOpenUpdate] = useState(false)
    const [dataUpdate, setDataUpdate] = useState(null)

    return (
        <>
            <Helmet>
                <title>Detail Konsumen</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack />
                    <p className="font-bold text-center uppercase">Detail Konsumen</p>
                    <div className="flex items-center justify-end gap-3">
                        <BtnDefault
                            handleClick={() => {
                                setDataUpdate(data)
                                setOpenUpdate(true)
                            }}
                            addClass={"bg-yellow-500 hover:bg-yellow-600 text-white"}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </BtnDefault>
                        <BtnDefault
                            handleClick={() => {
                                setMessageConfirm("Apakah anda yakin ingin menghapus data ini?")
                                setOpenConfirm(true)
                            }}
                            addClass={"bg-red-500 hover:bg-red-500/80 text-white"}>
                            <FontAwesomeIcon icon={faTrash} />
                        </BtnDefault>
                    </div>
                </CustomHeader>

                <div className="px-3 pt-2 pb-3 flex flex-col gap-3">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3 pt-3 items-start">
                        <table className={loading === true ? 'w-full' : 'w-fit'}>
                            {
                                loading === true ?
                                    Array.from({ length: 3 }).map(() => (
                                        <tr className="animate-pulse">
                                            <td className="px-3 py-2">
                                                <div className="w-full h-4 rounded-full bg-gray-300" />
                                            </td>
                                            <td className="px-3 py-2 w-16">
                                                <div className="w-full h-4 rounded-full bg-gray-300" />
                                            </td>
                                            <td className="px-3 py-2">
                                                <div className="w-full h-4 rounded-full bg-gray-300" />
                                            </td>
                                        </tr>
                                    )) :
                                    <>
                                        <MyRow name="Nama Lengkap" value={data?.name ?? 'Tidak Tersedia'} />
                                        <MyRow name="Nomor Telepon" value={data?.phone_number.replace(/(\d{4})(?=\d)/g, "$1 ") ?? 'Tidak Tersedia'} />
                                        <MyRow name="Email" value={data?.email ?? 'Tidak Tersedia'} />
                                    </>
                            }
                        </table>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-3 pt-3">
                        <div>
                            <p className="font-semibold border-b-2 border-black mb-2">Alamat</p>
                            <p className="text-justify">{data?.address}</p>
                        </div>

                        <div>
                            <p className="font-semibold border-b-2 border-black mb-2">Foto KTP</p>
                            <div className={`w-full aspect-video rounded-xl overflow-hidden relative ${loading ? 'bg-gray-300 animate-pulse' : ''}`}>
                                {!loading &&
                                    <img src={`${BaseUrl}/storage/${data?.ktp}`}
                                        alt="image terra app" layout="fill" className="w-full aspect-video object-cover" />
                                }
                            </div>
                        </div>

                        <div>
                            <p className="font-semibold border-b-2 border-black mb-2">Foto Kartu Keluarga</p>
                            <div className={`w-full aspect-video rounded-xl overflow-hidden relative ${loading ? 'bg-gray-300 animate-pulse' : ''}`}>
                                {!loading &&
                                    <img src={BaseUrl + "/storage/" + data?.kk}
                                        alt="image terra app" layout="fill" className="w-full aspect-video object-cover" />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <ConsumentUpdate
                    isOpen={openUpdate}
                    setIsOpen={setOpenUpdate}
                    getData={data}
                    fetchData={fetchData}
                    accessToken={accessToken}
                    setRefresh={setRefresh}
                    setAlert={setOpenAlert}
                    setTypeAlert={setTypeAlert}
                    setMessageAlert={setMessageAlert}
                />

                <CustomConfirm
                    open={openConfirm}
                    setOpen={setOpenConfirm}
                    message={messageConfirm}
                    handleConfirm={handleConfirm}
                    loading={loadingConfirm}
                />

                <CustomAlert
                    open={openAlert}
                    setOpen={setOpenAlert}
                    type={typeAlert}
                    message={messageAlert}
                />
            </LayoutDashboard>
        </>
    )
}

export default ConsumentDetail