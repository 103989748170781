import { Link } from "react-router-dom"

const LinkDefault = ({link, name, addClass}) => {
    return (
        <Link to={link} className={`px-3 py-2 rounded-full ${addClass}`}>
            {name}
        </Link>
    )
}

export default LinkDefault