import { Helmet } from 'react-helmet';
import videoSplash from '../../../assets/videos/splash.mp4';

const Splash = () => {
    return (
        <>
            <Helmet>Splash Screen</Helmet>
            <div className="w-full h-screen bg-custom-navy text-black flex flex-col items-center justify-center overflow-hidden">
                <video src={videoSplash} className="w-full h-full object-cover" autoPlay muted loop playsInline></video>
            </div>
        </>
    )
}

export default Splash