import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { getUnitSellingChart } from './../../services/unit/unit_selling_service';
import { getReportFinanceChart } from "../../services/finance/report_service";

const DashboardChart = () => {
    const [accessToken, setAccessToken] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState(new Date().getFullYear());

    // Default data to avoid undefined values
    const [data, setData] = useState([
        { debit: 0, credit: 0, balance: 0 },
        { debit: 0, credit: 0, balance: 0 },
        { debit: 0, credit: 0, balance: 0 },
        { debit: 0, credit: 0, balance: 0 },
        { debit: 0, credit: 0, balance: 0 },
        { debit: 0, credit: 0, balance: 0 },
        { debit: 0, credit: 0, balance: 0 },
        { debit: 0, credit: 0, balance: 0 },
        { debit: 0, credit: 0, balance: 0 },
        { debit: 0, credit: 0, balance: 0 },
        { debit: 0, credit: 0, balance: 0 },
        { debit: 0, credit: 0, balance: 0 },
    ]);

    const fetchData = async (accessToken) => {
        setLoading(true);
        try {
            const response = await getReportFinanceChart(accessToken);
            if (response.status === 200) {
                setData(response.data.data); // Assuming the response returns data in the correct format
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const [dataChart, setDataChart] = useState({
        series: [
            {
                name: "Pemasukan",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
                name: "Pengeluaran",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
                name: "Saldo",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
        ],

        options: {
            chart: {
                type: 'area',
                height: 350,
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: ['#4CAF50', '#F44336', '#2196F3']
                },
                formatter: function (value) {
                    return new Intl.NumberFormat('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    }).format(value);
                }
            },
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            title: {
                text: 'Penjualan Unit Rumah / Ruko',
                align: 'left'
            },
            subtitle: {
                text: 'Tahun 2024',
                align: 'left'
            },
            stroke: {
                curve: 'smooth'
            },
            yaxis: {
                opposite: true
            },
            legend: {
                horizontalAlign: 'left'
            },
            colors: ['#4CAF50', '#F44336', '#2196F3']
        }
    });

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token-terra_app');
        const userRole = localStorage.getItem('user_role-terra_app');
        if (!accessToken || !userRole) {
            navigate('/login');
        } else {
            setAccessToken(accessToken);
            setUserRole(userRole);
            fetchData(accessToken);
        }
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            setDataChart((prev) => ({
                ...prev,
                series: [
                    {
                        name: "Pemasukan",
                        data: data.map((item = { debit: 0 }, index) => item.debit)
                    },
                    {
                        name: "Pengeluaran",
                        data: data.map((item = { credit: 0 }, index) => item.credit)
                    },
                    {
                        name: "Saldo",
                        data: data.map((item = { balance: 0 }, index) => item.balance)
                    }
                ],
            }));
        }
    }, [data]);

    return (
        <div className="px-5">
            <div id="chart">
                <ReactApexChart
                    options={dataChart.options}
                    series={dataChart.series}
                    type="area"
                    height={350}
                />
            </div>
            <div id="html-dist"></div>
        </div>
    );
};

export default DashboardChart;
