import LayoutModal from './../../../components/layouts/layout_modal';
import InputDefault from './../../../components/form/input_default';
import { useState } from 'react';
import { useEffect } from 'react';
import { updateProfile } from '../../../services/user_management/profile_service';
import BtnDefault from '../../../components/buttons/btn_default';
import CustomSpinner from './../../../components/customs/spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faUpload, faUser } from '@fortawesome/free-solid-svg-icons';
import FormatDate from './../../../components/format/date';
import { BaseUrl } from '../../../services/base/base_url';
const ProfileUpdate = (
    {
        accessToken, open, setOpen, data,
        setRefresh,
        setAlert,
        setAlertType,
        setAlertMessage
    }
) => {

    const [loading, setLoading] = useState(false)

    const [image, setImage] = useState(null)
    const [imagePreview, setImagePreview] = useState(null)

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")

    const [errorName, setErrorName] = useState(null)
    const [errorEmail, setErrorEmail] = useState(null)
    const [errorPhone, setErrorPhone] = useState(null)

    useEffect(() => {
        if (data) {
            setName(data?.name)
            setEmail(data?.email)
            setPhoneNumber(data?.phone)
        }
    }, [data])

    const handleImage = (e) => {
        const file = e.target.files[0]
        setImage(file)
        setImagePreview(URL.createObjectURL(file))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {

            setErrorName(null)
            setErrorEmail(null)
            setErrorPhone(null)

            const formData = new FormData()
            formData.append('name', name)
            formData.append('phone', phoneNumber)
            if (image) {
                formData.append('image', image)
            }

            const response = await updateProfile({ accessToken, data: formData })
            console.log(response)
            if (response.status === 200) {
                setOpen(false)
                setAlert(true)
                setAlertType("success")
                setAlertMessage("Profil berhasil diubah..!")
                setRefresh(true)
            } else {
                setAlert(true)
                setAlertType("error")
                setAlertMessage(response.message ?? "Gagal mengubah profil..!")
            }
        } catch (error) {
            // setAlert(true)
        //     setAlertType("error")
        //     setAlertMessage("Terjadi kesalahan, silahkan coba lagi..!")
        }
        setLoading(false)
    }

    return (
        <LayoutModal name="Update Profil" open={open} setOpen={setOpen}>
            <form onSubmit={handleSubmit} className='flex flex-col gap-3'>
                <div className='flex flex-col items-center gap-3'>
                    <div className='w-32 aspect-square rounded-full object-cover mx-auto border-2'>
                        {imagePreview ? <img src={imagePreview} alt="preview" className='w-full h-full rounded-full object-cover' /> :
                            data?.image ? <img src={BaseUrl + "/storage/" + data?.image} alt="profile" className='w-full h-full rounded-full object-cover' /> :
                                <div className='w-full h-full rounded-full bg-custom-navy flex items-center justify-center'>
                                    <FontAwesomeIcon icon={faUser} className='text-white text-4xl' />
                                </div>
                        }
                    </div>

                    <label htmlFor="image" className='px-3 py-2  w-40 text-center rounded-full object-cover mx-auto border-2 flex items-center justify-center gap-2 border-dashed bg-gray-200 border-custom-navy cursor-pointer hover:bg-custom-navy transition-colors duration-300 hover:text-white'>
                        <p>Pilih Foto</p>
                        <FontAwesomeIcon icon={faUpload} />
                    </label>
                    <input type="file" id="image" className='hidden' onChange={handleImage} />
                </div>

                <InputDefault
                    label="Nama Lengkap"
                    name="name"
                    value={name}
                    setValue={setName}
                />

                <InputDefault
                    label="Email"
                    name="email"
                    value={email}
                    disabled={true}
                />

                <InputDefault
                    label="No. HP"
                    name="phone"
                    value={phoneNumber}
                    handleChange={(e) => {
                        const value = e.target.value
                        if (value.length <= 13) {
                            setPhoneNumber(value)
                        }
                    }}
                />

                <div className="w-full flex items-center gap-3 justify-evenly">
                    <BtnDefault type="button" handleClick={() => {
                        setOpen(false)
                        setImage(null)
                        setImagePreview(null)
                    }} addClass='bg-red-500 w-full '>
                        Kembali
                    </BtnDefault>
                    <BtnDefault type="submit" addClass='w-full'>
                        {loading === true ? <CustomSpinner addClass='' /> : "Simpan"}
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default ProfileUpdate