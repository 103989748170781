import { faEdit, faEye, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { deleteConsument, getConsument } from "../../../services/user_management/consument_service"
import { Helmet } from "react-helmet"
import LayoutDashboard from './../../../components/layouts/layout_dashboard';
import CustomHeader from './../../../components/customs/header';
import BtnDefault from './../../../components/buttons/btn_default';
import InputSearch from './../../../components/form/input_search';
import CustomPaginate from './../../../components/customs/paginate';
import ConsumentCreate from "./create"
import CustomConfirm from './../../../components/customs/confirm';
import CustomAlert from "../../../components/customs/alert"
import ConsumentUpdate from "./update"

const Consument = () => {
    const [accessToken, setAccessToken] = useState('')
    const [userRole, setUserRole] = useState('')
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [isOpen, setIsOpen] = useState(false)
    const [isOpenUpdate, setIsOpenUpdate] = useState(false)
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false)

    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState(0)
    let [from, setFrom] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)

    const [keyword, setKeyword] = useState('')
    const fetchData = async (accessToken, keyword, currentPage) => {
        setLoading(true)
        try {
            const response = await getConsument(accessToken, keyword, currentPage)
            if (response.status === 200) {
                setData(response.data.data)
                setTotalData(response.data.meta.total)
                setFrom(response.data.meta.from)
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    const handleSearch = (e) => {
        try {
            e.preventDefault()
            setKeyword(e.target.value)
            fetchData(accessToken, e.target.value, 1)
        } catch (error) {
            alert('Silahkan periksa koneksi internet Anda')
        }
    }

    useEffect(() => {
        if (refresh) {
            fetchData(accessToken, keyword, currentPage)
            setRefresh(false)
        }
    }, [refresh])

    useEffect(() => {
        const newAccessToken = localStorage.getItem('access_token-terra_app')
        const newUserRole = localStorage.getItem('user_role-terra_app')
        if (!newAccessToken) {
            navigate('/login')
        } else if (!['super admin', 'admin', 'keuangan'].includes(newUserRole)) {
            navigate('/403')
        } else {
            setAccessToken(newAccessToken)
            setUserRole(newUserRole)
            fetchData(newAccessToken, keyword, currentPage)
        }
    }, [])

    const [loadingDelete, setLoadingDelete] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const handleDelete = async () => {
        setLoadingDelete(true)
        try {
            const response = await deleteConsument(accessToken, deleteId)
            if (response.status === 200) {
                setRefresh(true)
                setOpenAlert(true)
                setTypeAlert('success')
                setMessageAlert('Data berhasil dihapus..!')
            } else {
                setOpenAlert(true)
                setTypeAlert('error')
                if (response.status === 404) {
                    setMessageAlert('Data tidak ditemukan..!')
                } else if (response.status === 403) {
                    setMessageAlert('Anda tidak diperbolehkan menghapus data ini..!')
                } else {
                    setMessageAlert('Data gagal dihapus..!')
                }
            }
        } catch (error) {
            setOpenAlert(true)
            setTypeAlert('error')
            setMessageAlert('Data gagal dihapus..!')
        }
        setOpenConfirmDelete(false)
        setLoadingDelete(false)
    }

    const [openAlert, setOpenAlert] = useState(false)
    const [messageAlert, setMessageAlert] = useState('')
    const [typeAlert, setTypeAlert] = useState('success')

    const [dataUpdate, setDataUpdate] = useState(null)
    return (
        <>
            <Helmet>
                <title>DataKonsumen</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <div className="hidden md:flex" />
                    <p className='text-custom-navy text-sm md:text-xl font-semibold text-center'>Data Konsumen</p>
                    <div className='flex items-center justify-end gap-3 text-custom-dark-yellow'>
                        <BtnDefault addClass="text-custom" handleClick={() => setIsOpen(true)}>
                            <FontAwesomeIcon icon={faPlus} />
                            <p className='text-sm font-semibold hidden md:flex'>Konsumen Baru</p>
                        </BtnDefault>
                    </div>
                </CustomHeader>

                <div className="px-3 sticky top-16 bg-white z-10 py-3">
                    <form  
                    className="flex items-center gap-3 relative"
                        onSubmit={(e) => {
                            e.preventDefault()
                            setCurrentPage(1)
                            fetchData(accessToken, keyword, 1)
                        }}
                    >
                        <InputSearch
                            keyword={keyword}
                            handleSearch={(e) => {
                                setKeyword(e.target.value)
                            }}
                            addClass="w-full"
                        />
                        <button className="absolute right-[2px] bg-custom-dark-yellow px-3 py-2 md:py-[2px] rounded-r-full">
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </form>
                </div>

                <div className="w-full px-3">
                    <div className="w-full overflow-auto ">
                        <table className={`max-w-screen-2xl ${loading ? 'min-w-full' : 'min-w-max lg:min-w-full'}`}>
                            <thead>
                                <tr className="bg-custom-dark-yellow">
                                    <th className="px-3 py-2 rounded-tl-xl">No</th>
                                    <th className="px-3 py-2">Nama</th>
                                    <th className="px-3 py-2">No. Telp</th>
                                    <th className="px-3 py-2">Email</th>
                                    <th className="px-3 py-2 rounded-tr-xl">Alamat</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loading ? "Loading...!" :
                                        totalData === 0 ? "Tidak Ada Data" :
                                            data.map((item, index) => (
                                                <tr onClick={() => navigate(`/dashboard/consument/detail/${item.id}`)} className={`hover:bg-gray-300 transition-colors cursor-pointer ${index % 2 === 1 ? 'bg-gray-200' : ''}`}>
                                                    <td className="px-3 py-2 text-center">{from++}</td>
                                                    <td className="px-3 py-2">{item.name}</td>
                                                    <td className="px-3 py-2">{item.phone_number}</td>
                                                    <td className="px-3 py-2">{item.email}</td>
                                                    <td className="px-3 py-2">{item.address}</td>
                                                </tr>
                                            ))
                                }
                                {lastPage < 2 && (
                                    <tr>
                                        <td className="px-3 py-4 bg-custom-dark-yellow" colSpan={20} />
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>


                {lastPage > 1 && (
                    <div className="w-full items-center flex justify-center md:justify-end px-3 bg-custom-dark-yellow py-1 sticky bottom-0 left-0">
                        <CustomPaginate
                            currentPage={currentPage}
                            lastPage={lastPage}
                            setCurrentPage={setCurrentPage}
                            setRefresh={setRefresh}
                        />
                    </div>
                )}

                {/* Modal */}
                <ConsumentCreate
                    isOpen={isOpen} setIsOpen={setIsOpen}
                    accessToken={accessToken} setRefresh={setRefresh}
                    setAlert={setOpenAlert}
                    setTypeAlert={setTypeAlert}
                    setMessageAlert={setMessageAlert}
                />

                <ConsumentUpdate
                    isOpen={isOpenUpdate} setIsOpen={setIsOpenUpdate}
                    accessToken={accessToken} setRefresh={setRefresh}
                    data={dataUpdate}
                    setAlert={setOpenAlert}
                    setTypeAlert={setTypeAlert}
                    setMessageAlert={setMessageAlert}
                />

                <CustomAlert
                    open={openAlert}
                    setOpen={setOpenAlert}
                    type={typeAlert}
                    message={messageAlert}
                />

                <CustomConfirm
                    open={openConfirmDelete}
                    setOpen={setOpenConfirmDelete}
                    handleConfirm={handleDelete}
                    loading={loadingDelete}
                    message="Apakah anda yakin ingin menghapus data ini..?"
                />

            </LayoutDashboard>
        </>
    )
}

export default Consument
