import React, { useState } from 'react';
import { useEffect } from 'react';
import { postDebiturCrediturCategory, updateDebiturCrediturCategory } from '../../../services/user_management/debitur_creditur_category_service';
import InputDefault from '../../../components/form/input_default';
import BtnDefault from '../../../components/buttons/btn_default';
import CustomSpinner from '../../../components/customs/spinner';

const CreditureCategoryAdd = ({accessToken, isOpen, setIsOpen, setRefresh, getId, getName}) => {

    const [id, setId] = useState('id')
    const [name, setName] = useState('');
    const [errorName, setErrorName] = useState(null);

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (getId) {
            setId(getId)
        }

        if (getName) {
            setName(getName)
        }

    }, [getId, getName])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            setErrorName(null);

            if (name.trim() === '') {
                setErrorName('Nama harus diisi..!');
            } else if (name.length < 3) {
                setErrorName('Nama minimal memiliki 3 huruf..!');
            } else {
                const formData = new FormData()
                formData.append('name', name)

                if (id) {
                    formData.append('id', id)
                    const response = await updateDebiturCrediturCategory( accessToken, formData)
                    if (response.status === 200) {
                        setId("")
                        setName("")

                        setIsOpen && setIsOpen(false)
                        setRefresh && setRefresh(true)
                    } else if (response.status === 422) {
                        alert(`${response.data.message}`)
                    } else {
                        alert('gagal menyimpan data..!')
                    }
                } else {
                    const response = await postDebiturCrediturCategory( accessToken, formData)
                    if (response.status === 201) {
                        setId("")
                        setName("")
                        
                        setIsOpen && setIsOpen(false)
                        setRefresh && setRefresh(true)
                    } else {
                        alert('gagal menyimpan data..!')
                    }
                }

            }
        } catch (error) {
            alert('Silahkan periksa koneksi internet Anda..!')
        }
        setLoading(false)
    }
    return (
        <div
            className={`fixed z-[100000] top-0 left-0 flex items-center justify-center bg-black/50 transition-all duration-700  
                ${isOpen === true
                    ? "w-full h-screen px-5"
                    : "h-screen w-0 rounded-3xl delay-500"
                }`}
        >
            <div
                className={`lg:max-w-[500px] bg-white rounded-2xl backdrop-blur-sm overflow-hidden flex flex-col items-center justify-center transition-all duration-700 ${isOpen === true
                    ? "w-full  max-h-[95%] delay-500"
                    : "max-h-0 w-full delay-0"
                    }`}
            >
                <div className="w-full absolute z-10 top-0 bg-custom-navy flex items-center justify-center">
                    <p className="text-center text-custom-dark-yellow text-base md:text-xl lg:text-2xl font-semibold capitalize px-3 py-3">
                        {getId ? "update" : "Tambah"} kategori
                    </p>
                </div>

                <form onSubmit={handleSubmit} className='w-full h-full px-3 py-20 flex flex-col gap-3 overflow-auto scrollbar-thin scrollbar-track-custom-navy scrollbar-thumb-custom-dark-yellow'>
                    <InputDefault
                        id="name"
                        label="Nama Kategori"
                        placeholder="Masukkan nama kategori"
                        value={name}
                        setValue={setName}
                        errorMessage={errorName ?? undefined}
                    />

                    <div className="w-full flex items-center gap-3 justify-evenly">
                        <BtnDefault type="button" handleClick={() => {
                            setId("")
                            setName("")

                            setIsOpen && setIsOpen(false)
                        }} addClass='bg-red-500 w-full '>
                            Kembali
                        </BtnDefault>
                        <BtnDefault type="submit" addClass='w-full'>
                            {loading ?
                                <CustomSpinner /> :
                                "Simpan"
                            }
                        </BtnDefault>
                    </div>
                </form>

                <div className="w-full absolute bottom-0 bg-custom-navy flex items-center justify-center">
                    <p className="text-center text-custom-dark-yellow text-base md:text-xl lg:text-2xl font-semibold uppercase px-3 py-3">
                        TERRA APP
                    </p>
                </div>
            </div>
        </div>
    )
}

export default CreditureCategoryAdd