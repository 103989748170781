import { faImage } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const LoadingUnit = () => {
    return (
        <div className="h-[350px] md:h-[380px] bg-gray-100 border-2 border-gray-300 shadow-md rounded-xl transition-all duration-300 relative overflow-hidden animate-pulse">
            <div className='w-full aspect-video flex items-center justify-center' >
                <FontAwesomeIcon icon={faImage} className="w-1/2 h-1/2 text-gray-300" />
            </div>
            <div className='text-custom-dark-yellow px-3 text-sm pt-2'>
                <div className="w-full h-7 bg-gray-300 rounded-full"></div>
                <div className='pt-2'>
                    {Array.from({ length: 4 }).map((_, index) => (
                        <div className='w-full text-sm flex items-center space-x-2 space-y-2'>
                            <div className="w-[100px] h-4 bg-gray-300 rounded-full"></div>
                            <div className="w-3 h-4 bg-gray-300 rounded-full"></div>
                            <div className="w-full h-4 bg-gray-300 rounded-full"></div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='w-[100px] h-7 rounded-full px-3 py-1 bg-gray-300 hover:bg-opacity-75 transition-all duration-300 text-black font-semibold absolute bottom-3 right-3'>
            </div>
        </div>
    )
}

export default LoadingUnit