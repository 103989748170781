import { useEffect, useState } from 'react';
import { Link, useHref, useNavigate } from 'react-router-dom';
import LayoutDashboard from '../../../components/layouts/layout_dashboard';
import React from 'react';
import CustomHeader from '../../../components/customs/header';
import Helmet from 'react-helmet';
const Finance = () => {
    const data = [
        { name: 'Data Akun', value: "account", link: "account" },
        { name: "Data Transaksi", value: "Rp. 100.000.000", link: "transaction" },
        { name: "Jurnal Umum", value: "Rp. 50.000.000", link: "jurnal-umum" },
        { name: 'Buku Utang', value: "", link: "utang" },
        { name: 'Buku Piutang', value: "", link: "piutang" },
        { name: "Laba Rugi", value: "Rp. 50.000.000", link: "laba-rugi" },
        { name: 'Buku Besar', value: "", link: "big-book" },
        { name: "Arus Kas", value: "Rp. 100.000.000", link: "arus-kas" },
        { name: 'Neraca Saldo', value: "", link: "neraca" },
        { name: 'laporan Keuangan', value: "", link: "report" },
    ];

    const [accessToken, setAccessToken] = useState('')
    const [userRole, setUserRole] = useState('')
    const navigate = useNavigate()

    const [client, setClient] = useState(false)
    useEffect(() => {
        try {
            setClient(true);
            const newAccessToken = localStorage.getItem('access_token-terra_app') ?? ''
            const newUserRole = localStorage.getItem('user_role-terra_app') ?? ''
            if (!newAccessToken) {
                navigate('/login')
            } else if (!['super admin', 'admin', 'lapangan', 'keuangan'].includes(newUserRole)) {
                navigate('/403')
            } else {
                setAccessToken(newAccessToken)
                setUserRole(newUserRole)
            }
        } catch (error) {
            alert('Silahkan periksa koneksi internet Anda')
        }
    }, [accessToken])
    return (
        <>
            <Helmet>
                <title>Management Keuangan</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <div></div>
                    <p className='text-center'>Management Keuangan</p>
                    <div></div>
                </CustomHeader>
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-3 p-3">
                    {data.map((item) => (
                        <Link to={`${item.link}`} className="w-full aspect-video border-2 rounded-xl bg-custom-dark-yellow flex items-center justify-center text-custom-navy font-bold text-xl overflow-hidden relative group hover:text-white">
                            <div className="w-0 h-full absolute bg-custom-navy rounded-full group-hover:rounded-sm group-hover:w-full transition-all duration-300"></div>
                            <p className="text-sm md:text-base lg:text-xl z-10 capitalize">{item?.name}</p>
                        </Link>
                    ))}
                </div>
            </LayoutDashboard>
        </>
    )
}

export default Finance