import { Link, useHref, useNavigate } from "react-router-dom";
import { BaseUrl } from "../../services/base/base_url";
import CustomCurrency from "../customs/currency";


const CardUnit = ({ id, image, name, category, type, price, dp, costOfBooking, status }) => {
  const navigate = useNavigate();
  const currentPath = useHref();

  const CustomTr = ({ name, value }) => {
    return (
      <tr className="text-xs md:text-sm">
        <td>{name}</td>
        <td className="px-1">:</td>
        <td>
          <p>{value}</p>
        </td>
      </tr>
    );
  };

  return (
    <div className="bg-custom-navy border-2 border-custom-navy shadow-md hover:shadow-black/50 rounded-xl transition-all duration-300 relative overflow-hidden pb-12 md:pb-14 lg:pb-16">
      <div className="w-full aspect-video object-cover bg-black/30 relative">
        <img
          src={`${BaseUrl}/storage/${image}`}
          alt={`perumahan-terra ${name}`}
          className="w-full aspect-video object-cover"
        />
      </div>
      <div className="text-custom-dark-yellow px-3 text-sm pt-2 ">
        <p className="text-sm md:text-base font-semibold line-clamp-2 border-b-2 border-custom-dark-yellow">
          {name}
        </p>
        <div className="pt-2">
          <CustomTr name="Kategori" value={category} />
          <CustomTr
            name="Tipe"
            value={<p className="line-clamp-2">{type}</p>}
          />
          <CustomTr name="Harga" value={<CustomCurrency value={price} />} />
          <CustomTr name="DP" value={<CustomCurrency value={dp} />} />
          <CustomTr name="Status" value={
            <p
              className={`${status === "sudah terjual"
                ? "bg-red-500"
                : status === "sudah dipesan"
                  ? "bg-yellow-500" : "bg-green-500"
                } rounded-full text-center font-semibold text-white  px-3`}
            >
              {status}
            </p>
          } />
        </div>
      </div>

      <Link
        to={
          currentPath === "/unit"
            ? `/unit/detail/${id}`
            : `/dashboard/unit/detail/${id}`
        }
        className="text-xs md:text-sm lg:text-base rounded-full px-3 py-1 bg-custom-dark-yellow hover:bg-opacity-75 transition-all duration-300 text-black font-semibold absolute bottom-3 right-3 "
      >
        Lihat Detail...
      </Link>
    </div>
  );
};

export default CardUnit;
