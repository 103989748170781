import ApiService from "../base/api_service"


export const getGaleries = async () => {
    try {
        const response = await ApiService('/content/galeries', {
            headers: {
                // Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
            return error.response
    }
}

export const postGalery = async (accessToken, formData) => {
    try {
        const response = await ApiService.post(`/content/galery/create`, formData, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}

export const deleteGalery = async (accessToken, id) => {
    try {
        const response = await ApiService.post(`/content/galery/delete/${id}`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}