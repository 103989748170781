import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useRef } from 'react';
import HandleClickOutside from '../utils/handle_click_outside';

const SelectDefault = ({
    id,
    label,
    options,
    value,
    onSelect,
    disabled,
    placeholder = 'Pilih salah satu',
    errorMessage,
    position,
    addOption
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        if (value != null) { // Memeriksa jika value tidak null
            const selected = options.find(option => option.value === value);
            setSelectedOption(selected || null);
            if (selected) {
                onSelect(selected.value);
            }
        } else if (!selectedOption && options.length > 0) {
            setSelectedOption(options[0]);
            onSelect(options[0].value);
        }
    }, [value, options]); // Menambahkan value dan options sebagai dependensi

    const handleSelect = (option) => {
        setSelectedOption(option);
        onSelect(option.value);
        setIsOpen(false);
    };

    const filterRef = useRef(null);

    return (
        <>
            <div className="custom-select-container text-xs md:text-sm " ref={filterRef} style={{ position: 'relative' }}>
                <label htmlFor={id || ""} className='font-semibold capitalize'>{label}</label>
                <div id={id || ""}
                    className={`selected-option w-full rounded-full px-3 py-2 border-2 focus:border-custom-navy outline-none flex items-center transition-colors justify-between bg-white capitalize gap-3 ${disabled ? "" : "hover:border-custom-navy cursor-pointer"}`}
                    onClick={() => {
                        if (!disabled) setIsOpen(!isOpen);
                    }}
                >
                    {selectedOption ? selectedOption.label : placeholder}
                    <FontAwesomeIcon icon={faCaretDown} className={`transition-all ${isOpen ? '-rotate-90' : 'rotate-0'}`} />
                </div>
                {isOpen && (
                    <div
                        className={`options-dropdown absolute top-[100%] border-2 rounded-xl max-h-[300px] max-w-full mt-2 bg-white w-full z-[1000] p-3 overflow-auto scrollbar-thin scrollbar-thumb-custom-navy scrollbar-track-custom-dark-yellow scrollbar-track-rounded-full scrollbar-thumb-rounded-full ${position ?? 'left-0'}`}

                    >
                        {options.map((option) => (
                            <div
                                key={option.value}
                                onClick={() => handleSelect(option)}
                                className="option-item hover:bg-gray-200 rounded-full px-3 capitalize"
                                style={{ padding: '8px', cursor: 'pointer', borderBottom: '1px solid #eee' }}
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <HandleClickOutside setOpen={setIsOpen} filterRef={filterRef} />
        </>
    );
};

export default SelectDefault;