import React, { useEffect, useState } from 'react';
import Sidebar from '../navigations/sidebar';
import { useNavigate } from 'react-router-dom';
import CheckInternetConnection from '../utils/check_internet_connection';

const LayoutDashboard = ({ children }) => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const navigate = useNavigate();

    CheckInternetConnection({ isOnline, setIsOnline });

    useEffect(() => {
        if (!isOnline) {
            navigate('/no-internet-connection'); 
        }
    }, [isOnline, navigate]);
    return (
        <div className="w-full h-screen flex overflow-auto bg-white text-black text-xs md:text-base">
            <Sidebar />
            <div className="w-full overflow-auto scrollbar-thin scrollbar-track-custom-dark-yellow scrollbar-thumb-custom-navy scrollbar-track-rounded-full scrollbar-thumb-rounded-full">
                {children}
            </div>
        </div>
    )
}

export default LayoutDashboard