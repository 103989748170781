import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons"
import Logo from '../../../assets/icons/Logo.png'
import Background from '../../../assets/images/bg_realestate.jpg'
import { Link } from "react-router-dom";
const WelcomeSection = () => {
  return (
    <div
      className="w-full h-screen grid grid-cols-1 bg-fixed lg:grid-cols-2 -mt-12 md:-mt-16 lg:-mt-0 lg:pt-16 bg-cover bg-center md:bg-left lg:bg-center"
      style={{ backgroundImage: `url(${Background})` }}
    >
      <div className="px-3 md:px-10 h-full flex flex-col justify-center backdrop-blur-md md:backdrop-blur-none">
        <img
          src={Logo}
          alt="Perumahan RENJANA PASOPATI"
          className="w-[70%] mx-auto md:w-[50%] lg:hidden"
        />
        <div className="text-center lg:text-justify text-custom-navy">
          <p className="text-4xl md:text-5xl font-bold hidden lg:block">
            TERRA APP
          </p>
          <p className="font-semibold text-xs md:text-sm text-black">
            Selamat Datang di Perumahan RENJANA PASOPATI - Hunian Nyaman dan
            Asri
          </p>
        </div>

        <div className="py-3 text-center lg:text-justify text-custom-navy">
          <p className="text-base md:text-2xl font-bold">
            Temukan rumah impian Anda di lingkungan yang tenang dan strategis
          </p>
          <p className="font-semibold text-xs md:text-sm text-black pt-3">
            Di Perumahan RENJANA PASOPATI kami menawarkan berbagai tipe rumah
            yang dirancang untuk memenuhi kebutuhan dan gaya hidup Anda. Dengan
            fasilitas lengkap dan lingkungan yang asri, kami siap menjadi tempat
            tinggal ideal bagi Anda dan keluarga.
          </p>
        </div>

        {/* Call to Action */}
        <div className="flex items-center justify-center space-x-3 pt-10">
          <Link
            to={`/unit`}
            className="w-full max-w-[300px] py-2 rounded-full bg-custom-navy hover:bg-blue-950 transition-colors text-custom-dark-yellow font-semibold flex items-center justify-center space-x-3"
          >
            <FontAwesomeIcon icon={faEye} />
            <p>Lihat Unit</p>
          </Link>
          <Link
            to={`https://wa.link/igcseg`}
            target="_blank"
            rel="noreferrer noopener"
            className="w-full max-w-[300px] py-2 rounded-full bg-custom-navy hover:bg-blue-950 transition-colors text-custom-dark-yellow font-semibold flex items-center justify-center space-x-3"
          >
            <FontAwesomeIcon icon={faWhatsapp} />
            <p>Hubungi Kami</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WelcomeSection;
