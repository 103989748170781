import { useState, useEffect } from "react";
import {
  faPlus,
  faTrash,
  faExclamationTriangle,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { deleteGalery, getGaleries } from "../../../../services/content/galery_service";
import { Helmet } from "react-helmet";
import LayoutDashboard from "../../../../components/layouts/layout_dashboard";
import CustomHeader from "../../../../components/customs/header";
import BtnDefault from "../../../../components/buttons/btn_default";
import CustomSpinner from "../../../../components/customs/spinner";
import { BaseUrl } from "../../../../services/base/base_url";
import GaleryCreate from "./create";

const Galery = () => {
  const [accessToken, setAccessToken] = useState('')
  const [userRole, setUserRole] = useState('')
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openModalAdd, setOpenModalAdd] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageToDelete, setImageToDelete] = useState(null);

  const [data, setData] = useState([]);
  const fetchData = async () => {
    setLoading(true);
    const response = await getGaleries();
    if (response && response.status === 200) {
      setData(response.data.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    try {
      const newAccessToken = localStorage.getItem('access_token-terra_app') ?? ''
      const newUserRole = localStorage.getItem('user_role-terra_app') ?? ''
      if (!newAccessToken) {
        navigate('/login')
      } else if (!['super admin', 'admin', 'lapangan', 'keuangan'].includes(newUserRole)) {
        navigate('/403')
      } else {
        setAccessToken(newAccessToken)
        setUserRole(newUserRole)
        fetchData()
      }
    } catch (error) {
      alert('Silahkan periksa koneksi internet Anda')
    }
  }, [accessToken, userRole]);

  useEffect(() => {
    if (refresh) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleDeleteClick = (image) => {
    setImageToDelete(image);
  };

  const [loadingDelete, setLoadingDelete] = useState(false)
  const handleConfirmDelete = async () => {
    if (imageToDelete) {
      setLoadingDelete(true);
      try {
        await deleteGalery(accessToken, imageToDelete);

        setRefresh(true);
        setImageToDelete(null);
      } catch (error) {
        alert("Silahkan periksa koneksi internet Anda..!");
      }
      setLoadingDelete(false);
    }
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setImageToDelete(null);
  };

  return (
    <>
      <Helmet>Galery</Helmet>
      <LayoutDashboard>
        <CustomHeader>
          <div className="hidden md:flex"></div>
          <p className="text-center">Galery</p>
          <div className="flex items-center justify-end">
            <BtnDefault handleClick={() => setOpenModalAdd(true)}>
              <FontAwesomeIcon icon={faPlus} />
              <p>Tambah Foto</p>
            </BtnDefault>
          </div>
        </CustomHeader>
        {loading ? (
          <div className="w-full h-screen flex items-center justify-center">
            <CustomSpinner addClass="" />
          </div>
        ) : (
          <div className="px-3 pt-3 pb-16 w-full" style={{columns: "150px"}}>
            {data.map((item, index) => (
              <motion.div
                initial={{ scale: 0 }}
                whileInView={{ scale: 1 }}
                transition={{
                  duration: 0.3,
                  ease: "easeInOut",
                  delay: index * 0.2,
                }}
                key={index}
                className="relative hover:scale-105 transition-all duration-300 rounded-md md:rounded-xl shadow-sm shadow-gray-300 overflow-hidden group my-3"
              >
                <img
                  src={BaseUrl + "/storage/" + item.file}
                  alt={item.file}
                  className="w-full object-cover cursor-pointer"
                  loading="lazy"
                  onClick={() =>
                    handleImageClick(BaseUrl + "/storage/" + item.file)
                  }
                />
                <button
                  type="button"
                  onClick={() => {
                    handleDeleteClick(item.id)
                  }}
                  className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hidden group-hover:block h-8 aspect-square"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </motion.div>
            ))}
          </div>
        )}

      </LayoutDashboard>

      {selectedImage && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
          <div className="relative w-full h-screen flex items-center justify-center">
            <img
              src={selectedImage}
              alt="Fullscreen"
              className="max-w-full max-h-full"
            />
            <button
              type="button"
              onClick={handleCloseModal}
              className="absolute top-5 right-5 md:right-10 md:top-10 bg-red-500 text-white rounded-full h-10 aspect-square text-2xl"
            >
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>
        </div>
      )}

      {imageToDelete && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
          <div className="bg-white text-black p-5 rounded-lg shadow-lg transform transition-all duration-300">
            <div className="flex items-center mb-4">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="text-red-500 text-2xl mr-3"
              />
              <p className="text-lg font-semibold">Konfirmasi Penghapusan</p>
            </div>
            <p>Apakah Anda yakin ingin menghapus gambar ini?</p>
            <div className="flex justify-end space-x-3 mt-4">
              <button
                type="button"
                onClick={handleCloseModal}
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded transition-colors"
              >
                Batal
              </button>
              <button
                type="button"
                onClick={handleConfirmDelete}
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded transition-colors"
              >
                {loadingDelete ?
                  <CustomSpinner /> :
                  "Hapus"
                }
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal */}
      <GaleryCreate
        accessToken={accessToken}
        open={openModalAdd}
        setOpen={setOpenModalAdd}
        setRefresh={setRefresh}
      />

    </>
  );
};

export default Galery;
