const InputSearch = ({ handleSearch, keyword, addClass, paddingRight }) => {
    return (
        <div className={`flex items-center group relative overflow-hidden text-xs md:text-sm ${addClass}`}>
            <input
              type="search"
              value={keyword}
              onChange={handleSearch}
              placeholder="Cari data"
              className={`w-full bg-white text-black px-3 py-2 md:py-1 outline-none border-2 border-custom-dark-yellow rounded-full focus:border-custom-navy group-hover:border-custom-navy transition-colors ${paddingRight ? 'pr-10' : ''}`}
            />
          </div>
    )
}

export default InputSearch;
