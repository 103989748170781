import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../../components/layouts/layout_dashboard"
import CustomHeader from "../../../../components/customs/header"
import BtnBack from "../../../../components/buttons/btn_back"
import InputDefault from "../../../../components/form/input_default"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import BtnDefault from "../../../../components/buttons/btn_default"
import CustomSpinner from "../../../../components/customs/spinner"
import { showCashflow, updateCashflow } from "../../../../services/finance/cashflow_service"
import CustomAlert from "../../../../components/customs/alert"

const months = [
    "Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
];

const CashflowUpdate = () => {
    const [accessToken, setAccessToken] = useState("")
    const [userRole, setUserRole] = useState("")
    const navigate = useNavigate()
    const { id } = useParams()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const year = queryParams.get('year');
    const [loading, setLoading] = useState(false)

    const [data, setData] = useState(null);

    const fetchData = async ({ accessToken }) => {
        setLoading(true);
        try {
            const response = await showCashflow({ accessToken, id, year });
            if (response.status === 200) {
                const data = response.data.data;
                const expectedValues = [
                    data.januari.expected.toString(),
                    data.februari.expected.toString(),
                    data.maret.expected.toString(),
                    data.april.expected.toString(),
                    data.mei.expected.toString(),
                    data.juni.expected.toString(),
                    data.juli.expected.toString(),
                    data.agustus.expected.toString(),
                    data.september.expected.toString(),
                    data.oktober.expected.toString(),
                    data.november.expected.toString(),
                    data.desember.expected.toString(),
                ];

                setValues(expectedValues);
                setData(data);
            } else {
                console.log(response);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const [values, setValues] = useState(Array(12).fill("0"));
    const handleChange = (index, value) => {
        const numericValue = value.replace(/[^0-9]/g, '');
        setValues(prev => {
            const newValues = [...prev];
            newValues[index] = numericValue === '' ? '0' : numericValue;
            return newValues;
        });
    };

    const formatCurrency = (value) => {
        if (typeof value !== 'string') {
            value = String(value);
        }
        const numberValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
        return isNaN(numberValue) || numberValue < 1 ? '0' : numberValue.toLocaleString('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    };

    useEffect(() => {
        const accessToken = localStorage.getItem("access_token-terra_app")
        const userRole = localStorage.getItem("user_role-terra_app")
        if (accessToken && userRole) {
            if (["super admin", "keuangan"].includes(userRole)) {
                setAccessToken(accessToken)
                setUserRole(userRole)
                fetchData({ accessToken })
            } else {
                navigate("/403")
            }
        } else {
            navigate("/login")
        }
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const payload = {
                year: year,
                data: values.reduce((acc, value, index) => {
                    acc[index + 1] = value.replace(/[^0-9.-]+/g, "");
                    return acc;
                }, {})
            };

            const response = await updateCashflow({ accessToken, id, data: payload });
            if (response.status === 200) {
                navigate(`/dashboard/financial/arus-kas/detail/${id}?year=${year}`)
            } else {
                setAlertType("error")
                setAlertMessage(response.data.message)
                setOpenAlert(true)
            }
        } catch (error) {
            setOpenAlert(true)
            setAlertType("error")
            setAlertMessage(error.response.data.message)
        }   
        setLoading(false)
    }

    const [openAlert, setOpenAlert] = useState(false)
    const [alertType, setAlertType] = useState("")
    const [alertMessage, setAlertMessage] = useState("")

    return (
        <>
            <Helmet>
                <title>Update Expected Cashflow</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack />
                    <p className="text-center uppercase">Update Expected Cashflow</p>
                </CustomHeader>

                <form onSubmit={handleSubmit} className="p-3 flex flex-col gap-3">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                        {months.map((month, index) => (
                            <InputDefault
                                key={index}
                                id={month.toLowerCase()}
                                label={month}
                                inputMode="numeric"
                                value={formatCurrency(values[index])}
                                handleChange={(e) => handleChange(index, e.target.value)}
                                addClass="w-full text-right"
                            />
                        ))}
                    </div>

                    <BtnDefault type={"submit"}>
                        {loading ? <CustomSpinner /> : "Simpan"}
                    </BtnDefault>
                </form>

                <CustomAlert
                    open={openAlert}
                    setOpen={setOpenAlert}
                    type={alertType}
                    message={alertMessage}
                />
            </LayoutDashboard>
        </>
    )
}

export default CashflowUpdate
