import { Helmet } from "react-helmet";
import LayoutLP from "../../../components/layouts/layout_lp";
import Background from '../../../assets/images/bg_realestate.jpg'
import Logo from '../../../assets/icons/Logo.png'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";


const ContactPage = () => {
  return (
    <>
      <Helmet>Kontak Kami</Helmet>
      <LayoutLP>
        <div
          className="w-full h-screen grid grid-cols-1 bg-fixed lg:grid-cols-2 -mt-12 md:-mt-16 lg:pt-16 bg-cover bg-center md:bg-left lg:bg-center"
          style={{ backgroundImage: `url(${Background})` }}
        >
          <div className="px-3 md:px-10 h-full flex flex-col justify-center backdrop-blur-md md:backdrop-blur-none ">
            <div className="w-[70%] mx-auto md:w-[50%] lg:mx-0">
              <img src={Logo} alt="Perumahan RENJANA PASOPATI" className="w-full object-contain" />
            </div>

            <div className="py-3 text-center lg:text-justify text-custom-navy">
              <p className="text-base md:text-2xl font-bold">
                KONTAK PERUM RENJANA PASOPATI
              </p>
              <p className="font-semibold text-xs md:text-sm text-black pt-3 pb-5">
                Hubungi Perum Renjana Pasopati untuk informasi lebih lanjut mengenai hunian impian Anda.
              </p>
              <Link
                to={`https://wa.link/igcseg`}
                target="_blank"
                rel="noreferrer noopener"
                className="w-fit py-2 rounded-full bg-custom-navy hover:bg-blue-950 transition-colors text-custom-dark-yellow font-semibold flex items-center justify-center space-x-3 px-5 mx-auto md:mx-0"
              >
                <FontAwesomeIcon icon={faWhatsapp} />
                <p>+62 838-3063-1598</p>
              </Link>
            </div>
          </div>
        </div>
      </LayoutLP>
    </>
  );
};

export default ContactPage;
