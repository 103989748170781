import { faTriangleExclamation, faCircleCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react"

const CustomAlert = (
    {
        open,
        setOpen,
        message,
        type,
    }
) => {

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                setOpen(false)
            }, 5000)
        }
    }, [open])
    return (
        <div className={`fixed top-0 left-0 w-full lg:pl-72  flex items-center justify-center z-[100000] p-3 lg:p-5 ${open ? 'block' : 'hidden'}`}>
            <div className="bg-black/50 backdrop-blur-sm rounded-md w-fit max-w-full">
                <div className={` px-3 md:px-5 lg:px-10 py-2 rounded-md w-full flex items-center justify-center gap-3 backdrop-blur-sm ${type === 'error' ? 'bg-red-500/50' : 'bg-green-500/50'}`}>
                    <FontAwesomeIcon icon={type === 'error' ? faTriangleExclamation : faCircleCheck} className={`text-2xl ${type === 'error' ? 'text-yellow-500' : 'text-green-500'}`} />
                    <p className="text-white text-xs md:text-sm font-semibold">
                        {message}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default CustomAlert