import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { getGaleries } from "../../../services/content/galery_service";
import SectionTitle from "./section_title";
import { BaseUrl } from "../../../services/base/base_url";
import { Link } from "react-router-dom";
import vidio from '../../../assets/videos/perum.mp4'

const GalerySection = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await getGaleries();
      if (response.status === 200) {
        setData(response.data.data);
      } else {
        setData([]);
      }
    } catch (error) {
      // alert("Silahkan cek koneksi internet Anda..!");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const openModal = (image) => {
  //   setCurrentImage(image);
  //   setModalIsOpen(true);
  // };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImage(null);
  };

  return (
    <div className="w-full min-h-screen  px-3 md:px-5 lg:px-10 py-10">
      <SectionTitle
        title="Galeri"
        subTitle="Lihat beberapa foto dari proyek kami yang telah selesai dan visualisasi dari hunian idaman Anda."
        color="text-black"
      />
      <div className="w-full flex flex-col lg:flex-row items-center lg:space-x-3 space-y-3 lg:space-y-0 pt-10 ">
        <div className="w-full lg:w-[30%]">
          <video
            src={vidio}
            className="w-full h-[500px] object-cover rounded-xl" controls>
          </video>
        </div>
        <div className="w-full lg:w-[70%]">
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-3 p-3">
            {data.slice(0, 0 ? data.length : 14).map((item, index) => (
              <motion.img
                initial={{ scale: 0 }}
                whileInView={{ scale: 1 }}
                transition={{
                  duration: 0.3,
                  ease: "easeInOut",
                  delay: index * 0.2,
                }}
                key={index}
                src={BaseUrl + "/storage/" + item.file}
                alt="Terra Galery"
                className="w-full aspect-square object-cover rounded-xl hover:z-10 hover:scale-105 transition-all duration-500 cursor-pointer shadow-sm shadow-custom-dark-yellow"
              // onClick={() => openModal(item.file)}
              />
            ))}
            {data?.length > 14 && (
              <Link
                to={"/galery"}
                className=" bg-custom-navy text-custom-dark-yellow px-4 py-2 rounded-xl w-full aspect-square shadow-sm shadow-custom-dark-yellow hover:scale-105 transition-all duration-500 flex items-center justify-center text-center"
              >
                Lihat Selengkapnya ...
              </Link>
            )}
          </div>
        </div>
      </div>

      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="flex items-center justify-center"
        overlayClassName="fixed z-50 inset-0 bg-black bg-opacity-75 flex items-center justify-center"
      >
        <div className="relative w-full h-screen flex items-center justify-center">
          <button
            onClick={closeModal}
            className="bg-red-500 rounded-full absolute top-5 right-5 text-white text-2xl w-10 aspect-square"
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
          <img
            src={BaseURL + "/storage/" + currentImage}
            alt="Terra Galery"
            className="max-w-full max-h-full"
          />
        </div>
      </Modal> */}
    </div>
  );
};

export default GalerySection;
