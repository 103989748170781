
import ApiService from '../base/api_service';

export const getUsers = async (accessToken, params) => {
    try {
        const response = await ApiService.get(`/users`, {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const postUser = async (accessToken, data) => {
    try {
        const response = await ApiService.post(`/user/create`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        })

        return response
    } catch (error) {
        return error.response;
    }
}

export const updateUser = async (accessToken, data, id) => {
    try {
        const response = await ApiService.post(`/user/update/${id}`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        })

        return response
    } catch (error) {
        return error.response;
    }
}

export const deleteUser = async (accessToken, id) => {
    try {
        const response = await ApiService.post(`/user/delete/${id}`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response;
    }
}
    