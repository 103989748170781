import { useEffect, useState } from 'react';

const useIntersectionObserver = ({ targetElementId }) => {
    
    const [whileInView, setWhileInView] = useState(false);

    useEffect(() => {
        const targetElement = document.getElementById(targetElementId || "");
        if (targetElement) {
            const observer = new IntersectionObserver(handleIntersection);
            observer.observe(targetElement);
            return () => {
                observer.unobserve(targetElement);
            };
        } else {
            console.error("Elemen target tidak ditemukan");
        }
        // eslint-disable-next-line
    }, [targetElementId]);

    function handleIntersection(entries) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                setWhileInView(true);
            } else {
                setWhileInView(false);
            }
        });
    }

    return whileInView;
}

export default useIntersectionObserver

