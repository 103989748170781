import Logo from "../../../assets/icons/Logo.png"
import Background from "../../../assets/images/bg_realestate.jpg";

import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faClose } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { getGaleries } from "../../../services/content/galery_service";
import { BaseUrl } from "../../../services/base/base_url";
import LayoutLP from "../../../components/layouts/layout_lp";

const GaleryLp = () => {
    const [accessToken, setAccessToken] = useState("");
    const [userRole, setUserRole] = useState(null);
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [data, setData] = useState([]);
    const fetchData = async () => {
        setLoading(true)
        const response = await getGaleries();
        if (response.status === 200) {
            setData(response.data.data);
        } else {
            setData([]);
        }
        setLoading(false)
    };

    useEffect(() => {
        try {
            const accessToken = localStorage.getItem("access_token-terra_app")
            const userRole = localStorage.getItem("user_role-terra_app")
            setAccessToken(accessToken)
            setUserRole(userRole)
            fetchData();
        } catch (error) {
            // alert("Silahkan periksa koneksi internet Anda..!");
        }
    }, []);


    const [open, setOpen] = useState(false);
    const [mainImageIndex, setMainImageIndex] = useState(0);
    const [isPreviewMode, setIsPreviewMode] = useState(false);

    const handlePrevImage = () => {
        setMainImageIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : (data?.length ?? 0) - 1
        );
    };

    const handleNextImage = () => {
        setMainImageIndex((prevIndex) =>
            prevIndex < (data?.length ?? 0) - 1 ? prevIndex + 1 : 0
        );
    };

    return (

        <LayoutLP>
            <div
                className="w-full h-screen grid grid-cols-1 bg-fixed lg:grid-cols-2 -mt-12 md:-mt-16 lg:pt-16 bg-cover bg-center md:bg-left lg:bg-center"
                style={{ backgroundImage: `url(${Background})` }}
            >
                <div className="px-3 md:px-10 h-full flex flex-col justify-center backdrop-blur-md md:backdrop-blur-none ">
                    <div className="w-[70%] mx-auto md:w-[50%] lg:mx-0">
                        <img src={Logo} alt="Perumahan RENJANA PASOPATI" className="w-full object-contain" />
                    </div>

                    <div className="py-3 text-center lg:text-justify text-custom-navy">
                        <p className="text-base md:text-2xl font-bold">
                            Galeri PERUM RENJANA PASOPATI
                        </p>
                        <p className="font-semibold text-xs md:text-sm text-black pt-3">
                            Lihat beberapa foto dari proyek kami yang telah selesai dan
                            visualisasi dari hunian idaman Anda.
                        </p>
                    </div>
                </div>
            </div>

            <div className="w-full p-3" style={{columns: "150px"}}>
                {data.map((item, index) => (
                    <motion.img
                        initial={{ scale: 0 }}
                        whileInView={{ scale: 1 }}
                        transition={{
                            duration: 0.3,
                            ease: "easeInOut",
                            delay: index * 0.2,
                        }}
                        key={index}
                        src={BaseUrl + "/storage/" + item.file}
                        alt="Terra Galery"
                        loading="lazy"
                        className="w-full object-cover rounded-xl hover:z-10 hover:scale-105 transition-all duration-500 cursor-pointer shadow-sm shadow-custom-dark-yellow my-2"
                        onClick={() => setIsPreviewMode(true)}
                    />
                ))}
            </div>

            {isPreviewMode && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <button
                        onClick={() => setIsPreviewMode(false)}
                        className="absolute top-5 right-5 bg-red-500 hover:bg-red-600 transition-colors text-white text-2xl h-10 aspect-square rounded-full"
                    >
                        <FontAwesomeIcon icon={faClose} />
                    </button>
                    <button
                        onClick={handlePrevImage}
                        className="absolute left-5 top-1/2 transform -translate-y-1/2 bg-custom-dark-yellow text-custom-navy h-10 aspect-square rounded-full"
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <img
                        src={BaseUrl + "/storage/" + data?.[mainImageIndex].file || ""}
                        alt="Sample Unit"
                        loading="lazy"
                        className="max-w-full max-h-full object-contain"
                    />
                    <button
                        onClick={handleNextImage}
                        className="absolute right-5 top-1/2 transform -translate-y-1/2 bg-custom-dark-yellow text-custom-navy h-10 aspect-square rounded-full"
                    >
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </div>
            )}
        </LayoutLP>
    );
};

export default GaleryLp;
