import { useEffect, useRef, useState } from "react";
import { deleteUnitImage, showUnit } from "../../services/unit/unit_service";
import { Link, useHref, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping, faChevronLeft, faChevronRight, faClose, faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import CustomCurrency from "../customs/currency";
import { BaseUrl } from "../../services/base/base_url";
import { getUsedMaterialItems } from "../../services/logistic/used_material_service";
import CustomPaginate from "../customs/paginate";
import BtnDefault from "../buttons/btn_default";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import UnitAddImage from "../../pages/dashboard/unit/add_image";
import CustomAlert from "../customs/alert";
import CustomConfirm from "../customs/confirm";
import CustomSpinner from "../customs/spinner";
import Booking from "../../pages/landing_page/page/unit/booking";

const CardUnitDetail = ({ id }) => {
    const [accessToken, setAccessToken] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const navigate = useNavigate()
    const router = useLocation();
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const currentPath = useHref()

    const [openModal, setOpenModal] = useState(false);
    const [openModalAddImage, setOpenModalAddImage] = useState(false);
    const [openModalEditName, setOpenModalEditName] = useState(false);
    const [openModalEditDetail, setOpenModalEditDetail] = useState(false);
    const [openModalEditProgress, setOpenModalEditProgress] = useState(false);
    const [openModalEditDescription, setOpenModalEditDescription] = useState(false);

    const [data, setData] = useState(null);
    const fetchData = async (accessToken) => {
        setLoading(true);
        try {
            const response = await showUnit(accessToken, id);
            if (response.status === 200) {
                setData(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    const [dataMaterial, setDataMaterial] = useState([])
    const [totalDataMaterial, setTotalDataMaterial] = useState(0)
    const [currentPageMaterial, setCurrentPageMaterial] = useState(1)
    const [lastPageMaterial, setLastPageMaterial] = useState(1)
    const [keywordMaterial, setKeywordMaterial] = useState('')
    const [totalPrice, setTotalPrice] = useState(0)

    const fetchDataMaterial = async (accessToken, keyword, currentPage) => {
        try {
            const params = {
                accessToken: accessToken,
                page: currentPage,
                keyword: keywordMaterial,
                reference_id: id
            }
            const response = await getUsedMaterialItems(accessToken, params)
            if (response.status === 200) {
                setTotalPrice(response.data.total_price)
                setDataMaterial(response.data.data)
                setTotalDataMaterial(response.data.meta.total)
                setLastPageMaterial(response.data.meta.last_page)
                setCurrentPageMaterial(response.data.meta.current_page)
            }
        } catch (error) {
            console.log("__")
        }
    }

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token-terra_app');
        const userRole = localStorage.getItem('user_role-terra_app');
        if (accessToken && userRole) {
            setAccessToken(accessToken)
            setUserRole(userRole)
            fetchDataMaterial(accessToken, keywordMaterial, currentPageMaterial)
        }

        fetchData(accessToken)
    }, []);


    useEffect(() => {
        if (refresh) {
            fetchData(accessToken)
            setRefresh(false)
        }
    }, [refresh])

    const [mainImageIndex, setMainImageIndex] = useState(0); // State untuk melacak gambar utama
    const [isPreviewMode, setIsPreviewMode] = useState(false); // State untuk mode pratinjau
    const [isOverflow, setIsOverflow] = useState(false);
    const [overflowWidth, setOverflowWidth] = useState(0); // State untuk melacak overflow
    const thumbnailsRef = useRef(null); // Ref untuk container gambar thumbnail

    const checkOverflow = () => {
        if (thumbnailsRef.current) {
            // setOverflowWidth(thumbnailsRef.current.offsetWidth);
        }
    };

    useEffect(() => {
        checkOverflow();
        window.addEventListener("resize", checkOverflow);
        return () => window.removeEventListener("resize", checkOverflow);
    }, []);

    const handlePrevImage = () => {
        setMainImageIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : (data?.images.length ?? 0) - 1
        );
    };

    const handleNextImage = () => {
        setMainImageIndex((prevIndex) =>
            prevIndex < (data?.images.length ?? 0) - 1 ? prevIndex + 1 : 0
        );
    };

    const scrollThumbnails = (direction) => {
        if (thumbnailsRef.current) {
            const scrollAmount = direction === "left" ? -100 : 100;
            (thumbnailsRef.current).scrollBy({
                left: scrollAmount,
                behavior: "smooth",
            });
        }
    };

    const CustomRow = ({ name, value }) => {
        return (
            <tr>
                <td className="w-[110px] md:w-fit font-bold py-1">{name}</td>
                <td className="px-1 font-bold py-1">:</td>
                <td className="py-1 flex flex-wrap w-full text-justify">{value}</td>
            </tr>
        );
    };

    const [loadingConfirm, setLoadingConfirm] = useState(false);


    const [openAddImage, setOpenAddImage] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [alertType, setAlertType] = useState('')

    const [openConfirm, setOpenConfirm] = useState(false)
    const [imageId, setImageId] = useState(null)
    const [messageConfirm, setMessageConfirm] = useState('')
    const handleConfirmDeleteImage = async (e) => {
        e.preventDefault()
        setLoadingConfirm(true)
        try {
            const data = new FormData()
            data.append('image_id', imageId)
            const response = await deleteUnitImage(accessToken, data)
            if (response.status === 200) {
                if (mainImageIndex > 0) {
                    setMainImageIndex(mainImageIndex - 1)
                }
                setOpenAlert(true)
                setAlertMessage("Gambar berhasil dihapus..!")
                setAlertType('success')
                setRefresh(true)
                setImageId(null)
                setOpenConfirm(false)
            } else if (response.status === 422) {
                setOpenAlert(true)
                setAlertMessage(response.data.message ?? "Gagal menghapus gambar..!")
                setAlertType('error')
                setImageId(null)
                setOpenConfirm(false)
            } else {
                setOpenAlert(true)
                setAlertMessage("Gagal menghapus gambar..!")
                setAlertType('error')
            }
        } catch (error) {
            setOpenAlert(true)
            setAlertMessage("Gagal menghapus gambar..!")
            setAlertType('error')
        }
        setLoadingConfirm(false)
    }

    const [openModalBooking, setOpenModalBooking] = useState(false)
    const handleCheckout = () => {
        if (!accessToken) {
            navigate(`/login?redirect=${currentPath}`)
        } else {
            if (data?.selling_status === "sudah terjual") {
                alert("Unit ini sudah terjual")
            } else if (data?.selling_status === "sudah dipesan") {
                alert("Unit ini sudah dipesan")
            } else if (data?.selling_status === "belum terjual") {
                setOpenModalBooking(true)
            }
        }
    }
    return (
        <>
            <div className="w-full flex flex-col lg:flex-row gap-3 px-3 md:px-5 py-3 md:py-5">
                {isPreviewMode && (
                    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                        <button
                            onClick={() => setIsPreviewMode(false)}
                            className="absolute top-5 right-5 bg-red-500 hover:bg-red-600 transition-colors text-white text-2xl h-10 aspect-square rounded-full"
                        >
                            <FontAwesomeIcon icon={faClose} />
                        </button>
                        <button
                            onClick={handlePrevImage}
                            className="absolute left-5 top-1/2 transform -translate-y-1/2 bg-custom-dark-yellow text-custom-navy h-10 aspect-square rounded-full"
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <img
                            src={BaseUrl + "/storage/" + data?.images[mainImageIndex].image}
                            alt={"image perum terra " + mainImageIndex}
                            loading="lazy"
                            className="max-w-full max-h-full object-contain"
                        />
                        <button
                            onClick={handleNextImage}
                            className="absolute right-5 top-1/2 transform -translate-y-1/2 bg-custom-dark-yellow text-custom-navy h-10 aspect-square rounded-full"
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                )}

                {/* Content Left */}
                <div className="w-full lg:w-[40%] space-y-3 relative">
                    <div className="relative w-full aspect-video rounded-2xl overflow-hidden shadow-md group">
                        <img
                            src={
                                BaseUrl + "/storage/" + data?.images[mainImageIndex].image
                            }
                            alt="Sample Unit"
                            loading="lazy"
                            className="w-full aspect-video object-contain mx-auto bg-custom-navy cursor-pointer"
                            onClick={() => setIsPreviewMode(true)} // Event handler untuk masuk ke mode pratinjau
                        />

                        {router.pathname.startsWith(`/dashboard/unit/detail/${id}`) && (
                            <button
                                type="button"
                                onClick={() => {
                                    setImageId(data?.images[mainImageIndex]?.id ?? null)
                                    setMessageConfirm("Apakah anda yakin ingin menghapus gambar ini..?")
                                    setOpenConfirm(true)

                                }}
                                className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full scale-0 group-hover:scale-100 transition-all duration-300"
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        )}
                    </div>

                    <div className="relative w-full">
                        {overflowWidth <
                            (data?.images.length ?? 0) * 80 + 12 * ((data?.images.length ?? 0) - 1) && (
                                <button
                                    onClick={() => scrollThumbnails("left")}
                                    className="absolute left-0 top-1/2 h-8 w-6 transform -translate-y-1/2 bg-custom-dark-yellow text-custom-navy rounded-full z-10 flex items-center justify-center"
                                >
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </button>
                            )}
                        <div className="w-full flex gap-3 py-2">
                            <div
                                ref={thumbnailsRef}
                                className="w-full flex items-center space-x-3 overflow-auto hide-scrollbar sticky bottom-0"
                            >
                                {data?.images.map((image, index) => (
                                    <img
                                        key={index}
                                        src={BaseUrl + "/storage/" + image.image}
                                        alt={"image perum terra " + index}
                                        loading="lazy"
                                        className="w-20 aspect-square object-cover bg-custom-navy shadow-md rounded-xl cursor-pointer"
                                        onClick={() => setMainImageIndex(index)} // Event handler untuk memperbarui gambar utama
                                    />
                                ))}
                            </div>
                            {router.pathname.startsWith(
                                `/dashboard/unit/detail/${id}`
                            ) && (
                                    <button
                                        type="button"
                                        onClick={() => setOpenAddImage(true)}
                                        className="w-20 aspect-square bg-custom-navy rounded-xl shadow-md text-custom-dark-yellow"
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                )}
                        </div>
                        {overflowWidth <
                            ((data?.images.length ?? 0) * 80) + 12 * ((data?.images.length ?? 0) - 1) && (
                                <button
                                    onClick={() => scrollThumbnails("right")}
                                    className={`absolute right-0 top-1/2 h-8 w-6 transform -translate-y-1/2 bg-custom-dark-yellow text-custom-navy rounded-full z-10 flex items-center justify-center ${router.pathname.startsWith(`/dashboard/unit/detail/[id]`)
                                        ? "right-20"
                                        : "right-0"
                                        } `}
                                >
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            )}
                    </div>
                </div>

                {/* Content Right */}
                <div className="w-full lg:w-[60%] text-custom-navy space-y-5">
                    <div className="w-full flex items-center justify-between border-b-2 border-custom-navy rounded-full px-3 py-2">
                        <p className="text-base md:text-lg font-bold line-clamp-2">{data?.name}</p>
                        {router.pathname === `/dashboard/unit/detail/[id]` && (
                            <span
                                className="tooltip tooltip-left"
                                data-tooltip="edit nama unit"
                            >
                                <button
                                    type="button"
                                    onClick={() => {
                                        setOpenModalEditName(true);
                                    }}
                                    className="px-3 text-custom-navy bg-custom-dark-yellow hover:scale-105 transition-all duration-300 rounded-full"
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                </button>
                            </span>
                        )}
                    </div>

                    <div className="relative">
                        <div className="flex flex-col md:flex-row gap-3">
                            <div className="w-full">
                                <table className="text-xs md:text-sm w-full">
                                    {['super admin', 'admin', 'lapangan', 'keuangan'].includes(userRole) && (
                                        <CustomRow
                                            name={`Mandor`}
                                            value={
                                                <p className="font-bold capitalize">{data?.mandor ?? '-'}</p>
                                            }
                                        />
                                    )}

                                    <CustomRow
                                        name={`Kategori`}
                                        value={
                                            <p className="font-bold uppercase">{data?.category}</p>
                                        }
                                    />
                                    <CustomRow name={`Tipe`} value={data?.type} />
                                    <CustomRow
                                        name={`Status Penjualan`}
                                        value={
                                            <p
                                                className={`${data?.selling_status === "sudah terjual"
                                                    ? "bg-red-500"
                                                    : data?.selling_status === "sudah dipesan"
                                                        ? "bg-yellow-500" : "bg-green-500"
                                                    } rounded-full text-center font-semibold text-white  px-3 w-full`}
                                            >
                                                {data?.selling_status}
                                            </p>
                                        }
                                    />

                                    <CustomRow
                                        name={"Pembangunan"}
                                        value={
                                            <div className="flex gap-3 w-full">
                                                <p
                                                    className={`px-3 rounded-full text-white font-semibold w-full text-center ${data?.progres === 0
                                                        ? "bg-red-500"
                                                        : data?.progres === 100
                                                            ? "bg-green-500"
                                                            : "bg-yellow-500"
                                                        }`}
                                                >
                                                    {data?.progres_status} ({data?.progres}%)
                                                </p>
                                                {router.pathname === `/dashboard/unit/detail/[id]` && (data?.progres || 100) < 100 && (
                                                    <span
                                                        className="tooltip tooltip-left"
                                                        data-tooltip="edit progres"
                                                    >
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                setOpenModalEditProgress(true);
                                                            }}
                                                            className="px-3 text-custom-navy bg-custom-dark-yellow hover:scale-105 transition-all duration-300 rounded-full"
                                                        >
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </button>
                                                    </span>
                                                )}

                                            </div>
                                        }
                                    />
                                </table>
                            </div>

                            <table className="text-xs md:text-sm w-full">
                                <CustomRow
                                    name={`Harga`}
                                    value={<CustomCurrency value={data?.price ?? 0} />}
                                />
                                <CustomRow
                                    name={`Total Dp`}
                                    value={<CustomCurrency value={data?.dp ?? 0} />}
                                />
                                <CustomRow
                                    name={`Biaya Proses`}
                                    value={<CustomCurrency value={data?.cost_of_booking ?? 0} />}
                                />
                                <CustomRow
                                    name={`Biaya Hook`}
                                    value={<CustomCurrency value={data?.cost_of_hook ?? 0} />}
                                />
                                <CustomRow
                                    name={`Biaya Akad`}
                                    value={<CustomCurrency value={data?.cost_of_akad ?? 0} />}
                                />
                                <CustomRow
                                    name={`Biaya Notaris`}
                                    value={<CustomCurrency value={data?.cost_of_notaris ?? 0} />}
                                />
                            </table>
                        </div>

                        {router.pathname === `/unit/detail/${id}` && data?.selling_status === "belum terjual" && (
                            <div className="flex items-center gap-3 mt-5">
                                <Link
                                    to={`https://wa.link/igcseg`}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="w-fit max-w-[300px] py-2 rounded-full bg-green-500 hover:bg-green-500/80 text-white transition-colors font-semibold flex items-center justify-center gap-3 px-3"
                                >
                                    <FontAwesomeIcon icon={faWhatsapp} />
                                    <p>Hubungi Kami</p>
                                </Link>

                                <BtnDefault handleClick={handleCheckout} addClass="text-white">
                                    <span>Pesan Sekarang</span>
                                    <FontAwesomeIcon icon={faCartShopping} />
                                </BtnDefault>
                            </div>
                        )}

                    </div>


                </div>
            </div>

            <div className="w-full px-3 md:px-5 space-y-3 pb-10">
                <div className="w-full px-5 py-2 rounded-full text-black font-semibold border-b-2 border-custom-navy flex items-center justify-between">
                    <p>Deskripsi</p>
                    {router.pathname === `/dashboard/unit/detail/[id]` && (
                        <span
                            className="tooltip tooltip-left"
                            data-tooltip="edit deskripsi"
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    setOpenModalEditDescription(true);
                                }}
                                className="px-3 text-custom-navy bg-custom-dark-yellow hover:scale-105 transition-all duration-300 rounded-full"
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </button>
                        </span>
                    )}
                </div>
                <div
                    className="rounded-xl p-3 bg-white text-xs md:text-sm lg:text-base"
                    dangerouslySetInnerHTML={{ __html: data?.description || '' }}
                />
            </div>

            {currentPath === `/dashboard/unit/detail/${id}` && accessToken && (
                <>
                    <div className="w-full pb-3 overflow-auto scrollbar-thin scrollbar-track-custom-dark-yellow scrollbar-thumb-custom-navy p-3">
                        <tr className="font-semibold">
                            <td >Total Biaya Alokasi</td>
                            <td className="px-3">:</td>
                            <td><CustomCurrency value={totalPrice} /></td>
                        </tr>
                        <table className={`max-w-screen-2xl text-xs md:text-sm ${loading || dataMaterial?.length === 0 ? 'min-w-max md:min-w-full' : 'min-w-max md:min-w-full'}`}>
                            <thead className="bg-custom-dark-yellow">
                                <tr className="border-b-2 border-custom-dark-yellow uppercase text-sm text-custom-navy">
                                    <th className="px-3 py-2 text-center w-fit rounded-tl-xl">No</th>
                                    <th className="px-3 py-2 text-center w-fit">Nama Suplayer</th>
                                    <th className="px-3 py-2 text-center">Nama Barang</th>
                                    <th className="px-3 py-2 text-center w-fit">Harga</th>
                                    <th className="px-3 py-2 text-center w-fit">Jumlah Alokasi</th>
                                    <th className="px-3 py-2 text-end min-w-fit rounded-tr-xl">
                                        Total Harga
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loading
                                        ? <tr>
                                            <td colSpan={10} className="py-2 flex items-center justify-center w-full">
                                                <CustomSpinner />
                                            </td>
                                        </tr>
                                        :

                                        dataMaterial.length === 0 ?
                                            "Belum ada data..!" :
                                            dataMaterial?.map((item, index) => (
                                                <tr
                                                    className={`${index % 2 === 1 ? "bg-gray-200" : ""
                                                        }  hover:bg-gray-300 transition-colors`}
                                                >
                                                    <td className="px-3 py-2 text-center">{index + 1}</td>
                                                    <td className="px-3 py-2 capitalize">{item?.material?.suplier?.name}</td>
                                                    <td className="px-3 py-2 capitalize">{item?.material?.name}</td>
                                                    <td className="px-3 py-2 text-end">
                                                        <CustomCurrency value={item.price} />
                                                    </td>
                                                    <td className="px-3 py-2 text-center">
                                                        {item.quantity} {item?.material?.unit}
                                                    </td>
                                                    <td className="px-3 py-2 text-end">
                                                        <CustomCurrency value={item.total_price} />
                                                    </td>
                                                </tr>
                                            ))
                                }
                            </tbody>
                        </table>

                    </div>

                    <div className="flex items-center justify-end px-3 py-1 bg-custom-dark-yellow sticky bottom-0 left-0">
                        <CustomPaginate
                            currentPage={currentPageMaterial}
                            lastPage={lastPageMaterial}
                            handlePaginate={(page) => {
                                setCurrentPageMaterial(page)
                                fetchDataMaterial(accessToken, keywordMaterial, page)
                            }}
                        />
                    </div>
                </>
            )}

            <UnitAddImage
                open={openAddImage}
                setOpen={setOpenAddImage}
                setRefresh={setRefresh}
                accessToken={accessToken}
                unitId={id}
                setOpenAlert={setOpenAlert}
                setAlertMessage={setAlertMessage}
                setAlertType={setAlertType}
            />

            <CustomAlert
                open={openAlert}
                setOpen={setOpenAlert}
                message={alertMessage}
                type={alertType}
            />

            <CustomConfirm
                open={openConfirm}
                setOpen={setOpenConfirm}
                loading={loadingConfirm}
                message={messageConfirm}
                handleConfirm={handleConfirmDeleteImage}
                type="delete"
            />

            <Booking
                open={openModalBooking}
                setOpen={setOpenModalBooking}
                setRefresh={setRefresh}
                setOpenAlert={setOpenAlert}
                setMessageAlert={setAlertMessage}
                setTypeAlert={setAlertType}
                getData={data}
            />
        </>
    )
}

export default CardUnitDetail