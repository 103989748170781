import { Helmet } from "react-helmet"
import LayoutDashboard from "../../components/layouts/layout_dashboard"
import CustomHeader from "../../components/customs/header"
import DashboardChart from "./chart"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { LogoutService } from './../../services/authenticate/auth_service';
import CustomDropdown from './../../components/customs/dropdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import CustomSpinner from "../../components/customs/spinner"
import LinkDefault from './../../components/links/link_default';

const Dashboard = () => {
    const [accessToken, setAccessToken] = useState('')
    const [userRole, setUserRole] = useState('')
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token-terra_app')
        const userRole = localStorage.getItem('user_role-terra_app')
        if (!accessToken) {
            navigate('/login')
        } else {
            setAccessToken(accessToken)
            setUserRole(userRole)
        }

    }, [])

    const handleLogout = async () => {
        setLoading(true)
        try {
            const response = await LogoutService(accessToken)
            if (response.status === 200) {
                localStorage.clear()
                navigate('/')
            } else {
                localStorage.clear()
                navigate('/')
            }
        } catch (error) {
            console.log('error')
        }
        setLoading(false)
    }
    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <div className="hidden md:flex" />
                    <p className="text-center uppercase">Dashboard</p>
                    <div className="flex items-center justify-end">
                        <CustomDropdown name={
                            <>
                                <div className="flex items-center justify-center">
                                    <div className="w-10 h-10 rounded-full bg-custom-navy relative"></div>
                                    <div className="flex items-center gap-3 bg-custom-navy rounded-r-full text-custom-dark-yellow -ml-3 pl-5 pr-3">
                                        <p>
                                            {userRole}
                                        </p>
                                    </div>
                                </div>
                            </>
                        }>
                            <LinkDefault name="Profile" link="/dashboard/setting" addClass="text-black text-center py-2 bg-custom-dark-yellow hover:bg-yellow-200 rounded-full transition-colors text-xs md:text-sm" />
                            <LinkDefault name="Kembali ke Home" link="/" addClass="text-black text-center py-2 bg-custom-dark-yellow hover:bg-yellow-200 rounded-full transition-colors text-xs md:text-sm" />
                            <button type="button" onClick={handleLogout} className="text-white text-center py-2 bg-red-500 hover:bg-red-600 rounded-full transition-colors flex items-center justify-center text-xs md:text-sm">
                                {loading ?
                                    <CustomSpinner addClass="" /> :
                                    "Logout"
                                }
                            </button>
                        </CustomDropdown>
                    </div>
                </CustomHeader>

                <DashboardChart />
            </LayoutDashboard>
        </>
    )
}

export default Dashboard