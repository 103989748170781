import { faCircleCheck, faPlus, faSave, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getSupliers } from "../../../../services/user_management/suplier_service"
import { postProcurement } from "../../../../services/logistic/procurment_service"
import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../../components/layouts/layout_dashboard"
import CustomHeader from "../../../../components/customs/header"
import BtnBack from "../../../../components/buttons/btn_back"
import CustomSpinner from "../../../../components/customs/spinner"
import SelectFilter from "../../../../components/form/select_filter"
import CustomCurrency from "../../../../components/customs/currency"
import BtnDefault from "../../../../components/buttons/btn_default"
import ProcurementPayment from "./payment"
import InputDefault from "../../../../components/form/input_default"

const ProcurementCreate = () => {
    const [accessToken, setAccessToken] = useState('')
    const [userRole, setUserRole] = useState('')
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [openConfirm, setOpenConfirm] = useState(false)
    const [openPayment, setOpenPayment] = useState(false)
    const [date, setDate] = useState("")
    const [errorDate, setErrorDate] = useState(null)

    const [openModalAdd, setOpenModalAdd] = useState(false)
    const [loadingSuplier, setLoadingSuplier] = useState(false)

    const [suplierId, setSuplierId] = useState('')
    const [errorSuplierId, setErrorSuplierId] = useState(null)

    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [from, setFrom] = useState(0)
    const [keyword, setKeyword] = useState('')
    const fetchData = async (accessToken, keyword, currentPage) => {
        try {
            setLoadingSuplier(true)
            const response = await getSupliers(accessToken, keyword, currentPage)
            if (response.status === 200) {
                setData(response.data.data)
                setCurrentPage(response.data.current_page)
                setLastPage(response.data.last_page)
                setFrom(response.data.from)
            }
        } catch (error) {
            console.log('--')
        }
        setLoadingSuplier(false)
    }

    useEffect(() => {
        const newAccessToken = localStorage.getItem('access_token-terra_app')
        const newUserRole = localStorage.getItem('user_role-terra_app')
        if (!newAccessToken) {
            navigate('/login')
        } else if (!['super admin', 'admin', 'lapangan', 'keuangan', 'logistik'].includes(newUserRole)) {
            navigate('/403')
        } else {
            setAccessToken(newAccessToken)
            setUserRole(newUserRole)
            fetchData(newAccessToken, keyword, currentPage)
        }
    }, [accessToken, userRole])

    useEffect(() => {
        if (refresh) {
            fetchData(accessToken, keyword, currentPage)
        }
    }, [refresh])


    let number = 1;

    const [items, setItems] = useState([
        {
            name: "",
            unit: "",
            price: "",
            quantity: 1,
        },
    ]);

    const [errorMessage, setErrorMessage] = useState(null);

    const validateItems = () => {
        for (const item of items) {
            if (!item.name || !item.unit || !item.price || item.quantity < 1 || item.quantity > item.stock) {
                return false;
            }
        }
        return true;
    };

    const handleAddItem = () => {
        setItems([
            ...items,
            {
                name: "",
                unit: "",
                price: "",
                quantity: 1,
            },
        ]);
    };

    const handleRemoveItem = (index) => {
        if (items.length > 1) {
            const values = [...items];
            values.splice(index, 1);
            setItems(values);
        }
    };

    const formatRupiah = (number) => {
        return new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            maximumFractionDigits: 0,
        }).format(number);
    };

    const handleChange = (index, event) => {
        const { name, value } = event.target;
        const values = [...items];
        if (name === "price") {
            const rawValue = value.replace(/[^0-9]/g, "");
            (values[index])[name] = rawValue;
            (values[index])["formattedPrice"] = formatRupiah(Number(rawValue));
        } else {
            (values[index])[name] = value;
        }
        setItems(values);
    };

    const getTotalHargaKeseluruhan = () => {
        return items.reduce((total, item) => {
            const price = Number(item.price) ?? 0;
            const quantity = Number(item.quantity) ?? 0;
            return total + price * quantity;
        }, 0);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            setErrorSuplierId(null)
            setErrorMessage(null);

            if (!suplierId) {
                setErrorSuplierId('suplier tidak boleh kosong..!')
            } else if (!validateItems()) {
                setErrorMessage("Semua item harus diisi dengan benar!");
            } else if (!date) {
                setErrorDate("Tanggal transaksi harus diisi")
            } else {
                setOpenConfirm(true)
                // const body = new FormData()
                // body.append('suplier_id', suplierId);
                // items.forEach((item, index) => {
                //     body.append(`material[${index}][name]`, item.name);
                //     body.append(`material[${index}][unit]`, item.unit);
                //     body.append(`material[${index}][price]`, item.price);
                //     body.append(`material[${index}][quantity]`, item.quantity.toString());
                // });

                // const response = await postProcurement(accessToken, body);
                // if (response.status === 201) {
                //     setOpenConfirm(true)
                // } else if (response.status === 403) {
                //     navigate(`/403}`);
                // } else {
                //     alert("tambah pengadaan baru gagal..!");
                // }
            }
        } catch (error) {
            alert("silahkan periksa koneksi internet Anda..!");
        }

        setLoading(false);
    };

    const [loadingSave, setLoadingSave] = useState(false)
    const handleSaveData = async () => {
        setLoadingSave(true)
        try {
            setErrorDate(null)
            if (!date) {
                setErrorDate("Tanggal transaksi harus diisi")
            } else {
                const body = new FormData()
                body.append('suplier_id', suplierId);
                body.append('date', date)
                items.forEach((item, index) => {
                    body.append(`material[${index}][name]`, item.name);
                    body.append(`material[${index}][unit]`, item.unit);
                    body.append(`material[${index}][price]`, item.price);
                    body.append(`material[${index}][quantity]`, item.quantity.toString());
                });

                const response = await postProcurement(accessToken, body);
                if (response.status === 201) {
                    navigate('/dashboard/logistic/procurement')
                } else if (response.status === 403) {
                    navigate(`/403`);
                } else {
                    alert("Gagal menyimpan data..!");
                }
            }
        } catch (error) {
            alert("Gagal menyimpan data..!");
        }
        setLoadingSave(false)
    }


    return (
        <>
            <Helmet>Pengadaan Baru</Helmet>

            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack />
                    <p className="text-center">Pengadaan Baru</p>
                    <div className="flex items-center justify-end">
                        <BtnDefault handleClick={handleSubmit}>
                            {loading === true ? <CustomSpinner addClass="" /> :
                                <>
                                    <FontAwesomeIcon icon={faSave} />
                                    <p>Simpan</p>
                                </>
                            }
                        </BtnDefault>
                    </div>
                </CustomHeader>

                <div className="px-3 py-3 bg-white sticky top-16 left-0 z-40 grid grid-cols-2 gap-5">
                    <div className="grid grid-cols-2 gap-3">
                        <SelectFilter
                            label="Suplier"
                            placeholder="Pilih Suplier"
                            onSelect={(e) => setSuplierId(e)}
                            addClass="w-56 rounded-md"
                            options={data.map(suplier => ({ value: suplier.id || '', label: suplier.name || '' }))}
                            value={suplierId}
                            errorMessage={errorSuplierId}
                        />

                        <InputDefault
                            id="date"
                            type='date'
                            label="Tanggal Transaksi"
                            value={date}
                            setValue={setDate}
                            errorMessage={errorDate}
                        />
                    </div>

                    <button
                        onClick={handleAddItem}
                        type="button"
                        className="px-3 py-2 bg-custom-navy flex items-center justify-center space-x-3 rounded-md font-semibold text-custom-dark-yellow"
                    >
                        <FontAwesomeIcon icon={faPlus} />
                        <p>Tambah Item</p>
                    </button>
                </div>

                <div className="p-3 w-full">
                    {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}
                    <table className="min-w-max md:min-w-full max-w-screen-2xl">
                        <thead>
                            <tr className="bg-custom-dark-yellow">
                                <th className="px-3 py-2 rounded-tl-xl">No</th>
                                <th className="px-3 py-2">Aksi</th>
                                <th className="px-3 py-2">Nama Barang</th>
                                <th className="px-3 py-2">Satuan Barang</th>
                                <th className="px-3 py-2">Harga Satuan</th>
                                <th className="px-3 py-2">Kuantitas</th>
                                <th className="px-3 py-2 rounded-tr-xl">Total Harga</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item, index) => (
                                <tr className="hover:bg-gray-200 transition-colors" key={index}>
                                    <td className="px-1 py-1 text-center">
                                        <button
                                            type="button"
                                            onClick={() => handleRemoveItem(index)}
                                            className="bg-red-500 hover:bg-red-600 transition-colors px-3 py-2 text-white rounded-md"
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </td>
                                    <td className="px-1 py-2 text-center">{number++}</td>
                                    <td className="px-1 py-1">
                                        <input
                                            name="name"
                                            value={item.name}
                                            placeholder="Nama barang"
                                            onChange={(event) => handleChange(index, event)}
                                            className="bg-white text-black w-full outline-none uppercase border-2 focus:border-custom_green rounded-md px-3 py-2"
                                        />
                                    </td>
                                    <td className="px-1 py-1">
                                        <input
                                            type="text"
                                            name="unit"
                                            value={item.unit}
                                            placeholder="satuan barang"
                                            onChange={(event) => handleChange(index, event)}
                                            className="bg-white text-black w-full outline-none uppercase border-2 focus:border-custom_green rounded-md px-3 py-2"
                                        />
                                    </td>
                                    <td className="px-1 py-1">
                                        <input
                                            type="text"
                                            name="price"
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                            value={item.price}
                                            onChange={(event) => handleChange(index, event)}
                                            className="bg-white text-black w-full outline-none border-2 focus:border-custom_green rounded-md px-3 py-2 text-end"
                                        />
                                    </td>
                                    <td className="px-1 py-1">
                                        <input
                                            type="number"
                                            name="quantity"
                                            inputMode="numeric"
                                            pattern="[0-9]*"
                                            value={item.quantity < 1 ? 0 : item.quantity}
                                            onChange={(event) => {
                                                const value = Math.max(0, Number(event.target.value));
                                                handleChange(index, {
                                                    target: { name: "quantity", value },
                                                });
                                            }}
                                            className="bg-white text-black w-full outline-none border-2 focus:border-custom_green rounded-md px-3 py-2 text-center"
                                        />
                                    </td>
                                    <td className="text-end px-1 py-1">
                                        <div className="border-2 rounded-md py-2 px-3 bg-white w-full">
                                            <CustomCurrency value={Number(item.price) * Number(item.quantity) || 0} />
                                        </div>
                                    </td>
                                </tr>
                            ))}

                            <tr className="bg-custom-dark-yellow">
                                <td
                                    className="text-end font-bold text-custom-navy px-1 py-2 uppercase"
                                    colSpan={6}
                                >
                                    Total Harga Keseluruhan :
                                </td>
                                <td className="text-end font-bold text-custom-navy px-1 py-2">
                                    <CustomCurrency value={getTotalHargaKeseluruhan()} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className={`fixed top-0 left-0 w-full h-screen bg-black/50 flex items-center justify-center z-[1000] ${openConfirm ? 'block' : 'hidden'}`}>
                    <div className="bg-white p-5 rounded-xl flex flex-col gap-3 ">
                        <div className="flex items-center justify-center">
                            <FontAwesomeIcon icon={faCircleCheck} className="text-green-500 text-5xl h-20 w-20" />
                        </div>

                        <p className="font-semibold text-center">
                            Pengadaan berhasil disimpan..!
                        </p>

                        <p className="text-center">
                            Lanjutkan untuk melakukan pembayaran..?
                        </p>

                        <div className="flex items-center justify-center space-x-3">
                            <BtnDefault
                                handleClick={() => handleSaveData()}
                            >
                                {loadingSave === true ? <CustomSpinner addClass="" /> : 'Tidak, Simpan saja'}
                            </BtnDefault>
                            <BtnDefault handleClick={() => setOpenPayment(true)}>Ya, lanjutkan</BtnDefault>
                        </div>
                    </div>
                </div>

                <ProcurementPayment
                    accessToken={accessToken}
                    open={openPayment}
                    setOpen={setOpenPayment}
                    suplierId={suplierId}
                    date={date}
                    items={items}
                    totalPayment={getTotalHargaKeseluruhan()}
                />
            </LayoutDashboard>
        </>
    )
}

export default ProcurementCreate