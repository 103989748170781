import CustomAccording from "../../../customs/according"
import LinkSidebar from "../../../links/link_sidebar"

const SidebarLogistic = () => {
    return (
        <>
            <LinkSidebar link="/dashboard" name="Dashboard" />
            <LinkSidebar link="/dashboard/unit" name="Unit / Kavling" />
            <LinkSidebar link="/dashboard/logistic/suplier" name="Data Suplier" />
            <LinkSidebar link="/dashboard/logistic/material" name="Monitoring Barang" />
            <LinkSidebar link="/dashboard/logistic/procurement" name="Pengadaan Barang" />
            <LinkSidebar link="/dashboard/logistic/used-material" name="Penggunaan Barang" />
        </>
    )
}

export default SidebarLogistic

