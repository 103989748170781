
import ApiService from './../base/api_service';

export const getAccount = async ( accessToken ) => {
    try {
        const response = await ApiService.get('/account', {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const postAccount = async ( accessToken, data ) => {
    try {
        const response = await ApiService.post('/account/create', data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const updateAccount = async ( accessToken, data, id ) => {
    try {
        const response = await ApiService.post(`/account/update/${id}`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response 

    } catch (error) {
        return error.response;
    }
}

export const getAccountCategory = async ( accessToken ) => {
    try {
        const response = await ApiService.get('/account/category', {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const getAccountBallances = async ({accessToken}) => {
    try {
        const response = await ApiService.get('/account-ballance', {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const getAccountPasiva = async ({accessToken}) => {
    try {
        const response = await ApiService.get('/account-pasiva', {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const getAccountEkuitas = async ({accessToken}) => {
    try {
        const response = await ApiService.get('/account-ekuitas', {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const getAccountPendapatan = async ({accessToken}) => {
    try {
        const response = await ApiService.get('/account-pendapatan', {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const getAccountBeban = async ({accessToken}) => {
    try {
        const response = await ApiService.get('/account-beban', {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}