import { faEdit, faEye, faFilter, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState, } from "react"
import { useNavigate } from "react-router-dom"
import { deleteDebiturCreditur, getDebiturCreditur } from './../../../services/user_management/debitur_creditur_service';
import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../components/layouts/layout_dashboard"
import CustomHeader from "../../../components/customs/header"
import BtnDefault from "../../../components/buttons/btn_default"
import CustomPaginate from './../../../components/customs/paginate';
import { getDebiturCrediturCategory } from "../../../services/user_management/debitur_creditur_category_service"
import InputSearch from "../../../components/form/input_search"
import CreditureAdd from "./add";
import CreditureUpdate from "./update";
import CustomAlert from './../../../components/customs/alert';
import CustomConfirm from "../../../components/customs/confirm";


const Crediture = () => {
    const [accessToken, setAccessToken] = useState('')
    const [userRole, setUserRole] = useState('')
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const [isOpen, setIsOpen] = useState(false)
    const [openModalUpdate, setOpenModalUpdate] = useState(false)

    const [categoryId, setCategoryId] = useState('')
    const [categoryName, setCategoryName] = useState('data')
    const [transactionCategory, setTransactionCategory] = useState('')

    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [lastPage, setLastPage] = useState(0)
    const [keyword, setKeyword] = useState("")

    const fetchData = async (accessToken, keyword, page) => {
        setLoading(true)
        try {
            const response = await getDebiturCreditur(accessToken, categoryId, keyword, page);
            if (response.status === 200) {
                setData(response.data.data);
                setLastPage(response.data.meta.last_page)
                setCurrentPage(response.data.meta.current_page)
                setTotalData(response.data.meta.total)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    };

    const [dataCategory, setDataCategory] = useState([])
    const fetchDataCategory = async (accessToken) => {
        setLoading(true)
        try {
            const response = await getDebiturCrediturCategory(accessToken)
            if (response.status === 200) {
                setDataCategory(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        try {
            const newAccessToken = localStorage.getItem('access_token-terra_app') ?? ''
            const newUserRole = localStorage.getItem('user_role-terra_app') ?? ''
            if (!newAccessToken) {
                navigate('/login')
            } else if (!['super admin', 'admin', 'lapangan', 'keuangan'].includes(newUserRole)) {
                navigate('/403')
            } else {
                setAccessToken(newAccessToken)
                setUserRole(newUserRole)
                fetchData(newAccessToken, keyword, currentPage)
                fetchDataCategory(newAccessToken)
            }
        } catch (error) {
            alert('Silahkan periksa koneksi internet Anda')
        }
    }, [])

    useEffect(() => {
        if (refresh === true) {
            fetchData(accessToken, keyword, currentPage)
            setRefresh(false)
        }
    }, [refresh])

    const handleSearch = (e) => {
        e.preventDefault()
        try {
            setCurrentPage(1)
            fetchData(accessToken, keyword, 1)
        } catch (error) {
            console.log(error)
        }
    }

    const [dataUpdate, setDataUpdate] = useState(null)

    const [openAlert, setOpenAlert] = useState(false)
    const [typeAlert, setTypeAlert] = useState('')
    const [messageAlert, setMessageAlert] = useState('')

    const [openConfirm, setOpenConfirm] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [messageConfirm, setMessageConfirm] = useState('')
    const [deletedId, setDeletedId] = useState('')
    const handleDelete = async () => {
        setLoadingDelete(true)
        try {
            const response = await deleteDebiturCreditur(accessToken, deletedId)
            if (response.status === 200) {
                setRefresh(true)
                setOpenAlert(true)
                setTypeAlert('success')
                setMessageAlert('Berhasil menghapus data..!')
                setDeletedId('')
                setOpenConfirm(false)
            } else if (response.status === 404) {
                setOpenAlert(true)
                setTypeAlert('error')
                setMessageAlert(response.data.message ?? 'gagal menghapus data..!')
            } else {
                setOpenAlert(true)
                setTypeAlert('error')
                setMessageAlert('gagal menghapus data..!')
            }

        } catch (error) {
            setOpenAlert(true)
            setTypeAlert('error')
            setMessageAlert('gagal menghapus data..!')
        }
        setLoadingDelete(false)
    }

    return (
        <>
            <Helmet>Data Kreditur / Debitur</Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <p>Data Kreditur / Debitur</p>
                    <p></p>
                    <div className="flex items-center justify-end gap-3">
                        <BtnDefault handleClick={() => setIsOpen(true)}>
                            <FontAwesomeIcon icon={faPlus} />
                            <p className="hidden md:flex">Tambah Data</p>
                        </BtnDefault>
                    </div>
                </CustomHeader>

                <div className="flex items-center justify-between gap-3 p-3">
                    <div className="flex items-center gap-3">
                        <div className="flex flex-col md:flex-row items-center justify-between  gap-3 bg-white sticky top-16 left-0">
                            <form onSubmit={handleSearch} className="w-full md:w-[250px] flex items-center gap-3 relative">
                                <InputSearch
                                    addClass="w-full"
                                    keyword={keyword}
                                    handleSearch={(e) => {
                                        setKeyword(e.target.value)
                                    }}
                                    paddingRight={true}
                                />
                                <button type="submit" className="absolute right-[2px] px-3 py-2 md:py-[2px] bg-custom-dark-yellow rounded-r-full">
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                            </form>
                        </div>
                        <p>{totalData} {categoryName}</p>
                    </div>
                </div>

                <div className="w-full overflow-auto scrollbar-thin scrollbar-track-custom-dark-yellow scrollbar-thumb-custom-navy scrollbar-track-rounded-full scrollbar-thumb-rounded-full p-3">
                    <table className={`max-w-screen-xl ${loading ? 'min-w-full' : 'min-w-max md:min-w-full'}`}>
                        <thead>
                            <tr className="bg-custom-dark-yellow">
                                <th className="px-3 py-2 rounded-tl-xl">No</th>
                                <th className="px-3 py-2">ID</th>
                                <th className="px-3 py-2">Nama</th>
                                <th className="px-3 py-2">Kategori</th>
                                <th className="px-3 py-2 rounded-tr-xl" colSpan={2}>Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    Array.from({ length: 10 }).map(() => (
                                        <tr className="animate-pulse">
                                            {Array.from({ length: 5 }).map(() => (
                                                <td className="px-3 py-2">
                                                    <div className="w-full h-5 rounded-full bg-gray-300" />
                                                </td>
                                            ))}
                                        </tr>
                                    )) :
                                    data?.length === 0 ? "Tidak ada data..!" :
                                        data.map((item, index) => (
                                            <tr className={`hover:bg-gray-300 transition-colors ${index % 2 === 1 ? 'bg-gray-200' : ''}`}>
                                                <td className="px-3 py-2 text-center">{index + 1}</td>
                                                <td className="px-3 py-2 text-center">{item.id}</td>
                                                <td className="px-3 py-2 capitalize">{item.name}</td>
                                                <td className="px-3 py-2 text-center capitalize">{item?.category?.name}</td>
                                                <td className="px-3 py-2 text-center w-10">
                                                    <BtnDefault handleClick={() => {
                                                        setDataUpdate(item)
                                                        setOpenModalUpdate(true)
                                                    }} addClass="bg-yellow-400 text-white">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </BtnDefault>
                                                </td>
                                                <td className="px-3 py-2 text-center w-10">
                                                    <BtnDefault handleClick={() => {
                                                        setDeletedId(item.id)
                                                        setOpenConfirm(true)
                                                        setMessageConfirm('Apakah Anda yakin akan menghapus data ini..?')
                                                    }} addClass="bg-red-400 text-white">
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </BtnDefault>
                                                </td>
                                            </tr>
                                        ))}
                            {lastPage < 2 && (
                                <tr>
                                    <td colSpan={10} className="py-4 bg-custom-dark-yellow" />
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {lastPage > 1 && (
                    <div className="p-3 sticky bottom-0 bg-white z-50">
                        <CustomPaginate
                            currentPage={currentPage} lastPage={lastPage}
                            handlePaginate={(e) => {
                                setCurrentPage(e)
                                fetchData(accessToken, keyword, e)
                            }} />
                    </div>
                )}

                {/* Modal */}
                <CreditureAdd
                    accessToken={accessToken}
                    isOpen={isOpen} setIsOpen={setIsOpen}
                    category={dataCategory} setRefresh={setRefresh}
                    setAlert={setOpenAlert}
                    setTypeAlert={setTypeAlert}
                    setMessageAlert={setMessageAlert}
                />
                <CreditureUpdate
                    accessToken={accessToken}
                    isOpen={openModalUpdate} setIsOpen={setOpenModalUpdate}
                    setRefresh={setRefresh}
                    getData={dataUpdate}
                    getDataCategory={dataCategory}
                    setAlert={setOpenAlert}
                    setTypeAlert={setTypeAlert}
                    setMessageAlert={setMessageAlert}
                />

                <CustomAlert
                    type={typeAlert}
                    open={openAlert} setOpen={setOpenAlert}
                    message={messageAlert}
                />

                <CustomConfirm
                    loading={loadingDelete}
                    open={openConfirm} setOpen={setOpenConfirm}
                    handleConfirm={handleDelete}
                    message={messageConfirm}
                />

            </LayoutDashboard>
        </>
    )
}

export default Crediture