import BtnBack from "../../../components/buttons/btn_back"
import BtnDefault from "../../../components/buttons/btn_default"
import CustomHeader from "../../../components/customs/header"
import LayoutDashboard from "../../../components/layouts/layout_dashboard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilter, faPlus, faUpload } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Helmet } from 'react-helmet';
import { getUnits } from "../../../services/unit/unit_service"
import CardUnit from "../../../components/card/unit"
import LoadingUnit from "../../../components/loading/unit"
import InputSearch from "../../../components/form/input_search"
import CustomDropdown from "../../../components/customs/dropdown"
import CustomPaginate from "../../../components/customs/paginate"

const Unit = () => {
    const [accessToken, setAccessToken] = useState('')
    const [userRole, setUserRole] = useState('')
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [mandorID, setMandorID] = useState('')
    const [categoryID, setCategoryID] = useState('')
    const [progressStatusID, setProgressStatusID] = useState('')
    const [sellingStatusID, setSellingStatusID] = useState('')

    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)
    const [fromNumber, setFromNumber] = useState(1)

    const [keyword, setKeyword] = useState('')
    const [category, setCategory] = useState("")
    const [status, setStatus] = useState("semua")

    const fetchData = async (accessToken, keyword, currentPage) => {
        setLoading(true)
        try {
            const params = {
                page: currentPage,
                keyword: keyword,
                status: status
            }
            const response = await getUnits(accessToken, params);
            if (response.status == 200) {
                setData(response.data.data)
                setTotalData(response.data.meta.total)
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
                setFromNumber(response.data.meta.from)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData(accessToken, keyword, currentPage)
    }, [status])

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token-terra_app')
        const userRole = localStorage.getItem('user_role-terra_app')

        if (accessToken && userRole) {
            if (['super admin', 'admin', 'keuangan', 'lapangan', 'logistik'].includes(userRole)) {
                setAccessToken(accessToken)
                setUserRole(userRole)
                fetchData(accessToken, keyword, currentPage)
            } else {
                navigate('/403')
            }
        } else {
            navigate('/login')
        }
    }, [])

    const optionStatus = [
        { 'value': "semua" },
        { 'value': "sudah terjual" },
        { 'value': "sudah dipesan" },
        { 'value': "belum terjual" }
    ]

    return (
        <>
            <Helmet>Data Unit</Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <div className="hidden md:flex" />
                    <p className="text-center uppercase">Data Unit</p>
                    <div className="flex items-center justify-end">
                        <BtnDefault handleClick={() => navigate('/dashboard/unit/create')}>
                            <FontAwesomeIcon icon={faPlus} />
                            <p className="hidden md:flex">Tambah Unit</p>
                        </BtnDefault>
                    </div>
                </CustomHeader>

                <div className="flex flex-col md:flex-row items-center justify-between gap-3 px-3 py-3 w-full sticky top-16 bg-white z-50 shadow-md " >
                    <div className="flex items-center gap-3 w-full md:w-auto">
                        <InputSearch
                            handleSearch={(e) => {
                                setKeyword(e.target.value)
                                setCurrentPage(1)
                                fetchData(accessToken, e.target.value, 1)
                            }}
                            keyword={keyword}
                            addClass="w-full"
                        />
                        <p className="hidden lg:flex items-center gap-3 font-semibold">
                            <span className="text-xl">{totalData}</span>
                            <span>Unit</span>
                        </p>
                    </div>

                    <div className="flex items-center justify-between gap-3 w-full lg:w-fit">
                        <p className="flex lg:hidden items-center gap-3 font-semibold">
                            <span className="text-xl">{totalData}</span>
                            <span>Unit</span>
                        </p>

                        <CustomDropdown
                            name={<>
                                <FontAwesomeIcon icon={faFilter} />
                                <span>{status}</span>
                            </>}
                        >
                            {optionStatus.map((item,) => (
                                <BtnDefault
                                    handleClick={() => setStatus(item.value)}
                                    addClass="w-full text-xs md:text-sm" inversColor={item.value === status ? false : true}>
                                    <span>{item.value}</span>
                                </BtnDefault>
                            ))}
                        </CustomDropdown>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 p-3 md:p-5">
                    {loading === true
                        ? Array.from({ length: 20 }).map(() => <LoadingUnit />)
                        : totalData === 0
                            ? "Tidak ada data"
                            : data.map((item) => (
                                <CardUnit
                                    id={item?.id}
                                    image={item?.images[0]?.image}
                                    name={item?.name}
                                    category={item?.category}
                                    type={item?.type}
                                    price={item?.price}
                                    dp={item?.dp}
                                    costOfBooking={item?.cost_of_booking}
                                    status={item?.selling_status}
                                />
                            ))}
                </div>

                {lastPage > 1 && (
                    <div className="p-3 sticky bottom-0 bg-white z-50">
                        <CustomPaginate
                            currentPage={currentPage}
                            lastPage={lastPage}
                            handlePaginate={(page) => {
                                setCurrentPage(page)
                                fetchData(accessToken, keyword, page)
                            }}
                        />
                    </div>
                )}
            </LayoutDashboard>
        </>
    )
}

export default Unit