import { useEffect, useState } from "react"
import SelectDefault from "../../../../components/form/select_default"
import LayoutModal from "../../../../components/layouts/layout_modal"
import SelectFilter from "../../../../components/form/select_filter";
import { getAccountBallances } from "../../../../services/finance/account_service";
import TextAreaDefault from "../../../../components/form/text_area_default";
import InputDefault from "../../../../components/form/input_default";
import BtnDefault from "../../../../components/buttons/btn_default";
import CustomSpinner from "../../../../components/customs/spinner";
import { getDebiturCreditur } from "../../../../services/user_management/debitur_creditur_service";
import SelectFilterWithPaginate from "../../../../components/form/select_filter_paginate";
import { postPiutang } from "../../../../services/finance/piutang_service";

const PiutangCreate = ({
    open, setOpen, setRefresh, accessToken, getCrediturId, getTotalPiutang,
    setOpenAlert, setMessageAlert, setTypeAlert
}) => {
    const [category, setCategory] = useState("piutang")
    const [accountFrom, setAccountFrom] = useState("piutang usaha")
    const [accountToId, setAccountToId] = useState("")
    const [crediturId, setCrediturId] = useState("")
    const [nominal, setNominal] = useState("0")
    const [description, setDescription] = useState("")
    const [debiturCrediturId, setDebiturCrediturId] = useState("")
    const [date, setDate] = useState("")

    const [errorCrediturId, setErrorCrediturId] = useState(null)
    const [errorAccountToId, setErrorAccountToId] = useState(null)
    const [errorNominal, setErrorNominal] = useState(null)
    const [errorDescription, setErrorDescription] = useState(null)
    const [errorDate, setErrorDate] = useState(null)
    const [loading, setLoading] = useState(false)
    const [loadingAccountBallance, setLoadingAccountBallance] = useState(false)
    const [loadingDebiturCreditur, setLoadingDebiturCreditur] = useState(false)

    const [dataAccountBallance, setDataAccountBallance] = useState([])
    const fetchDataAccountBallance = async (accessToken) => {
        setLoadingAccountBallance(true)
        try {
            const response = await getAccountBallances({ accessToken });
            if (response.status === 200) {
                setDataAccountBallance(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingAccountBallance(false)
    };

    const [dataDebiturCreditur, setDataDebiturCreditur] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [lastPage, setLastPage] = useState(0)
    const [keyword, setKeyword] = useState("")

    const fetchDataDebiturCreditur = async (accessToken, keyword, page) => {
        setLoadingDebiturCreditur(true)
        try {
            const response = await getDebiturCreditur(accessToken, "", keyword, page,);
            if (response.status === 200) {
                setDataDebiturCreditur(response.data.data);
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingDebiturCreditur(false)
    };



    useEffect(() => {

    }, [])

    useEffect(() => {
        if (accessToken) {
            fetchDataAccountBallance(accessToken)
            fetchDataDebiturCreditur(accessToken, keyword, currentPage)
            if (getCrediturId) {
                setDebiturCrediturId(getCrediturId)
            }
        }
    }, [getCrediturId, accessToken])

    const dataOption = [
        { 'name': 'piutang' },
        { 'name': 'piutang dibayar' }
    ]

    const dataOptionPiutang = [
        { 'name': 'piutang usaha' },
        { 'name': 'piutang non usaha' }
    ]

    const handleChange = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/[^0-9]/g, '');
        setNominal(numericValue === '' ? '0' : numericValue);
    };

    const formatCurrency = (value) => {
        const numberValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
        return isNaN(numberValue) || numberValue < 1 ? '0' : numberValue.toLocaleString('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {

            setErrorCrediturId(null)
            setErrorAccountToId(null)
            setErrorNominal(null)
            setErrorDescription(null)
            setErrorDate(null)

            if (!date) {
                setErrorDate("Silahkan masukan tanggal transaksi terlebih dahulu..!")
            } else if (debiturCrediturId.length === 0) {
                setErrorCrediturId("Silahkan pilih Kreditur terlebih dahulu..!")
            } else if (accountToId.length === 0) {
                setErrorAccountToId("Silahkan pilih Kas / Bank terlebih dahulu..!")
            } else if (Number(nominal) === 0) {
                setErrorNominal("Silahkan masukan nominal terlebih dahulu..!")
            } else if (description.length === 0) {
                setErrorDescription("Silahkan masukan keterangan terlebih dahulu..!")
            } else {
                const formData = new FormData()
                formData.append('debitur_creditur_id', debiturCrediturId)
                formData.append('category', category)
                formData.append('account_from', accountFrom)
                formData.append('account_to_id', accountToId)
                formData.append('nominal', Number(nominal))
                formData.append('description', description)
                formData.append('date', date)

                const response = await postPiutang(accessToken, formData)
                if (response.status === 201) {
                    setOpen(false)
                    setRefresh(true)
                    setNominal("0")
                    setDescription("0")
                    setDebiturCrediturId("")
                    setCrediturId("")

                    setOpenAlert(true)
                    setMessageAlert("Transaksi piutang berhasil..!")
                    setTypeAlert("success")
                } else {
                    setOpenAlert(true)
                    setMessageAlert("Transaksi piutang gagal..!")
                    setTypeAlert("error")
                }

            }
        } catch (error) {
            setOpenAlert(true)
            setMessageAlert("Transaksi piutang gagal..!")
            setTypeAlert("error")
        }
        setLoading(false)

    }

    return (
        <LayoutModal name={"Transaksi piutang"} open={open}>
            <form onSubmit={handleSubmit} className="flex flex-col gap-3">

                <InputDefault
                    id="date"
                    type='date'
                    label="Tanggal Transaksi"
                    value={date}
                    setValue={setDate}
                    errorMessage={errorDate}
                />

                {!getCrediturId &&
                    <SelectFilterWithPaginate
                        label='Debitur'
                        placeholder='Pilih debitur'
                        options={dataDebiturCreditur.map(item => ({ value: item?.id, label: `${item?.name} (${item?.id})` }))}
                        onSelect={(e) => setDebiturCrediturId(e)}
                        currentPage={currentPage}
                        lastPage={lastPage}
                        setCurrentPage={setCurrentPage}
                        keyword={keyword}
                        setKeyword={setKeyword}
                        handleSearch={(e) => {
                            setKeyword(e.target.value)
                            fetchDataDebiturCreditur(accessToken, e.target.value, currentPage)
                        }}
                        loading={loadingDebiturCreditur}
                        errorMessage={errorCrediturId}
                    />
                }

                <SelectDefault
                    label='Jenis Transaksi'
                    options={dataOption.map((item) => ({ value: item?.name, label: item?.name }))}
                    // disabled={getCrediturId ? false : true}
                    onSelect={(e) => setCategory(e)}
                />

                <SelectDefault
                    label='Peruntukan'
                    options={dataOptionPiutang.map((item) => ({ value: item?.name, label: item?.name }))}
                    onSelect={(e) => setAccountFrom(e)}
                />

                <SelectFilter
                    label={category === "piutang dibayar" ? "Masuk ke" : "Diambil dari"}
                    placeholder='Pilih kas / bank'
                    options={dataAccountBallance.map(account => ({ value: account.id, label: `(${account?.id}) ${account?.name}` }))}
                    onSelect={(e) => setAccountToId(e)}
                    errorMessage={errorAccountToId}
                />

                {getTotalPiutang && category === "piutang dibayar" &&
                    <InputDefault
                        id="total_piutang"
                        type='text'
                        label="Total Piutang"
                        disabled={true}
                        value={formatCurrency(getTotalPiutang.toString())}
                        handleChange={handleChange}
                    />
                }

                <InputDefault
                    id="name"
                    type='text'
                    label="Nominal"
                    value={formatCurrency(nominal)}
                    handleChange={handleChange}
                    errorMessage={errorNominal ?? undefined}
                />

                <TextAreaDefault
                    id="note"
                    label="Keterangan"
                    value={description}
                    setValue={setDescription}
                    errorMessage={errorDescription}
                />

                <div className="w-full flex items-center gap-3 justify-evenly">
                    <BtnDefault type="button" handleClick={() => {
                        setOpen(false)
                        if (!getCrediturId) {
                            setCrediturId("")
                        }
                        setNominal("0")
                        setDescription("")
                    }} addClass='bg-red-500 w-full '>
                        Kembali
                    </BtnDefault>
                    <BtnDefault type="submit" addClass='w-full'>
                        {loading === true ? <CustomSpinner addClass='' /> : "Simpan"}
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default PiutangCreate