import React, { useRef, useState } from "react";
import HandleClickOutside from "../utils/handle_click_outside";
import { faCaretRight, faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomDropdown = ({ children, height, name, addClass, width }) => {
    const [open, setOpen] = useState(false);
    let filterRef = useRef(null);

    return (
        <div className="relative" ref={filterRef}>
            <button
                onClick={() => setOpen(!open)}
                type="button"
                className={`px-3 py-2 rounded-full bg-custom-dark-yellow text-black font-semibold flex items-center justify-between gap-3 ${addClass}`}
            >
                {name}

                <FontAwesomeIcon icon={faCaretRight} className={`${open ? 'rotate-90' : 'rotate-0'} transition-all duration-500`}/>
            </button>
            <div
                className={`bg-black/10 backdrop-blur-sm absolute z-10 right-0 rounded-xl overflow-hidden transition-all duration-500 space-y-2 text-sm mt-5 flex flex-col px-2 ${
                    width ? width : 'min-w-[140px]'
                } ${
                    open
                        ? `${height ? height : 'max-h-[500px]'} py-2`
                        : 'max-h-0 overflow-hidden'
                }`}
            >
                {children}
            </div>

            <HandleClickOutside setOpen={setOpen} filterRef={filterRef} />
        </div>
    );
};

export default CustomDropdown;
