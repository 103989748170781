import { useState } from "react"
import LayoutModal from "../../../components/layouts/layout_modal"
import { useEffect } from "react"
import InputDefault from "../../../components/form/input_default"
import BtnDefault from "../../../components/buttons/btn_default"
import CustomSpinner from "../../../components/customs/spinner"
import { updateDebiturCreditur } from "../../../services/user_management/debitur_creditur_service"
import SelectFilter from "../../../components/form/select_filter"

const CreditureUpdate = ({
    accessToken,
    isOpen, setIsOpen,
    setRefresh,
    getData,
    getDataCategory,
    setAlert,
    setTypeAlert,
    setMessageAlert
}) => {

    const [loading, setLoading] = useState(false)
    const [loadingCategory, setLoadingCategory] = useState(false)

    const [openCreateCategory, setOpenCreateCategory] = useState(false)

    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [categoryId, setCategoryId] = useState('')

    const [errorName, setErrorName] = useState(null)
    const [errorCategory, setErrorCategory] = useState(null)

    const [dataCategory, setDataCategory] = useState([])

    useEffect(() => {
        if (getData) {
            setId(getData.id)
            setName(getData.name)
            setCategoryId(getData?.category?.id)
            setDataCategory(getDataCategory)
        }
    }, [getData])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const data = new FormData()
            data.append("name", name)
            data.append("category_id", categoryId)

            const response = await updateDebiturCreditur(accessToken, data, id)
            if (response.status === 200) {
                setIsOpen && setIsOpen(false)
                setRefresh && setRefresh(true)
                setAlert && setAlert(true)
                setTypeAlert && setTypeAlert('success')
                setMessageAlert && setMessageAlert('Berhasil mengubah data..!')
            } else if (response.status === 404) {
                setAlert && setAlert(true)
                setTypeAlert && setTypeAlert('error')
                setMessageAlert && setMessageAlert(response.data.message ?? 'gagal mengubah data..!')
            } else {
                setAlert && setAlert(true)
                setTypeAlert && setTypeAlert('error')
                setMessageAlert && setMessageAlert('gagal mengubah data..!')
            }

        } catch (error) {
            console.log("__");
        }
        setLoading(false)
    }
    return (
        <LayoutModal name={"Ubah Data Kreditur / Debitur"} open={isOpen} setOpen={setIsOpen}>
            <form onSubmit={handleSubmit} className="flex flex-col gap-3">
                <InputDefault
                    label="ID"
                    value={id}
                    disabled={true}
                />

                <InputDefault
                    label="Nama"
                    value={name}
                    setValue={setName}
                    placeholder={"Masukkan nama kreditur / debitur"}
                    error={errorName}
                />

                <SelectFilter
                    label='Kategori'
                    placeholder='Pilih Kategori'
                    value={categoryId}
                    options={dataCategory.map(item => ({ value: item.id, label: item.name }))}
                    onSelect={(e) => setCategoryId(e)}
                    loading={loadingCategory}
                    errorMessage={errorCategory}
                />

                <div className="w-full flex items-center gap-3 justify-evenly">
                    <BtnDefault type="button" handleClick={() => setIsOpen && setIsOpen(false)} addClass='bg-red-500 w-full '>
                        Kembali
                    </BtnDefault>
                    <BtnDefault type="submit" addClass='w-full'>
                        {loading ?
                            <CustomSpinner /> :
                            "Simpan"
                        }
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default CreditureUpdate