import { Helmet } from "react-helmet"
import LayoutAuth from "../../components/layouts/layout_auth"
import { Link, useLocation, useNavigate } from "react-router-dom"
import BtnDefault from "../../components/buttons/btn_default"
import InputDefault from "../../components/form/input_default"
import LogoNavy from "../../assets/icons/only_logo_navy.png"
import TextLogoNavy from "../../assets/icons/only_text_logo_navy.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import CustomSpinner from "../../components/customs/spinner";
import CustomAlert from "../../components/customs/alert";
import { useState } from "react"
import { CreateNewPasswordService } from "../../services/authenticate/auth_service"
import InputPassword from "../../components/form/input_password"

const CreateNewPassword = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const code = searchParams.get("code")
    const email = searchParams.get("email")

    const [loading, setLoading] = useState(false);

    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

    const [errorNewPassword, setErrorNewPassword] = useState(null);
    const [errorNewPasswordConfirm, setErrorNewPasswordConfirm] = useState(null);

    const [openAlert, setOpenAlert] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {

            setErrorNewPassword(null)
            setErrorNewPasswordConfirm(null)

            if (newPassword.length === 0) {
                setErrorNewPassword("password tidak boleh kosong..!");
            } else if (newPassword.length < 8) {
                setErrorNewPassword("password minimal terdiri dari 8 karacter..!");
            } else if (!/[A-Z]/.test(newPassword) || !/[a-z]/.test(newPassword) || !/\d/.test(newPassword) || !/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
                setErrorNewPassword("password harus mengandung huruf besar, huruf kecil, angka, dan simbol..!");
            } else if (newPasswordConfirm !== newPassword) {
                setErrorNewPasswordConfirm("Password berbeda..!");
            } else {
                const formData = new FormData()
                formData.append("email", email)
                formData.append("code", code)
                formData.append("new_password", newPassword)
                formData.append("new_password_confirm", newPasswordConfirm)
                const response = await CreateNewPasswordService(formData)

                if (response.status === 200) {
                    navigate("/login")
                } else {
                    setMessageAlert(response.data.message ?? "Password gagal diubah..!")
                    setTypeAlert("error")
                    setOpenAlert(true)
                }
            }
        } catch (error) {
            setMessageAlert("Password gagal diubah")
            setTypeAlert("error")
            setOpenAlert(true)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Helmet>
                <title>Create New Password</title>
            </Helmet>
            <LayoutAuth>
                <form
                    onSubmit={handleSubmit}
                    className="w-full h-fit max-h-full space-y-3 overflow-auto px-5 text-sm md:text-base"
                >
                    <div className="hidden lg:flex items-center justify-center py-3 space-x-3">
                        <p className="font-bold text-3xl text-custom-navy uppercase">
                            Masuk
                        </p>
                        <div className="flex flex-col items-center justify-center">
                            <img src={LogoNavy} alt="Logo TERRA APP" className="w-[80px]" />
                            <img
                                src={TextLogoNavy}
                                alt="Logo TEXT TERRA APP"
                                className="h-[30px] w-fit"
                            />
                        </div>
                    </div>

                    <p className="text-center text-custom-navy font-semibold">
                        Silahkan masukkan email anda, dan kami akan mengirimkan kode verifikasi ke email Anda..!
                    </p>

                    <InputPassword
                        label="Password Baru"
                        placeholder="Masukkan password baru"
                        value={newPassword}
                        handleChange={(e) => setNewPassword(e.target.value)}
                        errorMessage={errorNewPassword}
                    />

                    <InputPassword
                        label="Konfirmasi Password Baru"
                        placeholder="Masukkan konfirmasi password baru"
                        value={newPasswordConfirm}
                        handleChange={(e) => setNewPasswordConfirm(e.target.value)}
                        errorMessage={errorNewPasswordConfirm}
                    />

                    <BtnDefault type="submit" addClass="w-full">
                        {loading === true ? (
                            <CustomSpinner />
                        ) : "Simpan"}
                    </BtnDefault>

                    <div className="flex items-center justify-center space-x-3 ">
                        <Link to="/" className="text-custom-navy font-semibold">
                            Kembali ke halaman utama
                        </Link>
                    </div>
                </form>

                <CustomAlert open={openAlert} setOpen={setOpenAlert} message={messageAlert} type={typeAlert} />
            </LayoutAuth>
        </>
    )
}

export default CreateNewPassword