import { useState } from "react";
import LogoNavy from "../../assets/icons/only_logo_navy.png"
import TextLogoNavy from "../../assets/icons/only_text_logo_navy.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoginService } from "../../services/authenticate/auth_service";
import LayoutAuth from "../../components/layouts/layout_auth";
import { Helmet } from "react-helmet";
import CustomAlert from "../../components/customs/alert";
import InputPassword from "../../components/form/input_password";
import InputDefault from "../../components/form/input_default";
import BtnDefault from "../../components/buttons/btn_default";
import CustomSpinner from "../../components/customs/spinner";

const Login = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get('redirect');
    const [showPassword, setShowPassword] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const [errorEmail, setErrorEmail] = useState(null);
    const [errorPassword, setErrorPassword] = useState(null);

    const [errorMessage422, setErrorMessage422] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

       try {
        setErrorEmail(null);
        setErrorPassword(null);

        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

        if (!isValidEmail) {
            setErrorEmail("format email yang anda berikan salah..!");
            if (email.length === 0) {
                setErrorEmail("email tidak boleh kosong..!");
            }
        }else if (password.length === 0) {
            setErrorPassword("password tidak boleh kosong..!");
        } else if (password.length < 8) {
            setErrorPassword("password minimal terdiri dari 8 karacter..!");
        } else {
            const formData = new FormData();
            formData.append("email", email);
            formData.append("password", password);

            const response = await LoginService({ formData: formData });

            switch (response.status) {
                case 422:
                    setOpenAlert(true);
                    setTypeAlert("error");
                    setMessageAlert(response.data.message);
                    break;

                case 201:
                    navigate(`/verify-email?type=register&email=${email}`);
                    break;

                case 200:
                    localStorage.setItem("user_name-terra_app", response.data.user_name);
                    localStorage.setItem("user_email-terra_app", response.data.user_email);
                    localStorage.setItem("user_role-terra_app", response.data.user_role);
                    localStorage.setItem("access_token-terra_app", `Bearer ${response.data.access_token}`);
                    if (Array.isArray(redirect)) {
                        navigate(redirect[0]);
                    } else if (redirect) {
                        navigate(redirect);
                    } else {
                        navigate("/dashboard");
                    }

                    break;
            }
        }
        } catch (error) {
            alert("Silahkan periksa koneksi internet anda..!");
        }

        setLoading(false);
    };

    const [openAlert, setOpenAlert] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("");

    return (
        <>
            <Helmet>Login</Helmet>
            <LayoutAuth name={`Masuk`}>
                <form
                    onSubmit={handleSubmit}
                    className="w-full h-fit max-h-full space-y-3 overflow-auto px-5 text-sm md:text-base"
                >
                    <div className="hidden lg:flex items-center justify-center py-3 space-x-3">
                        <p className="font-bold text-3xl text-custom-navy uppercase">
                            Masuk
                        </p>
                        <div className="flex flex-col items-center justify-center">
                            <img src={LogoNavy} alt="Logo TERRA APP" className="w-[80px]" />
                            <img
                                src={TextLogoNavy}
                                alt="Logo TEXT TERRA APP"
                                className="h-[30px] w-fit"
                            />
                        </div>
                    </div>

                    {errorMessage422 === null ? (
                        ""
                    ) : (
                        <div className="flex items-center justify-center space-x-3 bg-red-500/30  text-sm rounded-md px-3 py-2 font-semibold relative">
                            {errorMessage422}
                            <button
                                type="button"
                                onClick={() => setErrorMessage422(null)}
                                className="absolute right-1 bg-red-500 hover:bg-red-600 transition-colors text-white rounded-full w-5 aspect-square flex items-center justify-center"
                            >
                                <FontAwesomeIcon icon={faClose} />
                            </button>
                        </div>
                    )}

                    <InputDefault
                        type="email"
                        label="Email"
                        placeholder="Masukkan email"
                        value={email}
                        handleChange={(e) => setEmail(e.target.value)}
                        inputMode="email"
                        errorMessage={errorEmail}
                    />

                    <InputPassword
                        label="Password"
                        placeholder="Masukkan password"
                        value={password}
                        handleChange={(e) => setPassword(e.target.value)}
                        errorMessage={errorPassword}
                    />  

                    <div className="flex items-center justify-end font-semibold pb-3 text-custom-navy">
                        <Link to="/forgot-password">Lupa password..?</Link>
                    </div>
                    <BtnDefault type="submit" addClass="w-full">
                        {loading === true ? (
                            <CustomSpinner />
                        ) : "MASUK"}
                    </BtnDefault>

                    <div className="flex items-center justify-center space-x-3 text-custom-navy">
                        <p>Belum memiliki akun..?</p>
                        <Link
                            to={redirect ? `/register?redirect=${redirect}` : "/register"}
                            className="text-custom-navy font-semibold"
                        >
                            DAFTAR
                        </Link>
                    </div>

                    <div className="flex items-center justify-center space-x-3 ">
                        <Link to="/" className="text-custom-navy font-semibold">
                            Kembali ke halaman utama
                        </Link>
                    </div>
                </form>

                <CustomAlert open={openAlert} setOpen={setOpenAlert} message={messageAlert} type={typeAlert} />
            </LayoutAuth>
        </>
    );
};

export default Login;
