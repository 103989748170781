import ApiService from "../base/api_service"


export const getAdvantages = async () => {
    try {
        const response = await ApiService.get(`/content/advantages`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}

export const showAdvantage = async (id) => {
    try {
        const response = await ApiService.get(`/content/advantage/show/${id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        
        return response

    } catch (error) {
        return error.response
    }
}

export const postAdvantage = async (accessToken, formData) => {
    try {
        const response = await ApiService.post(`/content/advantage/create`, formData, {
            headers: {
                Authorization: accessToken,
                'Content-type': 'application/json'
            }
        })
        return response

    } catch (error) {
        return error.response
    }
}

export const updateAdvantage = async (accessToken, formData, id) => {
    try {
        const response = await ApiService.post(`/content/advantage/update/${id}`, formData, {
            headers: {
                Authorization: accessToken,
                'Content-type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response
    }
}

export const deleteAdvantage = async (accessToken, id) => {
    try {
        const response = await ApiService.post(`/content/advantage/delete/${id}`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}