
import ApiService from '../base/api_service';

export const getSupliers = async (accessToken, keyword, page ) => {
    try {
        const response = await ApiService.get(`/supliers`, {
            params: {
                page: page,
                keyword: keyword
            },
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const showSuplier = async (accessToken, id) => {
    try {
        const response = await ApiService.get(`/suplier/show/${id}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const postSuplier = async (accessToken, data) => {
    try {
        const response = await ApiService.post(`/suplier/create`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const updateSuplier = async (accessToken, data) => {
    try {
        const response = await ApiService.post(`/suplier/update`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}
