import ApiService from "../base/api_service"

export const getUsedMaterials = async (accessToken, params) => {
    try {
        const response = await ApiService.get(`/used-materials`, {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}


export const showUsedMaterial = async (accessToken, id, params) => {
    try {
        const response = await ApiService.get(`/used-material/show/${id}`, {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}




export const postUsedMaterial = async (accessToken, formData) => {
    try {
        const response = await ApiService.post(`/used-material/create`, formData, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response
    }
}



export const getUsedMaterialItems = async (accessToken, params) => {
    try {
        const response = await ApiService.get(`/used-material-items`, {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}