import { Helmet } from "react-helmet"
import LayoutAuth from "../../components/layouts/layout_auth"
import { Link, useNavigate } from "react-router-dom"
import BtnDefault from "../../components/buttons/btn_default"
import CustomSpinner from "../../components/customs/spinner"
import LogoNavy from "../../assets/icons/only_logo_navy.png"
import TextLogoNavy from "../../assets/icons/only_text_logo_navy.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputDefault from "../../components/form/input_default";
import CustomAlert from "../../components/customs/alert";
import { useState } from "react"
import { MailSendService } from "../../services/authenticate/mail_verification_service"

const ForgotPassword = () => {
    const [loading, setLoading] = useState(false)

    const [email, setEmail] = useState("")
    const [errorEmail, setErrorEmail] = useState(null)

    const [openAlert, setOpenAlert] = useState(false)
    const [messageAlert, setMessageAlert] = useState("")
    const [typeAlert, setTypeAlert] = useState("")
    const [redirect, setRedirect] = useState("")

    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
            if (!isValidEmail) {
                setErrorEmail("format email yang anda berikan salah..!");
            } else {
                const response = await MailSendService({formData: {email}})
                if (response.status === 200) {
                    navigate(`/verify-email?type=forgot_password&email=${email}`)
                }
            }
        } catch (error) {
            setMessageAlert("Email tidak ditemukan")
            setTypeAlert("error")
            setOpenAlert(true)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Helmet>
                <title>Forgot Password</title>
            </Helmet>
            <LayoutAuth>
            <form
                    onSubmit={handleSubmit}
                    className="w-full h-fit max-h-full space-y-3 overflow-auto px-5 text-sm md:text-base"
                >
                    <div className="hidden lg:flex items-center justify-center py-3 space-x-3">
                        <p className="font-bold text-2xl text-custom-navy capitalize">
                            Lupa Password
                        </p>
                        <div className="flex flex-col items-center justify-center">
                            <img src={LogoNavy} alt="Logo TERRA APP" className="w-[80px]" />
                            <img
                                src={TextLogoNavy}
                                alt="Logo TEXT TERRA APP"
                                className="h-[30px] w-fit"
                            />
                        </div>
                    </div>

                    <p className="text-center text-custom-navy font-semibold">
                        Silahkan masukkan email anda, dan kami akan mengirimkan kode verifikasi ke email Anda..!
                    </p>

                    <InputDefault
                        type="email"
                        label="Email"
                        placeholder="Masukkan email"
                        value={email}
                        handleChange={(e) => setEmail(e.target.value)}
                        inputMode="email"
                        errorMessage={errorEmail}
                    />

                    <BtnDefault type="submit" addClass="w-full">
                        {loading === true ? (
                            <CustomSpinner />
                        ) : "Kirim kode verifikasi"}
                    </BtnDefault>

                    <div className="flex items-center justify-center space-x-3 text-custom-navy">
                        <p>Sudah ingat password..?</p>
                        <Link
                            to={redirect ? `/login?redirect=${redirect}` : "/login"}
                            className="text-custom-navy font-semibold"
                        >
                            Masuk
                        </Link>
                    </div>

                    <div className="flex items-center justify-center space-x-3 ">
                        <Link to="/" className="text-custom-navy font-semibold">
                            Kembali ke halaman utama
                        </Link>
                    </div>
                </form>

                <CustomAlert open={openAlert} setOpen={setOpenAlert} message={messageAlert} type={typeAlert} />
            </LayoutAuth>
        </>
    )
}

export default ForgotPassword