import { Helmet } from "react-helmet";
import LayoutDashboard from './../../../../components/layouts/layout_dashboard';
import CustomHeader from './../../../../components/customs/header';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMaterials } from "../../../../services/logistic/material_service";
import { useEffect } from "react";
import CustomCurrency from './../../../../components/customs/currency';
import InputSearch from './../../../../components/form/input_search';
import CustomPaginate from "../../../../components/customs/paginate";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Material = () => {
    const [accessToken, setAccessToken] = useState(null)
    const [userRole, setUserRole] = useState(null)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [keyword, setKeyword] = useState("")

    const fetchData = async (accessToken, keyword, currentPage) => {
        setLoading(true)
        try {
            const params = {
                page: currentPage,
                keyword: keyword
            }
            const response = await getMaterials(accessToken, params)

            if (response.status === 200) {
                setData(response.data.data)
                setTotalData(response.data.meta.total)
                setLastPage(response.data.meta.last_page)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token-terra_app')
        const userRole = localStorage.getItem('user_role-terra_app')
        if (accessToken) {
            setAccessToken(accessToken)
            setUserRole(userRole)
            fetchData(accessToken, keyword, currentPage)
        } else {
            navigate('/login')
        }
    }, [])

    useEffect(() => {
        if (refresh) {
            setRefresh(false)
            fetchData(accessToken, keyword, currentPage)
        }
    }, [refresh])

    return (
        <>
            <Helmet>
                <title>Monitoring Barang</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <div className="" />
                    <p className="text-center uppercase">Monitoring Barang</p>
                    <div />
                </CustomHeader>

                <div className="w-full flex p-3">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault()
                            setCurrentPage(1)
                            fetchData(accessToken, keyword, 1)
                        }}
                        className="w-full md:w-[250px] relative flex items-center"
                    >
                        <InputSearch
                            value={keyword}
                            handleSearch={(e) => setKeyword(e.target.value)}
                            addClass={"w-full md:w-[250px]"}
                        />
                        <button type="submit" className="absolute right-[2px] bg-custom-dark-yellow px-3 py-2 md:py-[2px] rounded-r-full">
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </form>
                </div>

                <div className="w-full px-3">
                    <div className="w-full overflow-auto scrollbar-thin scrollbar-track-custom-dark-yellow scrollbar-thumb-custom-navy">
                        <table className={`max-w-screen-2xl text-xs md:text-sm ${loading || data.length === 0 ? 'min-w-max md:min-w-full' : 'min-w-max md:min-w-full'}`}>
                            <thead className="bg-custom-dark-yellow">
                                <tr className="border-b-2 border-custom-dark-yellow uppercase text-custom-navy text-xs md:text-sm ">
                                    <th className="px-3 py-2 text-center w-fit rounded-tl-xl">No</th>
                                    <th className="px-3 py-2 text-center w-fit">Nama Suplayer</th>
                                    <th className="px-3 py-2 text-center">Nama Barang</th>
                                    <th className="px-3 py-2 text-center w-fit">Harga</th>
                                    <th className="px-3 py-2 text-center w-fit">Total Stock</th>
                                    <th className="px-3 py-2 text-center w-fit">Total Harga</th>
                                    <th className="px-3 py-2 text-end min-w-fit rounded-tr-xl">
                                        Sisa Stock
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loading
                                        ? Array.from({ length: 20 }).map(() => (
                                            <tr className="animate-pulse">
                                                {Array.from({ length: 7 }).map(() => (
                                                    <td className="px-3 py-2">
                                                        <div className="w-full h-5 rounded-full bg-gray-300"></div>
                                                    </td>
                                                ))}
                                            </tr>
                                        ))
                                        : data.length < 1 ? "Tidak ada data..!" :
                                            data?.map((item, index) => (
                                                <tr
                                                    className={`${index % 2 === 1 ? "bg-gray-200" : ""
                                                        }  hover:bg-gray-300 transition-colors cursor-pointer`}
                                                    onClick={() => navigate(`/dashboard/logistic/material/detail/${item.id}`)}
                                                >

                                                    <td className="px-3 py-2 text-center">{index + 1}</td>
                                                    <td className="px-3 py-2 capitalize">{item.suplier?.name}</td>
                                                    <td className="px-3 py-2 capitalize">{item.name}</td>
                                                    <td className="px-3 py-2 text-end">
                                                        <CustomCurrency value={item.price} />
                                                    </td>
                                                    <td className="px-3 py-2 text-center">
                                                        {item.ready_stock} {item.unit}
                                                    </td>
                                                    <td className="px-3 py-2 text-end">
                                                        <CustomCurrency value={item.price * item.ready_stock} />
                                                    </td>
                                                    <td className="px-3 py-2 text-center">
                                                        {item.remaining_stock} {item.unit}
                                                    </td>
                                                </tr>
                                            ))
                                }

                                {lastPage < 2 && (
                                    <tr>
                                        <td colSpan={7} className="py-4 bg-custom-dark-yellow" />
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                {lastPage > 1 &&
                    <div className="px-3 sticky bottom-0">
                        <div className="w-full items-center flex justify-center md:justify-end px-3 bg-custom-dark-yellow py-1">
                            <CustomPaginate
                                currentPage={currentPage}
                                lastPage={lastPage}
                                setCurrentPage={setCurrentPage}
                                setRefresh={setRefresh}
                            />
                        </div>
                    </div>
                }
            </LayoutDashboard>
        </>
    )
};

export default Material;
