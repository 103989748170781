
const CustomHeader = ({ children }) => {
    return (
      <div className="bg-custom-navy sticky top-0 left-0 z-[30]">
        <div className="w-full h-16 flex  md:grid grid-cols-3 items-center justify-between px-3 bg-custom-dark-yellow text-black font-semibold  shadow-md lg:rounded-tl-3xl">
          {children}
        </div>
      </div>
    );
  };
  
  export default CustomHeader;
  