import { Helmet } from 'react-helmet';
import LayoutDashboard from '../../../../components/layouts/layout_dashboard';
import CustomHeader from '../../../../components/customs/header';
import BtnBack from '../../../../components/buttons/btn_back';
import BtnDefault from '../../../../components/buttons/btn_default';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPrint } from '@fortawesome/free-solid-svg-icons';
import { getPiutang } from '../../../../services/finance/piutang_service';
import CustomCurrency from '../../../../components/customs/currency';
import FormatDate from '../../../../components/format/date';
import InputDefault from '../../../../components/form/input_default';
import { showDebiturCreditur } from '../../../../services/user_management/debitur_creditur_service';
import PiutangCreate from './create';
import FormatDateV2 from '../../../../components/format/date_v2';
const PiutangDetail = () => {
    const [accessToken, setAccessToken] = useState("")
    const [userRole, setUserRole] = useState("")
    const navigate = useNavigate()
    const { id } = useParams()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [openCreate, setOpenCreate] = useState(false)

    const [debit, setDebit] = useState(0)
    const [credit, setCredit] = useState(0)
    const [data, setData] = useState([])
    const fetchData = async (accessToken) => {
        setLoading(true)
        try {
            const response = await getPiutang(accessToken, id)
            if (response.status === 200) {
                setDebit(response.data.debit)
                setCredit(response.data.credit)
                setData(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    const [dataCreditur, setDataCreditur] = useState(null)
    const fetchDataCreditur = async (accessToken) => {
        setLoading(true)
        try {
            const response = await showDebiturCreditur(accessToken, id)
            if (response.status === 200) {
                setDataCreditur(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        const getAccessToken = localStorage.getItem('access_token-terra_app')
        const getUserRole = localStorage.getItem('user_role-terra_app')

        setAccessToken(getAccessToken)
        fetchData(getAccessToken)
        fetchDataCreditur(getAccessToken)
    }, [])

    const totalDebit = data.reduce((total, item) => {
        return item?.category?.name === 'piutang' ? total + item.nominal : total; // Hitung total debit
    }, 0);

    const totalKredit = data.reduce((total, item) => {
        return item?.category?.name === 'piutang dibayar' ? total + item.nominal : total; // Hitung total debit
    }, 0);


    return (
        <>
            <Helmet>
                <title>Detail Piutang</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack />
                    <p className='text-center'>Detail Piutang</p>
                    <div className="flex items-center justify-end gap-3">
                        <BtnDefault handleClick={() => navigate(`print`)}>
                            <FontAwesomeIcon icon={faPrint} />
                            <p className="hidden md:flex">Print</p>
                        </BtnDefault>
                        <BtnDefault handleClick={() => setOpenCreate(true)}>
                            <FontAwesomeIcon icon={faPlus} />
                            <p className="hidden md:flex">Transaksi Baru</p>
                        </BtnDefault>
                    </div>
                </CustomHeader>

                <div className="px-3 pt-3 flex items-start justify-between">
                    <div className="w-full">
                        <table className={`font-semibold`}>
                            {loading ?
                                Array.from({ length: 3 }).map(() => (
                                    <tr>
                                        {Array.from({ length: 2 }).map(() => (
                                            <td className="px-3 py-2 w-32">
                                                <div className="w-full h-5 bg-gray-300 rounded-full" />
                                            </td>
                                        ))}
                                    </tr>
                                )) :
                                <>
                                    <tr>
                                        <td>ID</td>
                                        <td className="px-3">:</td>
                                        <td>{dataCreditur?.id}</td>
                                    </tr>
                                    <tr>
                                        <td>Nama</td>
                                        <td className="px-3">:</td>
                                        <td className="capitalize">{dataCreditur?.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Kategori</td>
                                        <td className="px-3">:</td>
                                        <td className="capitalize">{dataCreditur?.category?.name}</td>
                                    </tr>
                                </>
                            }
                        </table>
                    </div>
                    <div>
                        <table className="font-semibold">
                            {loading ?
                                Array.from({ length: 3 }).map(() => (
                                    <tr>
                                        {Array.from({ length: 2 }).map(() => (
                                            <td className="px-3 py-2 w-32">
                                                <div className="w-full h-5 bg-gray-300 rounded-full" />
                                            </td>
                                        ))}
                                    </tr>
                                )) :
                                <>
                                    <tr>
                                        <td>Total Piutang</td>
                                        <td className="px-2">:</td>
                                        <td><CustomCurrency value={totalDebit} /></td>
                                    </tr>
                                    <tr>
                                        <td className="w-[250px]">Total Piutang Dibayar</td>
                                        <td className="px-2">:</td>
                                        <td><CustomCurrency value={totalKredit} /></td>
                                    </tr>
                                    <tr>
                                        <td>Sisa Piutang</td>
                                        <td className="px-2">:</td>
                                        <td><CustomCurrency value={totalDebit - totalKredit} /></td>
                                    </tr>
                                </>
                            }
                        </table>
                    </div>
                </div>

                <div className='w-full px-3'>
                    <table className={`max-w-screen-2xl ${data?.length === 0 || loading === true ? 'min-w-max md:min-w-full' : 'min-w-full'}`}>
                        <thead className="bg-custom-dark-yellow text-custom-navy sticky top-[130px] ">
                            <tr className="">
                                <th className="px-3 py-2 rounded-tl-xl" >No</th>
                                <th className="px-3 py-2" >Tanggal</th>
                                <th className="px-3 py-2" >Kode Transaksi</th>
                                <th className="px-3 py-2" >Kategori Transaksi</th>
                                <th className="px-3 py-2" >Jenis Peruntukan</th>
                                <th className="px-3 py-2" >Nominal</th>
                                <th className="px-3 py-2 rounded-tr-xl">Keterangan</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? "Loading..!" :
                                    data?.length === 0 ?
                                        <tr className="">
                                            <td className="px-3 py-2" colSpan={20}>
                                                Tidak ada transaksi..!
                                            </td>
                                        </tr> :
                                        data.map((item, index) => (
                                            <>
                                                <tr className={`${index % 2 === 1 ? 'bg-gray-200' : ''} hover:bg-gray-400 transition-colors`}>
                                                    <td className="px-3 py-2  text-center" >{index + 1}</td>
                                                    <td className="px-3 py-2  " >
                                                        <FormatDateV2 date={item?.date} />
                                                    </td>
                                                    <td className="px-3 py-2  ">
                                                        {item?.code}
                                                    </td>
                                                    <td className="px-3 py-2  ">
                                                        {item?.category?.name}
                                                    </td>
                                                    <td className="px-3 py-2  ">
                                                        {item?.account_from?.id}  -
                                                        {item?.account_from?.name}
                                                    </td>
                                                    <td className="px-3 py-2  text-end">
                                                        <CustomCurrency value={item?.nominal} />
                                                    </td>
                                                    <td className="px-3 py-2  max-w-[500px]">
                                                        {item?.description}
                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                        <tr>
                                            <td colSpan={20} className="px-3 py-5 text-center bg-custom-dark-yellow"/>
                                        </tr>
                        </tbody>
                    </table>
                </div>
            </LayoutDashboard>
            <PiutangCreate
                open={openCreate} setOpen={setOpenCreate}
                setRefresh={setRefresh}
                accessToken={accessToken}
                getCrediturId={id}
                getTotalPiutang={totalDebit - totalKredit}
            />
        </>
    )
}

export default PiutangDetail