import { useEffect } from "react";
import SelectFilterWithPaginate from "../../../components/form/select_filter_paginate";
import LayoutModal from "../../../components/layouts/layout_modal";
import { getUnits } from "../../../services/unit/unit_service";
import { getConsument } from "../../../services/user_management/consument_service";
import { useState } from "react";
import InputDefault from "../../../components/form/input_default";
import BtnDefault from './../../../components/buttons/btn_default';
import CustomCurrency from './../../../components/customs/currency';
import SelectFilter from "../../../components/form/select_filter";
import { getAccountBallances } from "../../../services/finance/account_service";
import CustomSpinner from "../../../components/customs/spinner";
import { postUnitSelling } from "../../../services/unit/unit_selling_service";

const SellingCreate = ({
    accessToken, open, setOpen, setRefresh,
    setOpenAlert, setMessageAlert, setTypeAlert
}) => {

    const [loading, setLoading] = useState(false)
    const [nominal, setNominal] = useState("0")
    const [errorNominal, setErrorNominal] = useState("")

    const [consumentId, setConsumentId] = useState("")
    const [errorConsument, setErrorConsument] = useState("")

    const [dataConsument, setDataConsument] = useState([])
    const [currentPageConsument, setCurrentPageConsument] = useState(1)
    const [lastPageConsument, setLastPageConsument] = useState(1)
    const [keywordConsument, setKeywordConsument] = useState("")
    const [loadingConsument, setLoadingConsument] = useState(false)
    const [errorDate, setErrorDate] = useState("")
    const fetchDataConsument = async (keywordConsument, currentPageConsument) => {
        setLoadingConsument(true)
        try {
            const response = await getConsument(accessToken, keywordConsument, currentPageConsument)
            if (response.status === 200) {
                setDataConsument(response.data.data)
                setCurrentPageConsument(response.data.meta.current_page)
                setLastPageConsument(response.data.meta.last_page)
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingConsument(false)
    }

    const [unitId, setUnitId] = useState("")
    const [errorUnit, setErrorUnit] = useState("")
    const [dataUnit, setDataUnit] = useState([])
    const [currentPageUnit, setCurrentPageUnit] = useState(1)
    const [lastPageUnit, setLastPageUnit] = useState(1)
    const [keywordUnit, setKeywordUnit] = useState("")
    const [loadingUnit, setLoadingUnit] = useState(false)

    const fetchDataUnit = async (keywordUnit, currentPageUnit) => {
        setLoadingUnit(true)
        try {
            const params = {
                page: currentPageUnit,
                keyword: keywordUnit,
                status: "belum terjual"
            }
            const response = await getUnits(accessToken, params)
            if (response.status === 200) {
                setDataUnit(response.data.data)
                setCurrentPageUnit(response.data.meta.current_page)
                setLastPageUnit(response.data.meta.last_page)
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingUnit(false)
    }

    const [accountToId, setAccountToId] = useState("")
    const [errorAccountToId, setErrorAccountToId] = useState("")
    const [dataAccountBallance, setDataAccountBallance] = useState([])
    const [loadingAccountBallance, setLoadingAccountBallance] = useState(false)
    const fetchDataAccountBallance = async (accessToken) => {
        setLoadingAccountBallance(true)
        try {
            const response = await getAccountBallances({ accessToken });
            if (response.status === 200) {
                setDataAccountBallance(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingAccountBallance(false)
    };

    useEffect(() => {
        if (accessToken) {
            fetchDataConsument(keywordConsument, currentPageConsument)
            fetchDataUnit(keywordUnit, currentPageUnit)
            fetchDataAccountBallance(accessToken)
        }
    }, [accessToken])

    const [dp, setDp] = useState(0)
    const [date, setDate] = useState("")
    const [bookingFee, setBookingFee] = useState(0)
    const [hookFee, setHookFee] = useState(0)
    const [akadFee, setAkadFee] = useState(0)
    const [notarisFee, setNotarisFee] = useState(0)
    const [total, setTotal] = useState(0)

    const handleUnitChange = (selectedUnit) => {
        const getId = dataUnit.find(unit => unit.id === selectedUnit)?.id || '';
        const getDp = dataUnit.find(unit => unit.id === selectedUnit)?.dp || 0;
        const getBookingFee = dataUnit.find(unit => unit.id === selectedUnit)?.cost_of_booking || 0;
        const getHookFee = dataUnit.find(unit => unit.id === selectedUnit)?.cost_of_hook || 0;
        const getAkadFee = dataUnit.find(unit => unit.id === selectedUnit)?.cost_of_akad || 0;
        const getNotarisFee = dataUnit.find(unit => unit.id === selectedUnit)?.cost_of_notaris || 0;

        setUnitId(getId)
        setDp(getDp);
        setBookingFee(getBookingFee);
        setHookFee(getHookFee);
        setAkadFee(getAkadFee);
        setNotarisFee(getNotarisFee);
        setTotal(getDp + getBookingFee + getHookFee + getAkadFee + getNotarisFee)
    };

    const handleChange = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/[^0-9]/g, '');
        setNominal(numericValue === '' ? '0' : numericValue);
    };

    const formatCurrency = (value) => {
        const numberValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
        return isNaN(numberValue) || numberValue < 1 ? '0' : numberValue.toLocaleString('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {

            setErrorConsument(null)
            setErrorUnit(null)
            setErrorNominal(null)
            setErrorAccountToId(null)

            if (consumentId === "") {
                setErrorConsument("Konsumen tidak boleh kosong..!")
            } else if (unitId === "") {
                setErrorUnit("Unit tidak boleh kosong..!")
            } else if (Number(nominal) < 1) {
                setErrorNominal("Nominal tidak boleh kosong..!")
            } else if (Number(nominal) > total) {
                setErrorNominal("Nominal tidak boleh lebih besar dari total..!")
            } else if (accountToId === "") {
                setErrorAccountToId("Masuk ke tidak boleh kosong..!")
            } else {

                const formData = new FormData()
                formData.append('date', date)
                formData.append('consument_id', consumentId)
                formData.append('unit_id', unitId)
                formData.append('nominal', Number(nominal))
                formData.append('account_id', accountToId)

                const response = await postUnitSelling(accessToken, formData)

                if (response.status === 201) {
                    setOpenAlert(true)
                    setMessageAlert("Berhasil menambahkan data penjualan..!")
                    setTypeAlert("success")
                    setOpen(false)
                    setRefresh(true)
                } else {
                    setOpenAlert(true)
                    setMessageAlert(response.data.message ?? "Gagal menambahkan data penjualan..!")
                    setTypeAlert("error")
                }
            }
        } catch (error) {
            setOpenAlert(true)
            setMessageAlert("Gagal menambahkan data penjualan")
            setTypeAlert("error")
        }
        setLoading(false)
    }

    return (
        <LayoutModal open={open} setOpen={setOpen} name={"Penjualan Baru"}>
            <form className="flex flex-col gap-3" onSubmit={handleSubmit}>

                <InputDefault
                    id="date"
                    type='date'
                    label="Date"
                    value={date}
                    handleChange={(e) => setDate(e.target.value)}
                    errorMessage={errorDate}
                />

                <SelectFilterWithPaginate
                    label="Nama Konsumen"
                    placeholder="Pilih konsumen"
                    options={dataConsument.map(item => ({ value: item?.id, label: `${item?.name}` }))}
                    value={consumentId}
                    onSelect={(e) => setConsumentId(e)}
                    currentPage={currentPageConsument}
                    lastPage={lastPageConsument}
                    setCurrentPage={setCurrentPageConsument}
                    keyword={keywordConsument}
                    setKeyword={setKeywordConsument}
                    loading={loadingConsument}
                    errorMessage={errorConsument}
                />

                <SelectFilterWithPaginate
                    label="Nama Unit"
                    placeholder="Pilih unit"
                    options={dataUnit.map(item => ({ value: item?.id, label: `${item?.name}` }))}
                    value={unitId}
                    onSelect={(e) => handleUnitChange(e)}
                    currentPage={currentPageUnit}
                    lastPage={lastPageUnit}
                    setCurrentPage={setCurrentPageUnit}
                    keyword={keywordUnit}
                    setKeyword={setKeywordUnit}
                    loading={loadingUnit}
                    errorMessage={errorUnit}
                />

                <table className='w-full'>
                    <tr>
                        <td className="px-3">
                            Biaya Proses
                        </td>
                        <td className="text-right px-3">
                            <CustomCurrency value={bookingFee} />
                        </td>
                    </tr>
                    <tr>
                        <td className="px-3">
                            DP
                        </td>
                        <td className="text-right px-3">
                            <CustomCurrency value={dp} />
                        </td>
                    </tr>
                    <tr>
                        <td className="px-3">
                            Biaya Hook
                        </td>
                        <td className="text-right px-3">
                            <CustomCurrency value={hookFee} />
                        </td>
                    </tr>
                    <tr>
                        <td className="px-3">
                            Biaya Akad
                        </td>
                        <td className="text-right px-3">
                            <CustomCurrency value={akadFee} />
                        </td>
                    </tr>
                    <tr>
                        <td className="px-3">
                            Biaya Notaris
                        </td>
                        <td className="text-right px-3">
                            <CustomCurrency value={notarisFee} />
                        </td>
                    </tr>
                    <tr className='font-bold border-t-2 border-custom-navy'>
                        <td className="px-3">
                            Total Pembayaran
                        </td>
                        <td className="text-right px-3">
                            <CustomCurrency value={total} />
                        </td>
                    </tr>
                </table>

                <InputDefault
                    id="nominal"
                    type='text'
                    label="Nominal"
                    value={formatCurrency(nominal)}
                    handleChange={handleChange}
                    errorMessage={errorNominal}
                    addClass={"text-right"}
                />


                <SelectFilter
                    label="Masuk ke"
                    placeholder='Pilih kas / bank'
                    options={dataAccountBallance.map(account => ({ value: account.id, label: `(${account?.id}) ${account?.name}` }))}
                    value={accountToId}
                    onSelect={(e) => setAccountToId(e)}
                    errorMessage={errorAccountToId}

                />


                <div className="w-full flex items-center gap-3 justify-evenly">
                    <BtnDefault type="button" handleClick={() => {
                        setOpen(false)
                    }} addClass='bg-red-500 w-full '>
                        Kembali
                    </BtnDefault>
                    <BtnDefault type="submit" addClass='w-full'>
                        {loading === true ? <CustomSpinner addClass='' /> : "Simpan"}
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default SellingCreate;