import { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../components/layouts/layout_dashboard"
import CustomHeader from "../../../components/customs/header"
import BtnDefault from "../../../components/buttons/btn_default"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave, faUpload } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import BtnBack from "../../../components/buttons/btn_back"
import SelectDefault from "../../../components/form/select_default"

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

import InputDefault from "../../../components/form/input_default"
import { getMandors } from "../../../services/user_management/mandor_service"
import SelectFilter from "../../../components/form/select_filter"
import SelectFilterWithPaginate from "../../../components/form/select_filter_paginate"
import { postUnit } from "../../../services/unit/unit_service"
import CustomAlert from './../../../components/customs/alert';
import CustomSpinner from "../../../components/customs/spinner"

const UnitCreate = () => {
    const [accessToken, setAccessToken] = useState(null)
    const [userRole, setUserRole] = useState(null)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [name, setName] = useState('');
    const [category, setCategory] = useState('rumah');
    const [type, setType] = useState('');

    const [progresStatus, setProgresStatus] = useState('belum dibangun');
    const [progres, setProgres] = useState(0);

    const [price, setPrice] = useState('0');
    const [dp, setDp] = useState('0');
    const [costOfBooking, setCostOfBooking] = useState('0');
    const [costOfHook, setCostOfHook] = useState('0');
    const [costOfAkad, setCostOfAkad] = useState('0');
    const [costOfNotaris, setCostOfNotaris] = useState('0');

    const [errorCategory, setErrorCategory] = useState(null);
    const [errorProgresStatus, setErrorProgresStatus] = useState(null);
    const [errorFiles, setErrorFiles] = useState(null);
    const [errorName, setErrorName] = useState(null);
    const [errorType, setErrorType] = useState(null);
    const [errorPrice, setErrorPrice] = useState(null);
    const [errorDp, setErrorDp] = useState(null);
    const [errorCostOfBooking, setErrorCostOfBooking] = useState(null);
    const [errorCostOfHook, setErrorCostOfHook] = useState(null);
    const [errorCostOfAkad, setErrorCostOfAkad] = useState(null);
    const [errorCostOfNotaris, setErrorCostOfNotaris] = useState(null);
    const [errorDescription, setErrorDescription] = useState(null);

    const [mandorId, setMandorId] = useState(null);
    const [errorMandor, setErrorMandor] = useState(null);

    const [dataMandor, setDataMandor] = useState([]);
    const [currentPageMandor, setCurrentPageMandor] = useState(1);
    const [lastPageMandor, setLastPageMandor] = useState(1);

    const [keywordMandor, setKeywordMandor] = useState('');
    const [loadingMandor, setLoadingMandor] = useState(false);
    const fetchMandor = async (accessToken, keyword, page) => {
        setLoadingMandor(true)
        try {
            const response = await getMandors(accessToken, keyword, page)
            if (response.status === 200) {
                setDataMandor(response.data.data)
                setLastPageMandor(response.data.last_page)
                setCurrentPageMandor(response.data.current_page)
            }
        } catch (error) {
            console.log(error)
        }
        setLoadingMandor(false)
    }

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token-terra_app')
        const userRole = localStorage.getItem('user_role-terra_app')
        if (!accessToken || !userRole) {
            navigate('/login')
        } else {
            setAccessToken(accessToken)
            setUserRole(userRole)
            fetchMandor(accessToken, keywordMandor, currentPageMandor)
        }
    }, [])

    const [images, setImages] = useState([]);
    const handleImageChange = (e) => {
        const files = Array.from(e.target.files || []);
        const newImages = files.map(file => ({
            file,
            url: URL.createObjectURL(file)
        }));
        setImages(prevImages => [...prevImages, ...newImages]);
    };

    const handleRemoveImage = (index) => {
        setImages(prevImages => prevImages.filter((_, i) => i !== index));
    };


    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };

    const formatRupiah = (number) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0
        }).format(number);
    };

    const handleChangeNumber = (e, setData) => {
        const value = parseInt(e.target.value.replace(/[^\d]/g, ''), 10);
        setData(formatRupiah(value)); // Format salary on change
    }

    const dataCategory = [
        { 'value': 'rumah' },
        { 'value': 'ruko' }
    ]

    const dataProgres = [
        { 'value': 'belum dibangun' },
        { 'value': 'sedang dibangun' },
        { 'value': 'selesai dibangun' }
    ]

    const description = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            setErrorFiles(null)
            setErrorMandor(null)
            setErrorName(null)
            setErrorType(null)

            setErrorPrice(null)
            setErrorCostOfBooking(null)
            setErrorDp(null)
            setErrorCostOfHook(null)

            setErrorDescription(null)

            if (!name) {
                setErrorName('Nama unit tidak boleh kosong..!')
            } else if (!type) {
                setErrorType('Tipe tidak boleh kosong..!')
            } else if (price === '0' || price === '0.00') {
                setErrorPrice('Silahkan masukkan harga unit..!')
            } else if (description.trim() === '<p></p>') {
                setErrorDescription('Deskripsi tidak boleh kosong..!')
            } else {
                const formData = new FormData()
                if (images.length > 0) {
                    images.forEach((image, index) => {
                        formData.append(`images[${index}]`, image.file)
                    })
                }
                formData.append('mandor_id', mandorId)
                formData.append('name', name)
                formData.append('category', category)
                formData.append('type', type)
                formData.append('progres', progres)

                formData.append('price', Number(price.replace(/[^\d]/g, '')).toString())
                formData.append('dp', Number(dp.replace(/[^\d]/g, '')).toString())
                formData.append('cost_of_booking', Number(costOfBooking.replace(/[^\d]/g, '')).toString())
                formData.append('cost_of_hook', Number(costOfHook.replace(/[^\d]/g, '')).toString())
                formData.append('cost_of_akad', Number(costOfAkad.replace(/[^\d]/g, '')).toString())
                formData.append('cost_of_notaris', Number(costOfNotaris.replace(/[^\d]/g, '')).toString())

                formData.append('description', description)
                const response = await postUnit(accessToken, formData)
                if (response.status === 201) {
                    navigate('/dashboard/unit')
                } else {
                    setOpenAlert(true)
                    setAlertMessage('Gagal menambahkan unit..!')
                    setAlertType('error')
                }
            }
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    const [openAlert, setOpenAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [alertType, setAlertType] = useState('')

    return (
        <>
            <Helmet>Tambah Unit</Helmet>
            <LayoutDashboard>
                <form onSubmit={handleSubmit}>
                    <CustomHeader>
                        <BtnBack />
                        <p className="text-center">Tambah Unit</p>
                        <div className="flex items-center justify-end">
                            <BtnDefault type="submit">
                                {loading ? <CustomSpinner /> :
                                    <>
                                        <FontAwesomeIcon icon={faSave} />
                                        <p className="hidden md:flex">Simpan</p>
                                    </>
                                }
                            </BtnDefault>
                        </div>
                    </CustomHeader>

                    <div className="p-3">
                        <div className="flex flex-col items-center justify-center mb-5">
                            <label htmlFor="file-upload" className="cursor-pointer px-3 py-2 flex flex-col items-center space-x-2 border-2  border-dashed rounded-xl w-full hover:border-custom-navy bg-gray-50">
                                <div className="flex items-center gap-3 font-semibold">
                                    <FontAwesomeIcon icon={faUpload} />
                                    <p>Upload Gambar</p>
                                </div>
                                <p className="text-sm text-gray-700">{images.length} gambar dipilih</p>
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                multiple
                                accept="image/*"
                                onChange={handleImageChange}
                                className="mr-3 hidden"
                            />
                        </div>

                        <div className="grid grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-4 ">
                            {images.map((image, index) => (
                                <div key={index} className="relative border shadow-md rounded-lg overflow-hidden">
                                    <img
                                        src={image.url}
                                        alt={`Preview ${index}`}
                                        className="w-full aspect-square object-cover"
                                    />
                                    <button
                                        type="button"
                                        onClick={() => handleRemoveImage(index)}
                                        className="absolute top-0 right-0 bg-red-500 hover:bg-red-600 text-white rounded-full aspect-square w-6"
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="px-3 grid grid-cols-1 md:grid-cols-2 gap-3 pb-3">
                        <div className="flex flex-col gap-3">

                            <SelectFilterWithPaginate
                                label='Mandor'
                                placeholder='Pilih mandor'
                                options={dataMandor.map(mandor => ({ value: mandor.id?.toString() ?? 'Tidak diketahui', label: mandor.name ?? 'Tidak diketahui' }))}
                                onSelect={(e) => setMandorId(e)}
                                loading={loadingMandor}
                                currentPage={currentPageMandor}
                                lastPage={lastPageMandor}
                                setCurrentPage={setCurrentPageMandor}
                                keyword={keywordMandor}
                                setKeyword={setKeywordMandor}
                                errorMessage={errorMandor}
                            />

                            <InputDefault
                                id="name"
                                label="Nama Unit"
                                value={name}
                                setValue={setName}
                                errorMessage={errorName ?? ''}
                                placeholder="nama unit"
                            />

                            <SelectDefault
                                id="category"
                                label="Kategori"
                                options={dataCategory.map((item) => ({ value: item.value, label: item.value }))}
                                value={category}
                                onSelect={(e) => setCategory(e)}
                                errorMessage={errorCategory}
                            />

                            <InputDefault
                                id="type"
                                label="Tipe"
                                value={type}
                                setValue={setType}
                                errorMessage={errorType ?? ''}
                                placeholder="tipe"
                            />


                            <SelectDefault
                                id="progresStatus"
                                label="Progres"
                                options={dataProgres.map((item) => ({ value: item.value, label: item.value }))}
                                value={progresStatus}
                                onSelect={(e) => {
                                    setProgresStatus(e)
                                    if (e === 'belum dibangun') {
                                        setProgres(0)
                                    } else if (e === 'selesai dibangun') {
                                        setProgres(100)
                                    }
                                }}
                                errorMessage={errorProgresStatus}
                            />

                            <InputDefault
                                id="progres"
                                type="number"
                                label="Progres Pembangunan (%)"
                                value={progres || 0} // Jika progres kosong, set ke 0
                                setValue={(value) => {
                                    const numericValue = Number(value); // Mengonversi input ke angka
                                    if (numericValue >= 0 && numericValue <= 100) {
                                        setProgres(numericValue); // Set progres jika dalam rentang 0-100
                                    } else if (value === "") {
                                        setProgres(0); // Set progres ke 0 jika input kosong
                                    }
                                }}
                                placeholder="100%"
                                disabled={progresStatus === 'sedang dibangun' ? false : true}
                            />
                        </div>

                        <div className="flex flex-col gap-3">
                            <InputDefault
                                id="price"
                                label="Harga Unit"
                                value={formatRupiah(Number(price.replace(/[^\d]/g, "")))}
                                handleChange={(e) => handleChangeNumber(e, setPrice)}
                                errorMessage={errorPrice ?? ''}
                                placeholder="harga unit"
                                inputMode="decimal"
                            />

                            <InputDefault
                                id="booking"
                                label="Biaya Proses"
                                value={formatRupiah(Number(costOfBooking.replace(/[^\d]/g, "")))}
                                handleChange={(e) => handleChangeNumber(e, setCostOfBooking)}
                                errorMessage={errorCostOfBooking ?? ''}
                                placeholder="biaya proses"
                                inputMode="decimal"
                            />

                            <InputDefault
                                id="dp"
                                label="DP Unit"
                                value={formatRupiah(Number(dp.replace(/[^\d]/g, "")))}
                                handleChange={(e) => handleChangeNumber(e, setDp)}
                                errorMessage={errorDp ?? ''}
                                placeholder="dp unit"
                                inputMode="decimal"
                            />

                            <InputDefault
                                id="hook"
                                label="Biaya Hook"
                                value={formatRupiah(Number(costOfHook.replace(/[^\d]/g, "")))}
                                handleChange={(e) => handleChangeNumber(e, setCostOfHook)}
                                errorMessage={errorCostOfHook ?? ''}
                                placeholder="biaya hook"
                                inputMode="decimal"
                            />

                            <InputDefault
                                id="akad"
                                label="Biaya Akad"
                                value={formatRupiah(Number(costOfAkad.replace(/[^\d]/g, "")))}
                                handleChange={(e) => handleChangeNumber(e, setCostOfAkad)}
                                errorMessage={errorCostOfAkad ?? ''}
                                placeholder="biaya akad"
                                inputMode="decimal"
                            />

                            <InputDefault
                                id="notaris"
                                label="Biaya Notaris"
                                value={formatRupiah(Number(costOfNotaris.replace(/[^\d]/g, "")))}
                                handleChange={(e) => handleChangeNumber(e, setCostOfNotaris)}
                                errorMessage={errorCostOfNotaris ?? ''}
                                placeholder="biaya notaris"
                                inputMode="decimal"
                            />
                        </div>
                    </div>


                    <div className="px-3 w-full overflow-auto pb-10">
                        {errorDescription && <p className="text-xs font-semibold text-red-500">{errorDescription}</p>}
                        <div className="border-2 rounded-xl p-3">
                            <label htmlFor="description" className="font-semibold text-xs md:text-sm">Deskripsi</label>
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={onEditorStateChange}
                            />
                        </div>
                    </div>
                </form>



                <CustomAlert
                    open={openAlert}
                    setOpen={setOpenAlert}
                    message={alertMessage}
                    type={alertType}
                />

            </LayoutDashboard>
        </>
    )
}

export default UnitCreate