import ApiService from "../base/api_service"

export const MailSendService = async ({formData}) => {
    try {
        const response = await ApiService.post('/mail/send', formData, {
            headers: {
                // Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const MailVerifyService = async (formData) => {
    try {
        const response = await ApiService.post('/mail/verify', formData, {
            headers: {
                // Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const MailResendService = async (formData) => {
    try {
        const response = await ApiService.post('/mail/resend', formData, {
            headers: {
                // Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}