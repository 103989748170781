import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import LayoutDashboard from "../../../../components/layouts/layout_dashboard";
import CustomHeader from "../../../../components/customs/header";
import BtnBack from "../../../../components/buttons/btn_back";
import FormatDate from "../../../../components/format/date";
import CustomCurrency from "../../../../components/customs/currency";
import { showProcurement } from "../../../../services/logistic/procurment_service";
import { getProcurementPayments } from "../../../../services/logistic/procurment_payment_service";
import { getMaterials } from "../../../../services/logistic/material_service";
import BtnDefault from "../../../../components/buttons/btn_default";
import ProcurementPayment from "./modal/payment";
import ProcurementUpdateReadyStock from "./modal/update_ready_stock";
import CustomAlert from "../../../../components/customs/alert";

const ProcurementDetail = () => {
    const [accessToken, setAccessToken] = useState('')

    const navigate = useNavigate()
    const { id } = useParams();

    const [openPayment, setOpenPayment] = useState(false)
    const [openUpdateReadyStock, setOpenUpdateReadyStock] = useState(false)

    const [materialId, setMaterialId] = useState('')
    const [readyStock, setReadyStock] = useState(0)
    const [unit, setUnit] = useState('')

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [data, setData] = useState(null);
    const fetchData = async (accessToken) => {
        try {
            const response = await showProcurement(accessToken, id);
            if (response.status === 200) {
                setData(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }

        setLoading(false)

    };

    const [loadingPayment, setLoadingPayment] = useState(false)
    const [refreshPayment, setRefreshPayment] = useState(false)
    const [dataPayment, setDataPayment] = useState([]);
    const fetchDataPayment = async (accessToken) => {
        setLoadingPayment(true);

        const response = await getProcurementPayments(accessToken, id);
        if (response.status === 200) {
            setDataPayment(response.data.data);
        }

        setLoadingPayment(false);
    };


    useEffect(() => {
        if (refresh === true) {
            fetchData(accessToken)
            fetchDataPayment(accessToken)
            setRefresh(false)
        }
    }, [refresh, refreshPayment]);

    useEffect(() => {
        setLoading(true);
        try {
            const newAccessToken = localStorage.getItem('access_token-terra_app')
            const newUserRole = localStorage.getItem('user_role-terra_app')
            if (!newAccessToken) {
                navigate('/login')
            } else if (!['super admin', 'admin', 'lapangan', 'keuangan', 'logistik'].includes(newUserRole)) {
                navigate('/403')
            } else {
                setAccessToken(newAccessToken)
                fetchData(newAccessToken)
                fetchDataPayment(newAccessToken)
            }
        } catch (error) {
            alert("Silahkan periksa koneksi internet Anda..!");
        }
    }, [id]);

    const MyRow = ({ name, value }) => {
        return (
            <tr>
                <td>{name}</td>
                <td className="px-3 ">:</td>
                <td>{value}</td>
            </tr>
        );
    };

    const [alert, setAlert] = useState(false)
    const [typeAlert, setTypeAlert] = useState('')
    const [messageAlert, setMessageAlert] = useState('')

    return (
        <>
            <Helmet>Detail Pengadaan Barang</Helmet>

            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack />
                    <p className="text-center">Detail Pengadaan Barang</p>
                </CustomHeader>

                <div className="p-3 ">
                    {loading ? (
                        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-3">
                            {
                                Array.from({ length: 2 }).map(() => (
                                    <div className="w-full">
                                        {
                                            Array.from({ length: 4 }).map(() => (
                                                <table className="w-full">
                                                    <tbody>
                                                        <tr className="animate-pulse">
                                                            <td className="w-32">
                                                                <div className="w-full h-5 rounded-full bg-gray-300" />
                                                            </td>
                                                            <td className="px-3 py-1 w-10">
                                                                <div className="w-full h-5 rounded-full bg-gray-300" />
                                                            </td>
                                                            <td className="w-32">
                                                                <div className="w-full h-5 rounded-full bg-gray-300" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    ) :
                        data ?
                            <>
                                <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-3">
                                    <table>
                                        <tbody>
                                            <MyRow
                                                name="Waktu & Tanggal Pengadaan"
                                                value={
                                                    <>
                                                        <FormatDate value={data?.created_at} />
                                                    </>
                                                }
                                            />
                                            <MyRow name="Kode Pengadaan" value={data?.code} />
                                            <MyRow
                                                name="Total Pengadaan"
                                                value={`${data?.items?.length} Barang`}
                                            />
                                            <MyRow name="Suplier" value={data?.suplier ?? '-'} />
                                            <MyRow name="Dibuat Oleh" value={data?.created_by} />
                                        </tbody>
                                    </table>
                                    <table>
                                        <tbody>
                                            <MyRow
                                                name="Total Pembayaran"
                                                value={<CustomCurrency value={data?.total_price ?? 0} />}
                                            />
                                            <MyRow
                                                name="Sisa Pembayaran"
                                                value={<CustomCurrency value={data?.remaining_payment ?? 0} />}
                                            />
                                            <MyRow
                                                name="Status Pembayaran"
                                                value={
                                                    <div
                                                        className={`px-3 rounded-full py-1 text-center font-semibold text-white ${data?.payment_status === "dibayar sebagian"
                                                            ? "bg-yellow-600"
                                                            : data?.payment_status === "sudah dibayar"
                                                                ? "bg-green-500"
                                                                : "bg-red-500"
                                                            }`}
                                                    >
                                                        {data?.payment_status}
                                                    </div>
                                                }
                                            />
                                            <MyRow
                                                name="Status Pengadaan"
                                                value={
                                                    <div
                                                        className={`px-3 rounded-full py-1 text-center font-semibold text-white ${data?.status === "dalam proses"
                                                            ? "bg-yellow-600"
                                                            : data?.status === "selesai"
                                                                ? "bg-green-500"
                                                                : "bg-red-500"
                                                            }`}
                                                    >
                                                        {data?.status}
                                                    </div>
                                                }
                                            />
                                        </tbody>
                                    </table>
                                </div>

                            </> :
                            ""
                    }
                </div>

                <div className="accordion-group px-3 gap-3">
                    {/* Material Data */}
                    <div className="accordion">
                        <input type="checkbox" id="toggle-15" className="accordion-toggle" />
                        <label htmlFor="toggle-15" className="accordion-title w-full px-3 py-2 bg-custom-navy rounded-xl font-bold text-custom-dark-yellow text-xs md:text-sm">
                            Data Barang Yang Dipesan
                        </label>
                        <div className="accordion-content text-content2">
                            <div className="min-h-0 w-full overflow-auto">
                                <div className="w-full pb-3 rounded-xl overflow-auto scrollbar-thin scrollbar-track-custom-dark-yellow scrollbar-thumb-custom-navy">
                                    <table className={`max-w-screen-2xl ${loading ? 'min-w-full' : 'min-w-max'}`}>
                                        <thead className="bg-custom-dark-yellow">
                                            <tr className="border-b-2 border-custom-dark-yellow uppercase text-xs md:text-sm text-custom-navy">
                                                <th className="px-3 py-2 text-center w-fit rounded-tl-xl">No</th>
                                                <th
                                                    className="px-3 py-2 text-center bg-custom_green "
                                                >
                                                    Aksi
                                                </th>
                                                <th className="px-3 py-2 text-center w-fit">Status</th>
                                                <th className="px-3 py-2 text-center w-fit">Nama Suplayer</th>
                                                <th className="px-3 py-2 text-center">Nama Barang</th>
                                                <th className="px-3 py-2 text-center w-fit">Jumlah</th>
                                                <th className="px-3 py-2 text-center w-fit">Harga</th>
                                                <th className="px-3 py-2 text-center w-fit">Sudah Datang</th>
                                                <th className="px-3 py-2 text-center w-fit">Belum Datang</th>
                                                <th className="px-3 py-2 text-end min-w-fit rounded-tr-xl">
                                                    Total Harga
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                loading
                                                    ? Array.from({ length: 5 }).map(() => (
                                                        <tr className="animate-pulse">
                                                            {Array.from({ length: 11 }).map(() => (
                                                                <td className="px-3 py-2">
                                                                    <div className="w-full h-5 rounded-full bg-gray-300"></div>
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    ))
                                                    : data?.items?.map((item, index) => (
                                                        <tr
                                                            className={`${index % 2 === 1 ? "bg-gray-200" : ""
                                                                } hover:bg-gray-300 transition-colors text-xs md:text-sm text-black`}
                                                        >
                                                            <td className="px-3 py-2 text-center">{index + 1}</td>
                                                            <td className="px-3 py-2 text-center">
                                                                {item?.status !== 'sudah datang' &&
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                            setMaterialId(item?.id)
                                                                            setReadyStock(item?.quantity - item?.ready_stock)
                                                                            setUnit(item?.unit)
                                                                            setOpenUpdateReadyStock(true)
                                                                        }}
                                                                        className="bg-yellow-500 hover:bg-yellow-600 transition-colors px-3 py-2 text-white rounded-md text-xs md:text-sm"
                                                                    >
                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                    </button>
                                                                }
                                                            </td>

                                                            <td className="px-3 py-2 ">
                                                                <div
                                                                    className={`px-3 text-xs md:text-sm rounded-full py-1 text-center font-semibold text-white ${item?.status === "datang sebagian"
                                                                        ? "bg-yellow-600"
                                                                        : item?.status === "sudah datang"
                                                                            ? "bg-green-500"
                                                                            : "bg-red-500"
                                                                        }`}
                                                                >
                                                                    {item?.status}
                                                                </div>
                                                            </td>
                                                            <td className="px-3 py-2 capitalize">{item.suplier?.name}</td>
                                                            <td className="px-3 py-2 capitalize">{item.name}</td>
                                                            <td className="px-3 py-2 text-center">
                                                                {item.quantity} {item.unit}
                                                            </td>
                                                            <td className="px-3 py-2 text-end">
                                                                <CustomCurrency value={item.price} />
                                                            </td>
                                                            <td className="px-3 py-2 text-center">
                                                                {item.ready_stock} {item.unit}
                                                            </td>
                                                            <td className="px-3 py-2 text-center">
                                                                {item?.quantity - item?.ready_stock} {item.unit}
                                                            </td>
                                                            <td className="px-3 py-2 text-end font-semibold">
                                                                <CustomCurrency value={item.total_price} />
                                                            </td>
                                                        </tr>
                                                    ))
                                            }

                                            <tr className="bg-custom-dark-yellow">
                                                <td className="px-3 py-5" colSpan={11}></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Payment Data */}
                    <div className="accordion">
                        <input type="checkbox" id="toggle-16" className="accordion-toggle" />
                        <label htmlFor="toggle-16" className="accordion-title w-full px-3 py-2 bg-custom-navy rounded-xl font-bold text-custom-dark-yellow text-xs md:text-sm">
                            Riwayat Pembayaran
                        </label>
                        <div className="accordion-content p-0">
                            <div className="min-h-0 -mt-3 overflow-auto">
                                <div className="w-full p-2 flex flex-col gap-3 border rounded-2xl overflow-auto scrollbar-thin scrollbar-track-custom-dark-yellow scrollbar-thumb-custom-navy">
                                    {data?.remaining_payment > 0 &&
                                        <BtnDefault handleClick={() => setOpenPayment(true)} addClass="w-[250px] justify-self-end place-items-end text-xs md:text-sm sticky top-0 left-0 mx-auto md:mx-0">
                                            <FontAwesomeIcon icon={faPlus} />
                                            <p>Pembayaran Baru</p>
                                        </BtnDefault>
                                    }
                                    <table className="min-w-max lg:min-w-full max-w-screen-2xl">
                                        <thead className="bg-custom-dark-yellow">
                                            <tr className="border-b-2 border-custom-dark-yellow uppercase text-xs md:text-sm text-custom-navy">
                                                <th className="px-3 py-2 text-center w-fit rounded-tl-xl">No</th>
                                                <th className="px-3 py-2 text-center w-fit">Tanggal</th>
                                                <th className="px-3 py-2 text-center">Jumlah Pembayaran</th>
                                                <th className="px-3 py-2 text-center rounded-tr-xl">
                                                    Dibuat Oleh
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                loadingPayment
                                                    ? Array.from({ length: 5 }).map(() => (
                                                        <tr className="animate-pulse">
                                                            {Array.from({ length: 4 }).map(() => (
                                                                <td className="px-3 py-2">
                                                                    <div className="w-full h-5 rounded-full bg-gray-300"></div>
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    ))
                                                    : dataPayment.length < 1 ? "Belum ada pembayaran..!" :
                                                        dataPayment?.map((item, index) => (
                                                            <tr
                                                                className={`${index % 2 === 1 ? "bg-gray-200" : ""
                                                                    } cursor-pointer hover:bg-gray-300 transition-colors`}
                                                            >
                                                                <td className="px-3 py-2 text-center">{index + 1}</td>
                                                                <td className="px-3 py-2 text-center">
                                                                    <FormatDate value={data?.created_at} />
                                                                </td>
                                                                <td className="px-3 py-2 text-end">
                                                                    <CustomCurrency value={item?.nominal ?? 0} />
                                                                </td>
                                                                <td className="px-3 py-2 ">{item.created_by}</td>
                                                            </tr>
                                                        ))
                                            }

                                            <tr className="bg-custom-dark-yellow">
                                                <td className="px-3 py-4" colSpan={10}></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ProcurementPayment
                    accessToken={accessToken}
                    isOpen={openPayment}
                    setIsOpen={setOpenPayment}
                    remainingPayment={data?.remaining_payment ?? 0}
                    setRefresh={setRefresh}
                    procurementId={data?.id}
                    setAlert={setAlert}
                    setTypeAlert={setTypeAlert}
                    setMessageAlert={setMessageAlert}
                />

                <ProcurementUpdateReadyStock
                    accessToken={accessToken}
                    isOpen={openUpdateReadyStock}
                    setIsOpen={setOpenUpdateReadyStock}
                    setRefresh={setRefresh}
                    materialId={materialId}
                    readyStock={readyStock}
                    unit={unit}
                />

                <CustomAlert
                    open={alert}
                    setOpen={setAlert}
                    type={typeAlert}
                    message={messageAlert}
                />

            </LayoutDashboard>

        </>
    );
};

export default ProcurementDetail;
