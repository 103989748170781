import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getAccountBallances } from "../../../../services/finance/account_service"
import { Helmet } from 'react-helmet';
import LayoutDashboard from '../../../../components/layouts/layout_dashboard';
import CustomHeader from '../../../../components/customs/header';
import BtnBack from '../../../../components/buttons/btn_back';
import CustomCurrency from '../../../../components/customs/currency';
import { getReportFinance } from "../../../../services/finance/report_service";
import SelectFilter from "../../../../components/form/select_filter";
import { months } from "../../../../components/utils/month_data";

const FinancialReport = () => {
    const [accessToken, setAccessToken] = useState('')
    const [userRole, setUserRole] = useState('')
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [year, setYear] = useState(null)
    const [month, setMonth] = useState(null)
    const [day, setDay] = useState(null)

    const [data, setData] = useState([])
    const fetchData = async (accessToken) => {
        setLoading(true)
        try {

            const params = {
                year: year,
                month: month,
                day: day
            }

            const response = await getReportFinance(accessToken, params)
            if (response.status === 200) {
                setData(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData(accessToken)
    }, [year, month, day])

    useEffect(() => {
        try {
            const newAccessToken = localStorage.getItem('access_token-terra_app') ?? ''
            const newUserRole = localStorage.getItem('user_role-terra_app') ?? ''
            if (!newAccessToken) {
                // navigate('/login')
            } else if (!['super admin', 'admin', 'lapangan', 'keuangan'].includes(newUserRole)) {
                // navigate('/403')
            } else {
                setAccessToken(newAccessToken)
                setUserRole(newUserRole)
                fetchData(newAccessToken)
            }
        } catch (error) {
            alert('Silahkan periksa koneksi internet Anda')
        }
    }, [])

    const totalDebit = data.reduce((data, item) => data + (item.debit || 0), 0);
    const totalCredit = data.reduce((data, item) => data + (item.credit || 0), 0);
    const totalBalance = data.reduce((data, item) => data + (item.ballance || 0), 0);

    let currentYear = new Date().getFullYear()
    const years = Array.from({ length: 20 }).map((_, index) => ({
        value: (currentYear - 10 + index).toString(),
        label: (currentYear - 10 + index).toString()
    }));


    return (
        <>
            <Helmet>
                <title>Laporan Keuangan</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack />
                    <p className="text-center">Laporan Keuangan</p>
                </CustomHeader>

                <div className="grid grid-cols-1 md:flex flex-col md:flex-row items-center gap-3 w-full sticky left-0 p-3">
                        <SelectFilter
                            placeholder='Pilih Tahun'
                            options={years}
                            onSelect={(e) => {
                                setYear(e);
                                setMonth(null)
                                setDay(null)
                            }}
                            value={year}
                            plusOption={
                                <button
                                    onClick={() => {
                                        setYear("")
                                        setMonth(null)
                                        setDay(null)
                                    }}
                                    className="w-full py-2 hover:bg-gray-200 transition-colors border-b rounded-full">
                                    Semua Tahun
                                </button>
                            }
                            widthName="w-full md:w-[200px]"
                        />

                        <SelectFilter
                            placeholder='Pilih bulan'
                            options={months.map(item => ({ value: item.id, label: item.name }))}
                            value={month}
                            plusOption={
                                <button
                                    onClick={() => {
                                        setMonth("")
                                        setDay(null)
                                    }}
                                    className="w-full py-2 hover:bg-gray-200 transition-colors border-b rounded-full">
                                    Semua Bulan
                                </button>
                            }
                            onSelect={(e) => {
                                setMonth(e)
                                setDay(null)
                            }}
                            widthName="w-full md:w-[200px]"
                        />

                        <SelectFilter
                            placeholder='Pilih Hari'
                            options={Array.from({ length: 31 }, (_, i) => ({
                                value: String(i + 1).padStart(2, '0'),
                                label: String(i + 1)
                            }))}
                            plusOption={
                                <button
                                    onClick={() => {
                                        setDay("")
                                    }}
                                    className="w-full py-2 hover:bg-gray-200 transition-colors border-b rounded-full">
                                    Semua Hari
                                </button>
                            }
                            value={day}
                            onSelect={(e) => { setDay(e) }}
                            widthName="w-full md:w-[200px]"
                        />
                    </div>

                <div className="px-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                    {/* <table className="max-w-screen-2xl min-w-max md:min-w-full">
                        <thead >
                            <tr className="bg-custom-dark-yellow">
                                <th className="px-3 py-2 rounded-tl-xl">Id</th>
                                <th className="px-3 py-2">Nama</th>
                                <th className="px-3 py-2">Total Debit</th>
                                <th className="px-3 py-2">Total Kredit</th>
                                <th className="px-3 py-2 rounded-tr-xl">Total Saldo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? "Loading..." :
                                data.map((item, index) => (
                                    <tr className={`hover:bg-gray-300 transition-colors ${index % 2 === 1 ? "bg-gray-200" : ""}`}>
                                        <td className="px-3 py-2">{item.id}</td>
                                        <td className="px-3 py-2">{item.name}</td>
                                        <td className="px-3 py-2 text-end">
                                            <CustomCurrency value={item?.debit ?? 0} />
                                        </td>
                                        <td className="px-3 py-2 text-end">
                                            <CustomCurrency value={item?.credit ?? 0} />
                                        </td>
                                        <td className="px-3 py-2 text-end font-semibold">
                                            <CustomCurrency value={item?.ballance} />
                                        </td>
                                    </tr>
                                ))
                            }

                            <tr className="bg-custom-dark-yellow font-semibold">
                                <td colSpan={3} className="px-3 py-2 text-end">
                                    <CustomCurrency value={totalDebit} />
                                </td >
                                <td className="px-3 py-2 text-end">
                                    <CustomCurrency value={totalCredit} />
                                </td>
                                <td className="px-3 py-2 text-end" >
                                    <CustomCurrency value={totalBalance} />
                                </td>
                            </tr>
                        </tbody>
                    </table> */}
                    {data.map((item, index) => (
                        <div key={index} className="border rounded-xl p-3 bg-white flex flex-col items-center justify-center gap-3 shadow-md">
                            <p>{item.name}</p>
                            <p className="font-semibold text-xl md:text-2xl">
                                <CustomCurrency value={item.ballance} />
                            </p>
                        </div>
                    ))}

                </div>
            </LayoutDashboard>
        </>
    )
}

export default FinancialReport